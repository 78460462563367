import "../Testride/test-ride.css";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/common/layout/Footer";
import { useScrollTo } from "../../hooks/useScrollTo";

const Payment = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();

  return (
    <>
      <div className="height_one_csrol">
        <div id="payment" className="top_header_pm">
          <div className="payment_secmain">
            <div className="payment_cont">
              <h2>Payment Gateway</h2>
              <div className="next_btn">
                <button
                  onClick={() => navigate("/orderconfirmation")}
                  className="default_btn"
                >
                  Make payment
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Payment;
