import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../../components/common/layout/Header";
import "./index.css";
import { useScrollTo } from "../../../hooks/useScrollTo";

const FAQ = () => {
  useScrollTo(0, 0);
  const [display, setDisplay] = useState("none");

  useEffect(() => {
    document
      .querySelector(".dropdown-mob")
      .querySelector(".dropdown-span").textContent =
      "About OXO Models And Colours";
    document.querySelector(".faq_right_sec").onscroll = function () {
      var Scroll_posY = this.scrollTop;
      document
        .querySelectorAll(".dropdown-mob .dropdown-list a")
        .forEach((e) => {
          if (
            Scroll_posY >=
            document.querySelector(e.getAttribute("id")).offsetTop - 530
          ) {
            document
              .querySelector(".dropdown-mob")
              .querySelector(".dropdown-span").textContent = e.textContent;
          }
        });
    };
  }, []);

  useEffect(() => {
    if (document.querySelectorAll("#drop_list .scroller").length) {
      document.querySelectorAll("#drop_list .scroller").forEach((ele) => {
        ele.onclick = function (e) {
          e.preventDefault();
          document
            .querySelector("#drop_list > .scroller.active")
            ?.classList.remove("active");
          this?.classList.add("active");
          document
            .querySelector(".faq_right_sec")
            .scroll({
              top:
                document.querySelector(this.id).offsetTop -
                document.querySelector(".faq_right_sec").offsetTop +
                parseFloat(
                  window
                    .getComputedStyle(document.querySelector(".faq-block"))
                    .paddingTop.replace(/[a-z]/g, "")
                ),
              behavior: "smooth",
            });
        };
      });
    }
  }, []);

  const myFunction = () => {
    if (display == "none") {
      setDisplay("block");
    } else {
      setDisplay("none");
    }
  };

  return (
    <>
      <Header />
      <div className="faq_page">
        <section className="faq-section-banner">
          <div className="faq_container top-section">
            <h1 className="heading-top text-white">
              FREQUENTLY ASKED QUESTIONS
            </h1>
            <p className="text-white">
              We are here to answer all your questions. You can browse the
              frequently asked questions and if you don´t find the answer you
              are looking for, please do not hesitate to e-mail us at
              <a href="mailto:hello@hopelectric.in"> hello@hopelectric.in</a>
            </p>
          </div>
        </section>
        <div className="breadcrumb-section breadcrumbs">
          <div className="faq_container bread">
            <ul className="d-flex align-items-center">
              <li>
                <Link to={"/home"}>Home</Link>
              </li>
              <li>
                <span>FAQ's</span>
              </li>
            </ul>
          </div>
        </div>
        <section className="faq-questions">
          <div className="faq_container">
            <div className="faq_sec">
              <div className="faq_left_sec dropdown-mob">
                <span
                  id="dp-list"
                  className="dropdown-span"
                  onClick={myFunction}
                />
                <div
                  className="left-headers dropdown-list"
                  id="drop_list"
                  style={{ display: display }}
                >
                  <a
                    id="#faq-0"
                    className="scroller active"
                    onClick={myFunction}
                  >
                    About OXO Models And Colours
                  </a>
                  <a id="#faq-1" className="scroller" onClick={myFunction}>
                    Purchase &amp; Booking
                  </a>
                  <a id="#faq-2" className="scroller" onClick={myFunction}>
                    Design &amp; Specifications
                  </a>
                  <a id="#faq-3" className="scroller" onClick={myFunction}>
                    Charging
                  </a>
                  <a id="#faq-4" className="scroller" onClick={myFunction}>
                    Battery
                  </a>
                  <a id="#faq-5" className="scroller" onClick={myFunction}>
                    Battery Safety
                  </a>
                  <a id="#faq-6" className="scroller" onClick={myFunction}>
                    Range
                  </a>
                  <a id="#faq-7" className="scroller" onClick={myFunction}>
                    Connectivity &amp; Specification
                  </a>
                  <a id="#faq-8" className="scroller" onClick={myFunction}>
                    HOP OXO SAFETY
                  </a>
                  <a id="#faq-9" className="scroller" onClick={myFunction}>
                    HOP NURON - Application
                  </a>
                  <a id="#faq-10" className="scroller" onClick={myFunction}>
                    Service
                  </a>
                  <a id="#faq-11" className="scroller" onClick={myFunction}>
                    Warranty
                  </a>
                  <a id="#faq-12" className="scroller" onClick={myFunction}>
                    Data Pack
                  </a>
                  <a id="#faq-13" className="scroller" onClick={myFunction}>
                    Data Renewal
                  </a>
                  <a id="#faq-14" className="scroller" onClick={myFunction}>
                    Fame II subsidy
                  </a>
                  <a id="#faq-15" className="scroller" onClick={myFunction}>
                    State Subsidy
                  </a>
                </div>
              </div>
              <div className="faq_right_sec">
                <div itemScope itemType="https://schema.org/FAQPage">
                  <div className="faq-block" id="faq-0">
                    <h4 className="heading-3">About OXO Models And Colours</h4>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. What are the colours available for OXO ?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            OXO is available in 5 different colours - True
                            Black, Twilight Grey, Electric Yellow, Magnetic
                            Blue, Candy Red
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. How many Models of OXO are available ?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            OXO is currently available in 3 variants named as
                            OXO, OXO Pro and OXO X.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. What are the ride modes available to drive Chetak?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            OXO has 4 ride modes. 3 Forward - Eco, Power and
                            Sports, 1 Reverse mode allows you to gracefully back
                            out of parking slots.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-1">
                    <h4 className="heading-3">Purchase &amp; Booking</h4>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p />
                      <h3 itemProp="name">1. How do I purchase the vehicle?</h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            You can book and purchase the vehicle on the website
                            <a
                              className="imp-links"
                              href="https://oxo.hopelectric.in/"
                            >
                              www.oxo.hopelectric.in/{" "}
                            </a>
                            or by visiting any of our authorised dealers as
                            mentioned on the website. If you book online our
                            Relationship Manager will contact you to take you
                            through the process.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. Is it mandatory to book the vehicle and purchase it?
                        Can't I directly purchase the vehicle at Hop dealerships
                        or online?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            It is advised that you make a booking to purchase
                            the HOP OXO. The booking amount is only INR 999.
                            This will help in expediting your delivery.
                            <br />
                            You can also buy the HOP OXO directly from the
                            dealership if stock is available at the dealership.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. How do I check my order status?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            After you book your vehicle online, you will get a
                            link that helps you track the status of your order
                            by logging into the website.
                            <br />
                            If you have booked a vehicle from the dealership,
                            you can also get updates from your dealership.
                            <br />
                            You can also get updates by messaging Hop CRM team
                            on WhatsApp - at
                            <a className="imp-links" href="tel:+91-80033 80063">
                              +91-80033 80063
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. I would like to buy more than one Vehicle from my
                        booking registration, is it possible?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            As per FAME guidelines only one vehicle can be
                            purchased on one Aadhar No. to avail subsidy
                            benefits. You may book as many vehicles as you like
                            but each vehicle should be purchased and registered
                            on a different Aadhar No.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        5. I don't live in a city where the Hop OXO is currently
                        sold, but I'd like to buy the vehicle and take it to my
                        city. How can I do that?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Buying the Hop OXO from a city where it is currently
                            not sold and taking it to another city may be a
                            challenge due to the state legalities. Also, we do
                            not have an after-sale service for Hop OXO in such
                            cities, which may hinder your experience.
                            <br />
                            In select few cities, we have opened direct-to-home
                            deliveries via a completely online buying process so
                            you may please check that on our website.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        6. My Booking was made in a different name, I want to
                        purchase the vehicle in a different name, how to do it?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Your bookings are allotted to your name and contact
                            details which are also part of your unique profile.
                            In the event, you would still want to go ahead with
                            any changes, feel free to contact Hop Electric
                            helpline on WhatsApp or ask your dealer for the
                            required changes and we will provide a decision on a
                            case-by-case basis. However, once invoiced,
                            ownership details cannot be changed.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        7. Can I purchase the vehicle on EMI? Do you offer
                        financing options?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes. HOP OXO is available for purchase through EMI
                            options through multiple finance partners. Please
                            check the website or contact the dealer for further
                            details.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        8. Can I get Hop OXO on the lease?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>Hop OXO is currently not available on lease.</p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        9. Do I need a licence to ride the Hop OXO as it is an
                        electric vehicle?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes, you will need a valid driver's licence to ride
                            the Hop OXO: The Hop OXO is a high-speed electric
                            motorcycle having a top speed of 95 km/h, which is
                            above the 25 km/h cap for registration and licence.
                            Thus, registration and driving license is
                            compulsory.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        10. Will I get a FAME II subsidy on purchasing the
                        vehicle?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes. However, the subsidy will apply to only one
                            electric vehicle per customer.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        11. Is the booking amount refundable?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes, the booking amount is fully refundable.
                            However, once the final payment for the vehicle has
                            been made, the booking and final payment amount is
                            non-refundable.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        12. What is the delivery timeline/waiting period for the
                        vehicle?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            We have received an overwhelming response for the
                            Hop OXO from our customers, and due to this we have
                            a maximum waiting period of 90 days on some
                            variants. Some of our colours and variants have
                            quicker deliveries however, please look out for the
                            estimated waiting period on the vehicle booking
                            summary page, once you indicate your variant and
                            colour preference.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        13. I would like to exchange my vehicle for the new Hop
                        OXO? / Is there any exchange offer available?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Exchange offers are not rolling out at the moment.
                            Please stay tuned to our social pages to know about
                            such offers!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-2">
                    <h4 className="heading-3">Design &amp; Specifications</h4>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. Is the vehicle and the LCD dashboard rainproof?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes, the display is rated IP 67, which means it is
                            fully water resistant and can be easily driven in
                            rain.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. Can the vehicle be given a regular water wash?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes, Hop OXO can be washed regularly. However, we
                            recommend you get it done only at Hop Electric
                            authorised service stations.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. Can I choose which data service's SIM goes on my
                        dashboard for data and connected services?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            It comes with a pre-installed SIM from a renowned
                            service provider.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. Can I ride the vehicle in stagnant water?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes, you can ride the Hop OXO in stagnant water up
                            to a depth of half a foot, with all safety
                            precautions taken for the rider. You can be assured
                            that all components on the floorboard are IP67
                            rated.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        5. How do I get the best range and energy efficiency out
                        of my Hop OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Here are a few tips to get the best performance out
                            of your Hop OXO.
                          </p>
                          <ul>
                            <li>
                              Do not hold brakes while riding. Though it is a
                              common practice among scooter riders to hold the
                              rear brakes ever so slightly while riding, it
                              should be avoided as it reduces the life of your
                              brakes and burns extra energy in the process, thus
                              reducing your efficiency.
                            </li>
                            <li>
                              Avoid overloading your scooter. The more weight
                              you carry the more energy is used
                            </li>
                            <li>
                              Rapid acceleration and riding at high speeds also
                              use up your battery faster.
                            </li>
                            <li>
                              Maintain the recommended tyre pressure and check
                              frequently. If they are under-inflated, their
                              rolling resistance increases. As a result, more
                              energy is wasted to move your scooter.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        6. Which motor is used in the Hop OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The motor used is an energy efficient low noise BLDC
                            hub motor with 6.3 kW peak power
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        7. Can the Hop OXO be used in hilly areas/regions?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The Hop OXO has a gradeability of 10 degrees which
                            makes it easy to ride over inclined roads.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-3">
                    <h4 className="heading-3">Battery / Charging</h4>
                    <h5 className="heading-4">Charging</h5>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. How much time does it take to charge the Hop OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The charging times for different variants are as
                            under:
                          </p>
                          <ul>
                            <li>
                              Hop OXO: It takes approximately 4 hours to charge
                              from 0-80%, and 5 hours from 0-100%
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. How much energy is consumed to charge the Hop OXO
                        completely? How much does it cost for one full charge of
                        the Hop OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            It takes around 4kWh of electricity to fully charge
                            the Hop OXO. This is equivalent to 4 units of
                            electricity. Assuming the cost of electricity to be
                            7, it would cost you just INR 28 charging your Hop
                            OXO from 0% to 100%.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. Does the Hop OXO come with fast charging?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Fast charging impacts the lifespan of Li-ion
                            batteries and as of now Hop OXO does not support
                            fast charging. We are continuously working on fast
                            charging technology that has the least impact on
                            battery life and would bring it to market at the
                            earliest.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. How do I charge my vehicle at home?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The Hop OXO comes with a 650 W plug-and-play
                            portable charger that can be used to charge a
                            vehicle at any standard 15A charging point.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p />
                      <h3 itemProp="name">
                        5. Do I need a separate metre for the charging unit?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Your Hop OXO charger has a consumption of just 850W.
                            In most cases, there won't be a need to install any
                            additional electricity metre, but it will be
                            dependent on a per-case basis.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        6. Does the charging socket consume any power on
                        standby?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The Hop Electric Qube's charging socket only
                            consumes nominal energy-less than a night lamp in
                            standby mode.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        7. How will I know when the charging is complete?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The charger will switch off after charging, and the
                            registered mobile App will show the true battery
                            charging status as full
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        8. Does the charger make a lot of noise while charging?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>There is absolutely no sound while charging.</p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        9. Is the charger price included with the total cost of
                        the vehicle or do we have to purchase it separately?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The price of the charger is included in the total
                            cost of the vehicle.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        10. Can I use other electric vehicle chargers for
                        charging the Hop OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            No, it is advised to use Hop Electric chargers for
                            charging your Hop OXO for better performance and
                            longer battery life.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        11. How do I know if Hop OXO is available in my city?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            You can check the availability in your city on the
                            booking page by putting the pincode of your city.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-4">
                    {/* <h4 class="heading-3">Battery</h4> */}
                    <h5 className="heading-4">Battery</h5>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. What is the self-drain percentage of the battery
                        pack?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            With the key-off condition, the battery will
                            discharge only 5% in 10 days.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. How is the battery pack protected considering its
                        Li-ion?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The battery is protected by a robust casing to rule
                            out physical damage. Also, the BMS has inbuilt
                            temperature, voltage, current protection system and
                            ensures optimum performance
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. What is the cost of battery replacement once the
                        battery degrades after a few years?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Hop OXO uses the best Lithium-ion battery technology
                            and our battery life is designed to be the same or
                            more than the lifetime of the scooter. However,
                            battery attenuation of 20-30% can be expected around
                            60,000-70,000 km. This can vary based on the riding
                            pattern.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. Which type of Lithium-ion battery is used?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The battery used in the Hop OXO is Lithium-ion with
                            the latest 811 NMC chemistry.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p />
                      <h3 itemProp="name">
                        5. Is an extra battery pack available for the vehicle?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            No, there is no extra battery pack available as of
                            now
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        6. How long does the battery last on a Hop OXO
                        motorcycle?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The Hop OXO lithium-ion battery is designed to last
                            for 1,00,000 kms which normally takes 7 years,
                            provided the user ensures the lithium-ion battery is
                            kept charged above 15% of capacity and used
                            regularly. We offer a warranty of 3/4 years or 50000
                            kms (whichever comes earlier) depending on the
                            variant.
                            <br />
                            <br />
                            In all lithium-ion battery Electric Vehicles the
                            battery capacity, and hence specified range, reduces
                            with time, use and cumulative charge-discharge
                            cycles. A lithium-ion Battery is considered
                            acceptable and within specified life when it has
                            upto 70% capacity of a brand new battery.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-5">
                    <h5 className="heading-4">Battery Safety</h5>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. Will the battery bulge if the vehicle is overcharged?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The battery is not designed to get overcharged. The
                            battery has a smart protection system that will
                            hinder the bulge and monitor battery health
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. During voltage fluctuations or low voltages is it
                        safe to charge and will the battery get charged?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The vehicle has inbuilt voltage fluctuation
                            protection.It is always recommended to use a
                            factory-provided charging cable.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. Can I take out the battery and charge it at home?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            This is not advisable as the batteries are
                            continuously monitored along with other controllers
                            in the vehicle which keeps the batteries safe.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-6">
                    <h4 className="heading-3">Features &amp; Specifications</h4>
                    <h5 className="heading-4">Range</h5>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. What features have been incorporated to address range
                        anxiety?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Hop OXO comes with a highly advanced AI and ML based
                            accurate distance to empty information display on
                            the vehicle cluster which helps you to plan your
                            trips and charging sessions. It will not only
                            display accurate information but also update the
                            data based on your riding pattern, battery health
                            and other parameters. In case you have to go for an
                            unplanned extra mile you can recharge your vehicle
                            at any of the charging stations appearing on the
                            vehicle app. We are continuously trying to bring
                            information about as many charging stations on Hop
                            OXO App to give you maximum options to recharge the
                            vehicle. The plug-and-play portable offboard charger
                            makes it easy to carry and can be used at any 15A
                            charging point, making it highly usable.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. Will the range of the vehicle get affected if the
                        charging is done very frequently?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            No, the range does not get affected by frequent
                            charging.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. Can I drive the vehicle after not using it for a long
                        period, like 2 months?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Absolutely. However, it is strongly recommended to
                            charge the vehicle up to 50% in case it's not going
                            to be used for a long period.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. What will be the range of the battery after it's been
                        used for more than a year?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            It will be till 93% to 95% of the initial capacity.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        5. What is the difference in the range in all variants
                        of the Hop OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            In terms of maximum range, the Hop OXO and Hop OXO-
                            Pro Package can reach up to 140 km and 150 kms per
                            charge respectively (Eco Mode).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-7">
                    <h4 className="heading-3">
                      Connectivity &amp; Specification
                    </h4>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. Can I get an alert if my vehicle is forcefully moved
                        to another location?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes, the Geofencing feature allows you to know the
                            whereabouts of your vehicle. Available only in
                            connectivity features (Pro Pack)
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. How do I connect my smartphone to the Hop OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Your smartphone will be connected to the vehicle
                            through the mobile app. This can be downloaded by
                            scanning the QR code provided in the user manual or
                            links in the website.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. Will it affect the mobile connectivity feature if I
                        change my mobile number?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            You must request the change of registered mobile
                            number at the authorised sales office or dealer
                            office.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. Is it possible for my friend to connect his mobile
                        with my vehicle?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes, it is possible to connect provided he has the
                            necessary information regarding the vehicle and
                            mobile app connectivity.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        5. What is an HMI Controller and how do we use it?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The HMI controller is a 5-way physical switch that
                            helps you navigate easily through various
                            functionalities on the dashboard.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        6. What are the subscription plans and how to avail the
                        same?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Subscription plans are packaged offerings of
                            services and features that enhance the overall ride
                            experience and vehicle ownership for customers. The
                            connected features on Hop OXO vehicles are packaged
                            in the form of the basic and advanced packs for all
                            owners: To avail of this, you will have to purchase
                            the subscription plan by going to the subscriptions
                            section available on the top left hamburger menu on
                            your Hop OXO app. The first 1 year is free for all.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-8">
                    <h4 className="heading-3">Hop OXO SAFETY</h4>
                    {/* <h5 class="heading-4">HOP OXO SAFETY</h5> */}
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. What measures have been taken to keep Hop OXO Safe
                        from thermal incidents?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            While designing the Hop OXO, safety of the rider and
                            vehicle is of paramount consideration. There have
                            been conscious choices made at every step to ensure
                            a safe &amp; incident-free ownership experience.
                          </p>
                          <ul>
                            <li>Use of high quality battery cells</li>
                          </ul>
                          <p>
                            Hop OXO uses high quality advanced chemistry NMC
                            Lithium Ion cells sourced directly from only reputed
                            top-quality manufacturers in the world. We validate
                            cell consistency and use automated sorting and
                            batching prior to battery assembly. Within the
                            battery itself, we use multiple internal safety
                            measures like a Current Interruption Device that
                            isolates the cell in any event of specific
                            malperformance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. Is there any Auto cut In charger to avoid any damage
                        to the battery?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes, The battery as well as Charger are CAN enabled
                            which means there is no overcharging and the battery
                            is safe even if the charger stays plugged in after
                            full charge of battery .
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. Is there any certification of battery safety ?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Yes, OXO Battery is AIS 156 Certified battery has
                            undergone many rigorous tests like 50 Mtr drop, 200
                            Degree flame, vibration tests etc.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. Which material is used to make HOP OXO ?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            OXO chassis are built with SKTM-11 A &amp; HRCS
                            grade Low carbon automotive steel. This provides
                            rigidity along with great dynamic balance.Designed
                            for a 360 Kg payload.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-9">
                    <h4 className="heading-3">HOP NURON - Application</h4>
                    {/* <h5 class="heading-4">HOP NURON - Application</h5> */}
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. What is the Hop OXO App, and what can it be used for?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Hop NURON is a connected vehicle app on google play
                            store and apple store for smartphone users, which
                            provides near real time status on vehicle battery /
                            range, historical information on recent trips, SMS /
                            System alerts, map-based navigation to reach from
                            your current location to vehicle. Riding tips etc.
                            among other features Hop Nuron app can be used by
                            all Hop OXO owners and their emergency/family
                            contacts to keep connected to vehicles from anywhere
                            anytime.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">2. Who can use the Hop OXO App?</h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            A registered Hop OXO vehicle owner with active
                            vehicle-app-specific data plan and emergency
                            contacts added by Hop OXO owner in Hop OXO app can
                            use the app features. The number of emergency
                            contacts and features available to them are limited.
                            This vehicle-app-specific data plan is separate
                            &amp; independent from any existing mobile service
                            provider’s data plan being used by the Customer for
                            their mobile data requirement.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. How can I register and pair a vehicle on the OXO App?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            During a vehicle delivery, dealerships shall guide
                            to download, install, register and pair your vehicle
                            on the Hop OXO App.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-10">
                    <h4 className="heading-3">Warranty and Service</h4>
                    <h5 className="heading-4">Service</h5>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. What is the schedule for free servicing of the Hop
                        OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The first free service of your Hop OXO is scheduled
                            after 1 month from the date of purchase or 1000 km
                            of riding, whichever is earlier. The second free
                            service is scheduled after 5 months from the date of
                            purchase or 4000 km of riding, whichever is earlier.
                            Third free service will be after 12 months from the
                            date of purchase or 10000 km of riding, whichever is
                            earlier and also refer to the Service Schedule
                            Maintenance chart described in the Owner’s Manual.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. What are the consumables that will be covered under
                        servicing?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            As electric vehicles are maintenance free type of
                            vehicles. In consumables we need to replace front
                            and rear brake pads after every 10,000 km’s.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. What is the turnaround time for roadside assistance?
                        How long after I place a request will someone reach the
                        location?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Our roadside assistance will generally get to you in
                            about 45 minutes, but traffic and weather are a few
                            factors beyond our control, which can impact the
                            time.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. Where do I reach out for help? Or how do I connect
                        with the customer service team?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <ul>
                            <li>
                              For any sales or product related inquiries, please
                              contact us by phone or whatsapp at
                              <a
                                className="imp-links"
                                href="tel:+91 8003380063"
                              >
                                +91 8003380063
                              </a>
                            </li>
                            <li>
                              For any service related queries, please contact us
                              by phone or whatsapp at
                              <a
                                className="imp-links"
                                href="tel:+91 8003380031"
                              >
                                +91 8003380031
                              </a>
                            </li>
                            <li>
                              You can also email us at{" "}
                              <a
                                className="imp-links"
                                href="mailto:service@hopelectric.in"
                                target="_blank"
                                style={{ cursor: "pointer" }}
                              >
                                service@hopelectric.in
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        5. Do I get a free service on the purchase of the Hop
                        OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>First three services for OXO are free of cost.</p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        6. Can I get my Hop OXO serviced at any Hop Electric
                        service centre?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            No, the Hop OXO can be serviced only at those Hop
                            Electric dealerships where Hop OXO is retailed at.
                            This is because we have deployed special service
                            set-ups and specially trained service technicians
                            for Hop OXO.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-11">
                    {/* <h4 class="heading-3">WARRANTY</h4> */}
                    <h5 className="heading-4">WARRANTY</h5>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. What is the warranty on Hop OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Hop OXO offers a warranty* of 3 years on Vehicle ,
                            Motor , Battery , charger , Controller .<br />
                            The replacement or repair of parts under warranty,
                            including the lithium-ion battery, shall be at the
                            sole discretion of Hop Electric Ltd. Warranty on
                            auxiliary battery and tyres shall be at the sole
                            discretion of their respective manufacturers.
                            Warranty and any claim arising thereof is subject to
                            the jurisdiction of the courts of Jaipur only.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. What is the warranty on Hop OXO Pro?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Hop OXO PRO offers a warranty* of 3 years on Vehicle
                            , Motor , charger , Controller . and 4 Years or
                            unlimited Kms on Battery *. The replacement or
                            repair of parts under warranty, including the
                            lithium-ion battery, shall be at the sole discretion
                            of Hop Electric Ltd. Warranty on auxillary battery
                            and tyres shall be at the sole discretion of their
                            respective manufacturers. Warranty and any claim
                            arising thereof is subject to the jurisdiction of
                            the courts of <mark>Jaipur only</mark>.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. What is the warranty on the battery of OXO PRO ?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Hop OXO offers a warranty of 50000 km or 4 years
                            (whichever comes earlier) on the product and the
                            Lithium-ion battery. The auxiliary battery has a
                            warranty of 18 months and tyres have a warranty of
                            12 months. Warranty is effective from the date of
                            the Hop OXO sales invoice.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. What is the warranty on the battery of OXO ?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Hop OXO offers a warranty of 30,000 km or 3 years
                            (whichever comes earlier) on the product and the
                            Lithium-ion battery. The auxiliary battery has a
                            warranty of 18 months and tyres have a warranty of
                            12 months. Warranty is effective from the date of
                            the Hop OXO sales invoice.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        5. What is the warranty on Motor of OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            On Both variants HOP OXO and OXO Pro there is a
                            fixed warranty of 3 years.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-12">
                    <h4 className="heading-3">Data Pack and Renewal</h4>
                    <h5 className="heading-4">DATA PACK</h5>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. What is a data pack for Hop OXO? Do I need to have a
                        data pack?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Data Pack subscription is available for connected
                            vehicle, Hop OXO, which provides near real time
                            status on vehicle battery, range, historical
                            information on recent trips, SMS, System alerts,
                            map-based navigation to reach from your current
                            location to vehicle, riding tips etc. among many
                            other features through Hop OXO app available on
                            google play store and apple store. The connected
                            vehicle services on Hop OXO app works through
                            vehicle-app-specific data plans. Purchase of a data
                            pack is not mandatory. It’s entirely at the option
                            of the consumer. If a data pack is not purchased,
                            the Hop OXO App on a customer’s mobile shall be
                            limited to , Owner’s manual and limited in-app
                            functionalities through blue-tooth connectivity with
                            a vehicle (provided the vehicle supports Bluetooth).
                            All other functions and alerts shall not be
                            available.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. I have purchased a Hop OXO, is the data pack included
                        in my purchase? How to purchase a data pack?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The data pack is a separate purchase and not
                            included in the vehicle price of the Hop OXO. Once
                            Hop OXO is booked, data packs can be purchased
                            online by visiting the Add-On section in your
                            booking. If you have not purchased a data pack and
                            your vehicle is already invoiced or delivered, you
                            may visit the website and purchase a data pack by
                            visiting “My Vehicles” and then renew the data pack.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. What are the charges for the data pack? How long is
                        it valid?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The data pack subscription charges as applicable on
                            the date will be displayed on the add-on and my
                            vehicles page. The total amount payable includes
                            GST. Currently, we provide a data pack with a 1 year
                            validity period. For new purchases, the validity
                            period starts from the date of vehicle invoice,
                            whereas for data renewals, it is effective from the
                            date of renewal. The data pack charges are subject
                            to change without any prior notice and prices as
                            applicable on the date and time of invoice shall be
                            applicable.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. I have not purchased data packs during vehicle
                        purchase, how can I buy it now?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            You can buy a data pack online anytime post-purchase
                            by visiting the My Vehicle section for the selected
                            vehicle. In the My Vehicle section of the selected
                            vehicle, click on data renewal and data pack shall
                            get activated within 24 hrs after successful
                            payment.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-13">
                    <h5 className="heading-4">DATA RENEWAL</h5>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. Who would need to renew the data pack?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Data renewal is required for the users who are using
                            Hop OXO app and the data pack purchased during
                            vehicle purchase is either expired or due for expiry
                            in next 30 days as well as for customers who haven’t
                            purchased a data pack subscription during vehicle
                            purchase but wish to buy at a later date.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">2. How to renew a data pack?</h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Log into your account using the registered mobile
                            number. In the My Vehicle section of the selected
                            vehicle, click on “Data renewal” where the status of
                            your current subscription will be displayed, with a
                            provision to make a payment and renew your data pack
                            online.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        3. What are the charges for data renewal?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            The renewal charges as applicable on the date will
                            be displayed on the data renewal page in my vehicles
                            section on the website. The total amount payable
                            includes GST. Payment gateway fees shall be charged
                            extra as per selected mode of payment on the gateway
                            page. The data renewal charges are subject to change
                            without any prior notice.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        4. What is the validity of the subscription renewal?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Currently, a data renewal plan with a validity of
                            365 days is available for renewal. Any new plans
                            with shorter or longer duration introduced or
                            revised in future will be displayed on the “data
                            renewal” page for selection and renewal.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        5. Can I choose a validity period for data subscription?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Currently, the data renewal plan comes with a
                            validity period of 365 days, and you cannot choose a
                            validity period. Any new plans with shorter or
                            longer duration introduced or revised from time to
                            time will be displayed on the “data renewal” page
                            for selection.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        6. I cannot see my data renewal status or renewal option
                        after login in.
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Please ensure to login using the registered mobile
                            number that was used for booking the Hop OXO online.
                            If you are not able to login or do not remember the
                            registered mobile number or no longer have access to
                            the registered mobile number, please contact our
                            customer care at
                            <a
                              className="imp-links"
                              href="mailto:hello@hopelectric.in"
                              target="_blank"
                              style={{ cursor: "pointer" }}
                            >
                              hello@hopelectric.in
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        7. I have renewed my data subscription, but still not
                        able to see the latest data in My Hop OXO app?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            It is not mandatory to use the Hop OXO app or renew
                            the data plan. However, it is recommended to
                            download the My Hop OXO app for accessing the
                            owner's manual.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-14">
                    <h4 className="heading-3">Subsidy</h4>
                    <h5 className="heading-4">FAME II subsidy</h5>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. Is FAME II subsidy available on Hop OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            FAME-II subsidy benefit, as approved by the
                            department of heavy industries, Govt. of India, is
                            available on purchase of the Hop OXO. The FAME-II
                            subsidy amount is displayed in the price breakup at
                            the time of online booking. Please note, the subsidy
                            is available for a customer only on purchase of one
                            electric vehicle (any make, any type of electric
                            vehicle) and subject to approval from the department
                            of heavy industries, Govt. of India. Customers who
                            have already availed subsidy benefit on any electric
                            vehicle (any make, any type of electric vehicle) is
                            not eligible for claiming FAME subsidy on the
                            subsequent electric vehicles purchased. The customer
                            shall provide an undertaking with regard to FAME
                            subsidy. The vehicle is sold subject to such an
                            undertaking. Hop Electric shall not be responsible
                            for the veracity of the said undertaking and any
                            liabilities or consequences arising in case of false
                            undertaking shall be the sole responsibility of the
                            customer. Further, the customer shall indemnify Hop
                            Electric or any of its associated parties against
                            any losses or liabilities incurred by Hop Electric
                            or any of its associated parties in the event of
                            such losses or liabilities arising out of a false
                            undertaking. The final price at the time of invoice
                            is inclusive of approved subsidies and the customer
                            shall make the full payment as on date and time of
                            vehicle invoice.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="faq-block" id="faq-15">
                    <h5 className="heading-4">state subsidy</h5>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        1. What State Govt Subsidy is available on Hop OXO?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            Each State Govt. has its own policy on electric two
                            wheelers. Any State Govt. the subsidy that is
                            provided to Hop Electric Pvt. Ltd. as the
                            manufacturer of Hop OXO, is passed on directly to
                            the buyer in accordance with the applicable State
                            Govt. policies. This would be reflected suitably in
                            the price details under the heading of State Govt.
                            subsidy (wherever applicable). Any State Govt.
                            subsidies such as reduced registration charges or
                            road tax would reflect actual figures under road tax
                            and registration. Other State Govt. subsidies may
                            need to be claimed directly by the buyer from the
                            Govt. in which case they are not shown in the price
                            payable by a buyer. Buyers are advised to check with
                            their respective State Govt. websites on the
                            procedure for claiming subsidy. Terms &amp;
                            Conditions apply. In my state, dealers are asking me
                            to apply for subsidies directly with the Govt.
                            department Some state Governments may offer
                            subsidies on purchase of electric vehicles, where
                            customers have to directly claim the subsidy
                            benefits from the respective state Govt. department
                            after vehicle delivery. In such cases, customers
                            shall submit the claim to the state Government
                            department, as per the defined process in respective
                            state. The documents required shall be provided by
                            the dealership at the time of vehicle delivery.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                      className="faq-inner"
                    >
                      <p></p>
                      <h3 itemProp="name">
                        2. Can I purchase a vehicle in one state and claim the
                        benefit in a different state?
                      </h3>
                      <p />
                      <div
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div itemProp="text">
                          <p>
                            No. The state Government subsidy offered by various
                            state governments makes it mandatory to purchase and
                            register a vehicle in the same state where the
                            customer is residing and getting a vehicle
                            registered.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default FAQ;
