

import UserDetails from "./UserDetails";

import { combineReducers } from "redux";

const reducers = combineReducers(
    {
    //   myNumber:changeTheNumber
    UserDetails
    }
);

export default reducers;