import "../Testride/test-ride.css";
import { useNavigate } from "react-router-dom";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import graphi_bike from "../../assets/images/graphics_bike.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const OrderBikehomeConfirmation = () => {
  
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const date = new Date();
  const estimateMonth = date.getMonth() + 1;
  const estimateYear = date.getFullYear();
  const newDate = new Date(estimateYear, estimateMonth + 3);

  const dateName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const estimate_date =
    dateName[estimateMonth] +
    " - " +
    dateName[newDate.getMonth()] +
    " " +
    newDate.getFullYear();
    
  return (
    <>
      <Header />
      <div className="height_one_csrol">
        <div id="order-bikehome-confirmation" className="top_header_pm">
          <div className="testride_map Confirmation graphics_sec orderbikehome_confirmation">
            <div className="map_left Confirmation_left">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure>
                  <img  src={graphi_bike} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec order_id">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
                <p>Est. Delivery Date: {estimate_date}</p>
              </div>

              <div className="only_mobileshow mobile_show">
                <img  src={popuplogomo} alt="imhvgb" />
                <h3 className="mobile_show">Ride Confirmed</h3>
              </div>

              <h3 className="destop_show">Confirmation</h3>

              <h3 className="mobile_show">
                Your ride has been Booked successfully
              </h3>

              <div className="order_sec_id">
                <p className="destop_show">
                  We have received your order <br />
                  welcome to the hop family
                </p>
                <strong>
                  <span>Order ID :</span> #123-456-789
                </strong>
              </div>
              <div className="next_btn">
                <button
                  onClick={() => navigate("/landingpage")}
                  className="default_btn"
                >
                  Back To home
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default OrderBikehomeConfirmation;
