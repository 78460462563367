import { Routes, Route } from "react-router-dom";
import Footer from "../components/common/layout/Footer";
import MenuList from "../components/common/layout/MenuList";
// import ActiveRides from "../components/common/layout/ActiveRides";
import AlltestRides from "../components/common/layout/AlltestRides";
import AllBookings from "../components/common/layout/AllBookings";
// import Past from "../components/common/layout/AllBookings";
// import Cancelled from "../components/common/layout/BookaVehicles";
import BookAnother from "../components/common/layout/BookAnother";
import BookaVehicles from "../components/common/layout/BookaVehicles";
import MyBookings from "../components/common/layout/MyBookings";
import OrderBike from "../components/common/layout/OrderBike";
import WelcomeUser from "../components/common/layout/WelcomeUser";
import Book from "../pages/Book/book";
import LandingPage from "../pages/LandingPage/landing";
import TermsServices from "../pages/policies/TermsService";
import TestRide from "../pages/Testride/test-ride";
import TestRideMap from "../pages/Testride/testride-map";
import BookTestRide from "../pages/Testride/book-testride";
import Confirmation from "../pages/Testride/confirmation";
import HomeAddressMap from "../pages/Testride/home-address-map";
import HomeBookTestride from "../pages/Testride/home-book-testride";
import HomeConfirmation from "../pages/Testride/home-confirmation";
import Payment from "../pages/Testride/payment";
import Graphics from "../pages/Order/graphics";
import OrderConfirmation from "../pages/Order/order-confirmation";
import OrderCityMap from "../pages/Order/order-city-map";
import OrderCityPayment from "../pages/Order/order-city-payment";
import OrderCityConfirmation from "../pages/Order/order-city-confirmation";
import OrderBikehomeMap from "../pages/Order/order-bikehome-map";
import OrderBikehomeConfirmation from "../pages/Order/order-bikehome-confirmation";
import PrivacyPolicy from "../pages/Testride/privacy-policy";
// import ServiceRequest from "../pages/Service/ServiceRequest";
import Dashboard from "../pages/dashboard/Dashboard";
import Refund from "../pages/policies/Refund";
import whattsapp from "../assets/images/whatsapp.png";
import phone from "../assets/images/phone.png";
import "./routing.css";
import Dashboardorder from "../pages/dashboard/dashboard-order";
import Colorpreferences from "../pages/dashboard/color-preferences";
import Registrationaddress from "../pages/dashboard/registrationaddress";
import Viewreceipts from "../pages/dashboard/viewreceipts";
import Changecolorpreferences from "../pages/dashboard/changecolorpreferences";
import Cancelbooking from "../pages/dashboard/cancelbooking";
import Congrats from "../pages/dashboard/congrats";
import Confirmationdash from "../pages/dashboard/confirmationdash";
import PrivacyPolicys from "../pages/policies/privacyPolicy";
import NewLeadGen from "../pages/Scooter";
import UserDashboard from "../pages/UserDashboard/user-dashboard";
import CancelTestrides from "../pages/Testride/CancelTestrides";
import TestrideRequested from "../pages/Testride/TestrideRequested";
import Event from "../pages/Testride/Event";
import ThanksTr from "../pages/Testride/ThanksTr";
import ThanksTestride from "../pages/Testride/ThanksTestride";
import CancelOrderids from "../pages/Testride/CancelOrderid";
import Forms from "../pages/form-sec/Form";
import DealerLists from "../pages/form-sec/DealerList/DealerList";
import OXO from "../pages/Oxo";
import Login from "../components/common/layout/HamburgerMenu/Login";
import GetInTouch from "../pages/GetInTouch";
import Dealers from "../pages/Dealers";
import ContactUs from "../pages/ContactUs";
import ModifyDashboard from "../pages/dashboard/Modify-dashboard";
// import AboutUsOld from "../pages/AboutUsOld";
import AboutUs from "../pages/AboutUs";
import SmartEnergy from "../pages/SmartEnergy";
import HopLeo from "../pages/HopLeo";
import CareerOne from "../pages/CareerOne";
import FAQ from "../pages/LandingPage/FAQ/FAQ";
import Service from "../pages/Servicepage";
import Community from "../pages/Community";
import ProtectedRoute from "./ProtectedRoute";
import BlogSubPage from "../components/CommunityComponents/CommunityBlogComponents/BlogSubPages";
import Enquiry from "../pages/Enquiry";
import ListEnquiries from "../pages/Enquiries";
import ThankYou from "../pages/ThankYou";

const Routing = () => {
  return (
    <>
      <Routes>       
        <Route path="/" element={<LandingPage />} />
        <Route path="/booknow" element={<LandingPage />} />
        <Route exact path="/home" element={<LandingPage />} />
        <Route
          exact
          path="/?utm_source=facebook&utm_medium=CPC&utm_campaign={{campaign.name}}&utm_content=wide_audience_website_traffic"
          element={<LandingPage />}
        />
        <Route exact path="/TermsService" element={<TermsServices />} />
        <Route exact path="/dealership" element={<Forms />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/dealerslist" element={<DealerLists />} />
        <Route exact path="/getInTouch" element={<GetInTouch />} />
        <Route exact path="/dealers" element={<Dealers />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/hop-leo" element={<HopLeo />} />
        <Route exact path="/Career" element={<CareerOne />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/service/:id" element={<Service />} />
        <Route exact path="/Faq" element={<FAQ />} />
        <Route exact path="/career-One" element={<CareerOne />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/smart-energy" element={<SmartEnergy />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicys />} />
        <Route exact path="/scooter" element={<NewLeadGen />} />
        <Route exact path="/oxo" element={<OXO />} />
        <Route exact path="/enquiry" element={<Enquiry />} />
        <Route exact path="/list-enquiries" element={<ListEnquiries />} />
        <Route exact path="thankyou-enquiry" element={<ThankYou />} />
        <Route exact path="thankyou-dealership" element={<ThankYou />} />

        {/* <Route exact path="/community" element={<Community />} />
        <Route
          exact
          path="/community/:community_name"
          element={<Community />}
        />
        <Route
          exact
          path="/community/:community_name/:sub_page"
          element={<Community />}
        />
        <Route
          exact
          path="/community/:community_name/:sub_page/:topic"
          element={<BlogSubPage />}
        /> */}

        {/* Protected Routes */}
        {/* <Route exact path="/servicerequest" element={<ServiceRequest />} /> */}
        <Route path="/" element={<ProtectedRoute />}>
          <Route exact path="/book" element={<Book />} />
          <Route exact path="/testride" element={<TestRide />} />
          <Route exact path="/booktestride" element={<BookTestRide />} />
          <Route exact path="/homeaddressmap" element={<HomeAddressMap />} />
          <Route exact path="/Payment" element={<Payment />} />
          <Route exact path="/graphics" element={<Graphics />} />
          <Route exact path="/graphics/:id" element={<Graphics />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/policy/refund" element={<Refund />} />
          <Route exact path="/Dashboardorder" element={<Dashboardorder />} />
          <Route exact path="/UserDashboard" element={<UserDashboard />} />
          <Route exact path="/UserDashboard" element={<UserDashboard />} />
          <Route exact path="/MenuList" element={<MenuList />} />
          <Route exact path="/AlltestRides" element={<AlltestRides />} />
          <Route exact path="/AllBookings" element={<AllBookings />} />
          <Route exacr path="/BookaVehicles" element={<BookaVehicles />} />
          <Route exact path="/BookAnother" element={<BookAnother />} />
          <Route exact path="/MyBookings" element={<MyBookings />} />
          <Route exact path="/OrderBike" element={<OrderBike />} />
          <Route exact path="/WelcomeUser" element={<WelcomeUser />} />
          <Route exact path="/Event" element={<Event />} />
          <Route exact path="/ThanksTr" element={<ThanksTr />} />
          <Route exact path="/ThanksTestride" element={<ThanksTestride />} />
          <Route exact path="/CancelTestrides" element={<CancelTestrides />} />
          <Route exact path="/Viewreceipts" element={<Viewreceipts />} />

          <Route
            exact
            path="/testridemap/:lat/:lng/:dealerid"
            element={<TestRideMap />}
          />

          <Route
            exact
            path="/booktestride/:userid/:orderid/"
            element={<BookTestRide />}
          />

          <Route
            exact
            path="/booktestride/:userid/:orderid/:ticketid/:pincode"
            element={<BookTestRide />}
          />

          <Route
            exact
            path="/booktestride/:userid/:orderid/:ticketid/:ticketid1/:pincode"
            element={<BookTestRide />}
          />

          <Route
            exact
            path="/booktestride/:pincode"
            element={<BookTestRide />}
          />

          <Route
            exact
            path="/confirmation/:userid/:orderid/:ticketid"
            element={<Confirmation />}
          />

          <Route
            exact
            path="/homebooktestride"
            element={<HomeBookTestride />}
          />

          <Route
            exact
            path="/homeconfirmation"
            element={<HomeConfirmation />}
          />

          <Route
            exact
            path="/homeconfirmation/:ticket_id/:type"
            element={<HomeConfirmation />}
          />

          <Route
            exact
            path="/homeconfirmation/:ticket_id/:type/:date/:time"
            element={<HomeConfirmation />}
          />

          <Route
            exact
            path="/homeconfirmation/:ticket_id/:userid/:orderid/"
            element={<HomeConfirmation />}
          />

          <Route
            exact
            path="/orderconfirmation/:id"
            element={<OrderConfirmation />}
          />

          <Route
            exact
            path="/ordercitymap/:lat/:lng/:dealerid"
            element={<OrderCityMap />}
          />

          <Route
            exact
            path="/ordercitypayment"
            element={<OrderCityPayment />}
          />

          <Route
            exact
            path="/ordercityconfirmation"
            element={<OrderCityConfirmation />}
          />

          <Route
            exact
            path="/orderbikehomemap"
            element={<OrderBikehomeMap />}
          />

          <Route
            exact
            path="/orderbikehomeconfirmation"
            element={<OrderBikehomeConfirmation />}
          />

          {/* <Route
            exact
            path="/servicerequest/:id"
            element={<ServiceRequest />}
          /> */}

          <Route
            exact
            path="/Dashboardorder/:id"
            element={<Dashboardorder />}
          />

          <Route
            exact
            path="/Colorpreferences/:id"
            element={<Colorpreferences />}
          />

          <Route
            exact
            path="/Colorpreferences"
            element={<Colorpreferences />}
          />
          <Route
            exact
            path="/Registrationaddress/:id"
            element={<Registrationaddress />}
          />

          <Route
            exact
            path="/Changecolorpreferences/:id"
            element={<Changecolorpreferences />}
          />

          <Route
            exact
            path="/Changecolorpreferences"
            element={<Changecolorpreferences />}
          />

          <Route exact path="/Cancelbooking/:id" element={<Cancelbooking />} />

          <Route exact path="/Congrats/:id" element={<Congrats />} />

          <Route
            exact
            path="/Confirmationdash"
            element={<Confirmationdash />}
          />

          <Route
            exact
            path="/CancelTestrides/:id"
            element={<CancelTestrides />}
          />

          <Route
            exact
            path="/TestrideRequested"
            element={<TestrideRequested />}
          />

          <Route
            exact
            path="/CancelOrderids/:id"
            element={<CancelOrderids />}
          />

          <Route
            exact
            path="/modifydashboard/:id"
            element={<ModifyDashboard />}
          />
          <Route path="*" element={<LandingPage />} />
        </Route>
      </Routes>
      <Footer />

      <div className="fix_social_sec">
        <div className="icons">
          <a
            rel="noopener"
            data-mobile-target=""
            data-desktop-target=""
            target=""
            href="tel:+918003380063"
            id="tracking_number"
          >
            <img  src={phone} />
          </a>
        </div>
        <div className="icons">
          <a
            rel="noopener"
            data-mobile-target=""
            onClick="ga('send', 'event', 'Whatsapp', 'Whatsapp Contact', 'Whatsapp Contact List')"
            data-desktop-target="_blank"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=918003380063"
          >
            <img  src={whattsapp} />
          </a>
        </div>
      </div>
    </>
  );
};
export default Routing;
