import "../Testride/test-ride.css";
import "../Order/order.css";
import "../dashboard/dashboard.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import Dashor_bike from "../../assets/images/Dashorder_bike.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const Confirmationdash = () => {
  useScrollTo(0, 0);
  return (
    <>
      <Header />
      <div className="height_one_csrol">
        <div id="Confirmationdash" className="top_header_pm">
          <div className="testride_map Confirmation graphics_sec Confirmationdash_page">
            <div className="map_left Confirmation_left dashboard_leftsec">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure>
                  <img  src={Dashor_bike} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec dashboard_rightsec Confirmationdash_right">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
                <p>Est. Delivery Date: Aug - Nov 2022</p>
              </div>

              <figure className="gra_logo mobile_show">
                <img  src={popuplogomo} alt="imhvgb" />
              </figure>

              <h3>Confirmationdash</h3>

              <p>We have received your order welcome to the hop family</p>

              <div className="color_id">
                <h4>
                  <span>Order ID:</span> 0222-NHM780
                </h4>
              </div>

              <div className="orderid_btn">
                <button class="default_btn">Back To home</button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Confirmationdash;
