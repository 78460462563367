import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import PureModal from "react-pure-modal";
import { BeatLoader } from "react-spinners";
import "../Testride/test-ride.css";
import "../Order/order.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import blackimage from "../../assets/images/blackimage/1.png";
import yellowimage from "../../assets/images/yellowimage/1.png";
import redimage from "../../assets/images/redimage/1.png";
import blueimage from "../../assets/images/blueimage/1.png";
import Footer from "../../components/common/layout/Footer";
import { bikeColor } from "../../actions";
import { Config } from "../../utils/apiUrl";
import { getCookie } from "../../utils";
import { useScrollTo } from "../../hooks/useScrollTo";

const Graphics = () => {
  useScrollTo(0, 0);
  const { id } = useParams();
  const navigate = useNavigate();
  const UserDetails = useSelector((state) => state.UserDetails);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [blackActive, setBlackActive] = useState("active");
  const [greyActive, setGreyActive] = useState("");
  const [redActive, setRedActive] = useState("");
  const [blueActive, setBlueActive] = useState("");
  const [yellowActive, setYellowActive] = useState("");
  const [color, setColor] = useState("Midnight Black");
  const [modalName, setModalName] = useState("OXO");
  const [activeOxo, setActiveOxo] = useState("activeBtn");
  const [activeOxoX, setActiveOxoX] = useState("");
  const [oxoModal, setOxoModal] = useState(false);
  const [oxoXModal, setOxoXModal] = useState(false);
  const [image, setImage] = useState(blackimage);
  const [utmSourceBook, setUtmSourceBook] = useState("");
  const [utmMediumBook, setUtmMediumBook] = useState("");
  const [utmCampaignBook, setUtmCampaignBook] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [myBooking, setMyBooking] = useState("");
  const [stateName, setStateName] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [proPrice, setProPrice] = useState("");

  useEffect(() => {
    getDataLocal();
  }, []);

  useEffect(() => {
    if (pinCode) {
      getStateName();
    }
  }, [pinCode]);

  useEffect(() => {
    getBookingData();
  }, []);

  useEffect(() => {
    document.getElementById("grahpic_pg").addEventListener("scroll", (e) => {
      var yAxis = $(".testride_righsec > .order_Launch_edit").offset().top;
      if (yAxis < 200) {
        $(".header").hide();
      } else {
        $(".header").show();
      }
    });
  }, []);

  useEffect(() => {
    if (myBooking?.model_name === "OXO") {
      setColor(myBooking?.color);
      handActiveClass(myBooking?.color);
      setModalName("OXO");
      setActiveOxo("activeBtn");
      setActiveOxoX("");
    } else {
      setActiveOxo("");
      setModalName("OXO X");
      setActiveOxoX("activeBtn");
      setColor(myBooking?.color);
      handActiveClass(myBooking?.color);
    }
  }, [myBooking]);

  useEffect(() => {
    if (stateName) {
      getPriceByState();
    }
  }, [stateName]);

  const getBookingData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const responseBook = await fetch(Config.mybookings, requestOptions);
      if (responseBook) {
        const jsonBook = await responseBook.json();
        let filtered_booking = jsonBook.bookings.filter(
          (item) => item.ticket_id == id
        );
        setMyBooking(filtered_booking[0]);
        setPinCode(filtered_booking[0].pin_code);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStateName = async () => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: `https://api.postalpincode.in/pincode/${pinCode}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        var newdata = JSON.parse(JSON.stringify(response.data));
        setStateName(newdata[0].PostOffice[0].State);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handActiveClass = (colors) => {
    setColor(colors);
    if (colors == "Candy Red") {
      setRedActive("active");
      setBlackActive("");
      setYellowActive("");
      setBlueActive("");
      setGreyActive("");
      setColor("Candy Red");
      setImage(redimage);
    }
    if (colors == "Electric Yellow") {
      setRedActive("");
      setBlackActive("");
      setYellowActive("active");
      setBlueActive("");
      setGreyActive("");
      setColor("Electric Yellow");
      setImage(yellowimage);
    }
    if (colors == "Cyan Blue") {
      setRedActive("");
      setBlackActive("");
      setYellowActive("");
      setGreyActive("");
      setBlueActive("active");
      setColor("Cyan Blue");
      setImage(blueimage);
    }
    if (colors == "Midnight Black") {
      setRedActive("");
      setBlackActive("");
      setBlueActive("");
      setYellowActive("");
      setGreyActive("");
      setBlackActive("active");
      setColor("Midnight Black");
      setImage(blackimage);
    }
    if (colors == "Twilight Grey") {
      setRedActive("");
      setBlackActive("");
      setBlueActive("");
      setYellowActive("");
      setGreyActive("active");
      setColor("Twilight Grey");
      setImage(blackimage);
    }
    dispatch(bikeColor({ bikeColor: color }));
  };

  const getDataLocal = () => {
    if (localStorage.getItem("Source") == "null") {
      setUtmSourceBook("Direct");
    } else {
      setUtmSourceBook(localStorage.getItem("Source"));
    }
    if (localStorage.getItem("Medium") == "null") {
      setUtmMediumBook("Direct");
    } else {
      setUtmMediumBook(localStorage.getItem("Medium"));
    }
    if (localStorage.getItem("Campaign") == "null") {
      setUtmCampaignBook("Direct");
    } else {
      setUtmCampaignBook(localStorage.getItem("Campaign"));
    }
  };

  const payment = async () => {
    setLoader(true);
    const requestOptionsForOtpVarification = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserDetails.OrderToken,
      },
      body: JSON.stringify({
        phone_num: `${UserDetails.phoneNum}`,
        email: `${UserDetails.userEmail}`,
        name: `${UserDetails.userName}`,
        pin_code: `${UserDetails.pincode}`,
        dealer_id: "",
        address: ``,
        alt_num: ``,
        model: `${modalName}`,
        source: `OXO website (Source: ${utmSourceBook}, Medium: ${utmMediumBook}, Campaign: ${utmCampaignBook})`,
        user_id: `${UserDetails.userId}`,
        landmark: "null",
        color: `${color}`,
        flag: "admin",
      }),
    };
    const ConfirmOtpUrl = Config.pre_booking_ticket;
    try {
      const response = await fetch(
        ConfirmOtpUrl,
        requestOptionsForOtpVarification
      );
      const json = await response.json();
      if (json.ticket_id != undefined) {
        setLoader(false);
        navigate("/orderconfirmation");
      }
    } catch (error) {
      return false;
    }
  };

  const getPriceByState = async () => {
    const sendData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state: stateName,
      }),
    };
    try {
      const response = await fetch(Config.get_price_all, sendData);
      const json = await response.json();
      if (json != undefined) {
        setBasePrice(json[0]);
        setProPrice(json[1]);
      }
    } catch (error) {
      return false;
    }
  };

  const handleActiveClassModalBtn = (modals) => {
    if (modals == "oxo") {
      setModalName("OXO");
      setActiveOxo("activeBtn");
      setActiveOxoX("");
    }
    if (modals == "oxox") {
      setActiveOxo("");
      setModalName("OXO X");
      setActiveOxoX("activeBtn");
    }
  };

  const openPrizeModal = () => {
    if (modalName == "OXO") {
      setOxoModal(true);
      setOxoXModal(false);
    }
    if (modalName == "OXO X") {
      setOxoXModal(true);
      setOxoModal(false);
    }
  };

  const date = new Date();
  const estimateMonth = date.getMonth() + 1;
  const estimateYear = date.getFullYear();
  const newDate = new Date(estimateYear, estimateMonth + 3);

  const dateName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const estimate_date =
    dateName[estimateMonth] +
    " - " +
    dateName[newDate.getMonth()] +
    " " +
    newDate.getFullYear();

  return (
    <>
      <div className="height_one_csrol" id="grahpic_pg">
        <div id="graphics" className="top_header_pm">
          <div className="testride_map Confirmation graphics_sec">
            <div className="map_left Confirmation_left">
              <figure>
                <img src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure className="gra_logo">
                  <img src={popuplogomo} alt="imhvgb" />
                </figure>

                <figure>
                  <img rc={image} alt="imhvgb" />
                </figure>
              </div>
            </div>
            <div className="testride_righsec graphics_rigth">
              <div className="logo_right_cont destop_show">
                <img src={logo_right} alt="imhvgb" />
              </div>
              <div className="only_mobileshow mobile_show">
                <img src={popuplogomo} alt="imhvgb" />
                <h3 className="mobile_show">Ride Confirmed</h3>
              </div>
              <h3 className="mobile_show">
                Your ride has been Booked successfully
              </h3>

              <div className="btn_banner Pur_Pote_btn">
                <button
                  className={`default_btn btn_pur ${activeOxo}`}
                  onClick={(e) => handleActiveClassModalBtn("oxo")}
                >
                  OXO
                </button>
                <button
                  className={`default_btn btn_pot ${activeOxoX}`}
                  onClick={(e) => handleActiveClassModalBtn("oxox")}
                >
                  OXO (Pro Package)
                </button>
              </div>

              <div className="order_Launch_edit">
                <p>Introductary Price</p>
                <span>
                  Rs{" "}
                  {modalName == "OXO"
                    ? basePrice.effective_on_road_price
                    : proPrice.effective_on_road_price}
                  .00
                </span>
              </div>

              <div className="incentives_sec">
                <span onClick={openPrizeModal} style={{ cursor: "pointer" }}>
                  View Price Breakup
                </span>

                <PureModal
                  onClose={() => {
                    setOxoModal(false);
                  }}
                  isOpen={oxoModal}
                  closeButtonPosition="top"
                >
                  <div className="learn_more_popupcont">
                    <div className="price_sec">
                      <ul>
                        <li>Ex Showroom Price (Without Fame II)</li>
                        <li>
                          {" "}
                          ₹ {basePrice.ex_showroom_price_including_gst}.00
                        </li>
                      </ul>
                      <ul>
                        <li>Charger</li>
                        <li> ₹ {basePrice.govt_subsidy}.00</li>
                      </ul>
                      <ul>
                        <li>Pro Package</li>
                        <li> ₹ {basePrice.insurrance_2}.00</li>
                      </ul>
                      <ul>
                        <li>Total Price</li>
                        <li>
                          ₹ {basePrice.net_payable_without_govt_subsidy}.00
                        </li>
                      </ul>
                      <ul>
                        <li>Less Additional Subsidy From State Government</li>
                        <li>₹ {basePrice.fame_subsidy}.00</li>
                      </ul>
                      <ul>
                        <li>Effective Price*</li>
                        <li>₹ {basePrice.effective_on_road_price}.00</li>
                      </ul>

                      <ul>
                        <li>Registration Cost</li>
                        <li>₹ {basePrice.registration_on_cost}.00</li>
                      </ul>
                      <ul>
                        <li>Handling Charges</li>
                        <li>₹ {basePrice.municipal_tax}.00</li>
                      </ul>
                      <ul>
                        <li>Insurance Cost**</li>
                        <li>₹ {basePrice.first_year}.00</li>
                      </ul>
                    </div>
                  </div>
                </PureModal>
                <PureModal
                  onClose={() => {
                    setOxoXModal(false);
                  }}
                  isOpen={oxoXModal}
                  closeButtonPosition="top"
                >
                  <div className="learn_more_popupcont">
                    <div className="price_sec">
                      <ul>
                        <li>Ex Showroom Price (Without Fame II)</li>
                        <li>
                          {" "}
                          ₹ {proPrice.ex_showroom_price_including_gst}.00
                        </li>
                      </ul>
                      <ul>
                        <li>Charger</li>
                        <li> ₹ {proPrice.govt_subsidy}.00</li>
                      </ul>
                      <ul>
                        <li>Pro Package</li>
                        <li> ₹ {proPrice.insurrance_2}.00</li>
                      </ul>
                      <ul>
                        <li>Total Price</li>
                        <li>
                          ₹ {proPrice.net_payable_without_govt_subsidy}.00
                        </li>
                      </ul>
                      <ul>
                        <li>Less Additional Subsidy From State Government</li>
                        <li>
                          ₹ {proPrice.net_payable_without_govt_subsidy}.00
                        </li>
                      </ul>
                      <ul>
                        <li>Effective Price*</li>
                        <li>₹ {proPrice.effective_on_road_price}.00</li>
                      </ul>

                      <ul>
                        <li>Registration Cost</li>
                        <li>₹ {proPrice.registration_on_cost}.00</li>
                      </ul>
                      <ul>
                        <li>Handling Charges</li>
                        <li>₹ {proPrice.municipal_tax}.00</li>
                      </ul>
                      <ul>
                        <li>Insurance Cost**</li>
                        <li>₹ {proPrice.first_year}.00</li>
                      </ul>
                    </div>
                  </div>
                </PureModal>
              </div>

              <div className="graphics_colors color_step">
                <h3>Graphics</h3>

                <form>
                  {modalName == "OXO" ? (
                    <div className="form-check">
                      <input
                        className={`form-check-input ${blackActive}`}
                        onClick={() => handActiveClass("Midnight Black")}
                        type="radio"
                        name="color_btn"
                        id="Black"
                      />
                      <label className="form-check-label" for="Black">
                        True Black
                      </label>
                    </div>
                  ) : (
                    <>
                      <div className="form-check">
                        <input
                          className={`form-check-input ${blackActive}`}
                          onClick={() => handActiveClass("Midnight Black")}
                          type="radio"
                          name="color_btn"
                          id="Black"
                        />
                        <label className="form-check-label" for="Black">
                          True Black
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className={`form-check-input ${greyActive}`}
                          onClick={() => handActiveClass("Twilight Grey")}
                          type="radio"
                          name="color_btn"
                          id="Grey"
                        />
                        <label className="form-check-label" for="Black">
                          Twilight Grey
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className={`form-check-input ${yellowActive}`}
                          onClick={() => handActiveClass("Electric Yellow")}
                          type="radio"
                          name="color_btn"
                          id="Yellow"
                        />
                        <label className="form-check-label" for="Yellow">
                          Electric Yellow
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className={`form-check-input ${blueActive}`}
                          onClick={() => handActiveClass("Cyan Blue")}
                          type="radio"
                          name="color_btn"
                          id="Blue"
                        />
                        <label className="form-check-label" for="Blue">
                          Magnetic Blue
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className={`form-check-input ${redActive}`}
                          onClick={() => handActiveClass("Candy Red")}
                          type="radio"
                          name="color_btn"
                          id="Red"
                        />
                        <label className="form-check-label" for="Red">
                          Candy Red
                        </label>
                      </div>
                    </>
                  )}
                </form>
              </div>

              <div
                className="react_form_group by_continuing"
                style={{ marginBottom: "10px" }}
              >
                <p>Est. Delivery Date: {estimate_date}</p>
              </div>

              {loader == false ? "" : <BeatLoader color="#36d7b7" />}
              <div className="next_btn">
                <button onClick={payment} className="default_btn">
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Graphics;
