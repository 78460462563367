import { useState, useEffect } from "react";
import "react-pure-modal/dist/react-pure-modal.min.css";
import PureModal from "react-pure-modal";
import { useNavigate } from "react-router-dom";
import { Config } from "../../../utils/apiUrl";
import res_chedule from "../../../assets/images/Reschedule.png";
import cnl_icon from "../../../assets/images/Cancel.png";
import infor_icon from "../../../assets/images/Information.png";
import Select from "react-select";
import moment from "moment";
import { toast } from "react-toastify";
import { POST_cancelServiceTicketAPI } from "../../../APIs";
import ReactPaginate from "react-paginate";
import "./activityPaginate.css";
import { getCookie } from "../../../utils";
import { useScrollTo } from "../../../hooks/useScrollTo";

function AlltestRides() {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const [showcancel, opencancel] = useState(false);
  const [cancelSingleTestPopup, setCancelSingleTestPopup] = useState(false);
  const [showSingleTestPopup, setShowSingleTestPopup] = useState(false);
  const [showSingleServicePopup, setShowSingleServicePopup] = useState(false);
  const [serviceCancelPopup, setServiceCancelPopup] = useState(false);

  const [preBookdetail, setPreBookdetail] = useState(false);
  const [tableMapData, setTableMapData] = useState([]);
  const [myBookingData, setMyBookingData] = useState(false);
  const [testDriveData, setTestDriveData] = useState(false);
  const [myServiceData, setMyServiceData] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "Booked Vehicles",
    label: "Booked Vehicles",
  });
  const [singleBookingData, setSingleBookingData] = useState([]);
  const [singleTestDriveData, setSingleTestDriveData] = useState([]);
  const [singleServiceData, setSingleServiceData] = useState([]);

  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const options = [
    { value: "Booked Vehicles", label: "Booked Vehicles" },
    { value: "Test Drives", label: "Test Drives" },
    { value: "Service", label: "Service" },
  ];

  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      navigate("/");
    }
  };

  useEffect(() => {
    getCookiedata("userdata");
  }, []);

  useEffect(() => {
    if (selectedOption.value == "Booked Vehicles") {
      getBookingData();
    } else if (selectedOption.value == "Test Drives") {
      getTestDriveData();
    } else if (selectedOption.value == "Service") {
      getServiceData();
    }
  }, [selectedOption]);

  const getBookingData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const responseBook = await fetch(Config.mybookings, requestOptions);
      if (responseBook) {
        const jsonBook = await responseBook.json();
        let bookings = jsonBook.bookings;
        bookings.sort((a, b) => new Date(b.date) - new Date(a.date));
        setTableMapData(bookings);
        setMyBookingData(true);
        setTestDriveData(false);
        setMyServiceData(false);
      }
    } catch (error) {
      // console.log(error);
      setTableMapData([]);
      setMyBookingData(true);
      setTestDriveData(false);
      setMyServiceData(false);
    }
  };

  const getTestDriveData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const responseTestDrive = await fetch(
        Config.my_test_drives,
        requestOptions
      );
      if (responseTestDrive) {
        const jsonTestDrive = await responseTestDrive.json();
        let test_Drives = jsonTestDrive;
        test_Drives.sort((a, b) => new Date(b.date) - new Date(a.date));
        setTableMapData(test_Drives);
        setMyBookingData(false);
        setTestDriveData(true);
        setMyServiceData(false);
      }
    } catch (error) {
      setTableMapData([]);
      setMyBookingData(false);
      setTestDriveData(true);
      setMyServiceData(false);
    }
  };

  const getServiceData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const responseService = await fetch(Config.myServiceAPI, requestOptions);
      if (responseService.status == 200) {
        const jsonService = await responseService.json();
        let services = jsonService;
        services.sort((a, b) => new Date(b.date) - new Date(a.date));
        setTableMapData(services);
        setMyBookingData(false);
        setTestDriveData(false);
        setMyServiceData(true);
      } else {
        setTableMapData([]);
        setMyBookingData(false);
        setTestDriveData(false);
        setMyServiceData(true);
        toast.error("Something went wrong...", { autoClose: 2000 });
      }
    } catch (error) {
      setTableMapData([]);
      setMyBookingData(false);
      setTestDriveData(false);
      setMyServiceData(true);
      toast.error("Something went wrong...", { autoClose: 2000 });
    }
  };

  const rescheduleTestDrive = (value) => {
    if (value.category == "Home Testride") {
      navigate(
        `/booktestride/Home/${Number(
          value?.ticket_id
        )}/homeconfirmation/${Number(value?.pin_code)}`
      );
    } else {
      navigate(
        `/booktestride/Dealer/${Number(value?.ticket_id)}/${
          value.dealer_username
        }/homeconfirmation/${Number(value?.pin_code)}`
      );
    }
  };

  const cancelTestRides = (ticket_id) => {
    opencancel(false);
    navigate(`/CancelTestrides/${ticket_id}`);
  };

  const modifyBooking = (ticket_id) => {
    opencancel(false);
    navigate(`/modifydashboard/${ticket_id}`);
  };

  const cancelServiceTicket = async (ticket_id) => {
    let response = await POST_cancelServiceTicketAPI(ticket_id);
    if (response) {
      toast.success(`Service Ticket ${ticket_id} cancelled Successfully.`, {
        autoClose: 1500,
      });
    }
    setServiceCancelPopup(false);
    getServiceData();
    // navigate(`/modifydashboard/${ticket_id}`);
  };

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((data, index) => (
            <tr>
              <td>
                <span>Date: </span>
                {myBookingData && moment(data.date).format("DD-MM-YYYY")}{" "}
                {testDriveData &&
                  moment(data.booking_date).format("DD-MM-YYYY")}{" "}
                {myServiceData && moment(data.date).format("DD-MM-YYYY")}
              </td>
              <td className="order_co">
                <span>Reference No: </span>
                {myServiceData || myBookingData
                  ? "Ticket Id:"
                  : "Order Id:"}{" "}
                {data.ticket_id}
              </td>
              <td>
                <span>Activity: </span>
                {myBookingData ? "Pre Booking" : ""}{" "}
                {testDriveData ? "Test Drive" : ""}{" "}
                {myServiceData ? "Service Request" : ""}
              </td>
              <td className="icon_sec">
                <span>Action: </span>

                {myBookingData && (
                  <button
                    className="cancel_btn infor_tool_main"
                    onClick={() => (
                      setPreBookdetail(true), setSingleBookingData(data)
                    )}
                  >
                    <img src={infor_icon} alt="reschedule" />
                    <span className="info_tooltip">Information</span>
                  </button>
                )}

                {testDriveData && (
                  <>
                    {/* //myLogic */}
                    {data.status !== 3
                      ? data.status === 1 &&
                        (moment(new Date()).format("YYYY-MM-DD") <
                        moment(new Date(data?.testdrive_date)).format(
                          "YYYY-MM-DD"
                        ) ? (
                          <button
                            className="cancel_btn infor_tool_main"
                            onClick={() => {
                              rescheduleTestDrive(data);
                            }}
                          >
                            <img src={res_chedule} alt="reschedule" />
                            <span className="info_tooltip">Reschedule</span>
                          </button>
                        ) : moment(new Date()).format("YYYY-MM-DD") <=
                            moment(new Date(data?.testdrive_date)).format(
                              "YYYY-MM-DD"
                            ) &&
                          (moment(new Date()).format("HH:mm") <
                          data?.time_slot.startsWith("9AM")
                            ? "08:00"
                            : data?.time_slot.startsWith("12PM")
                            ? "11:00"
                            : "14:00") ? (
                          <button
                            className="cancel_btn infor_tool_main"
                            onClick={() => {
                              rescheduleTestDrive(data);
                            }}
                          >
                            <img src={res_chedule} alt="reschedule" />
                            <span className="info_tooltip">Reschedule</span>
                          </button>
                        ) : null)
                      : null}

                    <button
                      className="cancel_btn infor_tool_main"
                      onClick={() => (
                        setShowSingleTestPopup(true),
                        setSingleTestDriveData(data)
                      )}
                    >
                      <img src={infor_icon} alt="reschedule" />
                      <span className="info_tooltip">Information</span>
                    </button>
                    {data.status === 1 && (
                      <button
                        className="cancel_btn infor_tool_main"
                        onClick={() => (
                          setCancelSingleTestPopup(true),
                          setSingleTestDriveData(data)
                        )}
                      >
                        <img src={cnl_icon} alt="reschedule" />
                        <span className="info_tooltip">Cancel</span>
                      </button>
                    )}
                  </>
                )}

                {myServiceData && (
                  <>
                    <button
                      className="cancel_btn infor_tool_main"
                      onClick={() => (
                        setShowSingleServicePopup(true),
                        setSingleServiceData(data)
                      )}
                    >
                      <img src={infor_icon} alt="reschedule" />
                      <span className="info_tooltip">Information</span>
                    </button>
                    {data.status === 1 && (
                      <button
                        className="cancel_btn infor_tool_main"
                        onClick={() => (
                          setServiceCancelPopup(true),
                          setSingleServiceData(data)
                        )}
                      >
                        <img src={cnl_icon} alt="reschedule" />
                        <span className="info_tooltip">Cancel</span>
                      </button>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
      </>
    );
  }

  let itemsPerPage = 5;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = tableMapData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(tableMapData?.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % tableMapData.length;

    setCurrentPage(event.selected);
    setItemOffset(newOffset);
  };

  const handleSelectedOption = (event) => {
    setSelectedOption(event);
    setCurrentPage(0);
    setItemOffset(0);
  };

  return (
    <>
      <div className="active_rides active_sec">
        <div className="filter_sec">
          <div className="dashboard_heading">
            <h2>My Activities</h2>
          </div>
          <div className="data_filter" style={{ zIndex: 99999999 }}>
            <Select
              isSearchable={false}
              value={selectedOption}
              options={options}
              onChange={(e) => handleSelectedOption(e)}
            />
          </div>
        </div>

        <div className="table_section_inforsection">
          <table className="user_info_table recent_acti">
            <thead style={{ position: "sticky", top: 0, zIndex: 9999 }}>
              <tr>
                <th>Date</th>
                <th>Reference No</th>
                <th>Activity</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {
                tableMapData && <Items currentItems={currentItems} />
                // ))
              }
              {tableMapData.length == 0 ? (
                <tr colspan="4" style={{ color: "#fff" }}>
                  No Data Found
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        {tableMapData.length > 5 ? (
          <ReactPaginate
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            forcePage={currentPage}
            previousLabel="Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            nextClassName="page-item"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            renderOnZeroPageCount={null}
          />
        ) : null}

        <div className="das_showlink">
          <ul>
            <li>
              <button
                className="default_btn"
                onClick={() => {
                  navigate("/booknow");
                }}
              >
                Book A Vehicle
              </button>
            </li>
          </ul>
        </div>

        <div
          style={{ zIndex: 999999999, position: "fixed" }}
          className="form-group modal_popup test_ride_popup form-group modal_popup close_button dasboard_cancel singleBook"
          id="cancel_testride"
        >
          <PureModal
            onClose={() => {
              setPreBookdetail(false);
              return true;
            }}
            isOpen={preBookdetail}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change reschedule_info requested">
              <h2>Booking Details </h2>
              <ul>
                <li>
                  <strong>Order Id: </strong>
                  {singleBookingData.order_id}
                </li>
                <li>
                  <strong>Ticket Id: </strong>
                  {singleBookingData.ticket_id}
                </li>
                <li>
                  <strong>Created Date: </strong>
                  {moment(singleBookingData.date).format("DD-MM-YYYY")}
                </li>
                {/* <li>
                  <strong>Delivery Date: </strong>
                  {singleBookingData.est_delivery_date}
                </li> */}
                <li>
                  <strong>Model Name: </strong>
                  {singleBookingData.model_name}
                </li>
                <li>
                  <strong>Color: </strong>
                  {singleBookingData.color}
                </li>
                {/* <li>
                  <strong>City: </strong>
                  {singleBookingData.city}
                </li> */}
                <li>
                  <strong>Name: </strong>
                  {singleBookingData.name}
                </li>
                <li>
                  <strong>Phone Number: </strong>
                  {singleBookingData.phone_num}
                </li>
                <li>
                  <strong>Email Id: </strong>
                  {singleBookingData.email}
                </li>
                <li>
                  <strong>Pin Code: </strong>
                  {singleBookingData.pin_code}
                </li>
                <li>
                  <strong>Remark: </strong>
                  {singleBookingData.remark}
                </li>
                {singleBookingData.status !== 3 ? null : (
                  <li>
                    <strong>Status: </strong>
                    {singleBookingData.status == 3 ? "Cancelled" : null}
                  </li>
                )}
              </ul>
              {singleBookingData.status == 3 ? null : (
                <div className="reschedule_infobtn">
                  <button
                    className="user_btn border_btn"
                    onClick={() => {
                      modifyBooking(singleBookingData.ticket_id);
                    }}
                  >
                    Modify Booking
                  </button>
                </div>
              )}
            </div>
          </PureModal>
        </div>

        <div
          style={{ zIndex: 999999999, position: "fixed" }}
          className="form-group modal_popup test_ride_popup form-group modal_popup close_button dasboard_cancel"
          id="cancel_testride"
        >
          <PureModal
            onClose={() => {
              setShowSingleTestPopup(false);
              return true;
            }}
            isOpen={showSingleTestPopup}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change reschedule_info requested">
              <h2>Test Drives </h2>
              <p className="location_ali">
                <span>Location Details</span>
                {singleTestDriveData.category == "Home Testride"
                  ? singleTestDriveData.address
                  : singleTestDriveData.dealer_address}
              </p>
              <p>
                <span>Phone No:</span> {singleTestDriveData.phone_num}
              </p>
              <p>
                <span>Test Drive Date:</span>{" "}
                {moment(singleTestDriveData.testdrive_date).format(
                  "DD-MM-YYYY"
                )}
              </p>
              <p>
                <span>Preferred Slot:</span> {singleTestDriveData.time_slot}
              </p>
              {singleTestDriveData.status == 3 ? (
                <p>
                  <span>Status:</span>{" "}
                  {singleTestDriveData.status == 3 ? "Cancelled" : null}
                </p>
              ) : null}

              {(singleTestDriveData.status === 1 &&
                moment(singleTestDriveData.testdrive_date).format(
                  "DD-MM-YYYY"
                ) > moment(new Date()).format("DD-MM-YYYY")) ||
              (moment(singleTestDriveData.testdrive_date).format(
                "DD-MM-YYYY"
              ) == moment(new Date()).format("DD-MM-YYYY") &&
                moment(new Date()).format("HH:mm") <
                  (singleTestDriveData.time_slot == "9AM - 12PM"
                    ? "08:00"
                    : singleTestDriveData.time_slot == "12PM - 3PM"
                    ? "11:00"
                    : "14:00")) ? (
                <div className="reschedule_infobtn">
                  <button
                    onClick={() => rescheduleTestDrive(singleTestDriveData)}
                    className="user_btn "
                  >
                    Reschedule
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </PureModal>
        </div>

        <div
          style={{ zIndex: 999999999, position: "fixed" }}
          className="form-group modal_popup test_ride_popup form-group modal_popup close_button dasboard_cancel"
          id="cancel_testride"
        >
          <PureModal
            onClose={() => {
              setCancelSingleTestPopup(false);
              return true;
            }}
            isOpen={cancelSingleTestPopup}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change reschedule_info requested">
              <h2>Cancel your Test Ride </h2>
              <p className="location_ali">
                <span>Location </span>
                {singleTestDriveData.category == "Home Testride"
                  ? singleTestDriveData.address
                  : singleTestDriveData.dealer_address}
              </p>
              <p>
                <span>Test Drive Date:</span>{" "}
                {moment(singleTestDriveData.testdrive_date).format(
                  "DD-MM-YYYY"
                )}
              </p>
              <p>
                <span>PREFERRED SLOT:</span> {singleTestDriveData.time_slot}
              </p>
              <div className="reschedule_infobtn">
                {moment(singleTestDriveData.testdrive_date).format(
                  "DD-MM-YYYY"
                ) > moment(new Date()).format("DD-MM-YYYY") ||
                (moment(singleTestDriveData.testdrive_date).format(
                  "DD-MM-YYYY"
                ) == moment(new Date()).format("DD-MM-YYYY") &&
                  moment(new Date()).format("HH:mm") <
                    (singleTestDriveData.time_slot == "9AM - 12PM"
                      ? "08:00"
                      : singleTestDriveData.time_slot == "12PM - 3PM"
                      ? "11:00"
                      : "14:00")) ? (
                  <button
                    onClick={() => rescheduleTestDrive(singleTestDriveData)}
                    className="user_btn "
                  >
                    Reschedule
                  </button>
                ) : (
                  ""
                )}
                <button
                  className="user_btn border_btn"
                  onClick={() => {
                    cancelTestRides(singleTestDriveData.ticket_id);
                  }}
                >
                  Confirm Cancel
                </button>
              </div>
            </div>
          </PureModal>
        </div>

        <div
          style={{ zIndex: 999999999, position: "fixed" }}
          className="form-group modal_popup test_ride_popup form-group modal_popup close_button dasboard_cancel singleBook"
          id="cancel_testride"
        >
          <PureModal
            onClose={() => {
              setShowSingleServicePopup(false);
              return true;
            }}
            isOpen={showSingleServicePopup}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change reschedule_info requested">
              <h2>Service Request </h2>
              <ul>
                <li>
                  <strong>Ticket Id: </strong>
                  {singleServiceData.ticket_id}
                </li>
                <li>
                  <strong>Name: </strong>
                  {singleServiceData.name}
                </li>
                <li>
                  <strong>Phone Number: </strong>
                  {singleServiceData.phone_num}
                </li>
                <li>
                  <strong>Email Id: </strong>
                  {singleServiceData.email}
                </li>
                <li>
                  <strong>Pin Code: </strong>
                  {singleServiceData.pin_code}
                </li>
                {singleServiceData.query ? (
                  <li>
                    <strong>Query: </strong>
                    {singleServiceData.query}
                  </li>
                ) : null}

                {singleServiceData.vin_num ? (
                  <li>
                    <strong>VIN No.: </strong>
                    {singleServiceData.vin_num}
                  </li>
                ) : null}

                {singleServiceData.purchase_within ? (
                  <li>
                    <strong>Purhcase Within: </strong>
                    {singleServiceData.purchase_within}
                  </li>
                ) : null}
                {singleServiceData.model ? (
                  <li>
                    <strong>Model: </strong>
                    {singleServiceData.model}
                  </li>
                ) : null}

                {singleServiceData.status == 3 ? (
                  <li>
                    <strong>Status: </strong>
                    {singleServiceData.status == 3 ? "Cancelled" : null}
                  </li>
                ) : null}
              </ul>

              {/* <div className="reschedule_infobtn">
                <button
                  onClick={() => rescheduleTestDrive(singleTestDriveData)}
                  className="user_btn "
                >
                  Reschedule
                </button>
              </div> */}
            </div>
          </PureModal>
        </div>

        {/* /cancel service ticket Modal */}
        <div
          style={{ zIndex: 999999999, position: "fixed" }}
          className="form-group modal_popup test_ride_popup form-group modal_popup close_button dasboard_cancel singleBook"
          id="service_cancel"
        >
          <PureModal
            onClose={() => {
              setServiceCancelPopup(false);
              return true;
            }}
            isOpen={serviceCancelPopup}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change reschedule_info requested">
              <h3>Are you sure you want to cancel this service request?</h3>
              {/* <ul>
                <li>
                  <strong>Order Id: </strong>
                  {singleBookingData.order_id}
                </li>
                <li>
                  <strong>Ticket Id: </strong>
                  {singleBookingData.ticket_id}
                </li>
                <li>
                  <strong>Created Date: </strong>
                  {moment(singleBookingData.date).format("DD-MM-YYYY")}
                </li>
                <li>
                  <strong>Delivery Date: </strong>
                  {singleBookingData.est_delivery_date}
                </li>
                <li>
                  <strong>Model Name: </strong>
                  {singleBookingData.model_name}
                </li>
                <li>
                  <strong>Bike Color: </strong>
                  {singleBookingData.color}
                </li>
                <li>
                  <strong>City: </strong>
                  {singleBookingData.city}
                </li>
                <li>
                  <strong>Name: </strong>
                  {singleBookingData.name}
                </li>
                <li>
                  <strong>Phone Number: </strong>
                  {singleBookingData.phone_num}
                </li>
                <li>
                  <strong>Email Id: </strong>
                  {singleBookingData.email}
                </li>
                <li>
                  <strong>Pin Code: </strong>
                  {singleBookingData.pin_code}
                </li>
                <li>
                  <strong>Remark: </strong>
                  {singleBookingData.remark}
                </li>
              </ul>
*/}{" "}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <div className="reschedule_infobtn">
                    <button
                      style={{ width: "unset" }}
                      className="user_btn border_btn"
                      onClick={() => {
                        cancelServiceTicket(singleServiceData.ticket_id);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="reschedule_infobtn">
                    <button
                      style={{ width: "unset", padding: "10px" }}
                      className="user_btn border_btn"
                      onClick={() => {
                        setServiceCancelPopup(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </PureModal>
        </div>
      </div>
    </>
  );
}
export default AlltestRides;
