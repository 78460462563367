import React from 'react';
import ReactDOM from 'react-dom/client'
// import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.min.js";
import './index.css';
import App from './app';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChakraProvider, theme } from '@chakra-ui/react'
import store from "./store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ChakraProvider theme={theme}>
        <Provider store={store}>
            {/* <React.StrictMode> */}
                <App />
            {/* </React.StrictMode> */}
        </Provider>
        <ToastContainer />
    </ChakraProvider>
);



