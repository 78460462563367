import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PureModal from "react-pure-modal";
import { toast } from "react-toastify";
import $ from "jquery";
import "../form-sec/form.css";
import Footer from "../../components/common/layout/Footer";
import { Config } from "../../utils/apiUrl";
import Header from "../../components/common/layout/Header";
import { Helmet } from "react-helmet-async";

const Forms = () => {

  const navigate = useNavigate();
  const [stateList, setStateList] = useState([]);
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [cityList, setCityList] = useState([]);
  const [useremail, setUseremail] = useState("");
  const [username, setUsername] = useState("");
  const [usernumber, setUsernumber] = useState("");
  const [emailerror, setEmailerror] = useState(false);
  const [emailformaterror, setemailformaterror] = useState(false);
  const [nameerror, setNameerror] = useState(false);
  const [numbererror, setNumbererror] = useState(false);
  const [numberlength, setNumberLengtherror] = useState(false);
  const [businesserror, setbussinesserror] = useState(false);
  const [bussniess_role, setbussbniessrole] = useState(false);
  const [bussniess_turnover, setTurnover] = useState(false);
  const [staterror, setSatteerror] = useState(false);
  const [cityerror, setcityerror] = useState(false);
  const [retailerror, setRetailerror] = useState(false);
  const [owner, setOwner] = useState(false);
  const [operate, setoperate] = useState(false);
  const [area, setArea] = useState(false);
  const [invest, setinvest] = useState(false);
  const [userabout, setuserabout] = useState(false);
  const [check, setcheck] = useState(false);
  const [currentbussniess_type, setCurrentbussniesstype] = useState("");
  const [currentbussniess_role, setCurrentbussniessrole] = useState("");
  const [currentbussniess_turnover, setCurrentbussniessturnover] = useState("");
  const [retails, setRetail] = useState("");
  const [owner1, setowner] = useState("");
  const [useroprate, setuserOpreate] = useState("");
  const [areas, setAreas] = useState("");
  const [userinvests, setuserinvest] = useState("");
  const [userabouts, setuserabouts] = useState("");
  const [apimessage, setApimessage] = useState(false);
  const [other, showother] = useState(false);
  const [other1, showother1] = useState(false);
  const [other2, showother2] = useState(false);
  const [otherchange, setOtherchange] = useState("");
  const [otherchange2, setOtherchange2] = useState("");
  const [otherchange3, setOtherchange3] = useState("");
  const [othererror3, setothererror3] = useState(false);
  const [othererror2, setothererror2] = useState(false);
  const [othererror1, setothererror1] = useState(false);
  const [numberexitserroer, setnumberexisterror] = useState(false);
  const [stateerror1, setStateeror1] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    document.getElementById("form").addEventListener("scroll", (e) => {
      let yAxisForHeader = document.querySelector("#UsingForHeader").scrollTop;
      var yAxisCareer = document.getElementById("form").scrollTop;
      if (yAxisForHeader > 10 || yAxisCareer > 10) {
        window.document.querySelector("header").style.display = "none";
      } else {
        window.document.querySelector("header").style.display = "block";
      }
    });
    return () => document.getElementById("form")?.addEventListener("scroll");
  }, []);

  useEffect(() => {
    document
      .getElementById("UsingForHeader")
      .addEventListener("scroll", (e) => {
        let yAxisForHeader =
          document.querySelector("#UsingForHeader").scrollTop;
        let yAxisCareer = document.getElementById("form").scrollTop;
        if (yAxisForHeader > 10 || yAxisCareer > 10) {
          window.document.querySelector("header").style.display = "none";
        } else {
          window.document.querySelector("header").style.display = "block";
        }
      });
    return () =>
      document.getElementById("UsingForHeader")?.addEventListener("scroll");
  }, []);

  useEffect(() => {
    LoadState();
  }, []);

  useEffect(() => {
    document.body.classList.remove("popup-open");
  }, []);

  const LoadState = async () => {
    try {
      let stateData = await fetch(Config.state);
      let json = await stateData.json();
      setStateList(json.states);
    } catch (r) {}
  };

  const stateChange = async (e) => {
    setStateName(e.target.value);
    const sendOtpOrderOptn2 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        state: `${e.target.value}`,
      }),
    };
    try {
      const responses = await fetch(Config.city_state, sendOtpOrderOptn2);
      const city = await responses.json();
      setCityList(city.cities);
    } catch (e) {
      console.log("not fetch City List" + e.message);
    }
  };

  const changeCity = async (e) => {
    setCityName(e.target.value);
  };

  const staterrorshow = () => {
    var sel3 = document.getElementById("state");
    var selectedText3 = sel3.options[sel3.selectedIndex].text;
    if (
      selectedText3 ==
      "Select the State/UT where you are interested to operate the dealership."
    ) {
      setcityerror(false);
      setStateeror1(true);
    } else {
      setStateeror1(false);
    }
  };

  const scroll = () => {
    const section = document.querySelector("#dealer_form");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handlemail = (e) => {
    setUseremail(e.target.value);
  };

  const handlename = (e) => {
    setUsername(e.target.value);
  };

  const onKeyPressAlphaWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (new RegExp("[^A-Z a-z]").test(keyValue)) event.preventDefault();
    return true;
  };

  const handlenumber = (e) => {
    let numberlength = e.target.value.length;
    if (numberlength < 11) {
      setUsernumber(e.target.value);
    }
  };

  const onKeyPressNumberWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const typebussniess = (e) => {
    setCurrentbussniesstype(e.target.value);
    if (e.target.value == "Other") {
      showother(true);
    } else {
      showother(false);
    }
  };

  const bussniessrole = (e) => {
    setCurrentbussniessrole(e.target.value);
    if (e.target.value == "Other") {
      showother1(true);
    } else {
      showother1(false);
    }
  };

  const bussniessturnover = (e) => {
    setCurrentbussniessturnover(e.target.value);
  };

  const reatil = (e) => {
    setRetail(e.target.value);
  };

  const owners = (e) => {
    setowner(e.target.value);
  };

  const operates = (e) => {
    setuserOpreate(e.target.value);
  };

  const userarea = (e) => {
    setAreas(e.target.value);
  };

  const userinvest = (e) => {
    setuserinvest(e.target.value);
  };

  const useraboutdata = (e) => {
    setuserabouts(e.target.value);
    if (e.target.value == "Others") {
      showother2(true);
    } else {
      showother2(false);
    }
  };

  const otherchage1 = (e) => {
    setOtherchange(e.target.value);
  };

  const otherchangevalue2 = (e) => {
    setOtherchange2(e.target.value);
  };

  const otherchangevalue3 = (e) => {
    setOtherchange3(e.target.value);
  };

  const formsubmit = async (e) => {
    e.preventDefault();
    var regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    var sel = document.getElementById("business");
    var selectedText = sel.options[sel.selectedIndex].text;
    var sel1 = document.getElementById("bussniess_role");
    var selectedText1 = sel1.options[sel1.selectedIndex].text;
    var sel2 = document.getElementById("bussiess_turnover");
    var selectedText2 = sel2.options[sel2.selectedIndex].text;
    var sel3 = document.getElementById("state");
    var selectedText3 = sel3.options[sel3.selectedIndex].text;
    var sel4 = document.getElementById("city");
    var selectedText4 = sel4.options[sel4.selectedIndex].text;
    var sel5 = document.getElementById("retail");
    var selectedText5 = sel5.options[sel5.selectedIndex].text;
    var sel6 = document.getElementById("owner");
    var selectedText6 = sel6.options[sel6.selectedIndex].text;
    var sel7 = document.getElementById("operate");
    var selectedtext7 = sel7.options[sel7.selectedIndex].text;
    var sel8 = document.getElementById("area");
    var selectedText8 = sel8.options[sel8.selectedIndex].text;
    var sel9 = document.getElementById("invest");
    var selectedText9 = sel9.options[sel9.selectedIndex].text;
    var sel10 = document.getElementById("about");
    var selectedText10 = sel10.options[sel10.selectedIndex].text;
    var confirm = document.getElementById("exampleRadios1");
    var retturn = true;
    if (useremail == " " || useremail == "" || useremail == null) {
      setEmailerror(true);
      setemailformaterror(false);
      retturn = false;
    } else if (!regex.test(useremail)) {
      setEmailerror(false);
      setemailformaterror(true);
      retturn = false;
    } else {
      setEmailerror(false);
      setemailformaterror(false);
    }

    if (username == " " || username == "" || username == null) {
      setNameerror(true);
      retturn = false;
    } else {
      setNameerror(false);
    }

    if (usernumber == "" || usernumber == " " || usernumber == null) {
      setNumbererror(true);
      setNumberLengtherror(false);
      retturn = false;
    } else if (usernumber.length < 10) {
      setNumberLengtherror(true);
      setNumbererror(false);
      retturn = false;
    }
    else {
      setNumberLengtherror(false);
      setNumbererror(false);
    }

    if (selectedText == "What is the nature of your current business?") {
      setbussinesserror(true);
      retturn = false;
    } else {
      setbussinesserror(false);
    }

    if (selectedText1 == "What is your role in your current business?") {
      setbussbniessrole(true);
      retturn = false;
    } else {
      setbussbniessrole(false);
    }

    if (
      selectedText2 ==
      "What is the turnover of your current business in last financial year (in Lakhs) ?"
    ) {
      setTurnover(true);
      retturn = false;
    } else {
      setTurnover(false);
    }

    if (
      selectedText3 ==
      "Select the State/UT where you are interested to operate the dealership."
    ) {
      setSatteerror(true);
      retturn = false;
    } else {
      setSatteerror(false);
    }

    if (
      selectedText4 ==
      "Which City/Town do you want to open & operate dealership ?"
    ) {
      setcityerror(true);
      setStateeror1(false);
      retturn = false;
    } else {
      setcityerror(false);
    }

    if (selectedText5 == "Do you have prior dealership or retail experience?") {
      setRetailerror(true);
      retturn = false;
    } else {
      setRetailerror(false);
    }

    if (
      selectedText6 ==
      "Do you plan to open Hop Experience Center is owned or rented property?"
    ) {
      setOwner(true);
      retturn = false;
    } else {
      setOwner(false);
    }
    if (
      selectedtext7 ==
      "What kind of Hop experience centre do you want to operate?"
    ) {
      setoperate(true);
      retturn = false;
    } else {
      setoperate(false);
    }

    if (
      selectedText8 ==
      "How much area do you propose for opening Hop experience center"
    ) {
      setArea(true);
      retturn = false;
    } else {
      setArea(false);
    }
    if (
      selectedText9 == "How much can you invest to open Hop experience centre?"
    ) {
      setinvest(true);
      retturn = false;
    } else {
      setinvest(false);
    }
    if (selectedText10 == "Where did you hear about us first ?") {
      setuserabout(true);
      retturn = false;
    } else {
      setuserabout(false);
    }

    if (confirm.checked == false) {
      setcheck(true);
      retturn = false;
    } else {
      setcheck(false);
    }

    if (other) {
      if (!otherchange) {
        setothererror1(true);
        retturn = false;
      } else {
        setothererror1(false);
      }
    }
    if (other1) {
      if (!otherchange2) {
        setothererror2(true);
        retturn = false;
      } else {
        setothererror2(false);
      }
    }
    if (other2) {
      if (!otherchange3) {
        setothererror3(true);
        retturn = false;
      } else {
        setothererror3(false);
      }
    }

    function refreshSelect() {
      $("select").val("");
    }

    if (retturn) {
      const requestOptions1 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: `${useremail}`,
          name: `${username}`,
          phone_number: `${usernumber}`,
          current_bussniess: `${other ? otherchange : currentbussniess_type}`,
          current_bussniess_role: `${
            other1 ? otherchange2 : currentbussniess_role
          }`,
          current_bussniess_turn_over: `${currentbussniess_turnover}`,
          state: `${stateName}`,
          city: `${cityName}`,
          retail_experience: `${retails}`,
          type_of_ownership: `${owner1}`,
          operate: `${useroprate}`,
          area: `${areas}`,
          investment_amount: `${userinvests}`,
          about: `${other2 ? otherchange3 : userabouts}`,
        }),
      };
      try {
        setDisableSubmit(true);
        const response = await fetch(Config.insertdealer, requestOptions1);
        const json = await response.json();
        if (json.msg == "Number Already Exists!") {
          setnumberexisterror(true);
          setApimessage(false);
          setUseremail("");
          setUsername("");
          setUsernumber("");
          setOtherchange("");
          setOtherchange2("");
          setOtherchange3("");
          setStateName("");
          setCityName("");
          showother(false);
          showother1(false);
          showother2(false);
          var confirm = document.getElementById("exampleRadios1");
          confirm.checked = false;
          refreshSelect();
          setDisableSubmit(false);
          setTimeout(() => setnumberexisterror(false), [5000]);
        } else {
          setnumberexisterror(false);
          setDisableSubmit(false);
        }

        if (json.msg == "Dealer Inserted Succesfully") {
          setApimessage(true);
          setUseremail("");
          setUsername("");
          setUsernumber("");
          setOtherchange("");
          setOtherchange2("");
          setOtherchange3("");
          showother(false);
          showother1(false);
          showother2(false);
          setnumberexisterror(false);
          setStateName("");
          setCityName("");
          var confirm = document.getElementById("exampleRadios1");
          confirm.checked = false;
          refreshSelect();
          setDisableSubmit(false);
          navigate(`/thankyou-dealership?name=${username}`);
        } else {
          setDisableSubmit(false);
          setApimessage(false);
        }
      } catch (error) {
        toast.error(error.message, { autoClose: 3500 });
        setDisableSubmit(false);
      }
    }
    return retturn;
  };

  return (
    <>
      <Helmet>
        <title>
          Electric Two Wheelers Dealership for Bikes | Franchise for Electric
          Scooter & Scooty
        </title>
        <meta
          name="title"
          description="Electric Two Wheelers Dealership for Bikes | Franchise for Electric Scooter & Scooty"
        />
        <meta
          name="description"
          content="Explore franchise opportunities and dealership options for electric bikes, scooters and two-wheelers. Start your journey with Hop Electric today"
        />
      </Helmet>

      <Header />
      <div className="height_one_csrol header_hidecss" id="UsingForHeader">
        <div id="form_sec" className="design_effa">
          <div className="form_container" id="form">
            <div className="form_cont">
              <h1>
                The Future is Electric, Become a Part of India’s Fastest Growing
                Network
              </h1>
              <button className="default_btn" onClick={scroll}>
                Join The Revolution
              </button>
              <strong>Why Join HOP Electric?</strong>
              <p>
                We are india's leading electric two-wheeler manufacturer. To
                meet our further expansion plans, we are looking for partners to
                join hands with us. Let's build the future, together.{" "}
              </p>
              <div className="back_color">
                <ul>
                  <li>India’s leading Electric Two-wheeler Brand</li>
                  <li>Preceding in 17+ states with 180+ Dealers</li>
                  <li>Assured Growth & maximum profitability</li>
                </ul>
              </div>
            </div>
            <form id="dealer_form">
              <h2>#CHANGETHEGAME </h2>
              <h3>Become Our Partner</h3>
              <div className="form-group move_label">
                <input
                  type={"Email"}
                  className="form-control"
                  value={useremail}
                  onChange={handlemail}
                  required
                />
                <label>Email*</label>
                {emailerror === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please enter email address*
                  </span>
                ) : null}
                {emailformaterror === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please enter valid email address*
                  </span>
                ) : null}
              </div>

              <div className="form-group move_label">
                <input
                  type="text"
                  className="form-control"
                  value={username}
                  onChange={handlename}
                  onKeyPress={onKeyPressAlphaWanto}
                  required
                />
                <label>Your Name*</label>
                {nameerror === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please enter your name*
                  </span>
                ) : null}
              </div>

              <div className="form-group move_label send_otp">
                <input
                  type={"tel"}
                  className="form-control"
                  value={usernumber}
                  onChange={handlenumber}
                  onKeyPress={onKeyPressNumberWanto}
                  required
                />
                <label>Your Phone Number*</label>
                {numbererror === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please enter phone number*
                  </span>
                ) : null}
                {numberlength === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please enter valid phone number*
                  </span>
                ) : null}
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }} for="buisness">
                  Current Business Nature*
                </label>
                <select
                  className="form-control"
                  id="business"
                  onChange={typebussniess}
                >
                  <option value="">
                    What is the nature of your current business?
                  </option>
                  <option>Manufacturing</option>
                  <option>Services (hotel/real estate/engineering etc)</option>
                  <option>Retail/ Dealer/Distributor</option>
                  <option>
                    I am currently in a job but plan to quit and start a
                    business.
                  </option>
                  <option>Other</option>
                </select>

                {businesserror === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please Select Your Current Business Nature*
                  </span>
                ) : null}

                <div
                  className="form-group move_label other_input_div"
                  style={{ display: other ? "block" : "none" }}
                  value={otherchange}
                  onChange={otherchage1}
                >
                  <input type="text" className="form-control" />
                  <label>Other</label>
                  {othererror1 === true ? (
                    <span className="error pl-4" style={{ color: "red" }}>
                      Please select other*
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }}>Business Role* </label>

                <select
                  className="form-control"
                  id="bussniess_role"
                  onChange={bussniessrole}
                >
                  <option value="">
                    What is your role in your current business?
                  </option>
                  <option>Proprietor / Promoter</option>
                  <option>Director</option>
                  <option>Employee</option>
                  <option>Other</option>
                </select>
                {bussniess_role === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please Select Your Current Business Role*
                  </span>
                ) : null}
                <div
                  className="form-group move_label other_input_div"
                  style={{ display: other1 ? "block" : "none" }}
                  value={otherchange2}
                  onChange={otherchangevalue2}
                >
                  <input type="text" className="form-control" />
                  <label>Other</label>
                  {othererror2 === true ? (
                    <span className="error pl-4" style={{ color: "red" }}>
                      Please select other*
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }}>Business Turn over*</label>
                <select
                  className="form-control"
                  id="bussiess_turnover"
                  onChange={bussniessturnover}
                >
                  <option value="">
                    What is the turnover of your current business in last
                    financial year (in Lakhs) ?
                  </option>
                  <option>less than 20 Lac</option>
                  <option>20 Lac to 50 Lac</option>
                  <option>50 Lac to 1 cr +</option>
                </select>
                {bussniess_turnover === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please select Business turn over of last FI*
                  </span>
                ) : null}
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }}>State*</label>

                <select
                  className="form-control"
                  onChange={stateChange}
                  value={stateName}
                  id="state"
                >
                  <option value="">
                    Select the State/UT where you are interested to operate the
                    dealership.
                  </option>
                  {stateList.map((item, index) => {
                    return (
                      <option value={stateList[index]} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {staterror === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please select state*
                  </span>
                ) : null}
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }}>City*</label>
                <select
                  className="form-control"
                  onChange={changeCity}
                  value={cityName}
                  id="city"
                  onClick={staterrorshow}
                >
                  <option value="">
                    Which City/Town do you want to open & operate dealership ?
                  </option>
                  {cityList.map((item, index) => {
                    return <option>{cityList[index]}</option>;
                  })}
                </select>
                {cityerror === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please select city*
                  </span>
                ) : null}
                {stateerror1 === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please select state first*
                  </span>
                ) : null}
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }}>Retail Experience*</label>
                <select className="form-control" id="retail" onChange={reatil}>
                  <option value="">
                    Do you have prior dealership or retail experience?
                  </option>
                  <option>
                    Yes, I have prior automotive dealership/retail experience.
                  </option>
                  <option>
                    Yes, I have prior dealership/retail experience but not in
                    automotive industry
                  </option>
                  <option>No, I am new to this business</option>
                </select>
                {retailerror === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please select retail experience*
                  </span>
                ) : null}
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }}>Type Of Ownership*</label>
                <select className="form-control" id="owner" onChange={owners}>
                  <option value="">
                    {" "}
                    Do you plan to open Hop Experience Center is owned or rented
                    property?
                  </option>
                  <option>Owned</option>
                  <option>Rented</option>
                </select>
                {owner === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please select type of ownership*
                  </span>
                ) : null}
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }}>Desired Operation*</label>
                <select
                  className="form-control"
                  id="operate"
                  onChange={operates}
                >
                  <option value="">
                    What kind of Hop experience centre do you want to operate?
                  </option>
                  <option>
                    {" "}
                    Master dealership of a scheduled territory with multiple sub
                    dealerships{" "}
                  </option>
                  <option>
                    {" "}
                    Dealership + Service center in a Tier 1, 2 or 3 city.{" "}
                  </option>
                  <option>
                    A sub dealership in small town, tehsil or village.
                  </option>
                </select>
                {operate === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please select your desired operation*
                  </span>
                ) : null}
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }}> Floor Area*</label>
                <select className="form-control" id="area" onChange={userarea}>
                  <option value="">
                    How much area do you propose for opening Hop experience
                    center
                  </option>
                  <option>
                    {" "}
                    Less than 1200 sq ft ( only for sub dealerhsip){" "}
                  </option>
                  <option> 1200-1500 sq ft </option>
                  <option> 1500-2000 sq ft </option>
                  <option>More than 2000 sq ft</option>
                </select>
                {area === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please select floor area*
                  </span>
                ) : null}
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }}>Investment Amount*</label>
                <select
                  className="form-control"
                  id="invest"
                  onChange={userinvest}
                >
                  <option value="">
                    How much can you invest to open Hop experience centre?
                  </option>
                  <option> 20-40 lakhs</option>
                  <option> 40-60 Lakhs </option>
                  <option> 60 Lakhs to 1 Cr</option>
                  <option> 1cr +</option>
                  <option>Less than 20 Lakhs</option>
                </select>
                {invest === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please select investment amount*
                  </span>
                ) : null}
              </div>

              <div className="form-group select_label">
                <label style={{ color: "white" }}>Source*</label>
                <select
                  className="form-control"
                  id="about"
                  onChange={useraboutdata}
                >
                  <option value="">Where did you hear about us first ? </option>
                  <option>Facebook</option>
                  <option>Instagram</option>
                  <option>Youtube</option>
                  <option>Google</option>
                  <option>Twitter</option>
                  <option>Linkedin</option>
                  <option>News/Newspaper</option>
                  <option>Radio</option>
                  <option>Referral</option>
                  <option>Convention/Exibition</option>
                  <option>Hop Employee Referral</option>
                  <option>Others</option>
                </select>
                {userabout === true ? (
                  <span className="error pl-4" style={{ color: "red" }}>
                    Please select where did you about us*{" "}
                  </span>
                ) : null}
                <div
                  className="form-group move_label other_input_div"
                  style={{ display: other2 ? "block" : "none" }}
                  value={otherchange3}
                  onChange={otherchangevalue3}
                >
                  <input type="text" className="form-control" />
                  <label>Other</label>
                  {othererror3 === true ? (
                    <span className="error pl-4" style={{ color: "red" }}>
                      Please select other*
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="form-group form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="check"
                />
                <label className="form-check-label" for="exampleRadios1">
                  I agree to the
                  <Link to="/TermsService"> Terms & Conditions </Link>
                  And
                  <Link to="/privacy-policy"> Privacy policy </Link>
                </label>
                {check === true ? (
                  <span
                    className="error pl-4"
                    style={{ color: "red", width: "100%" }}
                  >
                    Please accept the Terms and Conditions and privacy policy*
                  </span>
                ) : null}
              </div>

              <div className="submit_btn">
                <button
                  className="default_btn"
                  onClick={(Event) => formsubmit(Event)}
                  disabled={disableSubmit}
                >
                  {disableSubmit ? "Submitting..." : "Submit"}
                </button>
              </div>

              <div className="form-group opt_popup">
                <PureModal
                  onClose={() => {
                    setApimessage(false);
                    return true;
                  }}
                  isOpen={apimessage}
                  closeButton={<div className="close_btn"></div>}
                  closeButtonPosition="top"
                  width="100%"
                >
                  <div className="otp_cont">
                    <p>
                      We have received your request. Our team will get back to
                      you soon.
                    </p>
                  </div>
                </PureModal>
              </div>
              <div className="form-group opt_popup">
                <PureModal
                  onClose={() => {
                    setnumberexisterror(false);
                    return true;
                  }}
                  isOpen={numberexitserroer}
                  closeButton={<div className="close_btn"></div>}
                  closeButtonPosition="top"
                  width="100%"
                >
                  <div className="otp_cont">
                    <p>Number Already Exists!</p>
                  </div>
                </PureModal>
              </div>
            </form>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Forms;
