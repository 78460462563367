import React from "react";
import Slider from "react-slick";
import Simplified from "../../../assets/images/Simplified.jpg";
import Charge from "../../../assets/images/Charge.jpg";
import CAN_Enabled from "../../../assets/images/CAN_Enabled.jpg";

export default function SiliderSix() {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
  
        }
      },
    ]
  };
  
  return (
    <div className="slider_show">
      <Slider {...settings}>
        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Simplified} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Simplified Charging</h3>
            <p>
              Charge your Hop OXO with any 16 Amp plug point at office or at home. Everyday motorcyling 
              is simpler than ever. No more visit to petrol pumps.
            </p>
          </div>
        </div>
        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Charge} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Charge time</h3>
            <p>
              0-80% charge in just 3 hours and you get 150 Kms of range. Charge twice a week for a your everyday motorcyling needs.
            </p>
          </div>
        </div>
        <div className="featur_para pop_slideron">
          <figure>
            <img  src={CAN_Enabled } alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>CAN Enabled smart Charger</h3>
            <p>
              You OXO comes with smart charge technology. With CAN enabled smart chargers now
               get the time to charge information on the display or on your OXO mobile application.
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
}
