import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import $ from "jquery";
import "../Testride/test-ride.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import bike_re_conf from "../../assets/images/conf-bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import LoadingSpinner from "../../assets/images/spinner.svg";
import { GET_TestDriveDataAPI } from "../../APIs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useScrollTo } from "../../hooks/useScrollTo";
import { getCookie } from "../../utils";

const CancelOrderid = () => {

  useScrollTo(0, 0);
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [cancelledTestDrive, setCancelledTestDrive] = useState(null);
  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      navigate("/");
    }
  };

  useEffect(() => {
    getCookiedata("userdata");
  }, []);

  useEffect(() => {
    getMyTestDriveData();
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $(".header").show();
    });
    $(window).on("load", function () {
      $(".header").show();
    });
  }, []);

  const getMyTestDriveData = async () => {
    setLoading(true);
    let response = await GET_TestDriveDataAPI();
    let filteredTestDrive = response.filter(
      (item) => (item.ticket_id = params.id)
    );
    setCancelledTestDrive(filteredTestDrive[0]);
    setLoading(false);
  };

  return (
    <>
      <Header />
      <div className="height_one_csrol">
        <div
          id="home-Confirmation"
          className="top_header_pm cancellation_confirm"
        >
          <div className="testride_map Confirmation home_confirmation">
            <div className="map_left Confirmation_left">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>
              <div className="after_savings">
                <figure>
                  <img  src={bike_re_conf} alt="imhvgb" />
                </figure>
              </div>
            </div>
            <div className="testride_righsec order_id">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
              </div>
              <div className="only_mobileshow mobile_show">
                <img  src={popuplogomo} alt="imhvgb" />
              </div>
              <div className="check_confirm">
                <span className="checkmark_con_i">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                <h3 className="color_text_con">Cancellation Confirmation</h3>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <img  
                      style={{ height: "50px", height: "50px" }}
                      src={LoadingSpinner}
                    />
                  </div>
                ) : (
                  <div className="order_sec_id cancel_conf">
                    <p>
                      Your Test Ride- <br />{" "}
                      <strong>
                        <span>Order ID :</span>{" "}
                        <span className="color_text_con">
                          {cancelledTestDrive?.order_id}
                        </span>
                      </strong>{" "}
                      <br />
                      has been successfully cancelled.
                      <p className="thank_int">
                        {" "}
                        Thanks for your interest in <br />
                        <span className="color_text_con">HOP !!</span>
                      </p>
                    </p>
                  </div>
                )}
              </div>

              <div className="test_ridestep">
                <ul>
                  <li>
                    <Link to="/booknow">Book OXO</Link>
                  </li>
                </ul>
              </div>
              <div className="next_btn">
                <button
                  onClick={() => navigate("/userDashboard")}
                  className="default_btn"
                >
                  View Activities
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default CancelOrderid;
