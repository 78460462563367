import "../Testride/test-ride.css";
import { useNavigate } from "react-router-dom";
import "react-pure-modal/dist/react-pure-modal.min.css";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const ThanksTr = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="height_one_csrol">
        <div id="home-Confirmation" className="top_header_pm">
          <div className="payment_secmain  privacy_policy_sec">
            <div className="payment_cont pri_policy">
              <h2>Thak you Our Team Will Contact to you soon</h2>
              <div className="next_btn">
                <button onClick={() => navigate("/")} className="default_btn">
                  Back To Home
                </button>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default ThanksTr;
