import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import PureModal from "react-pure-modal";
import moment from "moment";
import axios from "axios";
import { AiFillCheckCircle } from "react-icons/ai";
import "./style.css";
import { Config } from "../../../utils/apiUrl";
import { BIKE_VARIANTS, SCOOTER_VARIANTS } from "../../../constants";
import { POST_SendVerifyOTPAPI, verifyCaptchaAPI } from "../../../APIs";
import ReCAPTCHA from "react-google-recaptcha";
import { convertSecondsToMinSec } from "../../../utils";

const EnquiryForm = () => {
  const OTPInputRef = useRef();
  const recaptcha = useRef();
  const captchaKey = process.env.REACT_APP_SITE_KEY;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [enquiryFormData, setEnquiryFormData] = useState({
    name: "",
    // email: "",
    phone_number: "",
    pincode: "",
    product_type: "",
    product_variant: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    // email: "",
    phone_number: "",
    pincode: "",
    product_type: "",
    product_variant: "",
  });

  const [OTP, setOTP] = useState("");
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [countdown, setCountdown] = useState(30); // Initial countdown time in seconds
  const [isCounting, setIsCounting] = useState(false);
  const [OTPVerified, setOTPVerified] = useState(false);
  const [OTPError, setOTPError] = useState("");
  const [resent, setResent] = useState(false);
  const [VerifyON, setVerifyON] = useState(false);
  const [adTrackingData, setAdTrackingData] = useState({});
  const [intervalToken, setIntervalToken] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    let params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setAdTrackingData(params);
  }, []);

  /// auto Detect SMS OTP
  // useEffect(() => {
  //   autoDetectOTP();
  // }, [showOTPModal]);

  // const autoDetectOTP = async () => {
  //   if (typeof navigator !== "undefined" && "credentials" in navigator) {
  //     const ac = new AbortController();

  //     navigator.credentials
  //       .get({ otp: { transport: ["sms"] }, signal: ac.signal })
  //       .then((otpCredential) => {
  //         if (otpCredential) {
  //           const otp = otpCredential.code;
  //           setOTP(otp);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Web OTP API error:", error);
  //       });
  //   }
  // };

  // Function to start the countdown
  // const startCountdown = () => {
  //   setIsCounting(true);
  //   setCountdown(30); // Reset the countdown to the initial value
  // };

  // useEffect(() => {
  //   // Function to handle the countdown
  //   const handleCountdown = () => {
  //     if (countdown > 0) {
  //       setCountdown((prev) => prev - 1);
  //     } else {
  //       if (resent) {
  //         setResent(false);
  //       }
  //       setIsCounting(false);
  //       clearInterval(intervalToken);
  //     }
  //   };

  //   // Start the countdown when isCounting is true
  //   if (isCounting) {
  //     setIntervalToken(setInterval(handleCountdown, 1000));
  //   }
  //   // Clean up the interval when the component unmounts
  //   return () => clearInterval(intervalToken);
  // }, [isCounting, countdown]);

  // const enableResend = () => {
  //   startCountdown();
  // };

  // const handleResend = () => {
  //   resendOTP();
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let validated = validateForm(name, value);
    if (validated) {
      setEnquiryFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateForm = (name, value) => {
    if (name == "phone_number") {
      if (value.length < 11) {
        return true;
      } else {
        return false;
      }
    }
    if (name == "pincode") {
      if (value.length < 7) {
        return true;
      } else {
        return false;
      }
    }
    // if (name == "email") {
    //   const EmailRegex =
    //     /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    //   if (!EmailRegex.test(value)) {
    //     setErrors((prevState) => ({
    //       ...prevState,
    //       [name]: "Please Enter Valid Email.",
    //     }));
    //   } else {
    //     setErrors((prevState) => ({
    //       ...prevState,
    //       [name]: "",
    //     }));
    //   }
    // }
    return true;
  };

  const onKeyPressNumberInput = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const onKeyPressZipWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  // const verifyCaptcha = (event) => {
  //   event.preventDefault();
  //   setShowCaptcha(true);
  //   setShowOTPModal(true);
  // };

  //Send OTP
  // const handleSendOTP = async (event) => {
  //   try {
  //     const response = await POST_SendVerifyOTPAPI({
  //       phone_num: enquiryFormData.phone_number,
  //       otp: "",
  //     });
  //     if (response.message === "sms sent sucessfully") {
  //       setShowOTPModal(true);
  //       enableResend();
  //     } else {
  //       toast.error(response.message, { autoClose: 1500 });
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong: " + error.message, {
  //       autoClose: 1500,
  //     });
  //   }
  // };

  //resendOTP
  // const resendOTP = async () => {
  //   try {
  //     const response = await POST_SendVerifyOTPAPI({
  //       phone_num: enquiryFormData.phone_number,
  //       otp: "",
  //     });
  //     if (response.message === "sms sent sucessfully") {
  //       setResent(true);
  //       enableResend();
  //       setOTPError();
  //     } else {
  //       toast.error(response.message, { autoClose: 1500 });
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong.", { autoClose: 1500 });
  //   }
  // };

  //verifyOTP
  // const handleVerifyOTP = async (event) => {
  //   event.preventDefault();
  //   clearInterval(intervalToken);
  //   setCountdown(0);
  //   setResent(false);
  //   setIsCounting(false);
  //   setOTPError("");
  //   setVerifyON(true);
  //   if (!OTP) {
  //     setOTPError("Please Enter OTP.");
  //     return false;
  //   } else if (OTP.length !== 6) {
  //     setOTPError("Please Enter Valid OTP.");
  //     return false;
  //   } else {
  //     setOTPError("");
  //   }

  //   try {
  //     const response = await POST_SendVerifyOTPAPI({
  //       phone_num: enquiryFormData.phone_number,
  //       otp: OTP,
  //     });
  //     if (response) {
  //       if (response.authenticated_user === "True") {
  //         setShowOTPModal(false);
  //         setOTPVerified(true);
  //         setOTP("");
  //         setVerifyON(false);
  //       } else {
  //         setVerifyON(false);
  //         setOTPError("Invalid or Incorrect OTP.");
  //       }
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong." + error.message, { autoClose: 1500 });
  //   }
  // };

  const handleEnquiryForm = async (event) => {
    event.preventDefault();
    // const captchaValue = recaptcha.current.getValue();
    // if (!captchaValue) {
    //   alert("Please verify the reCAPTCHA!");
    //   return false;
    // }
    const { phone_number, pincode, name, product_variant, product_type } =
      enquiryFormData;
    const nameRegex = /^[A-Za-z\s'-]+$/;
    let err = 0;

    if (name.length < 3) {
      setErrors((prevState) => ({
        ...prevState,
        name: "Please Enter Valid Name.",
      }));
      err++;
    } else if (!nameRegex.test(name)) {
      setErrors((prevState) => ({
        ...prevState,
        name: "Please Enter Valid Name.",
      }));
      err++;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        name: "",
      }));
    }

    if (phone_number.length < 10) {
      setErrors((prevState) => ({
        ...prevState,
        phone_number: "Please Enter Valid Mobile Number.",
      }));
      err++;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        phone_number: "",
      }));
    }
    // if (phone_number.length >= 10 && !OTPVerified) {
    //   setOTPError("Please Verify OTP.");
    //   err++;
    // } else {
    //   setOTPError("");
    // }

    if (pincode.length < 6) {
      setErrors((prevState) => ({
        ...prevState,
        pincode: "Please Enter Valid Pincode.",
      }));
      err++;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        pincode: "",
      }));
    }

    if (!product_type) {
      setErrors((prevState) => ({
        ...prevState,
        product_type: "Please Select Product Type.",
      }));
      err++;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        product_type: "",
      }));
    }

    if (!product_variant) {
      setErrors((prevState) => ({
        ...prevState,
        product_variant: "Please Select Variant.",
      }));
      err++;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        product_variant: "",
      }));
    }
    // if (errors.email) {
    //   err++;
    // }

    if (err == 0) {
      try {
        const data = {
          name: enquiryFormData.name,
          // email: enquiryFormData.email,
          phone_num: enquiryFormData.phone_number,
          pin_code: enquiryFormData.pincode,
          product_type: enquiryFormData.product_type,
          product_variant: enquiryFormData.product_variant,
          source: "enquiry_page",
          compaign_ad: JSON.stringify(adTrackingData),
          date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
        setDisableSubmit(true);
        let response = await axios.post(Config.addEnquiry, data);
        if (response.data.status == "success") {
          setEnquiryFormData({
            name: "",
            // email: "",
            phone_number: "",
            pincode: "",
            product_type: "",
            product_variant: "",
          });
          setErrors({
            name: "",
            // email: "",
            phone_number: "",
            pincode: "",
            product_type: "",
            product_variant: "",
          });
          setOTPVerified(false);
          // toast.success("Enquiry Registered Successfully.", {
          //   autoClose: 10000,
          // });
          navigate(`/thankyou-enquiry?name=${enquiryFormData.name}`);
        } else {
          setEnquiryFormData({
            name: "",
            // email: "",
            phone_number: "",
            pincode: "",
            product_type: "",
            product_variant: "",
          });
          setErrors({
            name: "",
            // email: "",
            phone_number: "",
            pincode: "",
            product_type: "",
            product_variant: "",
          });
          setOTPVerified(false);
          setOTPVerified(false);
        }
      } catch (error) {
        setDisableSubmit(false);
        toast.error(error.message, { autoClose: 10000 });
      }
    }
  };

  // const onExecute = async (e) => {
  //   let response = await verifyCaptchaAPI(e);
  //   if (response && response.status == "success") {
  //     setShowCaptcha(false);
  //     handleSendOTP();
  //   } else {
  //     recaptcha.current.reset();
  //   }
  // };

  return (
    <>
      {/* <div
        className={`form-group opt_popup service_otppop enquiry_modal ${
          showCaptcha ? "recaptchaPopup" : null
        } `}
      >
        <PureModal
          onClose={() => {
            setShowOTPModal(false);
            setIsCounting(false);
            setShowCaptcha(false);
            setOTP();
            setOTPError("");
            return true;
          }}
          isOpen={showOTPModal}
          // closeButton={<div className="close_btn"></div>}
          // closeButtonPosition="top"
          width="100%"
        >
          {showCaptcha ? (
            <ReCAPTCHA
              className="captcha"
              ref={recaptcha}
              onChange={onExecute}
              sitekey={captchaKey}
            />
          ) : (
            <div className="otp_cont">
              <h2>Enter the OTP sent to</h2>
              <h2>
                +91********
                {enquiryFormData.phone_number
                  ?.toString()
                  .slice(
                    Number(enquiryFormData.phone_number?.toString().length) - 2,
                    enquiryFormData.phone_number?.toString().length
                  )}
              </h2>
              <p>Please Enter The OTP Received on Your Mobile Number</p>
              {countdown > 0 ? (
                <p>Time left to Resend ({convertSecondsToMinSec(countdown)})</p>
              ) : null}
              {OTPError && !isCounting ? (
                <div className="otp_resend_div">
                  <p style={{ color: "red" }}>{OTPError}</p>
                  <button
                    style={{ marginLeft: "5px", color: "rgb(0, 0, 238)" }}
                    onClick={handleResend}
                  >
                    {" "}
                    Resend?
                  </button>
                </div>
              ) : null}

              {!isCounting && !resent && !OTPError && !VerifyON ? (
                <div className="otp_resend_div">
                  <p style={{ color: "#ffb3b3" }}>Didn’t get the OTP?</p>
                  <button
                    style={{ marginLeft: "5px", color: "rgb(0, 0, 238)" }}
                    onClick={handleResend}
                  >
                    {" "}
                    Resend?
                  </button>
                </div>
              ) : null}
              {isCounting && resent ? (
                <div className="otp_resend_div">
                  <p style={{ color: "red" }}>OTP Resent Successfully.</p>
                </div>
              ) : null}

              <div className="form-group">
                <input
                  id="otp-input"
                  inputMode="numeric"
                  autocomplete="one-time-code"
                  ref={OTPInputRef}
                  placeholder="ENTER OTP"
                  maxLength={6}
                  onChange={(e) => setOTP(e.target.value)}
                  value={OTP}
                  onKeyPress={onKeyPressZipWanto}
                />
              </div>
              <button className="default_btn" onClick={handleVerifyOTP}>
                Submit
              </button>
            </div>
          )}
        </PureModal>
      </div> */}
      <div className="form_sec_enq">
        <div className="enq_form_content">
          <h1>Book Free Test Ride</h1>
          {/* <h3>Enquire Now Switch To HOP</h3> */}

          <form onSubmit={handleEnquiryForm}>
            <div className="form_field">
              <input
                value={enquiryFormData.name}
                className="input"
                type="text"
                placeholder="Name*"
                name="name"
                onChange={handleChange}
                // required
              />
            </div>
            {errors.name && (
              <small
                style={{ display: "block", color: "red", marginTop: "-10px" }}
              >
                {errors.name}
              </small>
            )}
            {/* <div className="form_field">
              <input
                value={enquiryFormData.email}
                className="input"
                type="email"
                inputMode="email"
                name="email"
                placeholder="Email ID*"
                onChange={handleChange}
                required
              />
            </div>
            {errors.email && (
              <small
                style={{ display: "block", color: "red", marginTop: "-10px" }}
              >
                {errors.email}
              </small>
            )} */}
            <div className="form_field otp_field">
              <input
                value={enquiryFormData.phone_number}
                className="input"
                inputMode="tel"
                name="phone_number"
                // disabled={OTPVerified}
                placeholder="Phone No*"
                onChange={handleChange}
                onKeyPress={onKeyPressNumberInput}
                // required
              />
              {/* {enquiryFormData.phone_number.length == 10 ? (
                OTPVerified ? (
                  <p className="OTP_BTN bg_remove">
                    <AiFillCheckCircle color="#00EAFF" size={30} />
                  </p>
                ) : (
                  <button
                    className="OTP_BTN"
                    onClick={(event) => verifyCaptcha(event)}
                  >
                    Send OTP
                  </button>
                )
              ) : null} */}
            </div>
            {/* {!errors.phone_number && OTPError && (
              <small
                style={{ display: "block", color: "red", marginTop: "-10px" }}
              >
                {OTPError}
              </small>
            )} */}
            {errors.phone_number && (
              <small
                style={{ display: "block", color: "red", marginTop: "-10px" }}
              >
                {errors.phone_number}
              </small>
            )}
            <div className="form_field">
              <input
                value={enquiryFormData.pincode}
                className="input"
                inputMode="numeric"
                name="pincode"
                onChange={handleChange}
                onKeyPress={onKeyPressNumberInput}
                placeholder="Pincode*"
                // required
              />
            </div>
            {errors.pincode && (
              <small
                style={{ display: "block", color: "red", marginTop: "-10px" }}
              >
                {errors.pincode}
              </small>
            )}
            <div className="selectale_form">
              <div className="form_field">
                <select
                  value={enquiryFormData.product_type}
                  className="input"
                  name="product_type"
                  id="product_type"
                  onChange={handleChange}
                  // required
                >
                  <option value="">Select Product Type*</option>
                  <option value="Bike">Bike</option>
                  <option value="Scooter">Scooter</option>
                </select>
              </div>
              {errors.product_type && (
                <small
                  style={{ display: "block", color: "red", marginTop: "-10px" }}
                >
                  {errors.product_type}
                </small>
              )}
              <div className="form_field">
                <select
                  value={enquiryFormData.product_variant}
                  className="input"
                  name="product_variant"
                  id="product_variant"
                  onChange={handleChange}
                  // required
                >
                  <option value="">Select Variant*</option>
                  {enquiryFormData.product_type == "Bike"
                    ? BIKE_VARIANTS.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))
                    : enquiryFormData.product_type == "Scooter"
                    ? SCOOTER_VARIANTS.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              {errors.product_variant && (
                <small
                  style={{ display: "block", color: "red", marginTop: "-10px" }}
                >
                  {errors.product_variant}
                </small>
              )}
            </div>

            <div className="form_field">
              <button
                style={{
                  backgroundColor: disableSubmit ? "#ccc" : "#0098da",
                  cursor: disableSubmit ? "not-allowed" : "auto",
                  borderColor:disableSubmit ? "#ccc" : "#0098da"
                }}
                title={disableSubmit?"Please wait...":null}
                disabled={disableSubmit}
              >
                {disableSubmit ? "SUBMITTING..." : "SUBMIT"}
              </button>
            </div>
            <div className="form_field">
              <p>
                *By clicking Submit button I agree that I have read the{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EnquiryForm;
