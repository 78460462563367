import React from "react";
import Slider from "react-slick";
import Rugged from "../../../assets/images/Rugged.jpg";
import Dual_Disc from "../../../assets/images/Dual_Disc.jpg";
import ConnectedTechnology from "../../../assets/images/connected_technology.jpg";

export default function SiliderFour() {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider_show">
      <Slider {...settings}>
        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Rugged} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Rugged Chassis</h3>
            <p>
              Designed for a 360 Kg payload, OXO chassis are built with SKTM-11
              A & HRCS grade Low carbon automotive steel. This provides rigidity
              along with great dynamic balance.
            </p>
          </div>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Dual_Disc} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Dual Disc CBS with Regenerative Braking System </h3>
            <p>
              With more weight, good braking power is a must. OXO is equipped
              with a 240 MM front and a 220 MM Rear disc brake. Combi braking
              system with controller assisted regentraive braking not just stops
              the vehicle in time but also charge battery while braking.
            </p>
          </div>
        </div>
        <div className="featur_para pop_slideron">
          <figure>
            <img
               src={ConnectedTechnology}
              alt="imhvgb"
            />
          </figure>
          <div className="slide_cont">
            <h3>Connected Featuers </h3>
            <p>
              Hop OXO connect app offers a host of safety features like Anti
              theft alert, geo fencving, speed control, tow alert, vehicle
              tracking and much more. so that you, your loved one and your oxo
              are all safe at all the times.
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
}
