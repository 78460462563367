import React, { useState } from "react";
import Select from "react-select";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Book/book.css";
import bike_topsec from "../../assets/images/bike_re.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "react-pure-modal/dist/react-pure-modal.min.css";
import { toast } from "react-toastify";
import Header from "../../components/common/layout/Header";
import { COMPLETE_URL_STAGE } from "../../utils/apiUrl";
import { useScrollTo } from "../../hooks/useScrollTo";

const Book = () => {
  useScrollTo(0, 0);
  const [percentRange, setProgress] = useState(0);
  const [dateValue, setDate] = useState(null);
  const [selectValue, setSelect] = useState(null);
  const [submits, setSubmit] = useState(null);
  const notify = () => {
    toast("Thank you. Our team will contact you soon! for Book Test Drive");
  };

  const options = [
    { value: null, label: "Select Model" },
    { value: "HOP Electric OXO", label: "HOP Electric OXO" },
    { value: "Hop Electric LYF", label: "Hop Electric LYF" },
    { value: "Hop Electric LEO", label: "Hop Electric LEO" },
  ];

  const colourStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#50ebff" : null,
        color: "#454545",
      };
    },
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <FontAwesomeIcon icon={faAngleDown} onClick={props.onClick} />
      </>
    );
  });

  const handleSelect = (select) => {
    if (select.value == null) {
      setDate(null);
      setSubmit(null);
    }
    setSelect(select.value);
  };

  const handleDate = (date) => {
    const newDate = new Date(date);
    setDate(newDate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    var locatData = JSON.parse(localStorage.getItem("book_test"));
    const data = {
      name: locatData.name,
      phone_num: locatData.phone_num,
      pin_code: locatData.pin_code,
      model: selectValue,
      date: new Date(dateValue).toLocaleDateString(),
      source: "Book Test Drive",
    };
    let response = await axios.post(COMPLETE_URL_STAGE + "/test_drive/", data, {
      headers: {
        "Content-type": "application/json",
      },
    });
    if ((response.status = "200")) {
      notify();
      setSelect(null);
      setDate(null);
    }
  };

  var circle1_1 = "#484b50";

  if (selectValue == null) {
    circle1_1 = "#484b50";
  }
  if (selectValue !== null) {
    circle1_1 = "#00eaff";
  }
  const styles1 = {
    backgroundColor: circle1_1,
  };

  var innerCircle2_2 = "#484b50";
  if (dateValue !== null && selectValue !== null) {
    innerCircle2_2 = "#00eaff";
  }
  const innerCircleStyle2 = {
    backgroundColor: innerCircle2_2,
    borderColor: innerCircle2_2,
  };

  var innerCircle3_3 = "#484b50";
  if (submits !== null && selectValue !== null) {
    innerCircle3_3 = "#00eaff";
  }
  const innerCircleStyle3 = {
    backgroundColor: innerCircle3_3,
    borderColor: innerCircle3_3,
  };

  return (
    <>
      <Header />
      <div id="book">
        <div className="book_sec">
          <div className="left_sec">
            <figure>
              <img  src={bike_topsec} alt="imhvgb" />
            </figure>
          </div>
          <div className="right_sec">
            <h5>Book Test Drive</h5>
            <div className="circle_1">
              <div className="innerCircle1" style={styles1}></div>
            </div>
            <div className="circle_2">
              <div className="innerCircle2" style={innerCircleStyle2}></div>
            </div>
            <div className="circle_3">
              <div className="innerCircle3" style={innerCircleStyle3}></div>
            </div>
            <hr className="border1" style={innerCircleStyle2} />
            <hr className="border2" style={innerCircleStyle3} />
            <ul className="newCss">
              <li className="listBook">
                <span></span>
                <p>Select Model</p>
              </li>
              <li className="listBook">
                <span></span>
                <p>Select Date</p>
              </li>
              <li className="listBook">
                <span></span>
                <p>Confirmation</p>
              </li>
            </ul>
            <div className="form_sec">
              <div className="form-group">
                <Select
                  options={options}
                  onChange={handleSelect}
                  styles={colourStyles}
                />
              </div>
              <div className="form-group">
                <DatePicker
                  selected={dateValue}
                  onChange={handleDate}
                  onClick={() =>
                    setProgress(percentRange < 100 ? percentRange + 20 : 100)
                  }
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Date"
                  customInput={<CustomInput />}
                />
              </div>
              <div className="form-group">
                <button onClick={handleSubmit} className="default_btn">
                  Confirm Booking
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Book;
