import React from "react";
import Slider from "react-slick";

import Lightboximg1 from '../../../assets/images/ZIP_1.png';
import Lightboximg2 from '../../../assets/images/ZIP_2.png';
import Lightboximg3 from '../../../assets/images/ZIP_3.png';
import Lightboximg4 from '../../../assets/images/ZIP_4.png';
import Lightboximg5 from '../../../assets/images/ZIP_5.png';


export default function SiliderPopup() {
  const settings = {
    dots: false,
    nav : true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
  
        }
      },
    ]
  };
  return (

    
    <div className="slider_show">
      <Slider {...settings}>
      
        <div className="featur_para pop_slideron">
          <figure>
            {" "}
            <img  src={Lightboximg1} alt="imhvgb" />
          </figure>
          <caption>Caption 1</caption>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            {" "}
            <img  src={Lightboximg2} alt="imhvgb" />
          </figure>
          <caption>Caption 2</caption>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            {" "}
            <img  src={Lightboximg3} alt="imhvgb" />
          </figure>
          <caption>Caption 3</caption>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            {" "}
            <img  src={Lightboximg4} alt="imhvgb" />
          </figure>
          <caption>Caption 4</caption>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            {" "}
            <img  src={Lightboximg5} alt="imhvgb" />
          </figure>
          <caption>Caption 5</caption>
        </div>

      </Slider>
    </div>
  );
}
