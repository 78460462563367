import React, { useState } from "react";
import styles from "./HamburgerMenu.module.scss";
import Hamburger from "./Hamburger";
import Menu from "./Menu";

function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
    if (!isOpen === true) {
      document.body.classList.add("popup-open");
    } else {
      document.body.classList.remove("popup-open");
    }
  }
  
  const data = [
    { name: "LEO", url: "/hop-leo" },
    { name: "LYF", url: "/scooter" },
    { name: "OXO", url: "/oxo" },
    {
      name: "About Us",
      url: "/about-us",
    },
    { name: "All Dealers", url: "/dealers" },
    { name: "Career", url: "/career" },
    { name: "Get In Touch", url: "/contact-us" },
  ];

  return (
    <>
      <Hamburger
        onClick={handleClick}
        className={
          isOpen
            ? `${styles.hamburger} ${styles.hamburgeractive}`
            : styles.hamburger
        }
      />
      <Menu
        className={
          isOpen
            ? `${styles.navigation} ${styles.navigationactive}`
            : styles.navigation
        }
        data={data}
        setIsOpen={setIsOpen}
        isOpen={setIsOpen}
      />
    </>
  );
}
export default HamburgerMenu;
