import React from "react";
import "./style.css";
import thankYou from "../../../assets/images/Enquiry/thankyou.jpg";
import { Link, useSearchParams } from "react-router-dom";

const ThankYouCard = () => {
  const [searchParams] = useSearchParams();
  const handleCall = () => {
    window.location = "tel:+91 80033 80063";
  };
  return (
    <div className="thankyoucard">
      <div className="container">
        <img  src={thankYou} alt="thank you" />
        <h3>
          <span>{searchParams.get("name")}</span> for taking the time to
          complete our form, We have successfully received your request.
        </h3>
        <p>
          If you have any questions or need to share additional information,
          please don't hesitate to reach out to us at{" "}
          <span onClick={handleCall}>+91 80033 80063.</span>
        </p>
        <p className="second"> Thank you once again for choosing HOP !!</p>
      </div>
    </div>
  );
};

export default ThankYouCard;
