import React from "react";
import Header from "../../components/common/layout/Header";
import "./index.css";
import { useScrollTo } from "../../hooks/useScrollTo";
const GetInTouch = () => {
  useScrollTo(0,0);
  return (
    <>
      <Header />

      <div>
        <div className="form">
          <div class="header-form-title-wrapper">
            <h1 class="heading-style-h2 text-color-white">Reach out to Us</h1>
            <p>
              We are here to assist you, fill out the form below to reach out to
              our team.
            </p>
          </div>
          <div
            id="Contact-Form"
            formtype="Contact Form"
            class="contact-form-block w-form"
          >
            <form
              id="wf-form-Contact-Form"
              name="wf-form-Contact-Form"
              data-name="Contact Form"
              method="post"
              class="contact-form"
              aria-label="Contact Form"
            >
              <div class="form-field-wrapper">
                <div class="form-field">
                  <label for="First-Name" class="form-label-field">
                    First name
                  </label>
                  <input
                    type="text"
                    class="form-text-field w-input"
                    maxlength="256"
                    name="First-Name"
                    data-name="First Name"
                    placeholder="First name"
                    id="First-Name"
                    required=""
                  />
                </div>
                <div class="form-field">
                  <label for="Last-Name" class="form-label-field">
                    Last Name
                  </label>
                  <input
                    type="text"
                    class="form-text-field w-input"
                    maxlength="256"
                    name="Last-Name"
                    data-name="Last Name"
                    placeholder="Last Name"
                    id="Last-Name"
                    required=""
                  />
                </div>
              </div>
              <div class="form-field-wrapper">
                <div class="form-field">
                  <label for="Email-Address" class="form-label-field">
                    E-Mail Address
                  </label>
                  <input
                    type="text"
                    class="form-text-field w-input"
                    maxlength="256"
                    name="Email-Address"
                    data-name="Email Address"
                    placeholder="info@acne.com"
                    id="Email-Address"
                    required=""
                  />
                </div>
              </div>
              <div class="form-field-wrapper">
                <div class="form-field">
                  <label for="Mobile-Number" class="form-label-field">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    class="form-text-field w-input"
                    maxlength="256"
                    name="Mobile-Number"
                    data-name="Mobile Number"
                    placeholder="9123456789"
                    id="Mobile-Number"
                    required=""
                  />
                </div>
                <div class="form-field">
                  <label for="City" class="form-label-field">
                    State
                  </label>
                  <select
                    id="City"
                    name="City"
                    data-name="City"
                    required=""
                    class="form-text-field w-select"
                  >
                    <option value="Andra Pradesh">Andra Pradesh</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharastra">Maharastra</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telagana">Telagana</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>
              </div>
              <div class="form-field-wrapper">
                <div class="form-field">
                  <label for="Reason" class="form-label-field">
                    Reason
                  </label>
                  <select
                    id="Reason"
                    name="Reason"
                    data-name="Reason"
                    required=""
                    class="form-text-field w-select"
                  >
                    <option value="Become a Dealer">Become a Dealer</option>
                    <option value="Product Support">Product Support</option>
                    <option value="Careers">Careers</option>
                    <option value="Supplier">Supplier</option>
                    <option value="Booking Status">Booking Status</option>
                    <option value="Feedbacks">Feedbacks</option>
                  </select>
                </div>
              </div>
              <div class="form-field-wrapper">
                <div class="form-field">
                  <label for="Message" class="form-label-field">
                    Message
                  </label>
                  <textarea
                    id="Message"
                    name="Message"
                    maxlength="5000"
                    data-name="Message"
                    placeholder="Please Write your Message"
                    required=""
                    class="form-text-field w-input"
                  />
                </div>
              </div>
              <input
                type="submit"
                value="Send Message"
                data-wait="Please wait..."
                className="default_btn"
              />
              <div class="form-terms">
                <div class="text-size-regular">
                  By filling this form, you agree that your contact information
                  will be processed as per the Terms of Kabira Mobility.
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="Rimage"></div>
      </div>
    </>
  );
};

export default GetInTouch;
