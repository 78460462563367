import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import styles from "./HamburgerMenu.module.scss";
// import "../../../../assets/css/uikit.min.css";
import "../../../../assets/css/fontawesome.min.css";

import logo_img from "../../../../assets/images/HopLogo.svg";
import menu_bike_img from "../../../../assets/images/menu-bike-img.png";
import left_arrow_img from "../../../../assets/images/left_arrow.svg";
import Hop_Leo_scooty from "../../../../assets/images/Hop_Leo_scooty.jpg";
import hop_lyf_image from "../../../../assets/images/hop_lyf_image.png";
import { getCookie } from "../../../../utils";

const Menu = ({ className, data, isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [login, setLogin] = useState(false);
  const [imageHover, setImageHover] = useState(menu_bike_img);
  const [textHover, setTextHover] = useState("New OXO Prime");

  const handleHover = (name) => {
    if (name == "LYF") {
      setImageHover(hop_lyf_image);
      setTextHover("Hop LYF");
    } else if (name == "LEO") {
      setImageHover(Hop_Leo_scooty);
      setTextHover("Hop LEO");
    } else {
      setImageHover(menu_bike_img);
      setTextHover("New OXO Prime");
    }
  };

  const handleHoverafter = () => {
    setImageHover(menu_bike_img);
    setTextHover("New OXO Prime");
  };

  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  useEffect(() => {
    getCookiedata("userdata");
  });

  const redirectloginpage = (e) => {
    e.preventDefault();
    document.body.classList.remove("popup-open");
    isOpen(false);
    navigate("/login");
  };

  const deletecookie = (item, e) => {
    e.preventDefault();
    document.cookie =
      item + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.body.classList.remove("popup-open");
    isOpen(false);
    navigate("/");
    setLogin(true);
  };

  const redirect = (bookdata, e) => {
    e.preventDefault();
    document.body.classList.remove("popup-open");
    const cookieData = getCookie(bookdata);
    if (!cookieData) {
      isOpen(false);
      navigate(`/${"login"}?to=booktestride`);
    } else {
      isOpen(false);
      navigate("/booktestride");
    }
  };

  const dealer = (e) => {
    e.preventDefault();
    isOpen(false);
    document.body.classList.remove("popup-open");
    // navigate("/dealership");
    window.open(process.env.REACT_APP_WEBSITE_URL + "/dealership", "_self");
  };

  const redirecthomepage = (e) => {
    e.preventDefault();
    document.body.classList.remove("popup-open");
    isOpen(false);
    navigate("/");
  };

  const Dashboardredirect = (dashboarddata, e) => {
    const cookie = getCookie(dashboarddata);
    if (!cookie) {
      e.preventDefault();
      navigate("/");
    } else {
      e.preventDefault();
      navigate("/UserDashboard");
    }
  };

  const navigateFromHamburger = (to) => {
    document.body.classList.remove("popup-open");
    setIsOpen(false);
    if (to === "/hop-leo" && location.pathname == "/hop-leo") {
      window.open(process.env.REACT_APP_WEBSITE_URL + "/enquiry", "_self");
    } else if (to === "/oxo" && location.pathname == "/") {
      window.open(process.env.REACT_APP_WEBSITE_URL + "/enquiry", "_self");
    } else if (to === "/oxo" && location.pathname !== "/") {
      window.open(process.env.REACT_APP_WEBSITE_URL, "_self");
    } else if (to === "/scooter" && location.pathname !== "/enquiry") {
      window.open(process.env.REACT_APP_WEBSITE_URL + "/enquiry", "_self");
    } else if (to === "/scooter" && location.pathname === "/enquiry") {
      setTimeout(() => {
        setIsOpen(false);
      }, 0);
    } else if (to !== location.pathname) {
      window.open(process.env.REACT_APP_WEBSITE_URL + to, "_self");
    } else {
      setTimeout(() => {
        setIsOpen(false);
      }, 0);
    }
  };

  return (
    <div className={className}>
      <div className="logo_sec">
        <Link to=" ">
          <img src={logo_img} alt="Logo image" onClick={redirecthomepage} />
        </Link>
      </div>
      <div className="main_sec">
        <div className="left_sec">
          <div className="img_sec">
            <img src={imageHover} alt="bike image" />
            <span>{textHover}</span>
          </div>
        </div>
        <div className="right_sec">
          <ul className={styles.navigation__list}>
            {data.map((d, index) => (
              <li
                className={styles.navigation__item}
                onMouseLeave={handleHoverafter}
                onMouseOver={() => handleHover(d.name)}
                onClick={() => navigateFromHamburger(d.url)}
                key={index}
              >
                <p>
                  {d.name} {<img src={left_arrow_img} alt="Image" />}
                </p>
              </li>
            ))}
            <li className={styles.navigation__item}>
              <p onClick={dealer}>
                Become a Dealer {<img src={left_arrow_img} alt="Image" />}
              </p>
            </li>
            <li className={styles.navigation__item}>
              <p onClick={(e) => redirect("userdata", e)}>
                Book a Test Ride{<img src={left_arrow_img} alt="Image" />}
              </p>
            </li>

            {!login ? (
              <li className={styles.navigation__item}>
                <p onClick={(e) => Dashboardredirect("userdata", e)}>
                  Dashboard{<img src={left_arrow_img} alt="Image" />}
                </p>
              </li>
            ) : (
              ""
            )}

            {login ? (
              <li className={styles.navigation__item}>
                <p onClick={redirectloginpage}>
                  Login {<img src={left_arrow_img} alt="Image" />}
                </p>
              </li>
            ) : (
              <li className={styles.navigation__item}>
                <p onClick={(e) => deletecookie("userdata", e)}>
                  Logout{<img src={left_arrow_img} alt="Image" />}
                </p>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Menu;
