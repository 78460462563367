import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import Tridi from "react-tridi";
import PureModal from "react-pure-modal";
import $ from "jquery";
import "react-tridi/dist/index.css";
import "../LandingPage/home.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import lan_logo from "../../assets/images/HopLogo.svg";
import lines_bg from "../../assets/images/lines_bike.png";
import Group_img from "../../assets/images/Group.svg";
import charging from "../../assets/images/charging.jpg";
import connected_technology from "../../assets/images/connected_technology.jpg";
import LOK_2754 from "../../assets/images/LOK_2754.png";
import LOK_2765 from "../../assets/images/LOK_2765.png";
import LOK_2795 from "../../assets/images/LOK_2795.png";
import LOK_2808 from "../../assets/images/LOK_2808.png";
import LOK_2779 from "../../assets/images/LOK_2779.png";
import SPINNER from "../../assets/images/spinner.svg";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import user_n from "../../assets/images/user.png";
import email_id from "../../assets/images/email.png";
import india_mo from "../../assets/images/india.png";
import Featured from "../../components/MarqueNew";
import Header from "../../components/common/layout/Header";
import FooterLanding from "../../components/common/layout/FooterLanding";
import BookTestRide from "../Testride/book-testride";
import TestRide from "../Testride/test-ride";
import SiliderOne from "./SiliderComponent/siliderone";
import SiliderTwo from "./SiliderComponent/silidertwo";
import SiliderThree from "./SiliderComponent/siliderthree";
import SiliderFour from "./SiliderComponent/siliderfour";
import SiliderFive from "./SiliderComponent/siliderfive";
import SiliderSix from "./SiliderComponent/silidersix";

import { Config } from "../../utils/apiUrl";
import { getCookie, onlyAlphabetsAllowed } from "../../utils";
import { ALL_PRICE, OXO_EMI_STARTS, OXO_MODAL_PRICES } from "../../constants";
import { AddOrderToken, addUserDetails } from "../../actions";
import ReCAPTCHAModal from "../../components/common/ReCaptchaModal";
import { verifyCaptchaAPI } from "../../APIs";

const stateData = [];

const LandingPage = () => {
  const location = useLocation();
  const priceSecRef = useRef();
  const captchaRef = useRef();

  const UserDetails = useSelector((state) => state.UserDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal3, setModal3] = useState(false);
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [modalsiliderone, setModelSiliderOne] = useState(false);
  const [modalsilidertwo, setModelSiliderTwo] = useState(false);
  const [modalsiliderthree, setModelSiliderThree] = useState(false);
  const [modalsiliderfour, setModelSiliderFour] = useState(false);
  const [modalsiliderfive, setModelSiliderFive] = useState(false);
  const [modalsilidersix, setModelSiliderSix] = useState(false);
  const [display, setDisplay] = useState(true);
  const [zipError, setZipError] = useState(true);
  const [npcircle2, setNpCircle2] = useState("");
  const [npcirecle1, setNpCircle1] = useState("");
  const [image, setImage] = useState("./yellowimages");
  const blackImageLocation = "./blackimages";
  const redImageLocation = "./redimages";
  const yellowImageLocation = "./yellowimages";
  const blueImageLocation = "./blueimages";
  const [activeCircleBlack, setActiveCircleBlack] = useState("");
  const [activeCircleGrey, setActiveCircleGrey] = useState("");
  const [activeCircleRed, setActiveCircleRed] = useState("");
  const [activeCircleYellow, setActiveCircleYellow] = useState("acive");
  const [activeCircleBlue, setActiveCircleBlue] = useState("");
  const tridiRef = useRef(null);
  const [stateName, setStateName] = useState("Rajasthan");
  const [packageName, setPackageName] = useState("OXO Prime");
  const [cityName, setCityName] = useState("Jaipur");
  const [bikeColorName, setBikeColorName] = useState("True Black");
  const [cityList, setCityList] = useState([]);
  const [fullNameWanto, setFullNameWanto] = useState("");
  const [mobNumberWanto, setMobNumberWanto] = useState("");
  const [emailWanto, setEmailWanto] = useState("");
  const [zipCodeWanto, setZipCodeWanto] = useState("");
  const [emailErrorWanto, setEmailErrorWanto] = useState(true);
  const [activeBtnOxo1, setActiveBtnOxo1] = useState("");
  const [opneContentModal, setOpneContentModal] = useState(
    "ActiveOxoPrimeContent"
  );
  const [activeBtnOxoX1, setActiveBtnOxoX1] = useState("");
  const [activeBtnOxoPrime1, setActiveBtnOxoPrime1] = useState("activeBtnOxo1");
  const [stateList, setStateList] = useState([]);
  const [scrollwise, setScrollwise] = useState(true);
  const [loader, setLoader] = useState(false);
  const [impModal, setImpModal] = useState(false);
  const [utmSourceBook, setUtmSourceBook] = useState("");
  const [utmMediumBook, setUtmMediumBook] = useState("");
  const [utmCampaignBook, setUtmCampaignBook] = useState("");
  const [lasttwo, setLastTwo] = useState(null);
  const [mobilenoOrder, setMobilenoOrder] = useState(null);
  const [confirmOtp, setConfirmOtp] = useState(null);
  const [usernameOrder, setUsernameOrder] = useState("");
  const [mailOrder, setMailOrder] = useState("");
  const [pincode, setPincode] = useState("");
  const [userIdenty, setUserIdenty] = useState(null);
  const [verifyOtpOrderModal, setVerifyOtpOrderModal] = useState(false);
  const [modalOrder1, setModalOrder1] = useState(false);
  const [usernameErrorOrder, setUsernameErrorOrder] = useState(false);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const [otperror, setOtperror] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [resends, setresend] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const [changecolor, setColor] = useState(false);
  const [MODEL_PRICE, setMODAL_PRICE] = useState(OXO_MODAL_PRICES.OXO_PRIME);
  const [resent, setrenset] = useState("");
  const [tabindex, settabindex] = useState("oxoPrime");
  const [currentImage, setCurrentImage] = useState(LOK_2808);
  const [isLoading, setLoading] = useState(false);
  const [priceData, setPriceData] = useState();

  const [showCaptchaModal, setShowCaptchaModal] = useState(false);

  useEffect(() => {
    let token;
    if (location.pathname == "/booknow") {
      token = setTimeout(() => {
        priceSecRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
    return () => clearTimeout(token);
  }, [priceSecRef.current]);

  useEffect(() => {
    document.getElementById("home").addEventListener("scroll", (e) => {
      var yAxis = $("#usingForHeader").offset().top;
      if (yAxis < 91) {
        $(".header").hide();
      } else {
        $(".header").show();
      }
    });
  }, []);

  useEffect(() => {
    LoadState();
  }, []);

  useEffect(() => {
    stateChange();
  }, []);

  useEffect(() => {
    getDataLocal();
  }, []);

  useEffect(() => {
    getDataFromUrl();
  }, []);

  useEffect(() => {
    checkCookie();
  }, []);

  const handleOxoBtn1 = (key) => {
    settabindex(key);
    if (key == "oxo") {
      setActiveBtnOxo1("activeBtnOxo1");
      setOpneContentModal("ActiveOxoContent");
      setActiveBtnOxoX1("");
      setActiveBtnOxoPrime1("");
    } else if (key == "oxox") {
      setActiveBtnOxoX1("activeBtnOxo1");
      setOpneContentModal("ActiveOxoXContent");
      setActiveBtnOxo1("");
      setActiveBtnOxoPrime1("");
    } else if (key == "oxoPrime") {
      setActiveBtnOxoPrime1("activeBtnOxo1");
      setOpneContentModal("ActiveOxoPrimeContent");
      setActiveBtnOxoX1("");
      setActiveBtnOxo1("");
    } else {
      setActiveBtnOxo1("");
      setActiveBtnOxoX1("activeBtnOxo1");
      setOpneContentModal("ActiveOxoXContent");
    }
  };

  const PriviousRound = () => {
    tridiRef.current.prev();
    setNpCircle1("");
    setNpCircle2("#50ebff");
    setScrollwise(true);
  };

  const NextRound = () => {
    tridiRef.current.next();
    setNpCircle2("");
    setNpCircle1("#50ebff");
    setScrollwise(true);
  };

  window.addEventListener("keydown", function (e) {
    switch (e.keyCode) {
      case 37:
        setNpCircle1("#50ebff");
        setNpCircle2("");
        setScrollwise(false);

        break;
      case 39:
        setNpCircle2("#50ebff");
        setNpCircle1("");
        setScrollwise(false);

        break;
    }
  });

  function changeCar(color) {
    if (color === "black") {
      setLoader(true);
      setActiveCircleBlack("acive");
      setActiveCircleBlue("");
      setActiveCircleRed("");
      setActiveCircleYellow("");
      setActiveCircleGrey("");
      setImage(blackImageLocation);
    } else if (color === "grey") {
      setLoader(true);
      setActiveCircleBlack("");
      setActiveCircleBlue("");
      setActiveCircleRed("");
      setActiveCircleYellow("");
      setActiveCircleGrey("acive");
      setImage(blackImageLocation);
    } else if (color === "yellow") {
      setLoader(true);
      setActiveCircleBlack("");
      setActiveCircleGrey("");
      setActiveCircleBlue("");
      setActiveCircleRed("");
      setActiveCircleYellow("acive");
      setImage(yellowImageLocation);
    } else if (color === "red") {
      setLoader(true);
      setActiveCircleBlack("");
      setActiveCircleBlue("");
      setActiveCircleGrey("");
      setActiveCircleRed("acive");
      setActiveCircleYellow("");
      setImage(redImageLocation);
    } else if (color === "blue") {
      setLoader(true);
      setActiveCircleBlack("");
      setActiveCircleGrey("");
      setActiveCircleBlue("acive");
      setActiveCircleRed("");
      setActiveCircleYellow("");
      setImage(blueImageLocation);
    }
  }

  const openTechSpace = () => {
    setModal3(true);
  };

  const handlePincode = (e) => {
    if (e.target.value.length < 7) {
      setPincode(e.target.value);
    }
  };
  // const getPriceAccordingToFilter = (state, variant) => {
  //   // Filter data based on the provided state
  //   const filteredPriceFromData = ALL_PRICE.filter((item) => {
  //     return state === item.state;
  //   });

  //   if (filteredPriceFromData.length > 0) {
  //     const variantItem = filteredPriceFromData[0].variants.find(
  //       (v) => v.name === variant
  //     );
  //     if (variantItem) {
  //       setPriceData(variantItem.price);
  //       return variantItem.price;
  //     } else {
  //       return null;
  //     }
  //   } else {
  //     return null;
  //   }
  // };

  const getPriceAccordingToFilter = async () => {
    try {
      const sendOtpOrderOptn2 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          state: stateName,
          variant: packageName,
        }),
      };
      const response = await fetch(Config.price, sendOtpOrderOptn2);
      const responseJson = await response?.json();
      if (responseJson?.status) {
        setPriceData(responseJson?.data);
      } else {
        toast.error(responseJson?.msg);
      }
    } catch (e) {
      console.log("Error Fetching Data For Pr" + e.message);
    }
  };

  useEffect(() => {
    if (stateName || packageName) {
      getPriceAccordingToFilter(stateName, packageName);
    }
  }, [stateName, packageName]);

  const handleMobileOrder = (event) => {
    var nLength = event.target.value.length;
    if (event.target.value.length < 11) {
      setMobilenoOrder(event.target.value);
    }
    if (nLength < 10 && nLength > 0) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  };

  const handleConfirmOTPChange = (event) => {
    if (event.target.value.length < 7) {
      setConfirmOtp(event.target.value);
    }
  };
  const openOrderModal = (expiredata) => {
    console.log("hello");
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      setModalOrder1(true);
    } else {
      var userDataDetail = JSON.parse(cookieData);
      dispatch(AddOrderToken({ orderToken: userDataDetail.user_token }));
      let userbookingdata = {
        bikecolor: bikeColorName,
        username: userDataDetail.user_name,
        useremail: userDataDetail.user_email,
        userphonenumber: userDataDetail.user_number,
        userpincode: userDataDetail.user_pincode,
        userpackagename: packageName,
        time: new Date().getTime(),
      };
      localStorage.setItem("userbooknowdata", JSON.stringify(userbookingdata));
      preBooking(userDataDetail);
    }
  };

  const preBooking = async (userDataDetail) => {
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userDataDetail.user_token,
      },
      body: JSON.stringify({
        phone_num: `${userDataDetail.user_number}`,
        email: `${userDataDetail.user_email}`,
        name: `${userDataDetail.user_name}`,
        pin_code: `${userDataDetail.user_pincode}`,
        dealer_id: "",
        address: `City Name - ${cityName}, State Name - ${stateName}`,
        alt_num: ``,
        model: `${packageName}`,
        source: `OXO website (Source: ${utmSourceBook}, Medium: ${utmMediumBook}, Campaign: ${utmCampaignBook})`,
        user_id: `${userDataDetail.user_id}`,
        landmark: "null",
        color: `${bikeColorName}`,
        flag: "admin",
      }),
    };
    try {
      const response = await fetch(Config.pre_booking_ticket, requestOption);
      const json = await response.json();
      if (json.ticket_id != undefined) {
        navigate("/orderconfirmation/" + json.ticket_id);
      }
    } catch (error) {
      return false;
    }
  };

  async function LoadState() {
    try {
      let stateData = await fetch(Config.state);
      let json = await stateData.json();
      setStateList(json.states);
    } catch (r) {}
  }

  const notify = () => {
    toast("Thank you. Our team will contact you soon!");
  };

  const sendOtp = () => {
    setColor(true);
    if (
      mobilenoOrder == "" ||
      mobilenoOrder == null ||
      mobilenoOrder == " " ||
      mobilenoOrder.length > 12 ||
      mobilenoOrder.length < 10
    ) {
      return false;
    } else {
      fetchData();
    }
  };

  async function fetchData() {
    try {
      const g_token = await captchaRef.current.executeAsync();
      if (g_token) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone_num: `${mobilenoOrder}`,
            otp: "",
            g_response: g_token,
          }),
        };
        const response = await fetch(Config.otp_varification, requestOptions);
        const responseJson = await response.json();
        if (responseJson) {
          if (responseJson.status) {
            let last = mobilenoOrder[mobilenoOrder.length - 1];
            let lastsecond = mobilenoOrder[mobilenoOrder.length - 2];
            setUserIdenty(responseJson.data.user_id);
            setresend(true);
            setOtperror(false);
            setColor(false);
            otpmsgs(30000);
            setOtpVerified(false);
            setLastTwo(lastsecond + "" + last);
            dispatch(
              addUserDetails({
                userId: `${responseJson.data.user_id}`,
                messageStatus: true,
                phoneNum: `${responseJson.data.phone_num}`,
              })
            );
          } else {
            toast.error(responseJson.message, { autoClose: 3500 });
          }
        }
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3500 });
    } finally {
      captchaRef.current.reset();
    }
  }

  const confirotp = () => {
    if (!confirmOtp) {
      setOtperror(true);
      setOtpVerified(false);
      setresend(false);
    } else {
      verifyOtpOrder();
    }
  };

  const verifyOtpOrder = async (expiredata) => {
    if (mobilenoOrder) {
      const optionForOtpVerificationOrder = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phone_num: `${mobilenoOrder}`,
          otp: `${confirmOtp}`,
        }),
      };

      try {
        const response = await fetch(
          Config.otp_varification,
          optionForOtpVerificationOrder
        );
        const responseJson = await response.json();

        if (responseJson) {
          if (responseJson.status) {
            if (responseJson.data.authenticated_user == "True") {
              if (responseJson.data != undefined) {
                setUsernameOrder(responseJson.data.name);
                setMailOrder(responseJson.data.email);
                setPincode(responseJson.data.pin_code);
              }
              setWrongOtp(false);
              setOtpVerified(true);
              setresend(false);
              setUserIdenty(responseJson.data.user_id);
              setMobilenoOrder(responseJson.data.phone_num);
              dispatch(AddOrderToken({ orderToken: responseJson.data.token }));
              let userbookingdata = {
                bikecolor: bikeColorName,
                username: usernameOrder,
                useremail: mailOrder,
                userphonenumber: responseJson.data.phone_num,
                userpincode: pincode,
                userpackagename: packageName,
                time: new Date().getTime(),
              };
              localStorage.setItem(
                "userbooknowdata",
                JSON.stringify(userbookingdata)
              );
              setVerifyOtpOrderModal(false);
              payment();
              navigate("/orderconfirmation");
              const cookieData = getCookie(expiredata);
              if (!cookieData) {
                let userdetail = {
                  user_name: usernameOrder,
                  user_number: mobilenoOrder,
                  user_email: mailOrder,
                  user_token: responseJson.data.token,
                  user_id: responseJson.data.user_id,
                  user_pincode: pincode,
                };
                let date = new Date();
                date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
                const expires = "expires=" + date.toUTCString();
                document.cookie =
                  "userdata=" +
                  JSON.stringify(userdetail) +
                  "; " +
                  expires +
                  "; path=/";
                localStorage.setItem("pincode", JSON.stringify(pincode));
              }
            } else {
              setOtpVerified(true);
              setWrongOtp(true);
              setresend(false);
              setOtperror(false);
              clearTimeout(resent);
              setConfirmOtp("");
              return false;
            }
          } else {
            toast.error(responseJson.message, { autoClose: 3500 });
          }
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message, { autoClose: 3500 });
      }
      return false;
    }
  };

  async function stateChange(e) {
    var stateNames = "";
    try {
      setStateName(e.target.value);
      stateNames = e.target.value;
    } catch (e) {}
    const sendOtpOrderOptn2 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        state: stateNames == "" ? `${stateName}` : stateNames,
      }),
    };
    try {
      const responses = await fetch(Config.city_state, sendOtpOrderOptn2);
      const city = await responses.json();
      setCityList(city.cities);
    } catch (e) {
      console.log("not fetch City List" + e.message);
    }
  }

  async function changePackage(e) {
    setPackageName(e.target.value);
    if (e.target.value == "OXO Prime") {
      setMODAL_PRICE(OXO_MODAL_PRICES.OXO_PRIME);
    } else if (e.target.value == "OXO Prime Pro") {
      setMODAL_PRICE(OXO_MODAL_PRICES.OXO_PRIME_PRO);
    } else if (e.target.value == "OXO") {
      setMODAL_PRICE(OXO_MODAL_PRICES.OXO);
    } else if (e.target.value == "OXO Pro") {
      setMODAL_PRICE(OXO_MODAL_PRICES.OXO_PRO);
    } else if (e.target.value == "OXO-X") {
      setMODAL_PRICE(OXO_MODAL_PRICES.OXO_X);
    }
  }

  async function changeBikeColor(e) {
    setBikeColorName(e.target.value);
    let tab = e.target.value;
    if (tab === "True Black") {
      setCurrentImage(LOK_2808);
    } else if (tab === "Twilight Grey") {
      setCurrentImage(LOK_2765);
    } else if (tab === "Electric Yellow") {
      setCurrentImage(LOK_2779);
    } else if (tab === "Magnetic Blue") {
      setCurrentImage(LOK_2795);
    } else if (tab === "Candy Red") {
      setCurrentImage(LOK_2754);
    }
  }

  async function changeCity(e) {
    setCityName(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (mobNumberWanto.length < 10) {
      return false;
    }
    if (zipCodeWanto.length < 6) {
      return false;
    }
    try {
      setLoading(true);
      let data = {
        name: fullNameWanto,
        phone_num: mobNumberWanto,
        email: emailWanto,
        pin_code: zipCodeWanto,
        source: `Source: ${utmSource}, Medium: ${utmMedium}, Campaign: ${utmCampaign}`,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(Config.leads, requestOptions);

      if (response.status == 200) {
        setLoading(false);
        notify();
        setFullNameWanto("");
        setMobNumberWanto("");
        setEmailWanto("");
        setZipCodeWanto("");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message, { autoClose: 5000 });
    }
  };

  const getDataLocal = () => {
    if (localStorage.getItem("Source") == "null") {
      setUtmSourceBook("Direct");
    } else {
      setUtmSourceBook(localStorage.getItem("Source"));
    }
    if (localStorage.getItem("Medium") == "null") {
      setUtmMediumBook("Direct");
    } else {
      setUtmMediumBook(localStorage.getItem("Medium"));
    }
    if (localStorage.getItem("Campaign") == "null") {
      setUtmCampaignBook("Direct");
    } else {
      setUtmCampaignBook(localStorage.getItem("Campaign"));
    }
  };

  const payment = async () => {
    const requestOptionsForOtpVarification = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserDetails.OrderToken,
      },
      body: JSON.stringify({
        phone_num: `${mobilenoOrder}`,
        email: `${mailOrder}`,
        name: `${usernameOrder}`,
        pin_code: `${pincode}`,
        dealer_id: "",
        address: `City Name - ${cityName}, State Name - ${stateName}`,
        alt_num: ``,
        model: `${packageName}`,
        source: `OXO website (Source: ${utmSourceBook}, Medium: ${utmMediumBook}, Campaign: ${utmCampaignBook})`,
        user_id: `${userIdenty}`,
        landmark: "null",
        color: `${bikeColorName}`,
        flag: "admin",
      }),
    };
    try {
      const response = await fetch(
        Config.pre_booking_ticket,
        requestOptionsForOtpVarification
      );
      const json = await response.json();
      if (json.ticket_id != undefined) {
        navigate("/orderconfirmation/" + json.ticket_id);
      }
    } catch (error) {
      return false;
    }
  };

  const handleNameChangeWanto = (event) => {
    setFullNameWanto(event.target.value);
  };

  const handleMobNumberChangeWanto = (event) => {
    var nLength = event.target.value.length;

    if (event.target.value.length < 11) {
      setMobNumberWanto(event.target.value);
    }
    if (nLength < 10) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  };

  const onKeyPressNumberWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const handleEmailChangeWanto = (event) => {
    setEmailWanto(event.target.value);
    var regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(event.target.value) == true) {
      setEmailErrorWanto(true);
    } else {
      setEmailErrorWanto(false);
    }
  };

  const handleZipCodeChangeWanto = (event) => {
    var zLength = event.target.value.length;
    if (zLength < 7) {
      setZipCodeWanto(event.target.value);
    }
    if (zLength < 6) {
      setZipError(false);
    } else {
      setZipError(true);
    }
  };

  const getDataFromUrl = () => {
    const urlParamsSource = new URLSearchParams(window.location.search).get(
      "utm_source"
    );
    setUtmSource(urlParamsSource);
    localStorage.setItem("Source", urlParamsSource);
    const urlParamsMedium = new URLSearchParams(window.location.search).get(
      "utm_medium"
    );
    setUtmMedium(urlParamsMedium);
    localStorage.setItem("Medium", urlParamsMedium);
    const urlParamsCampaign = new URLSearchParams(window.location.search).get(
      "utm_campaign"
    );
    setUtmCampaign(urlParamsCampaign);
    localStorage.setItem("Campaign", urlParamsCampaign);
  };

  const onKeyPressZipWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const handleuserChangeOrder = (event) => {
    setUsernameOrder(event.target.value);
  };

  const handlemailChangeOrder = (event) => {
    setMailOrder(event.target.value);
    var Elength = event.target.value.length;
    var regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (regex.test(event.target.value) == true || Elength === 0) {
      setEmailErrorWanto(true);
    } else {
      setEmailErrorWanto(false);
    }
  };

  const handleSendOtpOrder = async () => {
    if (usernameOrder == "" || usernameOrder == " " || usernameOrder == null) {
      setUsernameErrorOrder(true);
      return false;
    } else {
      setUsernameErrorOrder(false);
    }

    if (
      mobilenoOrder == " " ||
      mobilenoOrder == "" ||
      mobilenoOrder == null ||
      mobilenoOrder.length < 10 ||
      mobilenoOrder.length > 10
    ) {
      setErrorPhoneNumber(true);
      return false;
    } else {
      setErrorPhoneNumber(false);
    }

    if (
      pincode == " " ||
      pincode == "" ||
      pincode == null ||
      pincode.length < 6
    ) {
      setZipError(false);
      return false;
    } else {
      setZipError(true);
    }

    try {
      const g_token = await captchaRef.current.executeAsync();

      if (g_token) {
        const sendOtpOrderOptn = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone_num: `${mobilenoOrder}`,
            otp: "",
            g_response: g_token,
          }),
        };
        const response = await fetch(Config.otp_varification, sendOtpOrderOptn);
        const responseJson = await response.json();
        if (responseJson) {
          if (responseJson.status) {
            let last = mobilenoOrder[mobilenoOrder.length - 1];
            let lastsecond = mobilenoOrder[mobilenoOrder.length - 2];
            setVerifyOtpOrderModal(true);
            setUserIdenty(responseJson.data.user_id);
            otpmsgs(60000);
            setLastTwo(lastsecond + "" + last);
            closeOrderModalOnContinue();
          } else {
            toast.error(responseJson.message, { autoClose: 3500 });
          }
        }
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3500 });
    } finally {
      captchaRef.current.reset();
    }
  };

  const checkCookie = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split(";");
    var f = parts.filter((e) => {
      if (e.trim() == "notice=1") return e;
    });
    var nt = "";
    if (f.length > 0) {
      nt = f[0].trimStart();
    }
    var ntFinal = nt.split("=")[1];
    if (ntFinal == "1") {
      setImpModal(false);
    } else {
      setImpModal(false);
    }
  };

  const closeOrderModal = () => {
    setModalOrder1(false);
    setUsernameOrder("");
    setMobilenoOrder("");
    setMailOrder("");
    setPincode("");
    setUsernameErrorOrder(false);
    setEmailErrorWanto(true);
    setDisplay(true);
    setZipError(true);
  };

  const closeOrderModalOnContinue = () => {
    setModalOrder1(false);
    setUsernameErrorOrder(false);
    setEmailErrorWanto(true);
    setDisplay(true);
    setZipError(true);
  };

  const setCookie = () => {
    setImpModal(false);
    var now = new Date();
    now.setTime(now.getTime() + 1 * 3600 * 1000);
    document.cookie = "notice=1; expires=" + now.toUTCString() + "; path=/";
  };

  $("#viewerImage").ready(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  });

  const otpmsgs = function (interval) {
    clearTimeout(resent);
    var a = setTimeout(() => {
      setOtperror(true);
    }, interval);
    setrenset(a);
  };

  // const onExecute = async (e) => {
  //   let response = await verifyCaptchaAPI(e);
  //   if (response && response.status == "success") {
  //     setShowCaptchaModal(false);
  //     handleSendOtpOrder();
  //   } else {
  //     captchaRef.current.reset();
  //   }
  // };

  // const onClose = () => {
  //   setShowCaptchaModal(false);
  // };

  return (
    <>
      <Header />

      <div id="home">
        <PureModal
          onClose={() => {
            setCookie();
            return true;
          }}
          scrollable={true}
          isOpen={impModal}
          className="imp_modal"
        >
          <h2>IMPORTANT NOTICE</h2>
          <h3>Hi There!</h3>
          <h3>
            Thanks to your love and patience, we have received a flood of
            pre-orders for Hop OXO.
          </h3>
          <p>
            Hop OXO is ready for deliveries now, but due to changes in
            government policies and regulations regarding the FAME II subsidy,
            the incentives disbursement is delayed. We have decided to start OXO
            deliveries without FAME II incentives in order to meet our delivery
            commitments. The effective price for "OXO" and "OXO PRO PACKAGE" has
            changed and will take effect from the 20th of October 2022. All pre
            bookings orders can now opt in for instant deliveries starting today
            without FAME II incentives.
          </p>
          <p>
            Now “OXO” is available with a peak range of 135 kms per charge and
            available in black colour only. “OXO PRO PACKAGE” (earlier OXO X) is
            fully specced with 150 Kms range per charge, colour options, Hop
            NURON Connectivity package & charger bag.
          </p>
          <p>Happy Hopping.</p>
        </PureModal>

        <TestRide />

        <div className="bike_color">
          <div className="landing_page_container">
            <div className="sec_heading">
              <h2>
                OXO 360<sup>0</sup>{" "}
              </h2>
            </div>
            <div className="img_bike">
              <figure>
                <div
                  style={{ width: "100%", display: "block", cursor: "pointer" }}
                  onClick={() => setScrollwise(true)}
                >
                  {loader == false ? (
                    <Tridi
                      ref={tridiRef}
                      location={image}
                      format="png"
                      count="100"
                      mousewheel
                      inverse={scrollwise}
                    />
                  ) : (
                    <BeatLoader color="#36d7b7" className="loader-img" />
                  )}
                </div>{" "}
              </figure>
              <div className="rotade_bike">
                <figure>
                  <img src={lines_bg} alt="imhvgb" />
                </figure>
                <div className="btn_slide_bike">
                  <span
                    onClick={NextRound}
                    style={{ backgroundColor: npcirecle1 }}
                  ></span>
                  <span style={{ backgroundColor: "#00EAFF" }}></span>
                  <span
                    onClick={PriviousRound}
                    style={{ backgroundColor: npcircle2 }}
                  ></span>
                </div>
              </div>
            </div>

            <div className="change_color_bike">
              <div className="hop_oxo">
                <img src={lan_logo} alt="imhvgb" />
              </div>
              <div className="color_step">
                <form>
                  <div className="form-check">
                    <input
                      onClick={() => changeCar("yellow")}
                      className={`form-check-input ${activeCircleYellow}`}
                      type="radio"
                      name="color_btn"
                      id="Yellow"
                    />
                    <label
                      onClick={() => changeCar("yellow")}
                      className="form-check-label"
                    >
                      Electric Yellow
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      onClick={() => changeCar("red")}
                      className={`form-check-input ${activeCircleRed}`}
                      type="radio"
                      name="color_btn"
                      id="Red"
                    />
                    <label
                      onClick={() => changeCar("red")}
                      className="form-check-label"
                    >
                      Candy Red
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      onClick={() => changeCar("blue")}
                      className={`form-check-input ${activeCircleBlue}`}
                      type="radio"
                      name="color_btn"
                      id="Blue"
                    />
                    <label
                      onClick={() => changeCar("blue")}
                      className="form-check-label"
                    >
                      Magnetic Blue
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      onClick={() => changeCar("grey")}
                      className={`form-check-input ${activeCircleGrey}`}
                      type="radio"
                      name="color_btn"
                      id="Grey"
                    />
                    <label
                      onClick={() => changeCar("grey")}
                      className="form-check-label"
                    >
                      Twilight Grey
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      onClick={() => changeCar("black")}
                      className={`form-check-input ${activeCircleBlack}`}
                      type="radio"
                      name="color_btn"
                      id="Black"
                    />
                    <label
                      onClick={() => changeCar("black")}
                      className="form-check-label"
                    >
                      True Black
                    </label>
                  </div>
                </form>
              </div>

              <div className="color_step onmobile_360">
                <form>
                  <div className="form-check">
                    <input
                      onClick={() => changeCar("yellow")}
                      className={`form-check-input ${activeCircleYellow}`}
                      type="radio"
                      name="color_btn"
                      id="Yellow"
                    />

                    <label
                      onClick={() => changeCar("yellow")}
                      className={`form-check-label yellow ${activeCircleYellow}`}
                    ></label>
                  </div>
                  <div className="form-check">
                    <input
                      onClick={() => changeCar("red")}
                      className={`form-check-input ${activeCircleRed}`}
                      type="radio"
                      name="color_btn"
                      id="Red"
                    />

                    <label
                      onClick={() => changeCar("red")}
                      className={`form-check-label red ${activeCircleRed}`}
                    ></label>
                  </div>
                  <div className="form-check">
                    <input
                      onClick={() => changeCar("blue")}
                      className={`form-check-input ${activeCircleBlue}`}
                      type="radio"
                      name="color_btn"
                      id="Blue"
                    />

                    <label
                      onClick={() => changeCar("blue")}
                      className={`form-check-label blue ${activeCircleBlue}`}
                    ></label>
                  </div>
                  <div className="form-check">
                    <input
                      onClick={() => changeCar("grey")}
                      className={`form-check-input ${activeCircleGrey}`}
                      type="radio"
                      name="color_btn"
                      id="Grey"
                    />
                    <label
                      onClick={() => changeCar("grey")}
                      className={`form-check-label grey ${activeCircleGrey}`}
                    ></label>
                  </div>

                  <div className="form-check">
                    <input
                      onClick={() => changeCar("black")}
                      className={`form-check-input ${activeCircleBlack}`}
                      type="radio"
                      name="color_btn"
                      id="Black"
                    />

                    <label
                      onClick={() => changeCar("black")}
                      className={`form-check-label black ${activeCircleBlack}`}
                    ></label>
                  </div>
                </form>
                <div className="mobile_labels_name">
                  <div className={`bike_color_names ${activeCircleYellow}`}>
                    {" "}
                    Electric Yellow
                  </div>
                  <div className={`bike_color_names ${activeCircleRed}`}>
                    {" "}
                    Candy Red
                  </div>
                  <div className={`bike_color_names ${activeCircleBlue}`}>
                    Magnetic Blue
                  </div>
                  <div className={`bike_color_names ${activeCircleGrey}`}>
                    Twilight Grey
                  </div>
                  <div className={`bike_color_names ${activeCircleBlack}`}>
                    True Black
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Features_section">
          <div className="features_step">
            <div className="landing_page_container fea_hea">
              <h2 className="sec_ste">Technology</h2>
            </div>
            <figure>
              <div className="vimeo-video">
                <iframe
                  src="https://player.vimeo.com/video/751206596?background=1&muted=1&autoplay=1&loop=1&"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  mozallowfullscreen="true"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  title="Performance - Web"
                ></iframe>
              </div>
              <div className="vimeo-video mobile">
                <iframe
                  src="https://player.vimeo.com/video/751206818?background=1&muted=1&autoplay=1&loop=1&"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  mozallowfullscreen="true"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  title="Performance - Web"
                ></iframe>
              </div>
            </figure>
            <div className="landing_page_container features_cont">
              <div className="featur_show">
                <div className="featur_para">
                  <h3>Electrified Riding Experience </h3>
                  <p>
                    Redefining the motorcycling experience an electric
                    motorcycle, Hop OXO is powered by state of the art 72 V
                    “eFlow electric powertrain” for a ride so smooth that you
                    will feel like riding an air carpet. No Noise. No
                    Vibrations. No Pollution.
                  </p>
                </div>
                <button
                  className="default_btn"
                  onClick={() => setModelSiliderOne(true)}
                >
                  Learn more
                </button>
              </div>
            </div>
            <div className="feature-content">
              <PureModal
                onClose={() => {
                  setModelSiliderOne(false);
                  return true;
                }}
                width="800px"
                scrollable={true}
                isOpen={modalsiliderone}
              >
                <SiliderOne />
              </PureModal>
            </div>
          </div>
        </div>

        <div className="Features_section">
          <div className="features_step">
            <div className="landing_page_container fea_hea">
              <h2 className="sec_ste">Performance</h2>
            </div>
            <figure>
              <div className="vimeo-video">
                <iframe
                  src="https://player.vimeo.com/video/751207765?background=1&muted=1&autoplay=1&loop=1&background=1"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  mozallowfullscreen="true"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  title="Performance - Web"
                ></iframe>
              </div>
              <div className="vimeo-video mobile">
                <iframe
                  src="https://player.vimeo.com/video/751207707?background=1&muted=1&autoplay=1&loop=1&background=1"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  mozallowfullscreen="true"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  title="Performance - Web"
                ></iframe>
              </div>
            </figure>
            <div className="landing_page_container features_cont">
              <div className="featur_show">
                <div className="featur_para">
                  <h3>A New Feeling. A Feeling Called Electric </h3>
                  <p>
                    Your everyday riding experience is set to reach a new
                    milestone. With optimised performance, Hop OXO is tuned to
                    conquer every terrain. It is good for you and your
                    environment—no more holes in your pocket or the ozone layer.
                  </p>
                </div>
                <button
                  className="default_btn"
                  onClick={() => setModelSiliderTwo(true)}
                >
                  Learn more
                </button>
              </div>
            </div>
            <PureModal
              onClose={() => {
                setModelSiliderTwo(false);
                return true;
              }}
              width="800px"
              scrollable={true}
              isOpen={modalsilidertwo}
            >
              <SiliderTwo />
            </PureModal>
          </div>
        </div>

        <div className="Features_section">
          <div className="features_step">
            <div className="landing_page_container fea_hea">
              <h2 className="sec_ste">Ergonomics & Styling</h2>
            </div>
            <figure>
              <div className="vimeo-video">
                <iframe
                  src="https://player.vimeo.com/video/751210254?background=1&muted=1&autoplay=1&loop=1&background=1"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  mozallowfullscreen="true"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  title="Performance - Web"
                ></iframe>
              </div>
              <div className="vimeo-video mobile">
                <iframe
                  src="https://player.vimeo.com/video/751210203?background=1&muted=1&autoplay=1&loop=1&background=1"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  mozallowfullscreen="true"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  title="Performance - Web"
                ></iframe>
              </div>
            </figure>
            <div className="landing_page_container features_cont">
              <div className="featur_show">
                <div className="featur_para">
                  <h3>Style That Makes Every Head Turn | Built Tough</h3>
                  <p>
                    Muscular design inspired by nature is engineered to suit
                    Indian Ergonomics. Hop OXO is a perfect everyday companion
                    and the best electric bike in India.
                  </p>
                </div>
                <button
                  className="default_btn"
                  onClick={() => setModelSiliderThree(true)}
                >
                  Learn more
                </button>
              </div>
            </div>
            <PureModal
              onClose={() => {
                setModelSiliderThree(false);
                return true;
              }}
              width="800px"
              scrollable={true}
              isOpen={modalsiliderthree}
            >
              <SiliderThree />
            </PureModal>
          </div>
        </div>

        <div className="Features_section">
          <div className="features_step">
            <div className="landing_page_container fea_hea">
              <h2 className="sec_ste">Safety</h2>
            </div>
            <figure>
              <div className="vimeo-video">
                <iframe
                  src="https://player.vimeo.com/video/751218973?background=1&muted=1&autoplay=1&loop=1&background=1"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  mozallowfullscreen="true"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  title="Performance - Web"
                ></iframe>
              </div>
              <div className="vimeo-video mobile">
                <iframe
                  src="https://player.vimeo.com/video/751218918?background=1&muted=1&autoplay=1&loop=1&background=1"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  mozallowfullscreen="true"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  title="Performance - Web"
                ></iframe>
              </div>
            </figure>
            <div className="landing_page_container features_cont">
              <div className="featur_show">
                <div className="featur_para">
                  <h3>Built Tough With Leading Safety Features</h3>
                  <p>
                    OXO is the new electric bike engineered for the highest
                    level of safety. From rugged chassis, double disc combi
                    braking system to mobile connected features like Anti-theft
                    alerts and geo-fencing, OXO’ is safe, reliable and robust.
                  </p>
                </div>
                <button
                  className="default_btn"
                  onClick={() => setModelSiliderFour(true)}
                >
                  Learn more
                </button>
              </div>
            </div>
            <PureModal
              onClose={() => {
                setModelSiliderFour(false);
                return true;
              }}
              width="800px"
              scrollable={true}
              isOpen={modalsiliderfour}
            >
              <SiliderFour />
            </PureModal>
          </div>
        </div>

        <div className="Features_section">
          <div className="features_step">
            <div className="landing_page_container fea_hea">
              <h2 className="sec_ste">Connected Technology</h2>
            </div>
            <figure>
              <img src={connected_technology} alt="imhvgb" />
            </figure>
            <div className="landing_page_container features_cont">
              <div className="featur_show">
                <div className="featur_para">
                  <h3>Loaded With Technology</h3>
                  <p>
                    OXO is designed with a lot of features that are available
                    onboard and can be accessed directly through the bike or can
                    be accessed via the HOP Nuron application.
                  </p>
                </div>
                <button
                  className="default_btn"
                  onClick={() => setModelSiliderFive(true)}
                >
                  Learn more
                </button>
              </div>
            </div>
            <PureModal
              onClose={() => {
                setModelSiliderFive(false);
                return true;
              }}
              width="800px"
              scrollable={true}
              isOpen={modalsiliderfive}
            >
              <SiliderFive />
            </PureModal>
          </div>
        </div>

        <div className="Features_section">
          <div className="features_step">
            <div className="landing_page_container fea_hea">
              <h2 className="sec_ste">Charging</h2>
            </div>
            <figure>
              <img src={charging} alt="imhvgb" />
            </figure>
            <div className="landing_page_container features_cont">
              <div className="featur_show">
                <div className="featur_para">
                  <h3>Smart Charging</h3>
                  <p>
                    Your Hop oxo comes with smart charge technology. Charge once
                    every three days.
                  </p>
                </div>
                <button
                  className="default_btn"
                  onClick={() => setModelSiliderSix(true)}
                >
                  Learn more
                </button>
              </div>
            </div>
            <PureModal
              onClose={() => {
                setModelSiliderSix(false);
                return true;
              }}
              width="800px"
              scrollable={true}
              isOpen={modalsilidersix}
            >
              <SiliderSix />
            </PureModal>
          </div>
        </div>

        <div className="tech_specs_sec">
          <div className="landing_page_container">
            <div className="sec_heading">
              <h2>Tech Specs</h2>
            </div>

            <p className="mobile-select-variant">Select Variant</p>
            <div className="btn-group-price oxo_both">
              <button
                className={`btn-price-oxo  ${activeBtnOxoPrime1}`}
                onClick={() => handleOxoBtn1("oxoPrime")}
              >
                OXO Prime
              </button>
              <button
                className={`btn-price-oxo ${activeBtnOxo1}`}
                onClick={() => handleOxoBtn1("oxo")}
              >
                OXO
              </button>
              <button
                className={`btn-price-oxo  ${activeBtnOxoX1}`}
                onClick={() => handleOxoBtn1("oxox")}
              >
                OXO - X
              </button>
            </div>

            <ul className={`tech_deails Specs_conbg  ${opneContentModal}`}>
              <div className="newtech_specs">
                <ul className="top_sec_oxo_x">
                  <li></li>
                  <li>
                    {" "}
                    <strong>OXO Prime</strong>{" "}
                    <p className="coming_soon_sec">(Coming Soon)</p>
                  </li>
                  <li>
                    {" "}
                    <strong>OXO</strong>{" "}
                  </li>
                  <li>
                    <strong>OXO - X</strong>
                    <p className="coming_soon_sec">(Coming Soon)</p>
                  </li>
                </ul>
                <span className="coming_soon_movbike">
                  {tabindex == "oxox"
                    ? "Coming Soon"
                    : tabindex == "oxoPrime"
                    ? "Coming Soon"
                    : tabindex == "oxo"
                    ? ""
                    : ""}
                </span>
                <h5>Performance</h5>
                <ul>
                  <li className="firs_heading">Top Speed</li>
                  <li>82 Kmph </li>
                  <li>88Kmph </li>
                  <li>95 Kmph*</li>
                  <li className="firs_heading">True Range (Eco Mode)</li>
                  <li>120kms </li>
                  <li>140kms </li>
                  <li>150Kms</li>
                  <li className="firs_heading">True Range (Power Mode)</li>
                  <li>80Kms</li>
                  <li>90Kms</li>
                  <li>100kms </li>
                  <li className="firs_heading">True Range (Sport Mode)</li>
                  <li>60kms</li>
                  <li>65kms</li>
                  <li>70Kms</li>
                  <li className="firs_heading">Voltage Architecture </li>
                  <li>72 V</li>
                  <li>72 V</li>
                  <li>72 V</li>
                  <li className="firs_heading">Motor Power (Peak)</li>
                  <li>4.2 kw</li>
                  <li>5.2Kw</li>
                  <li>6.2kw</li>
                  <li className="firs_heading">Max. Torque (At Wheel)</li>
                  <li>160Nm</li>
                  <li>175Nm</li>
                  <li>200Nm</li>
                  <li className="firs_heading">Motor Type </li>
                  <li>BLDC Hub motor</li>
                  <li>BLDC Hub motor</li>
                  <li>BLDC Hub motor</li>
                  <li className="firs_heading">Controller </li>
                  <li>Sinusoidal FOC Vector Control</li>
                  <li>Sinusoidal FOC Vector Control </li>
                  <li>Sinusoidal FOC Vector Control</li>
                  <li className="firs_heading">Riding Modes</li>
                  <li>Eco-Power-Sport</li>
                  <li>Eco-Power-Sport </li>
                  <li>Eco-Power-Sport-Turbo </li>
                  <li className="firs_heading">Top Speed (ECO Mode)</li>
                  <li>45 Kmph</li>
                  <li>45 Kmph</li>
                  <li>45 Kmph</li>
                </ul>
              </div>
            </ul>

            <div className="click_pop">
              <button className="open_pop_on" onClick={openTechSpace}>
                <img src={Group_img} alt="imhvgb" />
              </button>
            </div>
            <div className={`modal-content  ${opneContentModal}`}>
              <PureModal
                onClose={() => {
                  setModal3(false);
                  return true;
                }}
                width="800px"
                scrollable={true}
                isOpen={modal3}
              >
                <div className="sec_heading on_pop_Specs">
                  <h2>Tech Specs </h2>
                  <h2>
                    {tabindex == "oxox"
                      ? "OXO-X"
                      : tabindex == "oxoPrime"
                      ? "OXO Prime"
                      : tabindex == "oxo"
                      ? "OXO"
                      : ""}{" "}
                    {tabindex == "oxo" ? "" : "("}
                    <span className="coming_soon_movbike ">
                      {tabindex == "oxox"
                        ? "Coming Soon"
                        : tabindex == "oxoPrime"
                        ? "Coming Soon"
                        : tabindex == "oxo"
                        ? ""
                        : ""}
                    </span>
                    {tabindex == "oxo" ? "" : ")"}
                  </h2>
                </div>

                <ul className="tech_deails on_popup">
                  <div className="newtech_specs">
                    <ul className="top_sec_oxo_x">
                      <li></li>
                      <li>
                        {" "}
                        <strong>OXO Prime</strong>{" "}
                        <p className="coming_soon_sec">(Coming Soon)</p>
                      </li>
                      <li>
                        {" "}
                        <strong>OXO</strong>{" "}
                      </li>
                      <li>
                        <strong>OXO - X </strong>
                        <p className="coming_soon_sec">(Coming Soon)</p>
                      </li>
                    </ul>

                    <h5>Performance</h5>
                    <ul>
                      <li className="firs_heading">Top Speed</li>
                      <li>82 Kmph </li>
                      <li>88 Kmph </li>
                      <li>95 Kmph*</li>
                      <li className="firs_heading">True Range (Eco Mode)</li>
                      <li>120kms </li>
                      <li>140kms </li>
                      <li>150Kms</li>
                      <li className="firs_heading">True Range (Power Mode)</li>
                      <li>80 Kms</li>
                      <li>90Kms</li>
                      <li>100kms </li>
                      <li className="firs_heading">True Range (Sport Mode)</li>
                      <li>60 kms</li>
                      <li>65kms</li>
                      <li>70Kms</li>
                      <li className="firs_heading">Voltage Architecture </li>
                      <li>72 V</li>
                      <li>72 V</li>
                      <li>72 V</li>
                      <li className="firs_heading">Motor Power (Peak)</li>
                      <li>4.2 kw</li>
                      <li>5.2Kw</li>
                      <li>6.2kw</li>
                      <li className="firs_heading">Max. Torque (At Wheel)</li>
                      <li>160Nm</li>
                      <li>175Nm</li>
                      <li>200Nm</li>
                      <li className="firs_heading">Motor Type </li>
                      <li>BLDC Hub motor</li>
                      <li>BLDC Hub motor</li>
                      <li>BLDC Hub motor</li>
                      <li className="firs_heading">Controller </li>
                      <li>Sinusoidal FOC Vector Control </li>
                      <li>Sinusoidal FOC Vector Control </li>
                      <li>Sinusoidal FOC Vector Control</li>
                      <li className="firs_heading">Riding Modes</li>
                      <li>Eco-Power-Sport </li>
                      <li>Eco-Power-Sport </li>
                      <li>Eco-Power-Sport-Turbo </li>
                      <li className="firs_heading">Top Speed (ECO Mode)</li>
                      <li>45 Kmph</li>
                      <li>45 Kmph</li>
                      <li>45 Kmph</li>
                      <li className="firs_heading">Top Speed (Power Mode)</li>
                      <li>65 kmph </li>
                      <li>65 kmph </li>
                      <li>65 kmph</li>
                      <li className="firs_heading">Top Speed (Sport Mode) </li>
                      <li>82 kmph </li>
                      <li>88 kmph </li>
                      <li>88 kmph</li>
                      <li className="firs_heading">Top Speed (Turbo Mode)</li>
                      <li>NA </li>
                      <li>NA</li>
                      <li>95 kmph</li>
                      <li className="firs_heading">
                        Max Acceleration(0-40km/h)
                      </li>
                      <li>6 Sec</li>
                      <li>5 Sec</li>
                      <li>4 Sec**</li>
                      <li className="firs_heading">Climbing Ability</li>
                      <li>13 Degree</li>
                      <li>15 Degree</li>
                      <li>18 Degree</li>
                    </ul>

                    <h5>Battery </h5>
                    <ul>
                      <li className="firs_heading">Battery Type</li>
                      <li>Lithium-ion</li>
                      <li>Lithium-ion</li>
                      <li>Lithium-ion</li>
                      <li className="firs_heading">Installed capacity </li>
                      <li>3.75 Kwh</li>
                      <li>3.75 Kwh</li>
                      <li>3.75 Kwh</li>
                      <li className="firs_heading">Usable Capacity </li>
                      <li>3 kWh</li>
                      <li>3.37Kwh</li>
                      <li>3.61 kWh</li>
                      <li className="firs_heading">Battery positioning</li>
                      <li>Fixed</li>
                      <li>Fixed</li>
                      <li>Fixed</li>
                    </ul>

                    <h5>Charging</h5>
                    <ul>
                      <li className="firs_heading">Charging method </li>
                      <li>On vehicle, using Off-board charger</li>
                      <li>On vehicle, using Off-board charger</li>
                      <li>On vehicle, using Off-board charger</li>
                      <li className="firs_heading">Smart Charger </li>
                      <li>850W </li>
                      <li>850W </li>
                      <li>850W</li>
                      <li className="firs_heading">Charging Time (0-80%) </li>
                      <li>4hr</li>
                      <li>4hr 15mins </li>
                      <li>4hr 45Mins </li>
                      <li className="firs_heading">Charging Time (0-100%)</li>
                      <li>4hr 45Mins</li>
                      <li>5hr</li>
                      <li>5hr 30Mins</li>
                    </ul>

                    <h5>Suspension</h5>
                    <ul>
                      <li className="firs_heading">Front</li>
                      <li>Upright Telescopic Forks</li>
                      <li>Upright Telescopic Forks</li>
                      <li>Upright Telescopic Forks</li>
                      <li className="firs_heading">Rear </li>
                      <li>Hydraulic spring-loaded shock absorber </li>
                      <li>Hydraulic spring-loaded shock absorber </li>
                      <li>Hydraulic spring-loaded shock absorber </li>
                    </ul>

                    <h5>Braking</h5>
                    <ul>
                      <li className="firs_heading">Front </li>
                      <li>Disc- 240mm</li>
                      <li>Disc- 240mm</li>
                      <li>Disc- 240mm</li>
                      <li className="firs_heading">Rear </li>
                      <li>Disc- 220mm</li>
                      <li>Disc- 220mm</li>
                      <li>Disc- 220mm</li>
                      <li className="firs_heading">Brake type</li>
                      <li>Disc-Disc with Combi brake system</li>
                      <li>Disc-Disc with Combi brake system </li>
                      <li>Disc-Disc with Combi brake system </li>
                      <li className="firs_heading">Regenerative Braking </li>
                      <li>Yes, with adjustable settings</li>
                      <li>Yes, with adjustable settings</li>
                      <li>Yes, with adjustable settings</li>
                    </ul>

                    <h5>Display </h5>
                    <ul>
                      <li className="firs_heading">Display type</li>
                      <li>5inch Smart LCD Display </li>
                      <li>5inch Smart LCD Display </li>
                      <li>5inch Smart LCD Display </li>
                      <li className="firs_heading">IP rating for Display </li>
                      <li>IP67 </li>
                      <li>IP67 </li>
                      <li>IP67</li>
                      <li className="firs_heading">Max illumination Level</li>
                      <li>1000 cd/m2</li>
                      <li>1000 cd/m2</li>
                      <li>1000 cd/m2</li>
                      <li className="firs_heading">Background colour</li>
                      <li>White illumination </li>
                      <li>White illumination </li>
                      <li>White illumination </li>
                    </ul>

                    <h5>Wheel </h5>
                    <ul>
                      <li className="firs_heading">Wheel type</li>
                      <li>High Strength Aluminium Alloy </li>
                      <li>High Strength Aluminium Alloy </li>
                      <li>High Strength Aluminium Alloy </li>
                      <li className="firs_heading">Wheel Size (F&R) </li>
                      <li>18 inch Front, 17 inch Rear </li>
                      <li>18 inch Front, 17 inch Rear </li>
                      <li>18 inch Front, 17 inch Rear </li>
                      <li className="firs_heading">Tyre Size (Front) </li>
                      <li>90/90-r18 </li>
                      <li>90/90-r18 </li>
                      <li>90/90-r18</li>
                      <li className="firs_heading">Tyre Size (Rear) </li>
                      <li>130/70-r17</li>
                      <li>130/70-r17</li>
                      <li>130/70-r17</li>
                    </ul>

                    <h5>Lightning </h5>
                    <ul>
                      <li className="firs_heading">Headlight </li>
                      <li>Yes, with adjustable controls </li>
                      <li>Yes, with adjustable controls </li>
                      <li>Yes, with adjustable controls</li>
                      <li className="firs_heading">Tail Light </li>
                      <li>Yes</li>
                      <li>Yes</li>
                      <li>Yes</li>
                      <li className="firs_heading">Indicator </li>
                      <li>Yes</li>
                      <li>Yes</li>
                      <li>Yes</li>
                      <li className="firs_heading">
                        Day time running lights (DRL){" "}
                      </li>
                      <li>Yes</li>
                      <li>Yes</li>
                      <li>Yes</li>
                    </ul>

                    <h5>Structural</h5>
                    <ul>
                      <li className="firs_heading">Dimensions </li>
                      <li>L2100xW793xH1065 </li>
                      <li>L2100xW793xH1065 </li>
                      <li>L2100xW793xH1065</li>
                      <li className="firs_heading">Ground Clearance </li>
                      <li>180mm </li>
                      <li>180mm </li>
                      <li>180mm</li>
                      <li className="firs_heading">Seat height</li>
                      <li>780mm </li>
                      <li>780mm </li>
                      <li>780mm</li>
                      <li className="firs_heading">Kerb Weight</li>
                      <li>140kgs </li>
                      <li>143kgs </li>
                      <li>144kgs </li>
                      <li className="firs_heading">Loading Capacity </li>
                      <li>250kgs </li>
                      <li>250kgs </li>
                      <li>250kgs </li>
                    </ul>

                    <h5>IP Rating </h5>
                    <ul>
                      <li className="firs_heading">Motor </li>
                      <li>IP 67 </li>
                      <li>IP 67 </li>
                      <li>IP 67</li>
                      <li className="firs_heading">Controller </li>
                      <li>IP 65 </li>
                      <li>IP 65 </li>
                      <li>IP 65</li>
                      <li className="firs_heading">Battery </li>
                      <li>IP 67 </li>
                      <li>IP 67 </li>
                      <li>IP 67</li>
                      <li className="firs_heading">Display </li>
                      <li>IP 67 </li>
                      <li>IP 67 </li>
                      <li>IP 67</li>
                    </ul>

                    <h5>Connectivity </h5>
                    <ul>
                      <li className="firs_heading">Internet </li>
                      <li>NA</li>
                      <li>NA</li>
                      <li>YES, 4G LTE CAT4</li>
                      <li className="firs_heading">GPS</li>
                      <li>NA</li>
                      <li>NA</li>
                      <li>YES, GNSS with AGPS</li>
                      <li className="firs_heading">Bluetooth </li>
                      <li>NA</li>
                      <li>NA</li>
                      <li>BLE 5.0 </li>
                      <li className="firs_heading">Compute </li>
                      <li>YES</li>
                      <li>YES</li>
                      <li>Edge Analytics core engine</li>
                      <li className="firs_heading">Security</li>
                      <li>NA</li>
                      <li>NA</li>
                      <li>128bit Encryption (Edge to cloud security)</li>
                    </ul>

                    <h5>Smart Features </h5>
                    <ul>
                      <li className="firs_heading">Hop Nuron Mobile App</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">
                        Turn by Turn Navigation with distance mapping{" "}
                      </li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">Music Streaming</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">Artificial Sounds</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>

                      <li className="firs_heading">Phone & SMS Alerts</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">
                        Geofencing with speed control
                      </li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">
                        Vehicle & Trip Stats monitoring
                      </li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">Service Support</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">Crash & Tow Alerts</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">Accidental SOS Alerts</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">RSA Support</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">
                        Adaptive Display brightness adjustment
                      </li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">OTA updates</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">
                        Remote location monitoring
                      </li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">
                        Remote charging Monitoring
                      </li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">
                        TTC(Time to charge) Indicator
                      </li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">Rider Score</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">Live Efficiency tracker</li>
                      <li>Optional with Pro Pack^</li>
                      <li>Optional with Pro Pack^</li>
                      <li>YES</li>
                      <li className="firs_heading">Park Assist(Reverse)</li>
                      <li>Yes, Upto 5Kmph</li>
                      <li>Yes, Upto 5Kmph</li>
                      <li>Yes, Upto 5Kmph</li>
                    </ul>

                    <h5>Warranty</h5>
                    <ul>
                      <li className="firs_heading">Vehicle </li>
                      <li>3 Years or 50,000 Kms</li>
                      <li>3 Years or 50,000 Kms </li>
                      <li>3 Years or 50,000 Kms</li>
                      <li className="firs_heading">Battery</li>
                      <li>3 Years or 50,000Kms</li>
                      <li>3 Years or 50,000Kms</li>
                      <li>4 Years or Unlimited Kms***</li>
                      <li className="firs_heading">Motor </li>
                      <li>3 Years </li>
                      <li>3 Years </li>
                      <li>3 Years </li>
                      <li className="firs_heading">Charger </li>
                      <li>3 Years </li>
                      <li>3 Years </li>
                      <li>3 Years </li>
                      <li className="firs_heading">Controller </li>
                      <li>3 Years </li>
                      <li>3 Years </li>
                      <li>3 Years </li>
                    </ul>

                    <h5>Colors</h5>
                    <ul>
                      <li className="firs_heading">Colors Available</li>
                      <li>
                        <ul className="color-ul">
                          <li>Twilight Grey</li>
                          <li>Candy Red</li>
                          <li>Midnight Blue</li>
                          <li>Electric Yellow</li>
                          <li>True Black</li>
                        </ul>
                      </li>
                      <li>
                        <ul className="color-ul">
                          <li>Twilight Grey</li>
                          <li>Candy Red</li>
                          <li>Midnight Blue</li>
                          <li>Electric Yellow</li>
                          <li>True Black</li>
                        </ul>
                      </li>
                      <li>
                        <ul className="color-ul">
                          <li>Twilight Grey</li>
                          <li>Candy Red</li>
                          <li>Midnight Blue</li>
                          <li>Electric Yellow</li>
                          <li>True Black</li>
                        </ul>
                      </li>
                    </ul>

                    <ul>
                      <li className="firs_heading">RTO Registration </li>
                      <li>Yes, Required </li>
                      <li>Yes, Required </li>
                      <li>Yes, Required </li>
                    </ul>
                    <h5>T&C</h5>
                    <ul className="last_ul_tech">
                      <li>95 Kmph* </li>
                      <li>Under Turbo Mode with single rider </li>
                      <li>4 Secs** </li>
                      <li>Under Tubro mode with Single rider</li>
                      <li>Unlimited Kms***</li>
                      <li>
                        Applicable under Fair Usage Policy (upto 1600kms per
                        month){" "}
                      </li>
                      <li>^Pro pack </li>
                      <li>
                        Available as accessory for OXO, OXO prime at additional
                        cost basis{" "}
                      </li>
                    </ul>
                  </div>
                </ul>
              </PureModal>
            </div>
          </div>
        </div>

        <div className="pricing_sec" id="price_sec" ref={priceSecRef}>
          <div className="landing_page_container">
            <div className="sec_heading">
              <h2>Pricing</h2>
            </div>
            <div className="content">
              <div className="left_sec">
                <div className="image">
                  <img src={currentImage} alt="Image" />
                </div>
              </div>
              <div className="right_sec">
                <div className="oxo">
                  <div className="select-sec">
                    <p className="price-select-state varient">Select State</p>
                    <select
                      className="select-price-btn1"
                      onChange={stateChange}
                      value={stateName}
                    >
                      {stateList.map((item, index) => {
                        return (
                          <option value={stateList[index]} key={index}>
                            {stateList[index]}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="select-sec last">
                    <p className="price-select-city varient">Select City</p>
                    <select
                      className="select-price-btn2"
                      onChange={changeCity}
                      value={cityName}
                    >
                      {cityList.map((item, index) => {
                        return <option key={index}>{cityList[index]}</option>;
                      })}
                    </select>
                  </div>
                  <div className="select-sec package">
                    <p className="price-select-city varient">Select Variant</p>
                    <select
                      className="select-price-btn2"
                      onChange={changePackage}
                      value={packageName}
                    >
                      <option value="OXO Prime">OXO Prime</option>
                      <option value="OXO Prime Pro">OXO Prime Pro</option>
                      <option value="OXO">OXO</option>
                      <option value="OXO Pro">OXO Pro</option>
                      <option value="OXO-X">OXO-X</option>
                    </select>
                  </div>
                  <div className="select-sec color">
                    <p className="price-select-city varient">Select Colour</p>
                    <select
                      className="select-price-btn2"
                      onChange={changeBikeColor}
                      value={bikeColorName}
                    >
                      <>
                        <option value="True Black">True Black</option>
                        <option value="Twilight Grey">Twilight Grey</option>
                        <option value="Electric Yellow">Electric Yellow</option>
                        <option value="Magnetic Blue">Magnetic Blue</option>
                        <option value="Candy Red">Candy Red</option>
                      </>
                    </select>
                  </div>
                  <div className="price-content">
                    <ul>
                      <li>
                        <span className="left">
                          Ex Showroom Electric Bike Price (Including Charger)*
                        </span>
                        <span className="right">
                          {" "}
                          ₹ {priceData && priceData?.ex_showroom_price_including_charger.toLocaleString("en-IN")}
                          .00
                          {/* ₹ { priceData && priceData?.effective_on_road_price.toLocaleString("en-IN")}.00 */}
                        </span>
                      </li>
                      <li>
                        <span className="left">EMI Starts at #</span>
                        <span className="right">
                          ₹ {priceData && priceData?.emi_starts_at.toLocaleString("en-IN")}.00{" "}
                          {/* ₹ {OXO_EMI_STARTS.toLocaleString("en-IN")}.00{" "} */}
                        </span>
                      </li>
                      <li>
                        <button
                          className="book-nowbtn"
                          onClick={() => openOrderModal("userdata")}
                          disabled={
                            priceData && Object.keys(priceData).length === 0
                          }
                        >
                          Book now @ ₹999{" "}
                        </button>
                      </li>
                    </ul>

                    <p className="info">
                      *Above listed price is the minimum ex-showroom rate in
                      India. Please note that this price does not include
                      insurance, registration, road tax, or accessories, which
                      will be charged separately based on actual costs.
                    </p>
                    <p className="info">
                      # EMI is calculated over a 36-month tenure with a minimum
                      20% downpayment. EMI is subject to the consumer meeting
                      the eligibility criteria of different financing partners.
                    </p>
                    {/* <p className="info">
                      ^ For More information on Insurance, Registration,
                      Handling Charges and State Subsidies which may differ
                      across states, kindly reach out to your nearest Experience
                      Center/Showroom.
                    </p> */}
                    <div className="mobile">
                      <button
                        className="book-nowbtn"
                        onClick={() => openOrderModal("userdata")}
                        // disabled={Object.keys(priceData).length === 0}
                      >
                        Book now @ ₹999{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReCAPTCHAModal
          recaptchaRef={captchaRef}
          // onClose={onClose}
          // showCaptcha={showCaptchaModal}
          // onExecute={onExecute}
          on={"logincaptcha"}
        />
        <div
          className="form-group modal_popup test_ride_popup login_popup_detail"
          id="otpsendOrder"
        >
          <PureModal
            onClose={() => {
              closeOrderModal();
              return true;
            }}
            isOpen={modalOrder1}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            {/* {showCaptchaModal ? ( */}

            {/* ) : ( */}
            <div className="popup_cont color_change">
              <div className="mobile_showlogo">
                <img src={popuplogomo} alt="imhvgb" />
              </div>
              <h2>Give us Some Details</h2>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="react_form_group gi_nameinp">
                  <div className="mo_numb">
                    <input
                      required
                      type="text"
                      value={usernameOrder}
                      onChange={handleuserChangeOrder}
                      placeholder="Name"
                      onKeyPress={onlyAlphabetsAllowed}
                    />
                    <div className="in_img">
                      <img className="slide_des" src={user_n} alt="imhvgb" />
                    </div>
                    {usernameErrorOrder == true ? (
                      <span className="error pl-4">Required Name *</span>
                    ) : null}
                  </div>
                </div>

                <div className="react_form_group india_f">
                  <div className="mo_numb number">
                    <input
                      required
                      type="text"
                      value={mobilenoOrder}
                      onChange={handleMobileOrder}
                      placeholder="Mobile Number"
                      onKeyPress={onKeyPressNumberWanto}
                    />
                    <div className="in_img">
                      <img className="slide_des" src={india_mo} alt="imhvgb" />
                      <span>+91</span>
                    </div>
                    {!display && (
                      <span className="error">Invalid Phone Number</span>
                    )}
                  </div>
                </div>

                <div
                  className="react_form_group"
                  style={{ marginBottom: "12px !important" }}
                >
                  <div
                    className="mo_numb"
                    style={{ marginBottom: "0px !important" }}
                  >
                    <input
                      type="email"
                      value={mailOrder}
                      onChange={handlemailChangeOrder}
                      placeholder="Email id"
                    />
                    <div className="in_img">
                      <img className="slide_des" src={email_id} alt="imhvgb" />
                    </div>
                    {!emailErrorWanto && (
                      <span className="error">Invalid Email Id</span>
                    )}
                  </div>
                </div>

                <div className="react_form_group ser_mailmo">
                  <div className="mo_numb">
                    <input
                      required
                      type="text"
                      value={pincode}
                      onChange={handlePincode}
                      placeholder="Pin Code"
                      onKeyPress={onKeyPressZipWanto}
                    />
                    <div className="in_img"></div>
                    {!zipError && (
                      <span className="error">Invalid Pin Code</span>
                    )}
                  </div>
                </div>

                <div className="react_form_group next_popu">
                  <button
                    onClick={handleSendOtpOrder}
                    className="default_btn"
                    disabled={!emailErrorWanto || !display}
                  >
                    Continue to order
                  </button>
                </div>
                <div className="react_form_group by_continuing">
                  <p>
                    {" "}
                    By continuing, you agree to
                    <Link to="/TermsService" className="privacy_po">
                      {" "}
                      T&C{" "}
                    </Link>
                    &
                    <Link to="/privacy-policy" className="privacy_po">
                      {" "}
                      Privacy Policy
                    </Link>
                  </p>
                </div>
              </form>
            </div>
            {/* )} */}
          </PureModal>
        </div>

        <div
          className="form-group modal_popup test_ride_popup login_popup_detail login_popup_detail"
          id="verifyOtpOrder"
        >
          <PureModal
            onClose={() => {
              setVerifyOtpOrderModal(false);
              return true;
            }}
            isOpen={verifyOtpOrderModal}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change mo_optpopup">
              <div className="mobile_showlogo">
                <img src={popuplogomo} alt="imhvgb" />
                <h2>Give Us Some Details</h2>
              </div>
              <h2 className="otp_send">Enter the OTP sent to</h2>
              <h2 className="otp_no">+91********{lasttwo}</h2>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="react_form_group">
                  <label>
                    <span
                      style={{
                        color:
                          wrongOtp === true ||
                          otperror === true ||
                          resends === true
                            ? "red"
                            : "",
                      }}
                    >
                      {!otperror && !otpVerified && !resends
                        ? ""
                        : otperror && !otpVerified
                        ? "Didn’t get the OTP?"
                        : otpVerified
                        ? "Incorrect or Invalid OTP."
                        : resends
                        ? "OTP Successfully Resent."
                        : ""}{" "}
                    </span>{" "}
                    {otperror || otpVerified ? (
                      <span
                        style={{
                          color: changecolor ? "lightgreen" : "#0000EE",
                          cursor: "pointer",
                        }}
                        onClick={sendOtp}
                      >
                        Resend?
                      </span>
                    ) : (
                      ""
                    )}{" "}
                  </label>
                  <input
                    required
                    type="text"
                    value={confirmOtp}
                    onChange={handleConfirmOTPChange}
                    placeholder="X X X X X X"
                    onKeyPress={onKeyPressZipWanto}
                  />
                </div>
                <div className="react_form_group next_popu">
                  <button onClick={confirotp} className="default_btn">
                    Continue to order
                  </button>
                </div>
                <div className="react_form_group by_continuing">
                  <p>
                    {" "}
                    By continuing, you agree to
                    <Link to="/TermsService" className="privacy_po">
                      {" "}
                      T&C{" "}
                    </Link>
                    &
                    <Link to="/privacy-policy" className="privacy_po">
                      {" "}
                      Privacy Policy
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </PureModal>
        </div>

        <div className="locater home_testride_page">
          <div className="landing_page_container">
            <div className="sec_heading">
              <h2>Dealer Locater</h2>
            </div>
          </div>
          <div>
            <BookTestRide landingPage={"landingPageTestride"} />
          </div>
        </div>

        <div className="Want_knowmore" id="knowmore_form">
          <div className="uk-container">
            <div className="sec_heading">
              <h2>Want to know more</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <fieldset className="uk-fieldset foem_cus">
                <div className="form_input">
                  <legend className="uk-legend">
                    Name <span> *</span>
                  </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input uk-form-controls"
                      required
                      type="text"
                      value={fullNameWanto}
                      onChange={handleNameChangeWanto}
                    />
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">
                    Mobile Number <span> *</span>
                  </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input"
                      type="text"
                      required
                      value={mobNumberWanto}
                      onChange={handleMobNumberChangeWanto}
                      name="number"
                      onKeyPress={onKeyPressNumberWanto}
                    />
                    {!display && (
                      <span className="error">Invalid Phone Number</span>
                    )}
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">
                    Email ID <span> *</span>
                  </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input"
                      type="email"
                      value={emailWanto}
                      required
                      onChange={handleEmailChangeWanto}
                    />
                    {!emailErrorWanto && (
                      <span className="error">Invalid Email Id</span>
                    )}
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">
                    Pincode <span> *</span>
                  </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input"
                      type="text"
                      required
                      value={zipCodeWanto}
                      onChange={handleZipCodeChangeWanto}
                      onKeyPress={onKeyPressZipWanto}
                    />
                    {!zipError && (
                      <span className="error">Invalid Pin Code</span>
                    )}
                  </div>
                </div>

                <div className="btn_sec">
                  {isLoading ? (
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "52px", height: "52px" }}
                        src={SPINNER}
                      />
                    </p>
                  ) : (
                    <button
                      className="uk-button"
                      id="want_more_btn"
                      type="submit"
                      disabled={
                        !fullNameWanto ||
                        !mobNumberWanto ||
                        !emailWanto ||
                        !zipCodeWanto ||
                        !zipError ||
                        !emailErrorWanto ||
                        !display ||
                        isLoading
                      }
                    >
                      Submit Request
                    </button>
                  )}
                </div>
              </fieldset>
            </form>
          </div>
        </div>

        <div className="marque_heading">
          <div className="featured_heading">
            <h2>FEATURED IN</h2>
          </div>
          <div className="marquee_sec_home" id="knowmore_form1">
            <Featured />
          </div>
        </div>

        <FooterLanding />
      </div>
    </>
  );
};
export default LandingPage;
