import React, { useEffect, useState, useRef } from 'react'
import "./index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons'


function Section4() {
  const [activeBtnOxoX1, setActiveBtnOxoX1] = useState("");
  const [activeBtnOxoPrime1, setActiveBtnOxoPrime1] = useState("activeBtnOxo11");
  const [tabindex, settabindex] = useState("oxoPrime");
  const [activeBtnOxo1, setActiveBtnOxo1] = useState("");
  const [opneContentModal, setOpneContentModal] = useState(
    "ActiveOxoPrimeContent"
  );

  const handleOxoBtn1 = (key) => {
    settabindex(key);
    if (key == "oxo") {
      setActiveBtnOxo1("activeBtnOxo11");
      setOpneContentModal("ActiveOxoContent");
      setActiveBtnOxoX1("");
      setActiveBtnOxoPrime1("");
    } else if (key == "oxox") {
      setActiveBtnOxoX1("activeBtnOxo11");
      setOpneContentModal("ActiveOxoXContent");
      setActiveBtnOxo1("");
      setActiveBtnOxoPrime1("");
    } else if (key == "oxoPrime") {
      setActiveBtnOxoPrime1("activeBtnOxo11");
      setOpneContentModal("ActiveOxoPrimeContent");
      setActiveBtnOxoX1("");
      setActiveBtnOxo1("");
    } else {
      setActiveBtnOxo1("");
      setActiveBtnOxoX1("activeBtnOxo11");
      setOpneContentModal("ActiveOxoXContent");
    }
  };
  return (
    <>
      {/* For desk view only */}
      <div className="sec_content_steper">
        <div className="container_about_us">
          <h2>Timeline</h2>
          <div className="wrapper-progressBar">
            <div className="step-headings">
              <div className="step-box"><p>Hop v1.0</p></div>
              <div className="step-box"><p>Hop v2.0</p></div>
              <div className="step-box"><p>Hop v3.0</p></div>
            </div>
            <div className="step-headings">
              <div className="step-box"><p className="in_year">2018-20</p></div>
              <div className="step-box"><p className="in_year">2020-22</p></div>
              <div className="step-box"><p className="in_year">2023</p></div>
            </div>
            <ul className="progressBar">
              <li>Stealth stage</li>
              <li>Proof of Business stage</li>
              <li>Growth Stage</li>
            </ul>
            <div className="timeline_items">
              <ul className="progressBar_items">
                <li>Development of v1 proto of smart hot swapLi-Ion battery,
                  battery swapping station, IoT stack & ride sharing platform.</li>
              </ul>
              <ul className="progressBar_items">
                <li>Formal Incorporation</li>
                <li>Pilot of battery swapping platform</li>
                <li>Entry into retail with 2 Low speed electric scooters.</li>
                <li>150+ retail outlets</li>
                <li>11,000+ E2Ws sold</li>
                <li>$11 Mil revenue generated</li>
                <li>Launch of Hop OXO</li>
                <li>Champion OEM in PLI</li>
              </ul>
              <ul className="progressBar_items">
                <li>Deliveries begin for flagship electric motorcycle Hop OXO.</li>
                <li>New platform ‘Hop Nimbus’  development commenced.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      {/* For mobile view */}


      <div className="sec_content_steper mobile">
        <div className="container_about_us">
          <h2>Timeline</h2>

          <div className="tech_specs_sec">
            <div className="landing_page_container">
              {/* <p className="mobile-select-variant">Select Varient</p> */}
              <div className="btn-group-price oxo_both">
                {/* <button
            className={`btn-price-oxo ${activeBtnOxo1}`}
            onClick={() => handleOxoBtn1("oxoPrime")}
          >
            OXO Prime
          </button> */}
                <button
                  className={`btn-price-oxo  ${activeBtnOxoPrime1}`}
                  onClick={() => handleOxoBtn1("oxoPrime")}
                >
                  <span></span>
                  Hop v1.0
                  {/* <FontAwesomeIcon icon={faAnglesRight} /> */}
                </button>
                <button
                  className={`btn-price-oxo ${activeBtnOxo1}`}
                  onClick={() => handleOxoBtn1("oxo")}
                >
                  <span className='one'></span>
                  Hop v2.0
                  <span></span>
                  {/* {tabindex == "oxox"?  <FontAwesomeIcon icon={faAnglesLeft} />: <FontAwesomeIcon icon={faAnglesRight} />} */}
                </button>
                
                <button
                  className={`btn-price-oxo  ${activeBtnOxoX1}`}
                  onClick={() => handleOxoBtn1("oxox")}
                >
                  <span></span>
                  Hop v3.0
                  
                </button>
              </div>

              <ul className={`tech_deails Specs_conbg  `}>
                <div className="newtech_specs">
                  {/* <span className="coming_soon_movbike">
            {tabindex == "oxox"
              ? "Coming Soon"
              : tabindex == "oxoPrime"
                ? "Coming Soon"
                : tabindex == "oxo"
                  ? ""
                  : ""}
          </span> */}
                  <div className='year_subheading'>
                    <h4>{tabindex == "oxoPrime" ? "2018-20" : tabindex == "oxo" ? "2020-22" : tabindex == "oxox" ? "2023" : null}</h4>
                    <h4>{tabindex == "oxoPrime" ? "Stealth stage" : tabindex == "oxo" ? "Proof of Business stage" : tabindex == "oxox" ? "Growth Stage" : null}</h4>
                  </div>
                  <div className="mobile_timecontent">
                    {tabindex == "oxoPrime" ? <ul className="progressBar_items2">
                      <li>Development of v1 proto of smart hot swapLi-Ion battery,
                        battery swapping station, IoT stack & ride sharing platform.</li>
                    </ul> : null}
                    {tabindex == "oxo" ? <ul className="progressBar_items21">
                      <li>Formal Incorporation</li>
                      <li>Pilot of battery swapping platform</li>
                      <li>Entry into retail with 2 Low speed electric scooters.</li>
                      <li>150+ retail outlets</li>
                      <li>11,000+ E2Ws sold</li>
                      <li>$11 Mil revenue generated</li>
                      <li>Launch of Hop OXO</li>
                      <li>Champion OEM in PLI</li>
                    </ul> : null}
                    {tabindex == "oxox" ? <ul className="progressBar_items21">
                      <li>Deliveries begin for flagship electric motorcycle Hop OXO.</li>
                      <li>New platform ‘Hop Nimbus’  development commenced.</li>
                    </ul> : null}
                  </div>
                  {/* <ul>
          <li className="firs_heading">Top Speed</li>
          <li>82 Kmph </li>
          <li>88Kmph </li>
          <li>95 Kmph*</li>
          <li className="firs_heading">True Range (Eco Mode)</li>
          <li>120kms </li>
          <li>140kms </li>
          <li>150Kms</li>
          <li className="firs_heading">True Range (Power Mode)</li>
          <li>80Kms</li>
          <li>90Kms</li>
          <li>100kms </li>
          <li className="firs_heading">True Range (Sport Mode)</li>
          <li>60kms</li>
          <li>65kms</li>
          <li>70Kms</li>
          <li className="firs_heading">Voltage Architecture </li>
          <li>72 V</li>
          <li>72 V</li>
          <li>72 V</li>
          <li className="firs_heading">Motor Power (Peak)</li>
          <li>4.2 kw</li>
          <li>5.2Kw</li>
          <li>6.2kw</li>
          <li className="firs_heading">Max. Torque (At Wheel)</li>
          <li>160Nm</li>
          <li>175Nm</li>
          <li>200Nm</li>
          <li className="firs_heading">Motor Type </li>
          <li>BLDC Hub motor</li>
          <li>BLDC Hub motor</li>
          <li>BLDC Hub motor</li>
          <li className="firs_heading">Controller </li>
          <li>Sinusoidal FOC Vector Control</li>
          <li>Sinusoidal FOC Vector Control </li>
          <li>Sinusoidal FOC Vector Control</li>
          <li className="firs_heading">Riding Modes</li>
          <li>Eco-Power-Sport</li>
          <li>Eco-Power-Sport </li>
          <li>Eco-Power-Sport-Turbo </li>
          <li className="firs_heading">Top Speed (ECO Mode)</li>
          <li>45 Kmph</li>
          <li>45 Kmph</li>
          <li>45 Kmph</li>
        </ul> */}
                </div>
              </ul>

            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Section4