import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import "react-pure-modal/dist/react-pure-modal.min.css";

import LoadingSpinner from "../../../assets/images/spinner.svg";

import Header from "./Header";

import { Config } from "../../../utils/apiUrl";
import { getCookie } from "../../../utils";
import { JAIPUR_LATITUDE, JAIPUR_LONGITUDE } from "../../../constants";

import { useScrollTo } from "../../../hooks/useScrollTo";

function WelcomeUser() {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const [nodata, setnodata] = useState(false);
  const [service, setservice] = useState(false);
  const [book, setbook] = useState(false);
  const [booktestride, setBooktestride] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [testRideData, setTestRideData] = useState([]);
  const [myLat, setMyLat] = useState();
  const [myLong, setMyLong] = useState();
  const [permissionAllowed, setPermissionAllowed] = useState("");

  useEffect(() => {
    getLocation();
  }, []);
  function getLocation() {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "prompt" || result.state === "denied") {
          result.onchange = () => {
            window.location.reload();
          };
          setPermissionAllowed(false);
          // handleLocation();
        } else if (result.state == "granted") {
          result.onchange = () => {
            window.location.reload();
          };
          setPermissionAllowed(true);
        }
      });
    } else {
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Browser Not Supported");
    }
  }
  function showPosition(position) {
    setMyLat(position.coords.latitude);
    setMyLong(position.coords.longitude);
  }

  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      navigate("/");
    }
  };

  useEffect(() => {
    getCookiedata("userdata");
  }, []);

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const response = await fetch(Config.dashboardAPI, requestOptions);
      if (response) {
        const json = await response.json();
        setLoading(false);
        if (
          "query" in json.latest_task[0] == false &&
          json.latest_task[0].order_id.startsWith("TD")
        ) {
          setTestRideData(json.latest_task[0]);
          setBookingData([]);
          setServiceData([]);
          setBooktestride(true);
          setbook(false);
          setservice(false);
        } else if (
          "query" in json.latest_task[0] == false &&
          json.latest_task[0].order_id.startsWith("OR")
        ) {
          setTestRideData([]);
          setBookingData(json.latest_task[0]);
          setServiceData([]);
          setBooktestride(false);
          setbook(true);
          setservice(false);
        } else if ("query" in json.latest_task[0]) {
          setTestRideData([]);
          setBookingData([]);
          setServiceData(json.latest_task[0]);
          setBooktestride(false);
          setbook(false);
          setservice(true);
        }
        // setLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rescheduleTestDrive = (value) => {
    if (value.category == "Home Testride") {
      navigate(
        `/booktestride/Home/${Number(
          value?.ticket_id
        )}/homeconfirmation/${Number(value?.pin_code)}`
      );
    } else {
      navigate(
        `/booktestride/Dealer/${Number(value?.ticket_id)}/${
          value.dealer_username
        }/homeconfirmation/${Number(value?.pin_code)}`
      );
    }
  };

  const paymentButton = () => {
    window.document.getElementsByClassName("PaymentButton")[0].click();
  };

  const openMapInNewTab = (latitude, longitude) => {
    // if (parameter == 0) {

    if (myLat && myLong) {
      if (!latitude || !longitude) {
        toast.info("Direction Not Available for this dealer.", {
          autoClose: 1000,
        });
      } else {
        let url = `https://www.google.com/maps/dir/${myLat}+${myLong}+/${latitude},+${longitude}`;
        window.open(url, "_blank");
      }
    } else {
      let url = `https://www.google.com/maps/dir/${JAIPUR_LATITUDE}+${JAIPUR_LONGITUDE}+/${latitude},+${longitude}`;
      window.open(url, "_blank");
    }
  };
  return (
    <>
      <Header />

      <div className="active_rides wWelcomeuser_sec">
        <div className="coming_sec">
          {loading === true ? (
            <div
              className="coming_main"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <img
                style={{ height: "50px", height: "50px" }}
                src={LoadingSpinner}
                 
              />
            </div>
          ) : null}

          {!service && !book && !booktestride && !loading && (
            <div
              className="coming_main no_active_booking up_welecom"
              style={{ display: nodata ? "none" : "block" }}
            >
              <div className="upcoming_sec upcoming_Detail dasboard_pop_p">
                <div className="up_com_cent">
                  <p>
                    <span>Welcome User</span>
                  </p>

                  <p className="add_up">
                    {" "}
                    You have No Active Booking or Test Ride
                  </p>
                  <p className="add_up">How Can We Help You?</p>
                </div>
              </div>
              <div className="das_showlink">
                <ul>
                  <li>
                    <button
                      className="default_btn"
                      onClick={() => {
                        navigate("/booknow");
                      }}
                    >
                      Book OXO
                    </button>
                    <button
                      className="default_btn"
                      onClick={() => {
                        navigate("/booktestride");
                      }}
                    >
                      Book A Test Ride
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {booktestride && (
            <div className="coming_main headin_hight up_testride">
              <h4>Upcoming Test Ride</h4>
              <div className="upcoming_sec upcoming_Detail dasboard_pop_p">
                <div className="up_com_cent">
                  <h4>location details</h4>
                  <p>Hop Electric - {testRideData.dealer_name}</p>
                  <p className="add_up">{testRideData.dealer_address}</p>

                  <ul className="max_dash dashboard_sec_pop">
                    <li>
                      <span>Phone No: </span>{" "}
                      <a href={`tel:${testRideData.dealer_phone_num}`}>
                        {testRideData.dealer_phone_num}
                      </a>{" "}
                    </li>
                    <li>
                      <span>Date:</span>{" "}
                      {moment(testRideData.testdrive_date).format("DD-MM-YYYY")}
                    </li>
                    <li>
                      <span>PREFERRED SLOT: </span> {testRideData.time_slot}
                    </li>
                    {testRideData.status === 3 ? (
                      <li>
                        <span>STATUS: </span>{" "}
                        {testRideData.status === 3 ? "Cancelled" : ""}
                      </li>
                    ) : null}
                  </ul>

                  <div className="event_btn">
                    <a
                      className="default_btn"
                      target="_blank"
                      onClick={() =>
                        openMapInNewTab(
                          testRideData.dealer_lat,
                          testRideData.dealer_long
                        )
                      }
                      // href={`https://www.google.com/maps/search/?api=1&query=${testRideData.dealer_lat},${testRideData.dealer_long}`}
                    >
                      Get Directions
                    </a>
                  </div>
                </div>
              </div>

              <div className="das_showlink">
                <ul>
                  <li>
                    <button
                      className="default_btn"
                      onClick={() => {
                        navigate("/booknow");
                      }}
                    >
                      Book OXO
                    </button>
                    {/* saleem's reschedule button show logic */}
                    {testRideData.status !== 3
                      ? testRideData.status === 1 &&
                        (moment(new Date()).format("YYYY-MM-DD") <
                        moment(new Date(testRideData?.testdrive_date)).format(
                          "YYYY-MM-DD"
                        ) ? (
                          <button
                            className="default_btn"
                            onClick={() => rescheduleTestDrive(testRideData)}
                          >
                            {" "}
                            Reschedule{" "}
                          </button>
                        ) : moment(new Date()).format("YYYY-MM-DD") <=
                            moment(
                              new Date(testRideData?.testdrive_date)
                            ).format("YYYY-MM-DD") &&
                          (moment(new Date()).format("HH:mm") <
                          testRideData?.time_slot.startsWith("9AM")
                            ? "08:00"
                            : testRideData?.time_slot.startsWith("12PM")
                            ? "11:00"
                            : "14:00") ? (
                          <button
                            className="default_btn"
                            onClick={() => rescheduleTestDrive(testRideData)}
                          >
                            {" "}
                            Reschedule{" "}
                          </button>
                        ) : null)
                      : null}

                    
                  </li>
                </ul>
              </div>
            </div>
          )}

          {book && (
            <div className="coming_main received_ord">
              <div className="upcoming_sec upcoming_Detail dasboard_pop_p">
                <div className="up_com_cent">
                  <p>
                    <span>
                      We have received your booking request. <br />{" "}
                    </span>
                    Welcome To The Hop Family{" "}
                  </p>
                  <ul className="max_dash dashboard_sec_pop">
                    <li>
                      <span>Order Id: </span>
                      {bookingData.order_id}
                    </li>
                    <li>
                      <span>Name: </span>
                      {bookingData.name}
                    </li>
                    <li>
                      <span>Phone No:</span> {bookingData.phone_num}
                    </li>
                    <li>
                      <span>Pin Code:</span> {bookingData.pin_code}
                    </li>
                    <li>
                      <span>Model:</span>{" "}
                      {bookingData.model_name == "OXO-X"
                        ? "OXO (Pro Package)"
                        : bookingData.model_name}
                    </li>
                    <li>
                      <span>Color:</span> {bookingData.color}
                    </li>
                    {bookingData?.status == 3 ? (
                      <li>
                        <span>Status:</span>{" "}
                        {bookingData?.status == 3 ? "Cancelled" : null}
                      </li>
                    ) : null}
                  </ul>
                  {bookingData?.status !== 3 ? (
                    <div className="next_btn">
                      <button onClick={paymentButton} className="default_btn">
                        Continue Payment
                      </button>
                    </div>
                  ) : null}

                </div>
              </div>
              <div className="das_showlink">
                <ul>
                  <li>
                    <button
                      className="default_btn"
                      onClick={() => {
                        navigate("/booktestride");
                      }}
                    >
                      Book A Test Ride
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {service && (
            <div className="coming_main request_subt service_content">
              <div className="upcoming_sec upcoming_Detail dasboard_pop_p">
                <div className="up_com_cent">
                  <p>
                    <span>Your Service request </span>#SRN-
                    {serviceData.ticket_id} <br />{" "}
                    <span>has been submitted successfully</span>
                  </p>
                  <ul className="max_dash dashboard_sec_pop">
                    <li>
                      <span>Date:</span>
                      {moment(serviceData.date).format("DD-MM-YYYY")}{" "}
                    </li>
                    <li>
                      <span>Query:</span>{" "}
                      <strong style={{ fontWeight: "500" }}>
                        {serviceData.query}
                      </strong>
                    </li>
                    {serviceData.vin_number && (
                      <li>
                        <span>Vin No.:</span>{" "}
                        <strong style={{ fontWeight: "500" }}>
                          {serviceData.vin_number}
                        </strong>
                      </li>
                    )}
                    <li>
                      <span>Model:</span>{" "}
                      <strong style={{ fontWeight: "500" }}>
                        {serviceData.model}
                      </strong>
                    </li>
                    <li>
                      <span>Purchase Within:</span>{" "}
                      <strong style={{ fontWeight: "500" }}>
                        {serviceData.purchase_within}
                      </strong>
                    </li>
                    {serviceData.status == 3 ? (
                      <li>
                        <span>Status:</span>{" "}
                        <strong style={{ fontWeight: "500" }}>
                          {serviceData.status == 3 ? "Cancelled" : null}
                        </strong>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              <div className="das_showlink">
                <ul>
                  <li>
                    <button
                      className="default_btn"
                      onClick={() => {
                        navigate("/booknow");
                      }}
                    >
                      Book OXO
                    </button>
                    <button
                      className="default_btn"
                      onClick={() => {
                        navigate("/booktestride");
                      }}
                    >
                      Book A Test Ride
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default WelcomeUser;
