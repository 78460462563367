import "../Testride/test-ride.css";
import "react-pure-modal/dist/react-pure-modal.min.css";

import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const Event = () => {
  useScrollTo(0, 0);

  return (
    <>
      <Header />
      <div className="height_one_csrol">
        <div id="home-Confirmation" className="top_header_pm"></div>

        <Footer />
      </div>
    </>
  );
};
export default Event;
