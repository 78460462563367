import axios from "axios";
import { toast } from "react-toastify";
import { PYTHON_BASE_URL, Config } from "../utils/apiUrl";
import { getCookie } from "../utils";

export const getBookingDetailsAPI = async (ticket_id) => {
  try {
    const res = await axios.get(
      PYTHON_BASE_URL + `/ticket_details/${ticket_id}`
    );
  } catch (error) {
    toast.error(error.message, { position: "bottom-left", autoClose: 2000 });
  }
};

export const cancelBookingAPI = async (ticket_id) => {
  try {
    let data = {
      ticket_id: Number(ticket_id),
      reason: "Not Interested Now",
    };
    let response = await axios.post(Config.cancelPreBookingAPI, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
    });

    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const NewsLetterAPI = async (data) => {
  try {
    let response = await axios.post(Config.newsletter_subscribeAPI, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
    });

    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const POST_ApplyAPI = async (formData) => {
  try {
    const response = await axios.post(Config.POST_ApplyAPI, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
    });
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const GET_getTicketDetailsAPI = async (ticket_id) => {
  try {
    const response = await axios.post(
      Config.GET_preBookingTicketDetailsAPI,
      { ticket_id: ticket_id },
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(getCookie("userdata")).user_token,
        },
      }
    );
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const POST_modifyBookingColorAPI = async (
  state,
  city,
  email,
  name,
  DOB,
  pincode,
  house_flat_no,
  areaStreet,
  landmark,
  ticket_id,
  color,
  model_name
) => {
  try {
    const response = await axios.post(
      Config.POST_modifyBookingColorAPI,
      {
        state: state,
        city: city,
        email: email,
        name: name,
        dob: DOB,
        pincode: pincode,
        house_flat_no: house_flat_no,
        area_street: areaStreet,
        landmark: landmark,
        ticket_id: ticket_id,
        color: color,
        model_name: model_name,
        // state: state,
        // ticket_id: ticket_id,
        // // order_id: order_id,
        // color: color,
        // model_name: model_name,
      },
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(getCookie("userdata")).user_token,
        },
      }
    );
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const POST_cancelServiceTicketAPI = async (ticket_id) => {
  try {
    const response = await axios.post(
      Config.POST_cancelServiceTicketAPI,
      {
        ticket_id: ticket_id,
        reason: "No Reason",
        //no ui for reason
      },
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(getCookie("userdata")).user_token,
        },
      }
    );
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const POST_ServiceRequestAPI = async (serviceObject) => {
  try {
    const response = await axios.post(Config.service_ticket, serviceObject, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getCookie("userdata")
          ? "Bearer " + JSON.parse(getCookie("userdata"))?.user_token
          : null,
      },
    });
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const POST_SendVerifyOTPAPI = async (data) => {
  try {
    const response = await axios.post(Config.otp_varification, data, {
      headers: {
        "Content-Type": "application/json",
        // Authorization:
        // "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const GET_TestDriveDataAPI = async () => {
  try {
    const response = await axios.post(
      Config.my_test_drives,
      { user_id: JSON.parse(getCookie("userdata")).user_id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(getCookie("userdata")).user_token,
        },
      }
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const GET_UserDetailAPI = async () => {
  try {
    if (getCookie("userdata")) {
      const response = await axios.get(
        Config.GET_UserDetailAPI + JSON.parse(getCookie("userdata"))?.user_id,
        {
          headers: {
            // "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(getCookie("userdata"))?.user_token,
          },
        }
      );
      if (response) {
        return response.data;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const GET_ALL_DEALERS_API = async () => {
  try {
    const response = await axios.post(
      Config.fetchAllDealers,
      { lat: "", long: "" },
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization:
          //   "Bearer " + JSON.parse(getCookie("userdata")).user_token,
        },
      }
    );
    if (response) {
      return response.data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const GET_ALL_DEALERS_FROM_GET_ALL_API = async (
  myLat,
  myLong,
  pincode
) => {
  try {
    const response = await axios.post(
      Config.GET_AllData,
      {
        lat: myLat,
        long: myLong,
        user_id: JSON.parse(getCookie("userdata")).user_id,
        pincode: pincode
          ? pincode
          : JSON.parse(localStorage.getItem("pincode")),
      },

      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(getCookie("userdata")).user_token,
        },
      }
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const verifyCaptchaAPI = async (token) => {
  try {
    const response = await axios.post(
      Config.verifyCaptcha,
      { "g-recaptcha-response": token },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
