import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import "../Testride/test-ride.css";
import "../Order/order.css";
import "../dashboard/dashboard.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import Dashor_bike from "../../assets/images/Dashorder_bike.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { Config } from "../../utils/apiUrl";
import { getCookie } from "../../utils";
import { useScrollTo } from "../../hooks/useScrollTo";

const ModifyDashboard = () => {
  useScrollTo(0, 0);
  const { id } = useParams();
  const navigate = useNavigate();
  const [myBooking, setMyBooking] = useState();

  useEffect(() => {
    document
      .getElementById("height_one_home_confirmation")
      .addEventListener("scroll", (e) => {
        var yAxis = $(".dashboard_orde").offset().top;

        if (yAxis < 0) {
          $(".header").hide();
        } else {
          $(".header").show();
        }
      });
  }, []);

  useEffect(() => {
    getBookingData();
  }, []);

  const getBookingData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const responseBook = await fetch(Config.mybookings, requestOptions);
      if (responseBook) {
        const jsonBook = await responseBook.json();
        let filtered_booking = jsonBook.bookings.filter(
          (item) => item.ticket_id == id
        );
        setMyBooking(filtered_booking[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goToColorPreferencesPage = () => {
    navigate("/Colorpreferences/" + id);
  };

  return (
    <>
      <Header />
      <div className="height_one_csrol" id="height_one_home_confirmation">
        <div id="Dashboardorder" className="top_header_pm">
          <div className="testride_map Confirmation graphics_sec dashboard_orde">
            <div className="map_left Confirmation_left dashboard_leftsec">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure className="gra_logo">
                  <img  src={popuplogomo} alt="imhvgb" />
                </figure>

                <figure>
                  <img  src={Dashor_bike} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec dashboard_rightsec">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
              </div>

              <h3>Your orders</h3>
              <p>Choose an order to view its details</p>
              <div className="mobile_show das_orderimg">
                <img  src={Dashor_bike} alt="imhvgb" />
              </div>
              <div className="order_iddetails">
                <h4>
                  <span>Order ID:</span> {myBooking?.order_id}
                </h4>

                <ul className="order_details">
                  <li>
                    <ul>
                      <li>Color</li>
                      <li>
                        {myBooking?.color}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>Registered Name</li>
                      {myBooking?.model_name}
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>Delivery address</li>
                      {myBooking?.address?.split(",")[0]?.split("-")[1]},
                      {myBooking?.address?.split(",")[1]?.split("-")[1]}
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>Phone Number</li>
                      <li>+91{myBooking?.phone_num}</li>
                    </ul>
                  </li>
                </ul>

                <div className="orderid_btn">
                  <Link
                    className="default_btn"
                  >
                    Continue
                  </Link>
                  <button
                    class="default_btn tran_btn"
                    onClick={goToColorPreferencesPage}
                  >
                    Manage booking
                  </button>
                  <button class="default_btn test_again">
                    <Link to="/booktestride">Test ride again</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default ModifyDashboard;
