import React, { useRef } from "react";
import Header from "../../components/common/layout/Header";
import EnquirySlider from "../../components/Enquiry/EnquirySlider";
import EnquiryForm from "../../components/Enquiry/EnquiryForm";
import EnquiryProductsSlider from "../../components/Enquiry/EnquiryProductsSlider";
import EnquiryProductFeatures from "../../components/Enquiry/EnquiryProductFeatures";
import "./style.css";
import { useScrollTo } from "../../hooks/useScrollTo";

const Enquiry = () => {
  useScrollTo(0, 0);
  const section1Ref = useRef(null);

  return (
    <>
      <div id="Enquiryy">
        <Header />
        <div className="slider_with_form">
          <div className="home_sec">
            <EnquirySlider />
            <div className="enq_form" id="form" ref={section1Ref}>
              <EnquiryForm />
            </div>
          </div>
        </div>
        <div className="product_slide_paresnbt">
          <EnquiryProductsSlider />
        </div>
        <EnquiryProductFeatures />
      </div>
    </>
  );
};

export default Enquiry;
