import React from 'react'
import "./index.css";
import hop_launch_pad from "../../../assets/images/about_us/hop_launch_pad.png";
import hop_megaplex from "../../../assets/images/about_us/hop_megaplex.png";
import hop_HQ from "../../../assets/images/about_us/hop_HQ.png";
import giga_plex from "../../../assets/images/about_us/giga_plex.png";

const Section8 = () => {
  return (
    <>
      <div class="sec_8_content">
        <div className="container_about_us">
          <h2>FACILITIES </h2>
          <div className="image_box_wrapper">
            <div className="image_box_list1">
              <div className="bikes_type">
                <div className="bike_name1">
                  <p className="hop_lf">Hop Launchpad</p>
                  <p className="lfe_desc">R&D & Design Facility in Jaipur </p>
                </div>
              </div>
              <figure>
                <img  src={hop_launch_pad} alt="image not found" />
              </figure>
            </div>
            <div className="image_box_list1">
            <div className="bikes_type">
                <div className="bike_name1">
                <p className="hop_ox">Hop Megaplex</p>
                <p className="ox_desc">Hop Manufacturing facility in Jaipur with annual capacity of 1.2 Lakh Units</p>
                </div>
              </div>
              {/* <div className="bike_name3">
                <p className="hop_ox">Hop Megaplex</p>
                <p className="ox_desc">Hop Manufacturing facility in Jaipur with annual capacity of 1.2 Lakh Units</p>
              </div> */}
              <figure>
                <img  src={hop_megaplex} alt="image not found" />
              </figure>
            </div>
            <div className="image_box_list1">
              <div className="bikes_type">
                <div className="bike_name1">
                  <p className="hop_lf">Hop HQ</p>
                  <p className="lfe_desc">Corporate office in Gurugram, India </p>
                </div>
              </div>
              <figure>
                <img  src={hop_HQ} alt="image not found" />
              </figure>  
            </div>
            <div className="image_box_list1">
              <div className="bikes_type">
                <div className="bike_name1">
                  <p className="hop_lf">Hop GigAplex (FTIP)</p>
                  <p className="lfe_desc">60 Acre futuristic technologies industrial park in Neemrana. 1 Million units capacity Development to start in 2024. </p>
                </div>
              </div>
              <figure>
                <img  src={giga_plex} alt="image not found" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Section8