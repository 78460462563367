import React from "react";
import "../Testride/test-ride.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";
const Refund = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <div id="privacy-policy" className="top_header_pm">
        <div className="payment_secmain  privacy_policy_sec">
          <div className="payment_cont pri_policy">
            <h2>Currently web don't have Refund Policy Content</h2>
            <div className="next_btn">
              <button onClick={() => navigate("/ ")} className="default_btn">
                Refund Policy
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Refund;
