import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./Routing";
import "../constants/Colors.css";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Router>
          <Routing />
        </Router>
      </HelmetProvider>
    </>
  );
};
export default App;
