import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import "../Testride/test-ride.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import bike_re_conf from "../../assets/images/conf-bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const TestrideRequested = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const [showcancel, opencancel] = useState(false);
  const [showredetail, openredetail] = useState(false);

  return (
    <>
      <Header />
      <div className="height_one_csrol">
        <div id="home-Confirmation" className="top_header_pm">
          <div className="testride_map Confirmation home_confirmation">
            <div className="map_left Confirmation_left">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>
              <div className="after_savings">
                <figure>
                  <img  src={bike_re_conf} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec order_id">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
                <p>Est. Delivery Date: - Apr 2023</p>
              </div>
              <div className="only_mobileshow mobile_show">
                <img  src={popuplogomo} alt="imhvgb" />
                <h3 className="mobile_show">Ride Confirmed</h3>
              </div>
              <h3 className="destop_show">
                Test ride requested at your doorstep{" "}
                <span className="more_ifor" onClick={() => openredetail(true)}>
                  {" "}
                  i{" "}
                </span>{" "}
              </h3>

              <ul className="loc_add requested_rmore">
                <li>
                  <strong>LOCATION:</strong>
                  <p>
                    Hop Electric - Hop Experience Center, Mansarovar, Jaipur{" "}
                    <br />
                    35/96, Rajat Path, Shipra Path Mansarovar 302020
                  </p>
                </li>
                <li>
                  <strong>DATE: </strong>
                  <p>25/12/2022</p>
                </li>
                <li>
                  <strong>PREFERRED SLOT: </strong>
                  <p>9 AM - 12 PM</p>
                </li>
              </ul>

              <div className="test_ridestep">
                <ul>
                  <li>
                    <Link to="/booktestride">Reschedule Test Ride</Link>
                  </li>
                  <li>
                    <Link to="/booktestride">Book Another Test Ride</Link>
                  </li>
                  <li>
                    <Link to=" ">Book A Vehicle</Link>
                  </li>
                  <li onClick={() => opencancel(true)}>
                    <Link to=" ">Cancel Test Ride</Link>
                  </li>
                </ul>
              </div>

              <div className="next_btn">
                <button
                  className="default_btn"
                  onClick={() => {
                    return opencancel(false), navigate("/UserDashboard");
                  }}
                >
                  Back To Dashboard
                </button>
              </div>
            </div>
          </div>
          <div
            className="form-group modal_popup test_ride_popup form-group modal_popup"
            id="enter_mobile_no_desktop"
          >
            <PureModal
              onClose={() => {
                openredetail(false);
                return true;
              }}
              isOpen={showredetail}
              closeButton={<div className="close_btn"></div>}
              closeButtonPosition="top"
              width="100%"
            >
              <div className="popup_cont color_change reschedule_info loca_info">
                <ul className="loc_add active_req">
                  <li>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </li>
                  <li>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </li>
                  <li>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </li>
                  <li>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </li>
                  <li>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </li>
                </ul>
              </div>
            </PureModal>
          </div>
          <div
            className="form-group modal_popup test_ride_popup form-group modal_popup"
            id="enter_mobile_no_desktop"
          >
            <PureModal
              onClose={() => {
                opencancel(false);
                return true;
              }}
              isOpen={showcancel}
              closeButton={<div className="close_btn"></div>}
              closeButtonPosition="top"
              width="100%"
            >
              <div className="popup_cont color_change reschedule_info loca_info">
                <h2>Active Test-Ride</h2>

                <ul className="loc_add">
                  <li>
                    <strong>LOCATION:</strong>
                    <p>
                      Hop Electric - Hop Experience Center, Mansarovar, Jaipur{" "}
                      <br />
                      35/96, Rajat Path, Shipra Path Mansarovar 302020
                    </p>
                  </li>
                  <li>
                    <strong>DATE: </strong>
                    <p>25/12/2022</p>
                  </li>
                  <li>
                    <strong>PREFERRED SLOT: </strong>
                    <p>9 AM - 12 PM</p>
                  </li>
                </ul>
                <div className="reschedule_infobtn">
                  <button
                    onClick={() => navigate("/booktestride")}
                    className="user_btn border_btn"
                  >
                    Reschedule
                  </button>
                  <button
                    className="user_btn"
                    onClick={() => {
                      return opencancel(false), navigate("/CancelTestrides");
                    }}
                  >
                    Confirm Cancel
                  </button>
                </div>
              </div>
            </PureModal>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default TestrideRequested;
