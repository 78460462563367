export const addUserDetails = (user) => {
    return {
        type: 'ADD',
        payload: user
    }
}
export const pincodeSearch=(user)=>{
    return{
        type:"ADDSEARCH",
        payload:user
    }
}

export const updateUserDetails = (user) => {
    return {
        type: 'UPDATEBASIC',
        payload:user
    }
};
export const updateUserAddress = (user) => {
    return {
        type: 'UPDATEADDRESS', 
        payload:user
    }
};
export const AddTestRideToken = (user) => {
    return {
        type: 'TestRideToken',
        payload:user
    }
};
export const AddOrderToken = (user) => {
    return {
        type: 'OrderToken',
        payload:user
    }
};
export const emptyUserDetails = (num) => {
    return {
        type: 'EMPTY',
        payload: num
    }
}

export const bikeColor = (color) => {
    return {
        type: 'BikeColor',
        payload: color
    }
}

export const userName = (user) => {
    return {
        type: 'UserName',
        payload: user
    }
}

export const userEmail = (user) => {
    return {
        type: 'UserEmail',
        payload: user
    }
}

export const phoneNum = (user) => {
    return {
        type: 'PhoneNum',
        payload: user
    }
}

export const pinCode = (user) => {
    return {
        type: 'PinCode',
        payload: user
    }
}

export const orderModal = (user) => {
    return {
        type: 'OrderModal',
        payload: user
    }
}

export const bookNowModal = (toggle)=>{
    return {
        type: 'TOGGLE',
        payload: toggle
    } 
}