import { useState, useRef, useEffect } from "react";
import "./test-ride.css";
import "./Horizontal_Date_Picker.css";
import { useNavigate } from "react-router-dom";
import TestRideGoogleMap from "./TestRideGoogleMap";
import TestRideGoogleMapWithMultipleMarkers from "./TestRideGoogleMapMultipleMarkers";
import infor_icon from "../../assets/images/Information.png";
import { useDraggable } from "react-use-draggable-scroll";
import search_ico from "../../assets/images/search.png";
import logo_right from "../../assets/images/dx.png";
import DatePicker from "react-horizontal-datepicker";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../../components/common/layout/Footer";
import PureModal from "react-pure-modal";
import start_ic from "../../assets/images/start.png";
import { Config } from "../../utils/apiUrl";
import { Datepicker } from "@meinefinsternis/react-horizontal-date-picker";
import { enUS } from "date-fns/locale";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Header from "../../components/common/layout/Header";
import LoadingSpinner from "../../assets/images/spinner.svg";
import TestRideMAPZoom5 from "./TestRideMAPZoom5";
import { toast } from "react-toastify";
import { JAIPUR_LATITUDE, JAIPUR_LONGITUDE } from "../../constants";
import { GET_TestDriveDataAPI } from "../../APIs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import TestRideGoogleMapMob from "./TestrideGoogleMapMob";
import TestrideGoogleMapMobWithMarkers from "./TestrideGoogleMapMobWithMarkers";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getCookie } from "../../utils";

const BookTestRide = ({ landingPage }) => {
  const dealerid = useParams();
  const navigate = useNavigate();
  const ref = useRef();
  const params = useParams();
  const { events } = useDraggable(ref);
  const { width } = useWindowDimensions();
  const location = useLocation();
  const location2 = location.pathname.split("/")[4];
  const [showcancel, opencancel] = useState(false);
  const [dates, setDate] = useState(new Date());
  const [hometestridedate, setHometestdate] = useState("");
  const [btn1, setBtn1] = useState(false);
  const [btn2, setBtn2] = useState(false);
  const [btn3, setBtn3] = useState(false);
  const [hometestridebtn, setHometestbtn] = useState(false);
  const [hometestridebtn1, setHometestbtn1] = useState(false);
  const [hometestridebtn2, setHometestbtn2] = useState(false);
  const [search, setSearch] = useState(
    JSON.parse(localStorage.getItem("pincode"))
  );
  const [homefirstdate, setHomefirstdate] = useState("");
  const [homelastdate, setHomeLastdate] = useState("");
  const [dealerdata, setDealerdata] = useState([]);
  const [active, setActive] = useState(null);
  const [picodeerror1, setPincodeerror1] = useState(false);
  const [picodeerror2, setPincodeerror2] = useState(false);
  const [booktestridebtn, setBooktestbtn] = useState(false);
  const [date2, setDate2] = useState(null);
  const [event, setEvent] = useState([]);
  const [evenetid, setEventid] = useState("");
  const [globalmonth, setGlobalmonth] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [activeevent, setactiveEvent] = useState(null);
  const [hometestridehide, setHometestridehide] = useState(false);
  const [hometestdrivedisabel, setHometestridehidedsiabel] = useState(false);
  const [homtest, setHometest] = useState([]);
  const [homtestshow, setHometestshow] = useState([]);
  const [ride, setRide] = useState(false);
  const [eventaddress, setEventaddress] = useState([]);
  const [eventtime, setEventtime] = useState("");
  const [times, setTimes] = useState(" ");
  const [hometesttimes, setHometesttiem] = useState("");
  const [Hometestdateerror, setHometestdateerror] = useState("");
  const [timesslots, setTimeslots] = useState("");
  const [hometestrideid, sethometestrideid] = useState("");
  const [hometestslot, sethometestrideslot] = useState("");
  const [eventdates, setEventdate] = useState([]);
  const [storeeventdate, setStoreeventdate] = useState("");
  const [dealers, setDealer] = useState("Dealer");
  const [userhome, sethome] = useState("Home");
  const [homedate, setHomedate] = useState("");
  const [ticket, setTicketid] = useState("");
  const [open, setopen] = useState(false);
  const [id, setuserid] = useState(dealerid.orderid);
  const [type, setType] = useState(dealerid.userid);
  const [conf, setconf] = useState(dealerid.ticketid);
  const [conf1, setconf1] = useState(dealerid.ticketid1);
  const [dealerdatas, setDealerdatas] = useState([]);
  const [lat, setlat] = useState(null);
  const [long, setlong] = useState(null);
  const [myLat, setMyLat] = useState();
  const [myLong, setMyLong] = useState();
  const [apierror, seterror] = useState(false);
  const [loading, setLoading] = useState();
  const [searchDialog, setSearchDialog] = useState();
  const [previousTicketData, setPreviousTicketData] = useState({});
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [infoPopupData, setInfoPopupData] = useState();
  const [permissionAllowed, setPermissionAllowed] = useState("");
  const [pincodeParam, setPincodeParam] = useState("");
  const [searchDealerValue, setSearchDealerValue] = useState("");
  const [dealersDataToStore, setDealerDatasToStore] = useState([]);
  const [latLongArray, setLatLongArray] = useState([]);

  useEffect(() => {
    if (landingPage !== "landingPageTestride") {
      getCookiedata("userdata");
    }
  }, []);

  useEffect(() => {
    if (params.ticketid == "homeconfirmation" && params.userid == "Home") {
      setActive(params.orderid);
      getTestDrives(params.orderid);
    }
    if (params.pincode) {
      setPincodeParam(params.pincode);
      setSearch(params.pincode);
    }
  }, []);

  useEffect(() => {
    document.querySelectorAll('[data-testid="DAY_LABEL"]').forEach((e) => {
      if (e.innerHTML == "Th") {
        e.innerHTML = "THU";
      } else if (e.innerHTML == "Fr") {
        e.innerHTML = "FRI";
      } else if (e.innerHTML == "Sa") {
        e.innerHTML = "SAt";
      } else if (e.innerHTML == "Su") {
        e.innerHTML = "SUN";
      } else if (e.innerHTML == "Mo") {
        e.innerHTML = "MON";
      } else if (e.innerHTML == "Tu") {
        e.innerHTML = "TUE";
      } else if (e.innerHTML == "We") {
        e.innerHTML = "Wed";
      }
    });
    let btn1 = document.querySelector(".Ez");
    let btn2 = document.querySelector(".WP");
    if (btn1) {
      btn1.innerHTML = btn1.innerHTML = "←";
    }
    if (btn2) {
      btn2.innerHTML = btn2.innerHTML = "→";
    }
  });

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (landingPage === "landingPageTestride") {
      checkLocationAndCallDealers();
    }
  }, []);

  useEffect(() => {
    if (landingPage !== "landingPageTestride") {
      getdealerdata();
    }
  }, [myLat, myLong, pincodeParam]);

  useEffect(() => {
    hometestRidedateFun();
  }, [hometestridedate]);

  useEffect(() => {
    if (active) {
      localStorage.setItem("Dealername", JSON.stringify(active.username));
    }
  }, []);

  useEffect(() => {
    if (
      active &&
      params.ticketid != "homeconfirmation" &&
      params.userid != "Home"
    ) {
      scrolldown();
    }
  }, [active]);

  useEffect(() => {
    bookevent();
  }, [activeevent]);

  useEffect(() => {
    if (landingPage !== "landingPageTestride") {
      document
        .getElementById("right_testride_scroll")
        .addEventListener("scroll", (e) => {
          let yAxisForHeader =
            document.querySelector(".booktest_ride").scrollTop;
          var yAxisScrollForm =
            document.querySelector(".testride_righsec").scrollTop;
          if (yAxisForHeader > 10 || yAxisScrollForm > 10) {
            window.document.querySelector("header").style.display = "none";
          } else {
            window.document.querySelector("header").style.display = "block";
          }
        });
      return () =>
        document.querySelector(".testride_righsec")?.addEventListener("scroll");
    }
  }, []);

  useEffect(() => {
    if (landingPage !== "landingPageTestride") {
      document
        .getElementById("booktest-ridepage")
        .addEventListener("scroll", (e) => {
          let yAxisForHeader =
            document.querySelector(".height_one_csrol").scrollTop;
          let yAxisScrollForm =
            document.querySelector(".testride_righsec").scrollTop;
          if (yAxisForHeader > 10 || yAxisScrollForm > 10) {
            window.document.querySelector("header").style.display = "none";
          } else {
            window.document.querySelector("header").style.display = "block";
          }
        });
      return () =>
        document.querySelector(".height_one_csrol")?.addEventListener("scroll");
    }
  }, []);

  const getTestDrives = async (orderid) => {
    let mytestDrives = await GET_TestDriveDataAPI();
    let filteredTestDrive = mytestDrives?.filter((item) => {
      return item.ticket_id == orderid;
    });
    setSearch(filteredTestDrive[0].pincode);
  };

  function getLocation() {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "prompt" || result.state === "denied") {
          result.onchange = () => {
            window.location.reload();
          };
          setPermissionAllowed(false);
        } else if (result.state == "granted") {
          result.onchange = () => {
            window.location.reload();
          };
          setPermissionAllowed(true);
        }
      });
    } else {
      console.log("Permissions API is not supported by this browser.");
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Browser Not Supported");
    }
  }

  function showPosition(position) {
    setMyLat(position.coords.latitude);
    setMyLong(position.coords.longitude);
  }

  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      navigate("/");
    }
  };

  const selectedDay = (val) => {
    setBtn1(false);
    setBtn2(false);
    setBtn3(false);
    setDate(val);
    let year = val.getFullYear().toString();
    let month = val.getMonth() + 1;
    let month2 = (month = month < 10 ? "0" + month : month);
    let dtts = val.getDate() + "/" + month2 + "/" + year;
    let date = dtts.split("/").join("-");
    setDate2(date);
  };

  function btn(no) {
    if (no == 1) {
      setBtn1(true);
      setBtn2(false);
      setBtn3(false);
      setTimes("9AM - 12PM");
    } else if (no == 2) {
      setBtn1(false);
      setBtn2(true);
      setBtn3(false);
      setTimes("12PM - 3PM");
    } else if (no == 3) {
      setBtn1(false);
      setBtn2(false);
      setBtn3(true);
      setTimes("3PM - 6PM");
    } else if (no == 4) {
      setBtn1(false);
      setBtn2(false);
      setBtn3(false);
      setTimes("6 PM to 9 PM ");
    }
  }

  const handlechange = (e) => {
    setSearch(e.target.value);
  };

  const handleChangeDealer = (e) => {
    setSearchDealerValue(e.target.value);
  };

  const addFUNCTION = () => {
    setTimeout(() => {
      document.querySelectorAll('[data-testid="DAY_ITEM"]').forEach((e) => {
        e.onclick = function (e) {
          removeALLCLS();
          if (
            e.target.classList.contains("Lx") ||
            e.target.classList.contains("_1g")
          ) {
            e.target.parentNode.classList.add("ourclass");
          } else {
            e.target.classList.add("ourclass");
          }
        };
      });
    }, 80);
  };

  addFUNCTION();

  const removeALLCLS = () => {
    document.querySelectorAll('[data-testid="DAY_ITEM"]').forEach((e) => {
      e.classList.remove("ourclass");
    });
  };

  const getdealerdata = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        pincode: params.pincode
          ? params.pincode
          : search
          ? search
          : JSON.parse(localStorage.getItem("pincode")),
        user_id: JSON.parse(getCookie("userdata")).user_id,
        lat: myLat,
        lng: myLong,
      }),
    };
    try {
      setLoading(true);

      const response = await fetch(Config.pincode, requestOptions);
      if (response) {
        setLoading(false);
        const json = await response.json();
        const test_ridedate = json.testrides.map((item) => {
          return moment(
            Object.keys(item).toString().split("-").reverse().join("/")
          ).format("YYYY-MM-DD");
        });
        if (test_ridedate) {
          setHometestdate(test_ridedate);
        }
        if (type == "Dealer" || type == "Experience center") {
          const dealer = json.dealers.filter((item) => {
            return item.username == conf;
          });
          setDealerdatas(dealer);
        }
        setEvent(json.events);
        setHometest(json.testrides);
        setDealerdata(json.dealers);
        setHometestshow(json.testrides[0]);
        setHometestridehide(false);
        setHometestridehidedsiabel(false);
        seterror(false);
        setHometestshow([]);
        setRide(false);
        setHometestdateerror("");
        opencancel(false);
      }
    } catch (error) {
      console.log(error);
      seterror(true);
      setEvent([]);
      setHometest([]);
      setDealerdata([]);
      setHometestshow([]);
      setActive();
      setHometestridehidedsiabel(false);
      setHometestdateerror("");
      setRide(false);
    }
  };

  const checkLocationAndCallDealers = async () => {
    if ("geolocation" in navigator) {
      let x = await navigator.permissions.query({ name: "geolocation" });
      if (x.state === "granted") {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            getAllDealersOnLoad(
              position.coords.latitude,
              position.coords.longitude
            );
            setMyLat(position.coords.latitude);
            setMyLong(position.coords.longitude);
          },
          (error) => {
            getAllDealersOnLoad(JAIPUR_LATITUDE, JAIPUR_LONGITUDE);
          }
        );
      } else {
        getAllDealersOnLoad(JAIPUR_LATITUDE, JAIPUR_LONGITUDE);
      }
    } else {
      getAllDealersOnLoad(JAIPUR_LATITUDE, JAIPUR_LONGITUDE);
    }
  };

  const getAllDealersOnLoad = async (latitude, longitude) => {
    setLoading(true);
    const data = {
      lat: latitude ? latitude : JAIPUR_LATITUDE,
      long: longitude ? longitude : JAIPUR_LONGITUDE,
    };

    console.log(data, "data");

    let response = await axios.post(Config.fetchAllDealers, data, {
      headers: {
        "Content-type": "application/json",
      },
    });
    setDealerdatas(response.data.data);
    let latLongArrayData = [];
    response.data.data.forEach((item) => {
      latLongArrayData.push({ lat: item.lat, long: item.lng });
    });
    setLatLongArray(latLongArrayData);
    setDealerDatasToStore(response.data.data);
    setLoading(false);
  };

  const hometestRidedateFun = () => {
    let newdates = hometestridedate[0];
    let newdate4 = new Date(newdates);
    let time = newdate4.toLocaleTimeString("it-IT");
    let check1 = newdate4.getMonth();
    let check2 = newdate4.getDate();
    let chekc3 = newdate4.getFullYear();
    let date = (check2 = check2 < 10 ? "0" + check2 : check2);
    let month = globalmonth[check1];
    let collectdate = `${date} ${month} ${chekc3} ${time} UTC`;
    if (collectdate.startsWith("NaN")) {
      setHomefirstdate("");
    } else {
      setHomefirstdate(collectdate);
    }
    let dateindex = hometestridedate.length - 1;
    let dateindex2 = hometestridedate[dateindex];
    let lastdate = new Date(dateindex2);
    let lastdate_time = lastdate.toLocaleTimeString("it-IT");
    let lastdate_month = lastdate.getMonth();
    let lastdate_getdate = lastdate.getDate();
    let lastdate_getfullyear = lastdate.getFullYear();
    let lastdate_day = (lastdate_getdate =
      lastdate_getdate < 10 ? "0" + lastdate_getdate : lastdate_getdate);
    let lastdatemonth = globalmonth[lastdate_month];
    let collect_lastdate = `${lastdate_day} ${lastdatemonth} ${lastdate_getfullyear} ${lastdate_time} UTC`;
    if (collect_lastdate.startsWith("NaN")) {
      setHomeLastdate("");
    } else {
      setHomeLastdate(collect_lastdate);
    }
  };

  const searchDealerHome = async () => {
    if (searchDealerValue) {
      getDealersHome();
    } else {
      let latLongArrayData = [];
      dealersDataToStore.forEach((item) => {
        latLongArrayData.push({ lat: item.lat, long: item.lng });
      });
      setLatLongArray(latLongArrayData);
      setDealerdatas(dealersDataToStore);
    }
  };

  const getDealersHome = async () => {
    try {
      setLoading(true);
      const data = {
        location: searchDealerValue,
        lat: myLat,
        long: myLong,
      };

      let response = await axios.post(Config.fetchSearchDealer, data, {
        headers: {
          "Content-type": "application/json",
        },
      });
      if (response) {
        let latLongArrayData = [];
        response.data.data.forEach((item) => {
          latLongArrayData.push({ lat: item.lat, long: item.lng });
        });
        setLatLongArray(latLongArrayData);
        setDealerdatas(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const searchdealer = async () => {
    setSearchDialog(true);
    var pin = "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$";
    if (search === "" || search == null || search === " ") {
      setPincodeerror1(true);
      setPincodeerror2(false);
      return false;
    } else {
      setPincodeerror1(false);
    }
    if (search.length > 6 || search.length < 6 || !search.match(pin)) {
      setPincodeerror2(true);
      setPincodeerror1(false);
      return false;
    } else {
      setPincodeerror2(false);
    }

    localStorage.setItem("pincode", JSON.stringify(search));
    getdealerdata();
  };
  const handeler = (Event) => {
    return Event.key == "Enter" ? searchdealer() : "";
  };
  const searchDealerHandler = (e) => {
    return e.key == "Enter" ? searchDealerHome() : "";
  };

  const getdealerdatas = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        pincode: JSON.parse(localStorage.getItem("pincode")),
        user_id: JSON.parse(getCookie("userdata")).user_id,
        lat: myLat && myLat,
        lng: myLong && myLong,
      }),
    };
    try {
      const response = await fetch(Config.pincode, requestOptions);
      const json = await response.json();
      if (
        json.testdrive_ticket_id !== null ||
        json.testdrive_ticket_id !== "" ||
        json.testdrive_ticket_id !== " "
      ) {
        setTicketid(json.testdrive_ticket_id);
        setPreviousTicketData(json.testdrive_dealer_details);
        setopen(true);
      }
      if (
        json.testdrive_ticket_id == null ||
        json.testdrive_ticket_id == "" ||
        json.testdrive_ticket_id == " "
      ) {
        setopen(false);
        return hometestdrivedisabel ? bookhometestride() : booktestride1();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const openMapInNewTab = (latitude, longitude) => {
    if (myLat && myLong) {
      if (!latitude || !longitude) {
        toast.info("Direction Not Available for this dealer.", {
          autoClose: 1000,
        });
      } else {
        let url = `https://www.google.com/maps/dir/${myLat}+${myLong}+/${latitude},+${longitude}`;
        window.open(url, "_blank");
      }
    } else {
      let url = `https://www.google.com/maps/dir/${JAIPUR_LATITUDE}+${JAIPUR_LONGITUDE}+/${latitude},+${longitude}`;
      window.open(url, "_blank");
    }
  };

  const Booktestride = async () => {
    if (event.length == 0 && homtest.length == 0 && dealerdata.length > 0) {
      if (active == null || active == "" || active == " ") {
        alert("please select Dealer");
        return false;
      }
    }
    if (homtest.length > 0 && dealerdata.length > 0) {
      if (
        active == null ||
        active == "" ||
        active == " " ||
        (Hometestdateerror == null && Hometestdateerror == "") ||
        Hometestdateerror == " "
      ) {
        alert("please select Dealer or HomeTestRide");
        return false;
      }
    }
    if (event.length == 0 && dealerdata.length == 0 && homtest.length > 0) {
      if (
        Hometestdateerror == null ||
        Hometestdateerror == "" ||
        Hometestdateerror == " "
      ) {
        alert("please select HomeTestRide");
        return false;
      }
    }
    if (dealerdata.length > 0) {
      if (times == null || times == "" || times == " ") {
        alert("please select Test Ride Time");
        return false;
      }
    }
    getdealerdatas();
  };

  const cancelbooktestride = async () => {
    const requestOptionsForOtpVarification = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        ticket_id: `${ticket}`,
        reason: "Booked Another Test Ride",
      }),
    };
    try {
      const response = await fetch(
        Config.cancel_testride,
        requestOptionsForOtpVarification
      );
      const json = await response.json();
      if (json.data == "Test drive cancelled successfully") {
        setopen(false);
        return hometestdrivedisabel ? bookhometestride() : booktestride1();
      } else if (json.error) {
        alert("Already cancelled");
        setopen(false);
        return hometestdrivedisabel ? bookhometestride() : booktestride1();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const booktestride1 = async () => {
    const requestOptionsForOtpVarification = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        name: JSON.parse(getCookie("userdata")).user_name? JSON.parse(getCookie("userdata")).user_name:"",
        email: JSON.parse(getCookie("userdata")).user_email? JSON.parse(getCookie("userdata")).user_email:"",
        phone_num: JSON.parse(getCookie("userdata")).user_number ? JSON.parse(getCookie("userdata")).user_number:"",
        pin_code: JSON.parse(localStorage.getItem("pincode"))?JSON.parse(localStorage.getItem("pincode")):"",
        user_id: JSON.parse(getCookie("userdata")).user_id ?JSON.parse(getCookie("userdata")).user_id :"",
        source: "OXO Website",
        flag: "dealer",
        time: `${times}`,
        date: `${date2}`,
        model: "",
        dealer_id: `${active.id}`,
      }),
    };
    try {
      const response = await fetch(
        Config.test_drive_ticket,
        requestOptionsForOtpVarification
      );
      const json = await response.json();
      console.log(json)
      nexts();
      function nexts(conditons = false) {
        if (conditons == true || json.dealer_id != "") {
          let storebookdata = {
            dealer: `${active.address}`,
            date: date2,
            time: times,
            order_id: json.order_id,
            ticket_id: json.ticket_id,
            dealer_id: json.dealer_id,
            name: `${active.name}`,
            dealer_phone_number: `${active.phone_num}`,
            lat: `${active.latitude}`,
            long: `${active.longitude}`,
          };
          localStorage.setItem("dealerdata", JSON.stringify(storebookdata));
          localStorage.setItem("Dealername", JSON.stringify(active.username));
          navigate(`/homeconfirmation/${json.ticket_id}/${dealers}`);
        } else {
          alert("Please try again after some time");
          return false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangedate = (d) => {
    let date = new Date(d[0]);
    setHomedate(date);
    let getdate = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let lastdate = `${getdate}-${month}-${year}`;
    let filtertestride = homtest.filter((item) => {
      return Object.keys(item) == lastdate;
    });
    let object = Object.values(filtertestride[0]);
    let object_id = object[0].id;
    setHometestdateerror(lastdate);
    sethometestrideid(object_id);
    setHometestshow(filtertestride);
  };

  const hometestbutton = (data, slot) => {
    let splitslots = data.split("")[4];
    sethometestrideslot(splitslots);
    setTimeslots(slot);

    if (data == "slot1") {
      setHometestbtn(true);
      setHometestbtn1(false);
      setHometestbtn2(false);
      setHometesttiem("slot1");
    } else if (data == "slot2") {
      setHometestbtn(false);
      setHometestbtn1(true);
      setHometestbtn2(false);
      setHometesttiem("slot2");
    } else if (data == "slot3") {
      setHometestbtn(false);
      setHometestbtn1(false);
      setHometestbtn2(true);
      setHometesttiem("slot3");
    } else {
      setHometestbtn(false);
      setHometestbtn1(false);
      setHometestbtn2(false);
      setHometesttiem("");
    }
  };

  const hometestride = () => {
    if (
      Hometestdateerror == "" ||
      Hometestdateerror == " " ||
      Hometestdateerror == null
    ) {
      alert("please select HomeTestRide Date");
      return false;
    } else if (
      hometesttimes == "" ||
      hometesttimes == " " ||
      hometesttimes == null
    ) {
      alert("please select HomeTestRide Time");
      return false;
    }
    getdealerdatas();
  };

  const bookhometestride = () => {
    let storehometestridedata = {
      hometestride_date: Hometestdateerror,
      hometestride_time: timesslots,
      hometestride_id: hometestrideid,
      hometestride_slots: hometestslot,
    };
    localStorage.setItem(
      "homtestridedata",
      JSON.stringify(storehometestridedata)
    );
    navigate("/homeaddressmap");
  };

  const scrolldown = () => {
    let scrollToElement = document.getElementById("date_select");
    if (scrollToElement && document.documentElement.scrollHeight < 1020) {
      scrollToElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const bookevent = () => {
    if (activeevent) {
      let getvenue = activeevent.venue.split("||");
      let event = new Date(activeevent.date);
      let event_month = event.getMonth();
      let eventdate = event.getDate();
      let eventyear = event.getFullYear();
      let formatdate = (eventdate =
        eventdate < 10 ? "0" + eventdate : eventdate);
      let eventmonth = globalmonth[event_month];
      let formatedata = `${formatdate} ${eventmonth} ${eventyear}`.split(" ");
      setEventid(activeevent.id);
      setEventdate(formatedata);
      setEventaddress(getvenue[0]);
      setEventtime(getvenue[1]);
      setStoreeventdate(activeevent.date_formatted);
    }
  };

  const Booknow = async () => {
    const requestOptionsForOtpVarification = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        name: JSON.parse(getCookie("userdata")).user_name,
        email: JSON.parse(getCookie("userdata")).user_email,
        phone_num: JSON.parse(getCookie("userdata")).user_number,
        pin_code: JSON.parse(localStorage.getItem("pincode")),
        city: "",
        pincode: "",
        alt_num: "",
        user_id: JSON.parse(getCookie("userdata")).user_id,
        event_id: `${evenetid}`,
        source: "OXO Website",
      }),
    };
    try {
      const response = await fetch(
        Config.booktestride_event,
        requestOptionsForOtpVarification
      );
      const json = await response.json();
      nexts();
      function nexts() {
        let sotreeventdata = {
          event_address: eventaddress,
          event_date: storeeventdate,
          event_time: `${eventtime}`.replace("Event Time:-", ""),
        };
        localStorage.setItem("eventdata", JSON.stringify(sotreeventdata));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const reschedule = async () => {
    if (type == "Dealer" || type == "Experience center") {
      if (times == null || times == "" || times == " ") {
        alert("please select Test Ride Time");
        return false;
      }
      const requestOptionsForOtpVarification = {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(getCookie("userdata")).user_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ticket_id: `${id}`,
          category: `${type == "Dealer" ? "Dealer" : "Experience center"}`,
          date: `${date2}`,
          time_slot: `${times}`,
        }),
      };
      try {
        const _response = await fetch(
          Config.resechulde,
          requestOptionsForOtpVarification
        )
          .then((e) => {
            return e.json();
          })
          .then((res) => {
            if (
              (res.message == "Ticket resceduled successfully" &&
                type == "Dealer") ||
              type == "Experience center"
            ) {
              navigate("/userDashboard");
            }
            if (
              res.message == "Ticket resceduled successfully" &&
              conf1 == "homeconfirmation"
            ) {
              navigate(`/homeconfirmation/${id}/${dealers}/${date2}/${times}`);
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else if (type == "Home") {
      if (
        Hometestdateerror == "" ||
        Hometestdateerror == " " ||
        Hometestdateerror == null
      ) {
        alert("please select HomeTestRide Date");
        return false;
      } else if (
        hometesttimes == "" ||
        hometesttimes == " " ||
        hometesttimes == null
      ) {
        alert("please select HomeTestRide Time");
        return false;
      }
      const requestOptionsForOtpVarification = {
        method: "POST",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(getCookie("userdata")).user_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ticket_id: `${id}`,
          category: "Home Testride",
          hometestride_id: `${hometestrideid}`,
          time_slot: `${timesslots.trim()}`,
        }),
      };
      try {
        const _response = await fetch(
          Config.resechulde,
          requestOptionsForOtpVarification
        )
          .then((e) => {
            return e.json();
          })
          .then((res) => {
            if (
              res.message == "Ticket resceduled successfully" &&
              type == "Home"
            ) {
              navigate("/userDashboard");
            }
            if (
              res.message == "Ticket resceduled successfully" &&
              conf == "homeconfirmation" &&
              type == "Home"
            ) {
              navigate(
                `/homeconfirmation/${id}/${userhome}/${Hometestdateerror}/${timesslots}`
              );
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    rows: 4,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 1,
          margin: 10,
        },
      },
      {
        breakpoint: 400, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          rows: 1,
          margin: 10,
        },
      },
    ],
  };

  const callDealer = (phone_number) => {
    const phoneURI = `tel:${phone_number}`;
    window.location.href = phoneURI;
  };

  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      {landingPage !== "landingPageTestride" && <Header />}
      <div className="height_one_csrol" id="booktest-ridepage">
        <div
          id="book-test-ride"
          className={`top_header_pm ${
            landingPage === "landingPageTestride"
              ? "home_testride_landing_page"
              : ""
          }`}
        >
          <div className="testride_map select_dealer book_map">
            {landingPage === "landingPageTestride" &&
            dealerdatas.length === 0 ? (
              <TestRideMAPZoom5
                lant={JAIPUR_LATITUDE}
                long={JAIPUR_LONGITUDE}
              />
            ) : landingPage === "landingPageTestride" ? (
              <TestRideGoogleMapWithMultipleMarkers
                myLat={myLat}
                myLong={myLong}
                latLongArray={latLongArray}
              />
            ) : (
              ""
            )}

            {(landingPage !== "landingPageTestride" &&
              searchDialog &&
              dealerdata.length == 0 &&
              homtest.length == 0) ||
            apierror ? (
              <>
                <TestRideMAPZoom5
                  lant={JAIPUR_LATITUDE}
                  long={JAIPUR_LONGITUDE}
                  mob={false}
                />
              </>
            ) : landingPage !== "landingPageTestride" ? (
              <TestRideGoogleMap lant={myLat} long={myLong} />
            ) : (
              ""
            )}

            <div
              className="testride_righsec booktest_ride"
              id="right_testride_scroll"
            >
              <div className="bike_detailcus">
                <h4 className="onetwo_testcss enter_city">
                  {landingPage == "landingPageTestride"
                    ? ""
                    : "Enter your Pincode"}
                </h4>
                <div
                  className="search_bar"
                  style={{
                    opacity: type ? 0.4 : "",
                    pointerEvents: type ? "none" : "",
                  }}
                >
                  {landingPage == "landingPageTestride" ? (
                    <>
                      <input
                        className="search_inp"
                        type="text"
                        value={searchDealerValue}
                        onChange={handleChangeDealer}
                        required
                        onKeyPress={searchDealerHandler}
                        placeholder="Search Dealer by Name, Address or Pincode"
                      />
                      <div className="search_icon">
                        <button>
                          <img
                            className="search_img"
                            src={search_ico}
                            alt="imhvgb"
                            onClick={searchDealerHome}
                          />
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <input
                        className="search_inp"
                        type="text"
                        value={search}
                        onChange={handlechange}
                        required
                        onKeyPress={(event) => handeler(event)}
                        placeholder=" Enter Pin Code "
                        maxLength="6"
                      />
                      <div className="search_icon">
                        <button>
                          <img
                            className="search_img"
                            src={search_ico}
                            alt="imhvgb"
                            onClick={searchdealer}
                          />
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                      </div>
                    </>
                  )}
                </div>
                {picodeerror1 === true ? (
                  <span
                    className="error pl-4"
                    style={{
                      color: "red",
                      fontSize: "19px",
                      marginBottom: "10px",
                      display: "block",
                    }}
                  >
                    Please Enter Pincode*
                  </span>
                ) : null}
                {picodeerror2 === true ? (
                  <span
                    className="error pl-4"
                    style={{
                      color: "red",
                      fontSize: "19px",
                      marginBottom: "10px",
                      display: "block",
                    }}
                  >
                    Please Enter Valid Pincode*
                  </span>
                ) : null}
                {permissionAllowed}
                {permissionAllowed == false ? (
                  <span
                    className="error pl-4 location_error"
                    style={{
                      color:
                        landingPage == "landingPageTestride"
                          ? "#00eaff"
                          : "red",
                    }}
                  >
                    Please allow your location to access the nearest Hop Electric Bike showroom
                  </span>
                ) : null}

                {(searchDialog &&
                  dealerdata.length == 0 &&
                  homtest.length == 0) ||
                apierror ? (
                  <div className="testride_righsec1">
                    <div className="thak_test1">
                      <div className="logo_right_cont destop_show1">
                        <img src={logo_right} alt="imhvgb" />
                      </div>
                      <h3 style={{ marginBottom: "10px" }}>
                        Thank you for your interest.
                      </h3>
                      <p style={{ margin: "0px 10px 25px" }}>
                        {" "}
                        No test rides are happening in your location at the
                        moment. However, we are working on coming to you soon.
                        <br /> Please watch out for our next communication.{" "}
                      </p>
                      <div className="next_btn1">
                        <button
                          onClick={() => navigate("/")}
                          className="default_btn"
                          style={{ marginBottom: "30px" }}
                        >
                          Back To Home
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {loading === true ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <img
                      style={{ height: "50px", height: "50px" }}
                      src={LoadingSpinner}
                    />
                  </div>
                ) : null}
                {landingPage == "landingPageTestride" &&
                dealerdatas.length === 0 ? (
                  <TestRideMAPZoom5
                    lant={JAIPUR_LATITUDE}
                    long={JAIPUR_LONGITUDE}
                    mob={"true"}
                  />
                ) : (
                  landingPage == "landingPageTestride" && (
                    <TestrideGoogleMapMobWithMarkers
                      myLat={myLat}
                      myLong={myLong}
                      latLongArray={latLongArray}
                    />
                  )
                )}
                {landingPage == "landingPageTestride" &&
                dealerdatas?.length > 0 ? (
                  <div
                    className="dealer_main booktr_slider bookheding_sec"
                    style={{
                      opacity: hometestdrivedisabel || open || conf ? 0.6 : "",
                      pointerEvents:
                        hometestdrivedisabel || open || conf ? "none" : "",
                    }}
                  >
                    <p>Near You ({dealerdatas?.length} dealers)</p>
                    <Slider {...settings} style={{ width: "100%" }}>
                      {dealerdatas.map((item, index) => {
                        return (
                          <div className="select_dealer ddd" key={index}>
                            <div>
                              <strong title={item.name}>{item.name}</strong>
                              <p title={item.address}>{item.address}</p>
                            </div>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                margin: 0,
                                gap: "10px",
                              }}
                            >
                              <span
                                className="default_btn"
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: 0,
                                }}
                                onClick={() =>
                                  openMapInNewTab(item.lat, item.lng)
                                }
                              >
                                {width >= 767 ? (
                                  <span>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M14.1012 1.90749C13.8465 1.64786 13.5235 1.46563 13.1696 1.38187C12.8156 1.29811 12.4452 1.31623 12.1012 1.43415L2.66784 4.58749C2.28738 4.71202 1.95496 4.95159 1.71647 5.27311C1.47798 5.59464 1.34518 5.98227 1.3364 6.38249C1.32762 6.78271 1.4433 7.17579 1.66746 7.50747C1.89162 7.83914 2.21321 8.09306 2.58784 8.23415L6.08117 9.56749C6.16072 9.59791 6.23314 9.64439 6.29394 9.70403C6.35473 9.76367 6.40258 9.8352 6.43451 9.91415L7.76784 13.4142C7.90356 13.7835 8.14985 14.102 8.47315 14.3263C8.79645 14.5505 9.18103 14.6697 9.57451 14.6675H9.62117C10.0219 14.6602 10.4102 14.5277 10.7318 14.2886C11.0534 14.0495 11.2921 13.7158 11.4145 13.3342L14.5678 3.88749C14.6825 3.54678 14.6996 3.18078 14.6171 2.83088C14.5347 2.48097 14.3559 2.16112 14.1012 1.90749ZM13.3345 3.46749L10.1478 12.9208C10.1108 13.0405 10.0365 13.1451 9.93576 13.2195C9.83499 13.2939 9.71308 13.3341 9.58784 13.3342C9.46334 13.3362 9.34117 13.3003 9.23757 13.2312C9.13397 13.1622 9.05384 13.0632 9.00784 12.9475L7.67451 9.44749C7.57784 9.19317 7.42877 8.96203 7.23696 8.76908C7.04515 8.57612 6.81491 8.42567 6.56117 8.32749L3.06117 6.99415C2.9431 6.95089 2.84161 6.87162 2.77103 6.76755C2.70045 6.66347 2.66435 6.53985 2.66784 6.41415C2.66794 6.28892 2.70812 6.167 2.78249 6.06624C2.85686 5.96548 2.96153 5.89116 3.08117 5.85415L12.5345 2.70082C12.643 2.65659 12.7621 2.64474 12.8772 2.6667C12.9923 2.68867 13.0986 2.74352 13.1832 2.82461C13.2679 2.90571 13.3272 3.00957 13.354 3.12366C13.3809 3.23775 13.3741 3.35717 13.3345 3.46749Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                ) : (
                                  ""
                                )}
                                {width < 767 ? (
                                  <span>
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        d="M7.95573 11.7499L2.53906 9.66659C2.30295 9.59715 2.11892 9.45826 1.98698 9.24993C1.85503 9.04159 1.78906 8.81243 1.78906 8.56243C1.78906 8.32632 1.85851 8.12146 1.9974 7.94784C2.13628 7.77423 2.31684 7.63882 2.53906 7.54159L16.1849 2.29159C16.3793 2.20826 16.5668 2.1909 16.7474 2.23951C16.928 2.28812 17.0807 2.37493 17.2057 2.49993C17.3446 2.6527 17.4384 2.8159 17.487 2.98951C17.5356 3.16312 17.5182 3.3402 17.4349 3.52076L12.1849 17.1874C12.1155 17.4374 11.9766 17.6215 11.7682 17.7395C11.5599 17.8576 11.3446 17.9166 11.1224 17.9166C10.8863 17.9166 10.6641 17.861 10.4557 17.7499C10.2474 17.6388 10.1085 17.4583 10.0391 17.2083L7.95573 11.7499Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                              <span
                                className="default_btn"
                                onClick={() => callDealer(item?.phone_num)}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 25 25"
                                  fill="none"
                                >
                                  <path
                                    d="M8.73392 2.5825C9.2809 2.41759 9.86786 2.44498 10.3971 2.66014C10.9263 2.87529 11.3659 3.26521 11.6427 3.765L11.7352 3.95125L12.5627 5.79C12.8138 6.34811 12.8953 6.96773 12.7971 7.5718C12.6989 8.17587 12.4252 8.73773 12.0102 9.1875L11.8439 9.3525L10.5402 10.5688C10.3052 10.7913 10.4814 11.6525 11.3314 13.125C12.0964 14.45 12.7189 15.0688 13.0252 15.1025H13.0789L13.1452 15.09L15.7077 14.3063C16.0521 14.2006 16.4196 14.1965 16.7663 14.2942C17.113 14.392 17.4242 14.5875 17.6627 14.8575L17.7764 15.0013L19.4727 17.3513C19.8051 17.8119 19.9708 18.372 19.9424 18.9393C19.914 19.5066 19.6932 20.0474 19.3164 20.4725L19.1639 20.63L18.4864 21.2725C17.878 21.8487 17.117 22.238 16.2938 22.3942C15.4706 22.5504 14.6198 22.467 13.8427 22.1538C11.4239 21.1788 9.22642 18.9513 7.23017 15.4938C5.23017 12.0275 4.39892 9.00625 4.77017 6.41875C4.88251 5.63659 5.20459 4.89945 5.70221 4.28562C6.19983 3.6718 6.85441 3.2042 7.59642 2.9325L7.83767 2.8525L8.73392 2.5825Z"
                                    fill="#000"
                                  />
                                </svg>
                              </span>
                            </p>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                ) : (
                  <>
                    <TestRideMAPZoom5
                      lant={JAIPUR_LATITUDE}
                      long={JAIPUR_LONGITUDE}
                    />
                  </>
                )}

                {(dealerdata.length > 0 && !type) || type == "Home" ? (
                  <div
                    className="dealer_main booktr_slider bookheding_sec"
                    style={{
                      opacity: hometestdrivedisabel || open || conf ? 0.4 : "",
                      pointerEvents:
                        hometestdrivedisabel || open || conf ? "none" : "",
                    }}
                  >
                    <TestRideGoogleMapMob lant={myLat} long={myLong} />
                    <p>Near You</p>
                    <Slider {...settings} style={{ width: "100%" }}>
                      {dealerdata.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              if (
                                landingPage !== "landingPageTestride" &&
                                width < 767
                              ) {
                                setlat(item.latitude);
                                setlong(item.longitude);
                                setActive(item);
                                setHometestridehide(true);
                              }
                            }}
                            className={`select_dealer ${
                              active == item ? "highlight" : ""
                            }`}
                            key={index}
                          >
                            <strong>{item.name}</strong>
                            <p>{item.address}</p>
                            {width > 767 ? <p>0{item.phone_num}</p> : null}
                            <div
                              className="icon_sec"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent:
                                  item.distance == "null"
                                    ? "center"
                                    : "space-between",
                                marginBottom: "10px",
                              }}
                            >
                              {item.distance == "null" ? null : (
                                <>
                                  <span className="approx">Approx</span>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>{item.distance} kms</span>
                                  </div>
                                </>
                              )}
                              <button
                                className="cancel_btn"
                                onClick={() => (
                                  setShowInfoPopup(true), setInfoPopupData(item)
                                )}
                              >
                                <img src={infor_icon} alt="reschedule" />
                              </button>
                            </div>

                            {width > 767 &&
                              landingPage !== "landingPageTestride" && (
                                <button
                                  class="default_btn"
                                  onClick={() => {
                                    return (
                                      setlat(item.latitude),
                                      setlong(item.longitude),
                                      setActive(item),
                                      setHometestridehide(true)
                                    );
                                  }}
                                >
                                  Select Dealer
                                </button>
                              )}
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                ) : (dealerdatas.length > 0 && type == "Dealer") ||
                  type == "Experience center" ? (
                  <div
                    className="dealer_main booktr_slider bookheding_sec"
                    style={{
                      opacity: hometestdrivedisabel || open || conf ? 0.6 : "",
                      pointerEvents:
                        hometestdrivedisabel || open || conf ? "none" : "",
                    }}
                  >
                    <p>Near You</p>
                    <Slider {...settings} style={{ width: "100%" }}>
                      {dealerdatas.map((item, index) => {
                        return (
                          <div className="select_dealer highlight" key={index}>
                            <strong>{item.name}</strong>
                            <p>{item.address}</p>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                ) : (
                  ""
                )}

                {dealerdata.length > 0 && homtest.length > 0 ? (
                  <div className="dealer_border"></div>
                ) : (
                  ""
                )}
                {homelastdate && (
                  <div
                    className="next_btn bo_ather"
                    style={{
                      opacity:
                        hometestridehide ||
                        type == "Dealer" ||
                        open ||
                        type == "Home" ||
                        type == "Experience center"
                          ? 0.3
                          : "",
                      pointerEvents:
                        hometestridehide ||
                        type == "Dealer" ||
                        type == "Home" ||
                        open ||
                        type == "Experience center"
                          ? "none"
                          : "",
                      display:
                        (dealerdata.length == 0 && homtest.length == 0) ||
                        apierror
                          ? "none"
                          : "",
                    }}
                  >
                    <TestRideGoogleMapMob lant={lat} long={long} />
                    <button
                      onClick={() => {
                        return (
                          hometestdrivedisabel ? setRide(false) : setRide(true),
                          hometestdrivedisabel
                            ? setHometestridehidedsiabel(false)
                            : setHometestridehidedsiabel(true),
                          addFUNCTION(),
                          setHometestdateerror(""),
                          setHometestshow([])
                        );
                      }}
                      className={`avelabail_home ${
                        hometestdrivedisabel || type == "Home"
                          ? "home_testride"
                          : ""
                      }`}
                    >
                      <figure>
                        <img src={start_ic} alt="start" />
                      </figure>
                      Home Test Ride is Available
                    </button>
                  </div>
                )}

                <div
                  className="date_show bookheding_sec"
                  id="date_select"
                  {...events}
                  ref={ref}
                >
                  {(dealerdata.length > 0 && active !== null) || ride ? (
                    <p>Date:</p>
                  ) : (
                    ""
                  )}
                  {(dealerdata.length > 0 &&
                    active !== null &&
                    !hometestdrivedisabel &&
                    !type) ||
                  (!hometestdrivedisabel && type == "Dealer") ||
                  (!hometestdrivedisabel && type == "Experience center") ? (
                    <>
                      <DatePicker
                        className={"hello"}
                        getSelectedDay={selectedDay}
                        selectsStart
                        selectsEnd
                        startDate={new Date()}
                        endDate={31}
                        selectDate={dates}
                        labelFormat={"MMMM"}
                        color={"#00EAFF"}
                        enableScroll={true}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {(homefirstdate && homelastdate && hometestdrivedisabel) ||
                  (homefirstdate &&
                    homelastdate &&
                    type == "Home" &&
                    //  )
                    active != null) ? (
                    <div>
                      <Datepicker
                        className={"by"}
                        onChange={handleChangedate}
                        locale={enUS}
                        startValue={new Date(homefirstdate)}
                        startDate={new Date(homefirstdate)}
                        endDate={new Date(homelastdate)}
                        endValue={new Date(homelastdate)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="time_next bookheding_sec">
                  {(dealerdata.length > 0 &&
                    active !== null &&
                    !hometestdrivedisabel) ||
                  Hometestdateerror ? (
                    <p className="hide_mob">Time:</p>
                  ) : (
                    ""
                  )}
                  {(dealerdata.length > 0 &&
                    active !== null &&
                    !hometestdrivedisabel &&
                    !type) ||
                  (!hometestdrivedisabel && type == "Dealer") ||
                  (!hometestdrivedisabel && type == "Experience center") ? (
                    <div className="date_piker">
                      <ul>
                        {moment(new Date()).format("DD-MM-YYYY") ==
                          moment(dates).format("DD-MM-YYYY") && (
                          <>
                            <li>
                              <button
                                className={
                                  moment(new Date()).format("DD-MM-YYYY") ==
                                    moment(dates).format("DD-MM-YYYY") &&
                                  moment(new Date()).format("HH:mm") <= "11:00"
                                    ? ""
                                    : "btn-disabled"
                                }
                                onClick={() => btn(1)}
                                style={
                                  btn1 == true
                                    ? {
                                        color: "#00EAFF",
                                        border: "1px solid #00EAFF",
                                      }
                                    : {}
                                }
                              >
                                9 AM - 12 PM
                              </button>
                            </li>
                            <li>
                              <button
                                className={
                                  moment(new Date()).format("DD-MM-YYYY") ==
                                    moment(dates).format("DD-MM-YYYY") &&
                                  moment(new Date()).format("HH:mm") <= "14:00"
                                    ? ""
                                    : "btn-disabled"
                                }
                                onClick={() => btn(2)}
                                style={
                                  btn2 == true
                                    ? {
                                        color: "#00EAFF",
                                        border: "1px solid #00EAFF",
                                      }
                                    : {}
                                }
                              >
                                12 PM - 3 PM
                              </button>
                            </li>
                            <li>
                              <button
                                className={
                                  moment(new Date()).format("DD-MM-YYYY") ==
                                    moment(dates).format("DD-MM-YYYY") &&
                                  moment(new Date()).format("HH:mm") <= "17:00"
                                    ? ""
                                    : "btn-disabled"
                                }
                                onClick={() => btn(3)}
                                style={
                                  btn3 == true
                                    ? {
                                        color: "#00EAFF",
                                        border: "1px solid #00EAFF",
                                      }
                                    : {}
                                }
                              >
                                3 PM - 6 PM
                              </button>
                            </li>
                          </>
                        )}
                        {!(
                          moment(new Date()).format("DD-MM-YYYY") ==
                          moment(dates).format("DD-MM-YYYY")
                        ) && (
                          <>
                            <li>
                              <button
                                onClick={() => btn(1)}
                                style={
                                  btn1 == true
                                    ? {
                                        color: "#00EAFF",
                                        border: "1px solid #00EAFF",
                                      }
                                    : {}
                                }
                              >
                                9 AM - 12 PM
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => btn(2)}
                                style={
                                  btn2 == true
                                    ? {
                                        color: "#00EAFF",
                                        border: "1px solid #00EAFF",
                                      }
                                    : {}
                                }
                              >
                                12 PM - 3 PM
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => btn(3)}
                                style={
                                  btn3 == true
                                    ? {
                                        color: "#00EAFF",
                                        border: "1px solid #00EAFF",
                                      }
                                    : {}
                                }
                              >
                                3 PM - 6 PM
                              </button>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  ) : (homefirstdate && homelastdate && type == "Home") ||
                    ride ? (
                    homtestshow.map((item, index) => {
                      let data = Object.values(item);
                      return (
                        <div className="date_piker" key={index}>
                          <ul>
                            {moment(new Date()).format("DD-MM-YYYY") ==
                              moment(homedate).format("DD-MM-YYYY") && (
                              <>
                                <li>
                                  <button
                                    className={
                                      moment(new Date()).format("DD-MM-YYYY") ==
                                        moment(homedate).format("DD-MM-YYYY") &&
                                      moment(new Date()).format("HH:mm") <=
                                        "11:00"
                                        ? ""
                                        : "btn-disabled"
                                    }
                                    onClick={() =>
                                      hometestbutton("slot1", data[0].slot1)
                                    }
                                    style={
                                      hometestridebtn == true
                                        ? {
                                            color: "#00EAFF",
                                            border: "1px solid #00EAFF",
                                          }
                                        : {
                                            opacity:
                                              data[0].availability1 === false
                                                ? 0.3
                                                : "",
                                            pointerEvents:
                                              data[0].availability1 === false
                                                ? "none"
                                                : "",
                                          }
                                    }
                                  >
                                    {data[0].slot1}
                                  </button>
                                </li>

                                <li>
                                  <button
                                    className={
                                      moment(new Date()).format("DD-MM-YYYY") ==
                                        moment(homedate).format("DD-MM-YYYY") &&
                                      moment(new Date()).format("HH:mm") <=
                                        "14:00"
                                        ? ""
                                        : "btn-disabled"
                                    }
                                    onClick={() =>
                                      hometestbutton("slot2", data[0].slot2)
                                    }
                                    style={
                                      hometestridebtn1 == true
                                        ? {
                                            color: "#00EAFF",
                                            border: "1px solid #00EAFF",
                                          }
                                        : {
                                            opacity:
                                              data[0].availability2 === false
                                                ? 0.3
                                                : "",
                                            pointerEvents:
                                              data[0].availability2 === false
                                                ? "none"
                                                : "",
                                          }
                                    }
                                  >
                                    {data[0].slot2}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className={
                                      moment(new Date()).format("DD-MM-YYYY") ==
                                        moment(homedate).format("DD-MM-YYYY") &&
                                      moment(new Date()).format("HH:mm") <=
                                        "17:00"
                                        ? ""
                                        : "btn-disabled"
                                    }
                                    onClick={() =>
                                      hometestbutton("slot3", data[0].slot3)
                                    }
                                    style={
                                      hometestridebtn2 == true
                                        ? {
                                            color: "#00EAFF",
                                            border: "1px solid #00EAFF",
                                          }
                                        : {
                                            opacity:
                                              data[0].availability3 === false
                                                ? 0.3
                                                : "",
                                            pointerEvents:
                                              data[0].availability3 === false
                                                ? "none"
                                                : "",
                                          }
                                    }
                                  >
                                    {data[0].slot3}
                                  </button>
                                </li>
                              </>
                            )}
                            {!(
                              moment(new Date()).format("DD-MM-YYYY") ==
                              moment(homedate).format("DD-MM-YYYY")
                            ) && (
                              <>
                                <li>
                                  <button
                                    onClick={() =>
                                      hometestbutton("slot1", data[0].slot1)
                                    }
                                    style={
                                      hometestridebtn == true
                                        ? {
                                            color: "#00EAFF",
                                            border: "1px solid #00EAFF",
                                          }
                                        : {
                                            opacity:
                                              data[0].availability1 === false
                                                ? 0.3
                                                : "",
                                            pointerEvents:
                                              data[0].availability1 === false
                                                ? "none"
                                                : "",
                                          }
                                    }
                                  >
                                    {data[0].slot1}
                                  </button>
                                </li>

                                <li>
                                  <button
                                    onClick={() =>
                                      hometestbutton("slot2", data[0].slot2)
                                    }
                                    style={
                                      hometestridebtn1 == true
                                        ? {
                                            color: "#00EAFF",
                                            border: "1px solid #00EAFF",
                                          }
                                        : {
                                            opacity:
                                              data[0].availability2 === false
                                                ? 0.3
                                                : "",
                                            pointerEvents:
                                              data[0].availability2 === false
                                                ? "none"
                                                : "",
                                          }
                                    }
                                  >
                                    {data[0].slot2}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    onClick={() =>
                                      hometestbutton("slot3", data[0].slot3)
                                    }
                                    style={
                                      hometestridebtn2 == true
                                        ? {
                                            color: "#00EAFF",
                                            border: "1px solid #00EAFF",
                                          }
                                        : {
                                            opacity:
                                              data[0].availability3 === false
                                                ? 0.3
                                                : "",
                                            pointerEvents:
                                              data[0].availability3 === false
                                                ? "none"
                                                : "",
                                          }
                                    }
                                  >
                                    {data[0].slot3}
                                  </button>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    ""
                  )}
                  <div
                    className="next_btn"
                    style={{
                      opacity: booktestridebtn || open ? 0.4 : "",
                      pointerEvents: booktestridebtn || open ? "none" : "",
                      display:
                        type ||
                        (dealerdata.length == 0 && homtest.length == 0) ||
                        apierror
                          ? "none"
                          : "block",
                    }}
                  >
                    {landingPage !== "landingPageTestride" && (
                      <button
                        className="default_btn"
                        onClick={
                          hometestdrivedisabel ? hometestride : Booktestride
                        }
                      >
                        Book Test Ride{" "}
                      </button>
                    )}
                  </div>
                  <div
                    className="next_btn"
                    style={{
                      opacity: booktestridebtn ? 0.4 : "",
                      pointerEvents: booktestridebtn ? "none" : "",
                      display: type ? "block" : "none",
                    }}
                  >
                    <button
                      className="default_btn"
                      onClick={() => reschedule()}
                    >
                      Reschedule Test Ride{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="form-group modal_popup test_ride_popup form-group modal_popup close_button dasboard_cancel singleBook new_activity_icon"
              id="service_cancel"
              style={{ background: "#081119" }}
            >
              <div className="my_book_modal book_test">
                <PureModal
                  onClose={() => {
                    setShowInfoPopup(false);
                    return true;
                  }}
                  isOpen={showInfoPopup}
                  closeButton={
                    <div className="close_btn">
                      <span className="dissmiss">DISMISS</span>
                    </div>
                  }
                  closeButtonPosition="top"
                  width="100%"
                >
                  <div className="popup_cont color_change reschedule_info requested  booktest_modal">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className="webPOP_testride">
                          {" "}
                          <TestRideGoogleMapMob lant={lat} long={long} />{" "}
                        </div>

                        <h2 style={{ textAlign: "left", marginTop: "15px" }}>
                          {infoPopupData?.name}
                        </h2>
                        <h4 style={{ color: "#fff", textAlign: "left" }}>
                          {infoPopupData?.address}
                        </h4>
                        <div className="booktest_direction">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzA2MzNEMjlCRjA0MTFFRDg5NTJFOUYwRTczRUFGOEQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzA2MzNEMkFCRjA0MTFFRDg5NTJFOUYwRTczRUFGOEQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDMDYzM0QyN0JGMDQxMUVEODk1MkU5RjBFNzNFQUY4RCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMDYzM0QyOEJGMDQxMUVEODk1MkU5RjBFNzNFQUY4RCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnDCl8QAAAE6SURBVHjaYmR49Z8BCJiAuB+IPYGYDYgNgfg9AxHgvygqnwVKbwZiLyTxPiBOZCADMAJd6Aqkd2GRkwPix6S6EOTVGhxql5LjQpCBBjjkbIG4gBwD/+KRf0iOgeexiL+DunA9OQYuQhN7DcTKQHyE3DBcjeZtRiD+wEAmABn4DYhXIYmJkBvDsHQIotWA+CaWWD5CTjoEgVtAPB9N7RYgFiDXhTDDHwGxNJL8DSA2BeIvpLoQBP4BsQ0Q/0YS0wDiC0CsjSPh32Z8zTAdiLmwGQgCD4DYAU0MlIQuA3Eukpg4EO8HYhUgzoAaHIfuZWTgDsQ7sIiDImklNEsqY5E/gMtAELCE5hRxUtMhLnAciHWgXqOKgSDwBoidoF58TIR5x/B5GR2ASvcIKLYGYmYkuV9AvASICwECDAANmUaS+yftSAAAAABJRU5ErkJggg=="
                              alt="imhvgb"
                            />
                            <p
                              style={{
                                padding: 0,
                                color: "#00EAFF",
                                margin: 0,
                                paddingLeft: "5px",
                              }}
                            >
                              <a href={`tel:${infoPopupData?.phone_num}`}>
                                {infoPopupData?.phone_num}
                              </a>{" "}
                            </p>
                          </div>
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: 0,
                            }}
                            onClick={() =>
                              openMapInNewTab(
                                infoPopupData.latitude,
                                infoPopupData.longitude
                              )
                            }
                          >
                            <span style={{ cursor: "pointer", margin: 0 }}>
                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUMyMThENUFCRjA0MTFFREJFMDFBODJBQjUyQjA2QjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUMyMThENUJCRjA0MTFFREJFMDFBODJBQjUyQjA2QjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBQzIxOEQ1OEJGMDQxMUVEQkUwMUE4MkFCNTJCMDZCMSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBQzIxOEQ1OUJGMDQxMUVEQkUwMUE4MkFCNTJCMDZCMSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmdHuxQAAAGLSURBVHjajJQ9SANBEIU3KhrSWAkBFUQEmwSJQmwFC1stFFIIYpoQwb/CykZErGw9LMRCwcI2gpWNkEoFEUsLC0U9UJTY2Kxv5UXGy+5eDj525+cNw+7cKq21sqFedQ84BO9Akw9wDPpcusSvOPIlQjWH5QCEYB9UGRoFRZAGJd2l9hrEls6K7Gbd0/0acxYaYpHEdLQY9lmwCwKQE/5V5nb7ClbAp7DLFD2DJ+6XRfwFnPsK/nWHNUN7RcRL9OVpLxk7ruAY9yemM8v5PYAz7vPRgi2q8atfexJ8W+LG1yFy/42JreAQ1y3QixEqiHGaxDIANunKGrdzbND+LbgS9gaPwfhvuN8W8Qtw7zvDGYrahW+ct38KJoS/lbmzzoLiYo5cQy3yguiFuArW/5ROT7EUcxZjC1IQgmtPwSr4ssYcgkF2ULDEphjLNV2Qwh0KU5aLCJy6mIN/A3fCvgQ1n6ZN+b8MeMRAT2OtgRHQ71U0MR7z4sUux+VbX2zLC25e7CRe6OG43B8BBgCbNG9RNR9STwAAAABJRU5ErkJggg=="
                                alt="imhvgb"
                                style={{ cursor: "pointer" }}
                              />
                              Get direction
                            </span>
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </PureModal>
              </div>
            </div>
          </div>

          <div className="form-group modal_popup test_ride_popup close_button eventdetail_popup">
            <PureModal
              onClose={() => {
                opencancel(false);
                setBooktestbtn(false);
                setactiveEvent(null);
                return true;
              }}
              isOpen={showcancel}
              closeButton={<div className="close_btn"></div>}
              closeButtonPosition="top"
              width="100%"
            >
              <div className="popup_cont event_cont" ref={modalRef}>
                <div className="events_main">
                  <div className="event_cont">
                    <div className="event_name">
                      <h4>
                        <span>Indian 1</span> Electric Vehicles Auto Show 2023
                      </h4>
                      <div className="open_info">
                        <h2>Booking Open</h2>
                      </div>
                    </div>
                    <div className="date_time">
                      <p>
                        <em>Date: </em> <span>{eventdates[0]}</span>{" "}
                        {eventdates[1]} {eventdates[2]}
                      </p>
                      {eventtime ? (
                        <p>
                          <em>Event Time: </em>
                          {eventtime.replace("Event Time:-", "")}
                        </p>
                      ) : (
                        ""
                      )}
                      <p className="aling_cont">
                        <em>Address:</em>
                        {eventaddress}
                      </p>
                    </div>

                    <div className="event_booktr">
                      <button
                        onClick={() => Booknow}
                        className="default_btn elem"
                      >
                        I'm interested, Book Now
                      </button>
                      <button
                        onClick={() => {
                          return opencancel(false);
                        }}
                        className="no_tns"
                      >
                        No, Thanks
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </PureModal>
          </div>
          <div
            className="form-group modal_popup test_ride_popup book_another close_button"
            id="cancel_testride"
          >
            <PureModal
              onClose={() => {
                setopen(false);
                return true;
              }}
              isOpen={open}
              closeButton={
                <div className="close_btn" onClick={() => setopen(false)}></div>
              }
              closeButtonPosition="top"
              width="100%"
            >
              <div className="popup_cont requested previous_ride">
                <h3>Cancel previous Test Ride and continue?</h3>
                <p>
                  only 1 booking is allowed a time continuing with this booking
                  will cancel the previous one
                </p>

                <div className="another_test_book_time">
                  <span>Location</span>
                  <p> {previousTicketData?.dealer_address}</p>
                </div>
                {width > 767 ? (
                  <>
                    <p>
                      <span>Date</span> {previousTicketData?.testdrive_date}
                    </p>
                    <p>
                      <span>Time</span> {previousTicketData?.time_slot}
                    </p>
                  </>
                ) : null}

                {width < 767 ? (
                  <div className="date_tme_mobile">
                    <h3>Appointment slot</h3>
                    <p>
                      <span>
                        <FontAwesomeIcon icon={faCalendarDays} />
                      </span>
                      {previousTicketData?.testdrive_date}
                    </p>

                    <p>
                      <span>
                        <FontAwesomeIcon icon={faCalendarDays} />
                      </span>
                      {previousTicketData?.time_slot}
                    </p>
                  </div>
                ) : null}
                {width < 767 ? (
                  <h6 className="do_you_cancel">Do you want to cancel ?</h6>
                ) : null}
                <div className="book_anpo">
                  <button
                    className="user_btn border_btn"
                    onClick={() => setopen(false)}
                  >
                    No
                  </button>
                  <button
                    className="user_btn"
                    onClick={() => cancelbooktestride()}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </PureModal>
          </div>
        </div>
        {landingPage !== "landingPageTestride" && <Footer />}
      </div>
    </>
  );
};

export default BookTestRide;
