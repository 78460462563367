import React from "react";
import Slider from "react-slick";
 
import E_Flow from "../../../assets/images/E_Flow.webp";
import Advance from "../../../assets/images/Advance.webp";
import In_Wheel from "../../../assets/images/In_Wheel.webp";
import Intelligent from "../../../assets/images/Intelligent.jpg";


export default function SiliderOne() {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
  
        }
      },
    ]
  };
  return (

    
    <div className="slider_show">
      <Slider {...settings}>
      
        <div className="featur_para pop_slideron">
          <figure>
            <img  src={E_Flow} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>E-Flow Powertrain</h3>
            <p>
              Hop OXO is powered by e-Flow intelligent powertrain. There are no gears or clutch, no chains or belt just turn it on, put it on ride mode and start riding. 
               The intelligent ride system is hyper efficinet and delivers smooth performance.
            </p>
          </div>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Advance} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Advance Lithium Ion Battery</h3>
            <p>
              Advance Lithium-ion Battery with 18650 Cells and a smart BMS powers OXO. 
              AIS 156 Certified battery has undergone many rigorous tests like 50 Mtr drop, 200 Degree flame, vibration tests etc. 
              to deliver automotive-grade performance and reliability.
            </p>
          </div>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            <img  src={In_Wheel} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>In Wheel High Torque Motors | 6300 W Peak Power </h3>
            <p>
              IPMSM BLDC Motors with 40 MM 16 Pole Neodymium (NdFeB) magnets on Aluminium core is configured to deliver high quality & seamless motion. 
Efficiency η - 92.8%   <br/>
200 NM torque<br/>
IP 67 All weather performance
            </p>
          </div>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Intelligent} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Intelligent Controller | 72 V System</h3>
            <p>
              With the power to compute more than 132 decisions every mili-seconds, OXO is enabled by a brilliant brain to deliver smooth performance.  
This FOC based controller with 32-bit microprocessor ARM core is Lightning fast and enables features like Regenrative braking, predictive maintatnce etc. 
Connected to cloud with CAN Bus 2.0, this will always keep your OXO updated.
            </p>
          </div>
        </div>
  
      </Slider>
    </div>
  );
}
