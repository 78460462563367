import React from "react";
import PartsImg from '../../../assets/images/Image-23-1536x218.png';
import { HopLeoPlus } from "./HopLeoPlus";

const Tabing = () => {

return (
  <>
    <HopLeoPlus/> 
    <div className="bottom_img">
        <img  style={{width:"100%"}} src={PartsImg} alt="Parts Images" />
      </div>
    </>
  );
}

export default Tabing;

