import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./oxo.css";
import scooterBanner from "../../assets/images/oxo_main_banner.png";
import { OXO_MODELS_ARRAY } from "../../constants";
import { Config } from "../../utils/apiUrl";
import { useScrollTo } from "../../hooks/useScrollTo";

const OXO = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const [fullNameWanto, setFullNameWanto] = useState("");
  const [mobNumberWanto, setMobNumberWanto] = useState("");
  const [otpWanto, setOtpWanto] = useState("");
  const [zipCodeWanto, setZipCodeWanto] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [display, setDisplay] = useState(true);
  const [otpErrorWanto, setOtpErrorWanto] = useState(true);
  const [zipError, setZipError] = useState(true);
  const [activeOtpBtn, setActiveOtpBtn] = useState("none");
  const [resendOtp, setResendOtp] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [verifyOtp, setVerifyOtp] = useState(true);
  const [packageName, setPackageName] = useState("");

  const notify = () => {
    toast("Please verify your phone number.");
  };

  const notify1 = () => {
    toast("Thank you. Our team will contact you soon!", {
      autoClose: 1500,
    });
  };

  useEffect(() => {
    getDataFromUrl();
  }, []);

  const getDataFromUrl = () => {
    const urlParamsSource = new URLSearchParams(window.location.search).get(
      "utm_source"
    );
    setUtmSource(urlParamsSource);
    const urlParamsMedium = new URLSearchParams(window.location.search).get(
      "utm_medium"
    );
    setUtmMedium(urlParamsMedium);
    const urlParamsCampaign = new URLSearchParams(window.location.search).get(
      "utm_campaign"
    );
    setUtmCampaign(urlParamsCampaign);
  };

  const handleNameChangeWanto = (event) => {
    setFullNameWanto(event.target.value);
  };

  const handleMobNumberChangeWanto = (event) => {
    var nLength = event.target.value.length;
    if (event.target.value.length < 11) {
      setMobNumberWanto(event.target.value);
    }
    if (nLength > 0 && nLength < 10) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  };

  const handleOtpChangeWanto = (event) => {
    var nLength = event.target.value.length;
    if (event.target.value.length < 7) {
      setOtpWanto(event.target.value);
    }
    if (nLength >0 && nLength < 6) {
      setOtpErrorWanto(false);
    } else {
      setOtpErrorWanto(true);
    }
  };

  const onKeyPressNumberWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const onKeyPressOtpWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const handleZipCodeChangeWanto = (event) => {
    var zLength = event.target.value.length;
    if (zLength < 7) {
      setZipCodeWanto(event.target.value);
    }
    if (zLength >0 && zLength< 6) {
      setZipError(false);
    } else {
      setZipError(true);
    }
  };

  const onKeyPressZipWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const requestOtp = async () => {
    const apiUrl = Config.otp_varification;
    const sendOtpOptn = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ phone_num: `${mobNumberWanto}`, otp: "" }),
    };
    try {
      const response = await fetch(apiUrl, sendOtpOptn);
      const json = await response.json();
      if (json.message == "sms sent sucessfully") {
        if (mobNumberWanto.length === 10) {
          setActiveOtpBtn("block");
          setResendOtp(true);
        }
      } else {
        setActiveOtpBtn("none");
        setResendOtp(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const requestOtpComplete = async () => {
    const apiUrl = Config.otp_varification;
    const sendOtpOptn = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        phone_num: `${mobNumberWanto}`,
        otp: `${otpWanto}`,
      }),
    };
    try {
      const response = await fetch(apiUrl, sendOtpOptn);
      const json = await response.json();
      if (json.authenticated_user == "True") {
        setToken(json.token);
        setUserId(json.user_id);
        setVerifyOtp(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  async function changePackage(e) {
    setPackageName(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mobNumberWanto.length < 10) {
      return false;
    }
    if (zipCodeWanto.length < 6) {
      return false;
    }
    const data = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        phone_num: `${mobNumberWanto}`,
        email: "",
        name: `${fullNameWanto}`,
        pin_code: `${zipCodeWanto}`,
        dealer_id: "",
        address: ``,
        alt_num: ``,
        model: `${packageName}`,
        source: `OXO website (Source: ${utmSource}, Medium: ${utmMedium}, Campaign: ${utmCampaign})`,
        user_id: `${userId}`,
        landmark: "null",
        color: "",
        flag: "admin",
      }),
    };
    const apiUrl = Config.pre_booking_ticket;
    try {
      const response = await fetch(apiUrl, data);
      const json = await response.json();
      if (json.error == "Unauthorized") {
        notify();
      } else if (json != undefined) {
        setMobNumberWanto("");
        setOtpWanto("");
        setActiveOtpBtn("none");
        setVerifyOtp(true);
        setFullNameWanto("");
        setZipCodeWanto("");
        setPackageName("");
        notify1();
        setTimeout(() => {
          navigate("/");
        }, 2500);
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <div id="lead_gen">
      <div className="main-content-sec">
        <div className="content">
          <div className="left-sec">
            <img src={scooterBanner} alt="left banner image" />
          </div>
          <div className="right-sec">
            <div className="heading">
              <h2>Thank you for your interest in Hop Electric.</h2>
              <p>
                We request you to fill-up the form below. Our representative
                will get in touch with you shortly.
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <fieldset className="uk-fieldset foem_cus">
                <div className="form_input">
                  <legend className="uk-legend">Phone </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input"
                      type="text"
                      required
                      value={mobNumberWanto}
                      onChange={handleMobNumberChangeWanto}
                      name="number"
                      onKeyPress={onKeyPressNumberWanto}
                    />
                    {!display && (
                      <span className="error">Invalid Phone Number</span>
                    )}
                  </div>
                  <div className="requestOtp" onClick={requestOtp}>
                    {resendOtp === false ? "Request OTP" : "Resend OTP"}
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">Enter OTP </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input"
                      type="text"
                      value={otpWanto}
                      required
                      name="otp"
                      onChange={handleOtpChangeWanto}
                      onKeyPress={onKeyPressOtpWanto}
                    />
                    {!otpErrorWanto && (
                      <span className="error">Enter valid OTP</span>
                    )}
                    {!verifyOtp && (
                      <span className="success">OTP verified successfully</span>
                    )}
                  </div>
                  <div
                    className="requestOtp otp"
                    onClick={requestOtpComplete}
                    style={{ display: activeOtpBtn }}
                  >
                    Verify OTP
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">Name </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input uk-form-controls"
                      required
                      type="text"
                      value={fullNameWanto}
                      onChange={handleNameChangeWanto}
                    />
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">Pincode </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input"
                      type="text"
                      required
                      value={zipCodeWanto}
                      onChange={handleZipCodeChangeWanto}
                      onKeyPress={onKeyPressZipWanto}
                    />
                    {!zipError && (
                      <span className="error">Invalid Pin Code</span>
                    )}
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">Variant </legend>
                  <select
                    className="select-price-btn2"
                    onChange={changePackage}
                    value={packageName}
                  >
                    <option value="">Select a variant</option>
                    {OXO_MODELS_ARRAY?.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>

                <div className="btn_sec">
                  <button
                    className="uk-button"
                    id="oxo_lead"
                    type="submit"
                    disabled={
                      !fullNameWanto ||
                      !mobNumberWanto ||
                      !otpWanto ||
                      !zipCodeWanto ||
                      !packageName
                    }
                  >
                    Submit
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OXO;
