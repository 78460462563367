import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import "react-pure-modal/dist/react-pure-modal.min.css";
import PureModal from "react-pure-modal";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { useScrollTo } from "../../../hooks/useScrollTo";

function MyBookings() {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const [showcancel, opencancel] = useState(false);

  return (
    <>
      <Header />
      <div className="active_rides mybook_sec">
        <div className="dashboard_heading">
          <h2>My Bookings</h2>
        </div>
        <table className="user_info_table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Status</th>
              <th>action</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>19-12-2022</td>
              <td>Murari</td>
              <td>TestRide</td>
              <td>
                <button
                  className="user_btn"
                  onClick={() => {
                    navigate("/booktestride");
                  }}
                >
                  Reschedule
                </button>
                <div className="cancel_btnsec">
                  <button
                    className="cancel_btn"
                    onClick={() => opencancel(true)}
                  >
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>19-12-2022</td>
              <td>Murari</td>
              <td>TestRide</td>
              <td>
                <button
                  className="user_btn"
                  onClick={() => {
                    navigate("/booktestride");
                  }}
                >
                  Reschedule
                </button>
                <div className="cancel_btnsec">
                  <button
                    className="cancel_btn"
                    onClick={() => opencancel(true)}
                  >
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>19-12-2022</td>
              <td>Murari</td>
              <td>TestRide</td>
              <td>
                <button
                  className="user_btn"
                  onClick={() => {
                    navigate("/booktestride");
                  }}
                >
                  Reschedule
                </button>
                <div className="cancel_btnsec">
                  <button
                    className="cancel_btn"
                    onClick={() => opencancel(true)}
                  >
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>19-12-2022</td>
              <td>Murari</td>
              <td>TestRide</td>
              <td>
                <button
                  className="user_btn"
                  onClick={() => {
                    navigate("/booktestride");
                  }}
                >
                  Reschedule
                </button>
                <div className="cancel_btnsec">
                  <button
                    className="cancel_btn"
                    onClick={() => opencancel(true)}
                  >
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>19-12-2022</td>
              <td>Murari</td>
              <td>TestRide</td>
              <td>
                <button
                  className="user_btn"
                  onClick={() => {
                    navigate("/booktestride");
                  }}
                >
                  Reschedule
                </button>
                <div className="cancel_btnsec">
                  <button
                    className="cancel_btn"
                    onClick={() => opencancel(true)}
                  >
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>19-12-2022</td>
              <td>Murari</td>
              <td>TestRide</td>
              <td>
                <button
                  className="user_btn"
                  onClick={() => {
                    navigate("/booktestride");
                  }}
                >
                  Reschedule
                </button>
                <div className="cancel_btnsec">
                  <button
                    className="cancel_btn"
                    onClick={() => opencancel(true)}
                  >
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>19-12-2022</td>
              <td>Murari</td>
              <td>TestRide</td>
              <td>
                <button
                  className="user_btn"
                  onClick={() => {
                    navigate("/booktestride");
                  }}
                >
                  Reschedule
                </button>
                <div className="cancel_btnsec">
                  <button
                    className="cancel_btn"
                    onClick={() => opencancel(true)}
                  >
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          className="form-group modal_popup test_ride_popup form-group modal_popup"
          id="enter_mobile_no_desktop"
        >
          <PureModal
            onClose={() => {
              opencancel(false);
              return true;
            }}
            isOpen={showcancel}
            // scrollable={false}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change reschedule_info">
              <h2> Test Rides Cancel</h2>
              <ul className="loc_add">
                <li>
                  <strong>LOCATION:</strong>
                  <p>
                    Hop Electric - Hop Experience Center, Mansarovar, Jaipur{" "}
                    <br />
                    35/96, Rajat Path, Shipra Path Mansarovar 302020
                  </p>
                </li>
                <li>
                  <strong>DATE: </strong>
                  <p>25/12/2022</p>
                </li>
                <li>
                  <strong>PREFERRED SLOT: </strong>
                  <p>9 AM - 12 PM</p>
                </li>
              </ul>
              <div className="reschedule_infobtn">
                <button
                  onClick={() => navigate("/booktestride")}
                  className="user_btn border_btn"
                >
                  Reschedule
                </button>
                <button
                  className="user_btn"
                  onClick={() => {
                    return opencancel(false), navigate("/CancelTestrides");
                  }}
                >
                  Confirm Cancel
                </button>
              </div>
            </div>
          </PureModal>
        </div>
      </div>
    </>
  );
}

export default MyBookings;
