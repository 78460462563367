import React from "react";
import Slider from "react-slick";
import Comfort_Seat from "../../../assets/images/Comfort_Seat.jpg";
import Extra_Long from "../../../assets/images/Extra_Long.jpg";
import Good_Ground from "../../../assets/images/Good_Ground.jpg";

export default function SiliderThree() {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
  
        }
      },
    ]
  };
  return (
    <div className="slider_show">
      <Slider {...settings}>
        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Comfort_Seat} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Comfort Seat</h3>
            <p>
              Engineered for a comfortable daylong ride, the Riding position is relaxed
               and upright so that your back, knees and arms are maintained for a good posture.
            </p>
          </div>
        </div>
        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Extra_Long} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Extra Long Seat</h3>
            <p>
              Well cushoned extra longs and wide seat of Hop OXO is comfortable for you and your family.
            </p>
          </div>
        </div>
        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Good_Ground} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Good Ground Clearence </h3>
            <p>
              With 180mm of ground clearence glide easily over any potholes and speedbrakes.
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
}
