import React, { useState } from "react";
import "./style.css";

import Slider from "react-slick";
import { IMAGES } from "../../../constants/IMAGES";

const EnquiryProductFeatures = () => {
  // const {
  //   energy1,
  //   energy2,
  //   energy1hover,
  //   noise1,
  //   noise2,
  //   noiseonhover,
  //   rain1,
  //   rain2,
  //   rainonhover,
  //   storm1,
  //   storm2,
  //   stormonhover,
  //   maintanaince1,
  //   maintanaince2,
  //   maintanainceonhover,
  //   insects1,
  //   insectsonhover,
  //   dust1,
  //   dust2,
  //   dustonhover,
  //   customize1,
  //   customize2,
  //   customizeonhover,
  //   weather1,
  //   weather2,
  //   weatheronhover,
  //   elegant1,
  //   elegant2,
  //   elegantonhover,
  //   OXO_1100_600_1,
  //   OXO_1100_600_2,
  //   OXO_1100_600_3,
  //   OXO_1100_600_4,
  //   LEO_1100_600_1,
  //   LEO_1100_600_2,
  //   LEO_1100_600_3,
  //   LEO_1100_600_4,
  //   LEO_1100_600_5,
  //   LEO_1100_600_6,
  //   LYF_1100_600_1,
  //   LYF_1100_600_2,
  //   LYF_1100_600_3,
  //   LYF_1100_600_4,
  //   LYF_1100_600_5,
  //   LYF_1100_600_6,
  //   LYF_1100_600_7,
  // } = IMAGES;

  // new images update
  const {
    energy1,
    energy2,
    energy1hover,
    noise1,
    noise2,
    noiseonhover,
    rain1,
    rain2,
    rainonhover,
    storm1,
    storm2,
    stormonhover,
    maintanaince1,
    maintanaince2,
    maintanainceonhover,
    insects1,
    insects2,
    insectsonhover,
    dust1,
    dust2,
    dustonhover,
    customize1,
    customize2,
    customizeonhover,
    weather1,
    weather2,
    weatheronhover,
    elegant1,
    elegant2,
    elegantonhover,
    MobileApp,
    Wheels,
    Breaking,
    DisplayMeter,
    LeoMotor,
    Chasis,
    chasis1,
    chasis2,
    motorPower,
    LedHeadlight,
    accellaration,
    LionBattery,
    RidingModes,
    Charging1,
    Charging2,
    charging,
  } = IMAGES;

  const [ImageHover, setImageHover] = useState(MobileApp);
  const [ImageText, setImageText] = useState("NEURON MOBILE");

  const HoverItemData = [
    {
      id: 1,
      image1: energy1,
      image2: energy2,
      text1: "NEURON MOBILE",
      text2: "Application",
    },
    {
      id: 2,
      image1: noise1,
      image2: noise2,
      text1: " Wheels",
      text2: "& Suspension",
    },
    {
      id: 3,
      image1: rain1,
      image2: rain2,
      text1: " Combi ",
      text2: " Braking System ",
    },
    {
      id: 4,
      image1: storm1,
      image2: storm2,
      text1: " Advance   ",
      text2: "Display",
    },
    {
      id: 5,
      image1: maintanaince1,
      image2: maintanaince2,
      text1: " BLDC Hub ",
      text2: " Motor",
    },
    {
      id: 6,
      image1: chasis1,
      image2: chasis2,
      text1: " ULTRA ROBUST",
      text2: " Chassis [HSSC]",
    },
  ];
  const HoverItemData2 = [
    {
      id: 1,
      image1: insects1,
      image2: insects2,
      text1: "MOTOR POWER",
      text2: "6200W",
    },
    {
      id: 2,
      image1: dust1,
      image2: dust2,
      text1: "  LED",
      text2: "  Headlights",
    },
    {
      id: 3,
      image1: customize1,
      image2: customize2,
      text1: " Ultra fast",
      text2: " Acceleration   ",
    },
    {
      id: 4,
      image1: weather1,
      image2: weather2,
      text1: " ADVANCE BATTERY",
      text2: " Li-Ion",
    },
    {
      id: 5,
      image1: elegant1,
      image2: elegant2,
      text1: "4 Riding",
      text2: " Modes ",
    },
    {
      id: 6,
      image1: Charging1,
      image2: Charging2,
      text1: " Charging ",
      text2: "  In 3HR 30MIN ",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    smooth: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    focusOnSelect: true,
    easing: "ease",
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Breakpoint for mobile devices
        settings: {
          centerMode:false,
          slidesToShow: 1.2,
          slidesToScroll: 1,
          rows: 1,
          margin: 10,
        },
      },
      {
        breakpoint: 480, // Breakpoint for mobile devices
        settings: {
          centerMode:false,
          slidesToShow: 1.2,
        },
      },
    ],
  };

  const onHoverImage = (text1) => {
    setImageText(text1.trim());
    if (text1.trim() === "NEURON MOBILE") {
      setImageHover(MobileApp);
    } else if (text1.trim() === "Wheels") {
      setImageHover(Wheels);
    } else if (text1.trim() === "Combi") {
      setImageHover(Breaking);
    } else if (text1.trim() === "Advance") {
      setImageHover(DisplayMeter);
    } else if (text1.trim() === "BLDC Hub") {
      setImageHover(LeoMotor);
    } else if (text1.trim() === "ULTRA ROBUST") {
      setImageHover(Chasis);
    } else if (text1.trim() === "MOTOR POWER") {
      setImageHover(motorPower);
    } else if (text1.trim() === "LED") {
      setImageHover(LedHeadlight);
    } else if (text1.trim() === "Ultra fast") {
      setImageHover(accellaration);
    } else if (text1.trim() === "ADVANCE BATTERY") {
      setImageHover(LionBattery);
    } else if (text1.trim() === "4 Riding") {
      setImageHover(RidingModes);
    } else if (text1.trim() === "Charging") {
      setImageHover(charging);
    }
  };

  return (
    <div className="product_features">
      <div className="feature_head">
        <h4>Introduction to India's Best Electric Two-Wheelers</h4>
        <p>
          Introducing the ground-breaking OXO, LEO, and LYF electric
          two-wheelers from HOP Electric Mobility, designed specifically for the
          diverse Indian topography and the first made in India electric
          two-wheelers. Our Products are performance powerhouses that conquer
          India's various terrains. Its environmental friendliness makes it
          ideal for both frantic metropolitan streets and peaceful country
          rides.
        </p>
      </div>
      <div className="feature_body">
        <div className="section1 for_desk">
          {HoverItemData?.map((item) => (
            <div
              key={item.id}
              className={`hover_item${
                item.text1.trim() == ImageText ? " active" : ""
              }`}
              onMouseEnter={() => onHoverImage(item.text1)}
            >
              <div className="image_item">
                <img
                  src={
                    item.text1.trim() == ImageText ? item.image2 : item.image1
                  }
                   
                  className="without_hover"
                  alt="imgonhover"
                />
              </div>
              <div className="item_detail">
                <p> {item.text1} </p>
                <h6> {item.text2} </h6>
              </div>
            </div>
          ))}
        </div>
        <div className="section1 for_Mob">
          <Slider {...settings}>
            {HoverItemData?.map((item) => (
              <div
                key={item.id}
                className={`hover_item${
                  item.text1.trim() == ImageText ? " active" : ""
                }`}
                onMouseEnter={() => onHoverImage(item.text1)}
              >
                <div className="content_second_slide">
                  {" "}
                  <div className="image_item">
                    <img
                      src={
                        item.text1.trim() == ImageText
                          ? item.image2
                          : item.image1
                      }
                       
                      className="without_hover"
                      alt="imgonhover"
                    />
                  </div>
                  <div className="item_detail">
                    <p> {item.text1} </p>
                    <h6> {item.text2} </h6>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="section2">
          <div className="img_chnage_sec">
            <img  src={ImageHover} alt="" />
          </div>
        </div>
        <div className="section3 for_desk">
          {HoverItemData2?.map((item) => (
            <div
              key={item.id}
              className={`hover_item ${
                item.text1.trim() == ImageText ? "active" : ""
              }`}
              onMouseEnter={() => onHoverImage(item.text1)}
            >
              <div className="image_item">
                <img
                  src={
                    item.text1.trim() === ImageText ? item.image2 : item.image1
                  }
                   
                  className="without_hover"
                  alt="imgonhover"
                />
              </div>
              <div className="item_detail">
                <p> {item.text1} </p>
                <h6> {item.text2} </h6>
              </div>
            </div>
          ))}
        </div>

        <div className="section3 for_Mob">
          <Slider {...settings}>
            {HoverItemData2?.map((item) => (
              <div
                key={item.id}
                className={`hover_item ${
                  item.text1.trim() == ImageText ? "active" : ""
                }`}
                onMouseEnter={() => onHoverImage(item.text1)}
              >
                <div className="content_second_slide">
                  <div className="image_item">
                    <img
                      src={
                        item.text1.trim() === ImageText
                          ? item.image2
                          : item.image1
                      }
                      className="without_hover"
                      alt="imgonhover"
                       
                    />
                  </div>
                  <div className="item_detail">
                    <p> {item.text1} </p>
                    <h6> {item.text2} </h6>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default EnquiryProductFeatures;
