import React from 'react'
import OXO_Line_Drawing from "../../../assets/images/about_us/OXO_Line_Drawing.png";
import "./index.css";
const Section1 = () => {
  return (
    <>
      <div class="sec_1_content">
        <div className="container_about_us">
          <div class="text-block">
            <h3>
              <span>“</span>We are the <span>first generation</span> of the <span>Human Race</span> to feel the effects of <span>Climate Change</span> and the <span>last generation</span> to do SOMETHING about it.<span>”</span>
            </h3>
            <p class="sm-description">
              Hop electric is committed  to accelerate the advent of a sustainable future by delivering products, technologies and solutions that redefine everyday commute for millions.
            </p>
          </div>
          <div class="bike_drawing_img">
            <div class="bike_sec">
              <img  src={OXO_Line_Drawing} alt="Image not found" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Section1