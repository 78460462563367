import React from "react";
import Header from "../../components/common/layout/Header";
import Section1 from "../../components/AboutUsComponents/Section1";
import Section2 from "../../components/AboutUsComponents/Section2";
import Section3 from "../../components/AboutUsComponents/Section3";
import Section4 from "../../components/AboutUsComponents/Section4";
import Section5 from "../../components/AboutUsComponents/Section5";
import Section6 from "../../components/AboutUsComponents/Section6";
import Section7 from "../../components/AboutUsComponents/Section7";
import Section8 from "../../components/AboutUsComponents/Section8";
import Section9 from "../../components/AboutUsComponents/Section9";
import Section10 from "../../components/AboutUsComponents/Section10";
import Section11 from "../../components/AboutUsComponents/Section11";

import "./index.css";
import { useScrollTo } from "../../hooks/useScrollTo";

const AboutUs = () => {
  useScrollTo(0, 0);
  return (
    <>
      <div id="about_us">
        <Header />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
        <Section11 />
      </div>
    </>
  );
};

export default AboutUs;
