import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import moment from "moment";
import $ from "jquery";
import "../../form-sec/DealerList/dealerlist.css";
import "react-toastify/dist/ReactToastify.css";
import "../../form-sec/DealerList/dealerlist.css";
import form_search from "../../../assets/images/searchIcon.png";
import form_search2 from "../../../assets/images/refreshbutton.png";
import Download from "../../../assets/images/directdownload.png";
import Footer from "../../../components/common/layout/Footer";
import Header from "../../../components/common/layout/Header";
import { Config } from "../../../utils/apiUrl";
import { useScrollTo } from "../../../hooks/useScrollTo";

const DealerLists = () => {
  useScrollTo(0, 0);
  const [isQuerytoken, setIsQueryToken] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.toLocaleLowerCase() === "/dealerslist") {
      if (!location.search) {
        setIsQueryToken(false);
      }
    }
  }, []);

  useEffect(() => {
    if (!timeLeft) return;

    if (timeLeft === 1 && !isQuerytoken) {
      navigate("/home");
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(6);
  }, []);

  useEffect(() => {
    document.getElementById("form").addEventListener("scroll", (e) => {
      var yAxis = $(".form_container").scrollTop();
      if (yAxis >= 60) {
        $(".header").hide();
      } else {
        $(".header").show();
      }
    });
  }, []);

  const goToHome = () => {
    navigate("/home");
  };

  const [dealerdata, setDealerdata] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  const [search, setSearch] = useState("");
  const [dateerror, setDaterror] = useState(false);
  const fetchData = async () => {
    const response = await fetch(Config.fectch_dealer);
    const json = await response.json();
    setDealerdata(json.data);
    setFilterData(json.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlesearch = (event) => {
    setSearch(event.target.value);
  };

  const notify = () => {
    toast("Please Enter the Keyword   ", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 800,
    });
  };

  const notify1 = () => {
    toast("End date should be greater than Start date", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 800,
    });
  };

  const notify2 = () => {
    toast("Please select  end date", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 800,
    });
  };

  const notify3 = () => {
    toast("Please select  start date", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 800,
    });
  };

  const handlesubmit = async () => {
    var startdate = document.getElementById("date1").value;
    var enddate = document.getElementById("date2").value;
    var stDate = `${moment(new Date(startdate)).format("YYYY-MM-DD")}`;
    var enDate = `${moment(new Date(enddate)).format("YYYY-MM-DD")}`;
    if (!search && !startdate && !enddate) {
      notify();
      return false;
    } else if (startdate && !enddate) {
      notify2();
      return false;
    } else if (!startdate && enddate) {
      notify3();
      return false;
    } else if (new Date(startdate) > new Date(enddate)) {
      notify1();
      return false;
    } else {
      if (search && startdate && enddate) {
        const requestOptions1 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: `${search}`,
            startDate: stDate,
            endDate: enDate,
          }),
        };
        try {
          const response = await fetch(Config.search_dealer, requestOptions1);
          const json = await response.json();
          setFilterData(json.data);
          if (json.data.length === 0) {
            setDaterror(true);
          } else {
            setDaterror(false);
          }
        } catch (error) {
          setDaterror(true);
          console.log(error);
        }
      } else if (startdate && enddate && !search) {
        const requestOptions1 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: "",
            startDate: stDate,
            endDate: enDate,
          }),
        };
        try {
          const response = await fetch(Config.search_dealer, requestOptions1);
          const json = await response.json();
          setFilterData(json.data);
          if (json.data.length == 0) {
            setDaterror(true);
          } else {
            setDaterror(false);
          }
        } catch (error) {
          setDaterror(true);
          console.log(error);
        }
      } else {
        const requestOptions1 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: `${search}`,
            startDate: "",
            endDate: "",
          }),
        };
        try {
          const response = await fetch(Config.search_dealer, requestOptions1);
          const json = await response.json();
          setFilterData(json.data);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const downloaddata = async () => {
    if (filterdata.length > 0) {
      const requestOptions1 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: filterdata,
        }),
      };

      try {
        const response = await fetch(Config.export_dealer, requestOptions1);
        if (response) {
          const json = await response.json();
          window.open(Config.downloadDealerList);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      return false;
    }
  };

  const refreshdata = () => {
    document.getElementById("date1").value = "";
    document.getElementById("date2").value = "";
    setFilterData(dealerdata);
    setSearch("");
    setDaterror(false);
  };
  
  const ExpandedComponent = (dealerdata) => {
    return (
      <>
        <div className="dealer_alldata">
          <ul className="data_cont">
            <li>
              {" "}
              <p>General Information</p>
            </li>
            <li>
              Submission Date:{" "}
              <span>
                {" "}
                {moment(new Date(dealerdata.data.submission_date)).format(
                  "DD-MM-YYYY"
                )}
              </span>
            </li>
            <li>
              Name: <span>{dealerdata.data.name}</span>
            </li>
            <li>
              Email: <span>{dealerdata.data.email}</span>
            </li>
            <li>
              Phone No.: <span>{dealerdata.data.phone}</span>
            </li>
            <li>
              Business Role:{" "}
              <span> {dealerdata.data.current_bussniess_role}</span>
            </li>
          </ul>

          <ul className="data_cont">
            <li>
              {" "}
              <p></p>
            </li>
            <li>
              Business: <span>{dealerdata.data.current_bussniess}</span>
            </li>
            <li>
              Turn Over:{" "}
              <span>{dealerdata.data.current_bussniess_turn_over}</span>
            </li>
            <li>
              Investment <br /> Amount:{" "}
              <span>{dealerdata.data.investment_amount}</span>
            </li>
            <li>
              Operate: <span> {dealerdata.data.operate}</span>
            </li>
          </ul>

          <ul className="data_cont">
            <li>
              {" "}
              <p></p>
            </li>
            <li>
              City: <span>{dealerdata.data.city}</span>
            </li>
            <li>
              State: <span>{dealerdata.data.state}</span>
            </li>
            <li>
              Area: <span>{dealerdata.data.area}</span>
            </li>
            <li>
              Source: <span> {dealerdata.data.about}</span>
            </li>
            <li>
              Type of ownership:{" "}
              <span> {dealerdata.data.type_of_ownership}</span>
            </li>
            <li>
              Retail Experience:{" "}
              <span> {dealerdata.data.retail_experience}</span>
            </li>
          </ul>
        </div>
      </>
    );
  };

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.name.toLowerCase();
    const b = rowB.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortemail = (rowA, rowB) => {
    const a = rowA.phone.toLowerCase();
    const b = rowB.phone.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortnumber = (rowA, rowB) => {
    const a = rowA.email.toLowerCase();
    const b = rowB.email.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortdesignation = (rowA, rowB) => {
    const a = rowA.current_bussniess_role.toLowerCase();
    const b = rowB.current_bussniess_role.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortdate = (rowA, rowB) => {
    const a = rowA.submission_date.toLowerCase();
    const b = rowB.submission_date.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "Submission Date",
      selector: (dealerdata) =>
        moment(new Date(dealerdata.submission_date)).format("DD-MM-YYYY"),
      sortable: true,
      sortFunction: caseInsensitiveSortdate,
    },
    {
      name: "Name",
      selector: (dealerdata) => dealerdata.name,
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "Email",
      selector: (dealerdata) => dealerdata.email,
      sortable: true,
      sortFunction: caseInsensitiveSortemail,
    },
    {
      name: "Phone Number",
      selector: (dealerdata) => dealerdata.phone,
      sortable: true,
      sortFunction: caseInsensitiveSortnumber,
    },
    {
      name: "Business Role",
      selector: (dealerdata) => dealerdata.current_bussniess_role,
      sortable: true,
      sortFunction: caseInsensitiveSortdesignation,
    },
  ];

  return (
    <>
      <Header />
      {!isQuerytoken ? (
        <>
          <div className="height_one_csrol header_hidecss">
            <div id="form_sec" className="design_effa">
              <div className="dealer_detail form_container">
                <div className="content_error">
                  <h3>404 | Page Not Found</h3>
                  <p>
                    Redirecting to Home
                    <span>{timeLeft - 1}</span>
                  </p>
                  <p
                    style={{
                      marginTop: "20px",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={goToHome}
                  >
                    Click to Redirect Manually
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="height_one_csrol header_hidecss">
          <div id="form_sec" className="design_effa">
            <div className="dealer_detail form_container" id="form">
              <div className="top_barsearch">
                <div className="form-group search_calendar">
                  <input
                    type="date"
                    id="date1"
                    placeholder="dd-mm-yyyy"
                    className="form-control"
                  />
                </div>

                <div className="form-group search_calendar">
                  <input type="date" id="date2" className="form-control" />
                </div>

                <div className="form-group">
                  <input
                    class="form-control"
                    type="search"
                    placeholder="Search Filter"
                    value={search}
                    onChange={handlesearch}
                  />
                </div>

                <div className="form-group button_group">
                  <button
                    class="form-control second tooltip"
                    onClick={handlesubmit}
                  >
                    <img  src={form_search} alt="imhvgb" />
                    <span class="tooltiptext">Search</span>
                  </button>
                  <button className="first tooltip" onClick={downloaddata}>
                    <img  src={Download} alt="idowvgb" />
                    <span class="tooltiptext">Download</span>
                  </button>
                  <button
                    class="form-control third tooltip"
                    onClick={refreshdata}
                  >
                    <img  src={form_search2} alt="imhfvgb" />
                    <span class="tooltiptext last">Refresh</span>
                  </button>
                </div>
              </div>

              <div className="datatable_show">
                <DataTable
                  columns={columns}
                  data={filterdata}
                  noDataComponent={
                    dateerror
                      ? "As per the date range no records found"
                      : "There are no records to display"
                  }
                  pagination
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                />
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};
export default DealerLists;
