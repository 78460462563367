import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import $ from "jquery";
import DealerImage from "../../assets/images/hopimg.svg";
import Header from "../../components/common/layout/Header";
import searchIcon from "../../assets/images/search_icon.png";
import LoadingSpinner from "../../assets/images/spinner.svg";
import { Config } from "../../utils/apiUrl";
import { useScrollTo } from "../../hooks/useScrollTo";
import "./index.css";
import "./pagination.css";

const Dealers = () => {
  useScrollTo(0, 0);
  const [dealers, setDealers] = useState([]);
  const [allStoredDealers, setDealersToStore] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [totalDealers, setTotalDealers] = useState();
  const [searchLocation, setSearchLocation] = useState();
  const [loading, setLoading] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    document.getElementById("all_dealers").addEventListener("scroll", (e) => {
      var yAxis = $(".dealers_main").offset().top;
      if (yAxis < 0) {
        $(".header").hide();
      } else {
        $(".header").show();
      }
    });
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
    getDealers();
  }, []);

  const getDealers = async () => {
    setLoading(true);
    const data = {
      lat: latitude,
      long: longitude,
    };

    let response = await axios.post(Config.fetchAllDealers, data, {
      headers: {
        "Content-type": "application/json",
      },
    });
    setTotalDealers(response.data.TotalLength);
    setDealers(response.data.data);
    setDealersToStore(response.data.data);
    setLoading(false);
    setItemOffset(0);
  };

  const searchOnEnter = async (event) => {
    if (event.key === "Enter") {
      SearchDealer();
    }
  };

  const searchOnClick = () => {
    SearchDealer();
  };

  const SearchDealer = async () => {
    try {
      if (searchLocation) {
        setLoading(true);
        const data = {
          location: searchLocation,
        };

        let response = await axios.post(Config.fetchSearchDealer, data, {
          headers: {
            "Content-type": "application/json",
          },
        });
        if (response) {
          setItemOffset(0);
          setTotalDealers(response.data.TotalLength);
          setDealers(response.data.data);
        }
        setLoading(false);
      } else {
        setItemOffset(0);
        setLoading(false);
        setTotalDealers(allStoredDealers.length);
        setDealers(allStoredDealers);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const openMapInNewTab = (lat, lng) => {
    let url = "https://www.google.com/maps/search/" + lat + "," + lng;
    window.open(url, "_blank");
  };

  const callDealer = (phone_number) => {
    const phoneURI = `tel:${phone_number}`;
    window.location.href = phoneURI;
  };

  const Items = ({ currentItems }) => {
    return (
      <>
        {currentItems &&
          currentItems.map((item, index) => (
            <div key={index} class="dealer_card">
              <div
                class="image_card"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={DealerImage} alt="Image" />
              </div>
              <div class="card_body">
                <div class="heading_card">{item.name}</div>

                <div class="card_tex_body">
                  <p title={item.address}>{item.address}</p>
                </div>
                {item?.DISTANCE && (
                  <div
                    className="kms flex-end"
                    style={{
                      position: "absolute",
                      top: 7,
                      right: 7,
                      background: "",
                      display: "flex",
                      margin: "5px 0",
                      backgroundColor: "#262626",
                      padding: "3px 5px",
                      gap: "5px",
                      borderRadius: "10px",
                    }}
                  >
                    <p style={{ color: "#00eaff" }}>
                      {item?.DISTANCE.toFixed(1)} kms
                    </p>
                  </div>
                )}
              </div>
              <div
                class="direction"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "7px",
                }}
              >
                <div>
                  {item?.phone_num && (
                    <button onClick={() => callDealer(item.phone_num)}>
                      Call
                    </button>
                  )}
                </div>
                <button onClick={() => openMapInNewTab(item.lat, item.lng)}>
                  DIRECTIONS
                </button>
              </div>
            </div>
          ))}
      </>
    );
  };

  let itemsPerPage = 9;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = dealers.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dealers.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % dealers.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Header />

      <div className=" dealer_parent" id="all_dealers">
        <section class="dealers_main" style={{ paddingTop: "150px" }}>
          <div className="dealer_container">
            <div class="headings">
              <h1>Dealers Locations</h1>
              <p>
                Locate your Nearest Dealer location to Experience the Future
                Mobility.
              </p>
            </div>
            <div class="Search_Location">
              <div class="input_section" style={{ position: "relative" }}>
                <input
                  type="text"
                  value={searchLocation}
                  placeholder="Search Dealer..."
                  onChange={(e) => setSearchLocation(e.target.value)}
                  onKeyDown={searchOnEnter}
                />
                <img
                  src={searchIcon}
                  alt="imhvgb"
                  onClick={searchOnClick}
                  style={{
                    position: "absolute",
                    top: "40%",
                    height: "20px",
                    width: "20px",
                    bottom: "transform(-50%)",
                    right: "5%",
                  }}
                />
              </div>
              <div class="exper_center">
                <h3>
                  Hop Electric Experience Centers :
                  {!loading && " " + totalDealers}
                </h3>
              </div>
            </div>
            {loading ? (
              <div
                className="coming_main"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <img
                  style={{ height: "50px", height: "50px" }}
                  src={LoadingSpinner}
                />
              </div>
            ) : null}
            {!loading && (
              <div class="dealer_main">
                <Items currentItems={currentItems} />
              </div>
            )}
          </div>
          {!loading && (
            <ReactPaginate
              nextLabel=">"
              previousLabel="<"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              nextClassName="page-item"
              breakLabel="---"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              renderOnZeroPageCount={null}
            />
          )}
        </section>
      </div>
    </>
  );
};

export default Dealers;
