import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import "../Testride/test-ride.css";
import "../Order/order.css";
import "../dashboard/dashboard.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import blackimage from "../../assets/images/blackimage/1.png";
import yellowimage from "../../assets/images/yellowimage/1.png";
import redimage from "../../assets/images/redimage/1.png";
import blueimage from "../../assets/images/blueimage/1.png";
import da_orderimg from "../../assets/images/dashb_orderimg.jpg";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { bikeColor } from "../../actions";
import { Config } from "../../utils/apiUrl";
import { POST_modifyBookingColorAPI } from "../../APIs";
import { useScrollTo } from "../../hooks/useScrollTo";
import { getCookie } from "../../utils";

const Changecolorpreferences = () => {
  useScrollTo(0, 0);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [blackActive, setBlackActive] = useState("active");
  const [redActive, setRedActive] = useState("");
  const [blueActive, setBlueActive] = useState("");
  const [yellowActive, setYellowActive] = useState("");
  const [greyActive, setGreyActive] = useState("");
  const [color, setColor] = useState("True Black");
  const [image, setImage] = useState(blackimage);
  const [myBooking, setMyBooking] = useState();
  const [showOnlyBlack, setShowOnlyBlack] = useState("");

  useEffect(() => {
    getBookingData();
  }, []);

  useEffect(() => {
    if (myBooking?.model_name === "OXO") {
      setShowOnlyBlack(true);
      setColor(myBooking?.color);
      handActiveClass(myBooking?.color);
    } else {
      setShowOnlyBlack(false);
      setColor(myBooking?.color);
      handActiveClass(myBooking?.color);
    }
  }, [myBooking]);

  const handActiveClass = (colors) => {
    setColor(colors);
    if (colors == "Candy Red") {
      setRedActive("active");
      setBlackActive("");
      setGreyActive("");
      setYellowActive("");
      setBlueActive("");
      setColor("Candy Red");
      setImage(redimage);
    }
    if (colors == "Electric Yellow") {
      setRedActive("");
      setBlackActive("");
      setYellowActive("active");
      setGreyActive("");
      setBlueActive("");
      setColor("Electric Yellow");
      setImage(yellowimage);
    }
    if (colors == "Magnetic Blue") {
      setRedActive("");
      setBlackActive("");
      setYellowActive("");
      setGreyActive("");
      setBlueActive("active");
      setColor("Cyan Blue");
      setImage(blueimage);
    }
    if (colors == "Midnight Black") {
      setRedActive("");
      setBlueActive("");
      setYellowActive("");
      setGreyActive("");
      setBlackActive("active");
      setColor("Midnight Black");
      setImage(blackimage);
    }
    if (colors == "Twilight Grey") {
      setRedActive("");
      setBlackActive("");
      setBlueActive("");
      setYellowActive("");
      setGreyActive("active");
      setColor("Twilight Grey");
      setImage(blackimage);
    }
    if (colors == "True Black") {
      setRedActive("");
      setBlackActive("");
      setBlueActive("");
      setYellowActive("");
      setBlackActive("active");
      setGreyActive("");
      setColor("True Black");
      setImage(blackimage);
    }
    dispatch(bikeColor({ bikeColor: color }));
  };

  const getBookingData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const responseBook = await fetch(Config.mybookings, requestOptions);
      if (responseBook) {
        const jsonBook = await responseBook.json();
        let filtered_booking = jsonBook.bookings.filter(
          (item) => item.ticket_id == id
        );
        setMyBooking(filtered_booking[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ModifyColor = async () => {
    let response = await POST_modifyBookingColorAPI(
      myBooking.address.split(",")[1].split("-")[1].trim(),
      myBooking?.ticket_id,
      color,
      myBooking?.model_name
    );
  };

  const BackToHome = () => {
    navigate("/userDashboard");
  };

  return (
    <>
      <Header />

      <div className="height_one_csrol">
        <div id="Changecolorpreferences" className="top_header_pm">
          <div className="testride_map Confirmation graphics_sec Changecolorpreferences_page">
            <div className="map_left Confirmation_left">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure>
                  <img  src={image} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec graphics_rigth Changecolorpreferences_right">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
              </div>
              <h3>Update color preferences</h3>

              <div className="mobile_show das_orderimg">
                <img  src={da_orderimg} alt="imhvgb" />
              </div>

              <div className="graphics_colors color_step Changecolor_sec">
                {showOnlyBlack == true ? (
                  <form style={{ justifyContent: "center" }}>

                    <div className="form-check">
                      <input
                        className={`form-check-input ${blackActive}`}
                        onClick={() => handActiveClass("Midnight Black")}
                        type="radio"
                        name="color_btn"
                        id="Black"
                      />
                      <label className="form-check-label" for="Black">
                        {myBooking?.color}
                      </label>
                    </div>

                    
                  </form>
                ) : showOnlyBlack == false ? (
                  <form>
                    <div className="form-check">
                      <input
                        className={`form-check-input ${blackActive}`}
                        onClick={() => handActiveClass("True Black")}
                        type="radio"
                        name="color_btn"
                        id="Black"
                      />
                      <label className="form-check-label" for="Black">
                        True Black
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className={`form-check-input ${greyActive}`}
                        onClick={() => handActiveClass("Twilight Grey")}
                        type="radio"
                        name="color_btn"
                        id="Grey"
                      />
                      <label className="form-check-label" for="Black">
                        Twilight Grey
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className={`form-check-input ${yellowActive}`}
                        onClick={() => handActiveClass("Electric Yellow")}
                        type="radio"
                        name="color_btn"
                        id="Yellow"
                      />
                      <label className="form-check-label" for="Yellow">
                        Electric Yellow
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className={`form-check-input ${blueActive}`}
                        onClick={() => handActiveClass("Magnetic Blue")}
                        type="radio"
                        name="color_btn"
                        id="Blue"
                      />
                      <label className="form-check-label" for="Blue">
                        Magnetic Blue
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className={`form-check-input ${redActive}`}
                        onClick={() => handActiveClass("Candy Red")}
                        type="radio"
                        name="color_btn"
                        id="Red"
                      />
                      <label className="form-check-label" for="Red">
                        Candy Red
                      </label>
                    </div>

                   
                  </form>
                ) : null}
              </div>

              <div className="colorsele_btn">
                <div className="orderid_btn">
                  <button class="default_btn tran_btn" onClick={ModifyColor}>
                    Confirm selection
                  </button>
                  <button
                    class="default_btn sel_Back_home "
                    onClick={BackToHome}
                  >
                    Back To Home
                  </button>
                </div>
              </div>

              {loader == false ? "" : <BeatLoader color="#36d7b7" />}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Changecolorpreferences;
