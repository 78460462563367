import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "react-pure-modal/dist/react-pure-modal.min.css";
import PureModal from "react-pure-modal";
import { useNavigate } from "react-router-dom";
import { Config } from "../../../utils/apiUrl";
import DataTable from "react-data-table-component";
import moment from "moment";
import Header from "./Header";
import { getCookie } from "../../../utils";
import { useScrollTo } from "../../../hooks/useScrollTo";

function AllBookings(props) {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const [showcancel, opencancel] = useState(false);
  const [showcancedata1, opencancedata1] = useState(false);
  const [bookingdata, setAllbookingdata] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      navigate("/");
    }
  };

  useEffect(() => {
    getCookiedata("userdata");
  }, []);

  const fetchData = async () => {
    const requestOptionsForOtpVarification = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const response = await fetch(
        Config.mybookings,
        requestOptionsForOtpVarification
      );
      const json = await response.json();
      setAllbookingdata(json.bookings);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("usertoken");
    if (!token) {
      navigate("/");
    }
  });

  const columns = [
    {
      name: "ORDER DATE",
      selector: (rows) => moment(new Date(rows.date)).format("DD-MM-YYYY"),
    },
    {
      name: "ORDER ID",
      selector: (rows) => rows.order_id,
    },
    {
      name: "MODEL",
      selector: (rows) => rows.model_name,
    },
    {
      name: "COLOR",
      selector: (rows) => rows.color,
    },
    {
      name: "PAYMENT STATUS",
      selector: (rows) => rows.payment_status,
    },
    {
      name: "AMOUNT",
      selector: (rows) =>
        rows.payment_status === "Not Paid"
          ? "₹ 00.00"
          : rows.payment_status === "Paid"
          ? "₹ 999.00"
          : "",
    },
  ];

  const handlechange = (e) => {
    let targetdata = e.target.value;
    if (targetdata == "All") {
      let all = bookingdata.map((item) => {
        return item;
      });
      setfilterdata(all);
      setFiltering(true);
    } else if (targetdata == "Not paid") {
      let Notpaid = bookingdata.filter((item) => {
        return item.payment_status == "Not Paid";
      });
      setfilterdata(Notpaid);
      setFiltering(true);
    } else if (targetdata == "Paid") {
      let paid = bookingdata.filter((item) => {
        return item.payment_status == "Paid";
      });
      setFiltering(true);
      setfilterdata(paid);
    } else {
      setFiltering(false);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(bookingdata);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [bookingdata]);

  return (
    <>
      <Header />
      <div className="active_rides past_sec">
        <div className="filter_sec">
          <div className="dashboard_heading">
            <h2>All Booking </h2>
          </div>
          {/* <div className="data_filter">
            <select className="data_table form-select" aria-label="Default select example" onChange={handlechange}>
              <option selected value="All">All</option>
              <option value="Paid" >Paid</option>
              <option value="Not Paid">Not paid</option>
            </select>
          </div> */}
        </div>
        <div className="datatable_show">
          <DataTable
            columns={columns}
            data={bookingdata}
            pagination
            progressPending={pending}
          />
        </div>

        <div className="das_showlink">
          <ul>
            <li>
              <button
                className="default_btn"
                onClick={() => {
                  navigate("/booknow");
                }}
              >
                Book A Vehicle
              </button>
              <button
                className="default_btn"
                onClick={() => props.getalldata()}
              >
                Book Another Test Rides
              </button>
            </li>
          </ul>
        </div>

        <div
          className="form-group modal_popup test_ride_popup form-group modal_popup close_button dasboard_cancel"
          id="cancel_testride"
        >
          <PureModal
            onClose={() => {
              opencancedata1(false);
              return true;
            }}
            isOpen={showcancedata1}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change reschedule_info requested">
              <h2> Test Rides Cancel</h2>

              {/* <ul className="loc_add">
                <li>
                  <strong>LOCATION:</strong>
                  <p>Hop Electric - Hop Experience Center, Mansarovar, Jaipur <br />
                    35/96, Rajat Path, Shipra Path
                    Mansarovar 302020</p>
                </li>
                <li>
                  <strong>DATE: </strong>
                  <p>25/12/2022</p>
                </li>
                <li>
                  <strong>PREFERRED SLOT: </strong>
                  <p>9 AM - 12 PM</p>
                </li>
              </ul> */}

              <p className="location_ali">
                <span>Location </span>
                Hop Electric - Hop Experience Center, Mansarovar, Jaipur <br />
                35/96, Rajat Path, Shipra Path Mansarovar 302020
              </p>
              <p>
                <span>Date:</span>
                12-01-2023
              </p>
              <p>
                <span>PREFERRED SLOT:</span>9 AM - 12 PM
              </p>

              <div className="reschedule_infobtn">
                <button
                  onClick={() => navigate("/booktestride")}
                  className="user_btn "
                >
                  Reschedule
                </button>
                <button
                  className="user_btn border_btn"
                  onClick={() => {
                    return opencancel(false), navigate("/CancelTestrides");
                  }}
                >
                  Confirm Cancel
                </button>
              </div>
            </div>
          </PureModal>
        </div>
      </div>
    </>
  );
}
export default AllBookings;
