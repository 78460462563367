import React, { useState, useRef } from "react";
import { useEffect } from "react";
import LEO_BOLD_BLACK_ from "../../../assets/images/LEO_BOLD_BLACK_.png"
import LEO_PEARL_BLUE_ from "../../../assets/images/LEO_PEARL_BLUE_.png"
import LEO_RED_GLOSS_ from "../../../assets/images/LEO_RED_GLOSS_.png"
import LEO_SILVER_SHINE_ from "../../../assets/images/LEO_SILVER_SHINE_.png"
import LEO_WHITE_ from "../../../assets/images/LEO_WHITE_.png"


export default function SelectBikeLeo() {
    const LEO_BIKE_RATES = {
        LEO_HS: 97504,
        LEO_LS: 84360
    };
    const checkBox_prices = {
        body_Guard: 2800,
        Insurance_price: 2800,
    }
    const [selectedButton, setSelectedButton] = useState(LEO_BIKE_RATES.LEO_HS);
    const [activeClass, setactiveClass] = useState(LEO_BIKE_RATES.LEO_HS);
    const [currentImage, setCurrentImage] = useState(LEO_BOLD_BLACK_);
    const [activeColor, setActiveColor] = useState("black");
    const [totalPrice, setTotalPrice] = useState(selectedButton)
    const [isCheckedHS, setIsCheckedHS] = useState(false)
    const [isCheckedLS, setIsCheckedLS] = useState(false)
    // const [isChecked1, setIsChecked1] = useState("")

    const handleClickbutton = (price) => {
        setSelectedButton(price);
        setTotalPrice(price);
        setactiveClass(price);
        setIsCheckedLS(false);
        setIsCheckedHS(false)
        
    }
    const handleTabChange = (tab) => {
        if (tab === "black") {
            setActiveColor(tab);
            setCurrentImage(LEO_BOLD_BLACK_);
        }
        else if (tab === "blue") {
            setActiveColor(tab);
            setCurrentImage(LEO_PEARL_BLUE_);
        }
        else if (tab === "red") {
            setActiveColor(tab);
            setCurrentImage(LEO_RED_GLOSS_);
        }
        else if (tab === "grey") {
            setActiveColor(tab);
            setCurrentImage(LEO_SILVER_SHINE_);
        }
        else if (tab === "white") {
            setActiveColor(tab)
            setCurrentImage(LEO_WHITE_)
        }
    }
    const handleCheckboxClick = (parameter) => {
        if (parameter === "LS") {
            setIsCheckedHS(false);
            setIsCheckedLS(!isCheckedLS);
            // setTotalPrice(totalPrice + checkBox_prices.body_Guard)
            if (isCheckedLS) {
                setTotalPrice(totalPrice - checkBox_prices.body_Guard);
            } else {
                setTotalPrice(totalPrice + checkBox_prices.body_Guard);
            }
        } else if (parameter === "HS") {
            setIsCheckedLS(false);
            setIsCheckedHS(!isCheckedHS);
            // setTotalPrice(totalPrice + checkBox_prices.body_Guard)
            if (isCheckedHS) {
                setTotalPrice(totalPrice - checkBox_prices.body_Guard);
            } else {
                setTotalPrice(totalPrice + checkBox_prices.body_Guard);
            }
        }

    }
    return (
        <div className="select_modal_image">
            <div className="left_bike">
                <figure>
                    <img  src={currentImage}></img>
                </figure>
            </div>
            <div className="right_bike">
                <div className="select_modal">
                    <h3>SELECT MODEL</h3>
                    <div className="tab_three">
                        <button onClick={() => handleClickbutton(LEO_BIKE_RATES.LEO_HS)} className={activeClass === LEO_BIKE_RATES.LEO_HS ? "active" : ""} >LEO HS</button>
                        <button onClick={() => handleClickbutton(LEO_BIKE_RATES.LEO_LS)} className={activeClass === LEO_BIKE_RATES.LEO_LS ? "active" : ""}>LEO LS</button>
                    </div>
                    <div className="select_color">
                        <h3>SELECT COLOR</h3>
                        <div className="color_step onmobile_360 ">
                            <form>
                                <div className="form-check">
                                    <input
                                        onClick={() => handleTabChange('black')}
                                        className={activeColor === "black" ? "form-check-input acive" : "form-check-input"}
                                        type="radio"
                                        name="color_btn"
                                        id="Black"
                                    />
                                    <label
                                        onClick={() => handleTabChange('black')}
                                        className={activeColor === "black" ? "form-check-label black acive" : "form-check-label black"}
                                        for="Black"
                                    >

                                    </label>

                                </div>
                                <div className="form-check">

                                    <input
                                        onClick={() => handleTabChange('blue')}
                                        className={activeColor === "blue" ? "form-check-input acive" : "form-check-input"}
                                        type="radio"
                                        name="color_btn"
                                        id="Blue"
                                    />

                                    <label
                                        onClick={() => handleTabChange('blue')}
                                        className={activeColor === "blue" ? "form-check-label blue acive" : "form-check-label blue"}
                                        for="Blue"
                                    >
                                    </label>
                                </div>
                                <div className="form-check">

                                    <input
                                        onClick={() => handleTabChange('red')}
                                        className={activeColor === "red" ? "form-check-input acive" : "form-check-input"}
                                        type="radio"
                                        name="color_btn"
                                        id="Red"
                                    />

                                    <label
                                        onClick={() => handleTabChange('red')}
                                        className={activeColor === "red" ? "form-check-label red acive" : "form-check-label red"}
                                        for="Red"
                                    >
                                    </label>
                                </div>

                                <div className="form-check">
                                    <input
                                        onClick={() => handleTabChange('grey')}
                                        className={activeColor === "grey" ? "form-check-input acive" : "form-check-input"}
                                        type="radio"
                                        name="color_btn"
                                        id="Grey"
                                    />
                                    <label
                                        onClick={() => handleTabChange('grey')}
                                        className={activeColor === "grey" ? "form-check-label grey acive" : "form-check-label grey"}
                                        for="Grey"
                                    >
                                    </label>
                                </div>
                                <div className="form-check">

                                    <input
                                        onClick={() => handleTabChange('white')}
                                        className={activeColor === "white" ? "form-check-input acive" : "form-check-input"}
                                        type="radio"
                                        name="color_btn"
                                        id="white"
                                    />

                                    <label
                                        onClick={() => handleTabChange('white')}
                                        className={activeColor === "white" ? "form-check-label white acive" : "form-check-label white"}
                                        for="white"
                                    >
                                    </label>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="Price_sectionINR">INR {selectedButton.toLocaleString("en-IN")}</div>

                    <div className="Chek_box_book">
                        <div className="Chek_box">
                            {
                                activeClass === LEO_BIKE_RATES.LEO_HS ? (
                                    <div>
                                        <span>
                                            <input
                                                type="checkbox"
                                                checked={isCheckedHS}
                                                onChange={()=>handleCheckboxClick("HS")}
                                            />
                                            Body Guard
                                        </span>
                                        <p>+ INR {checkBox_prices.body_Guard.toLocaleString("en-IN")}</p>
                                    </div>
                                ) : (<div>
                                    <span>
                                        <input
                                            type="checkbox"
                                            checked={isCheckedLS}
                                            onChange={()=>handleCheckboxClick("LS")}
                                            />
                                        Body Guard
                                    </span>
                                    <p>+ INR {checkBox_prices.body_Guard.toLocaleString("en-IN")}</p>
                                </div>)
                            }
                        </div>
                        <div className="Book_now">
                            <div className="INR_price">
                                <h5>INR {totalPrice.toLocaleString("en-IN")}</h5>
                                <p>Total</p>
                            </div>
                            <button>BOOK NOW</button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
