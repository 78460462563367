import React from 'react'
import "./index.css";
import Join_Us from "../../../assets/images/about_us/Join_Us.png";
import QR_code from "../../../assets/images/about_us/QR_code.png";

const Section11 = () => {
  return (
    <>
      <a href="https://oxo.hopelectric.in/career">
          <div class="sec_joinus_content">
            <div className="container_about_us">
              <figure className="join_now_img">
                <img  src={Join_Us} alt="image not found" />
              </figure>
            </div>
        </div>
      </a>
    </>
  )
}

export default Section11