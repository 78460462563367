export const JAIPUR_LATITUDE = 26.81384603388286;
export const JAIPUR_LONGITUDE = 75.78529234390639;

export const OXO_MODAL_PRICES = {
  OXO_PRIME: 133798,
  OXO_PRIME_PRO: 141598,
  OXO: 139452,
  OXO_PRO: 146999,
  OXO_X: 162558,
};

export const EX_SHOWROOM_PRICE_OXO = 133999;

export const OXO_MODELS_ARRAY = [
  "OXO Prime",
  "OXO Prime Pro",
  "OXO",
  "OXO Pro",
  "OXO-X",
];

export const SCOOTER_VARIANTS = ["LEO HS", "LEO LS", "LYF LS"];

export const BIKE_VARIANTS = [
  "OXO Prime",
  "OXO Prime Pro",
  "OXO",
  "OXO Pro",
  "OXO - X",
];
export const OXO_EMI_STARTS = 2999;

export const ALL_PRICE = [
  {
    state: "Andhra Pradesh",
    variants: [
      { name: "OXO Prime", price: 136999 },
      { name: "OXO Prime Pro", price: 144999 },
      { name: "OXO", price: 141999 },
      { name: "OXO Pro", price: 151999 },
      { name: "OXO-X", price: 164499 }
    ]
  },
  {
    state: "Assam",
    variants: [
      { name: "OXO Prime", price: 137999 },
      { name: "OXO Prime Pro", price: 145499 },
      { name: "OXO", price: 142499 },
      { name: "OXO Pro", price: 152499 },
      { name: "OXO-X", price: 164999 }
    ]
  },
  {
    state: "Bihar",
    variants: [
      { name: "OXO Prime", price: 135999 },
      { name: "OXO Prime Pro", price: 143499 },
      { name: "OXO", price: 140499 },
      { name: "OXO Pro", price: 150499 },
      { name: "OXO-X", price: 162999 }
    ]
  },
  {
    state: "Chhattisgarh",
    variants: [
      { name: "OXO Prime", price: 135499 },
      { name: "OXO Prime Pro", price: 143499 },
      { name: "OXO", price: 139999 },
      { name: "OXO Pro", price: 149999 },
      { name: "OXO-X", price: 162499 }
    ]
  },
  {
    state: "Delhi",
    variants: [
      { name: "OXO Prime", price: 133499 },
      { name: "OXO Prime Pro", price: 141499 },
      { name: "OXO", price: 137999 },
      { name: "OXO Pro", price: 148499 },
      { name: "OXO-X", price: 160999 }
    ]
  },
  {
    state: "Gujarat",
    variants: [
      { name: "OXO Prime", price: 134999 },
      { name: "OXO Prime Pro", price: 142499 },
      { name: "OXO", price: 139499 },
      { name: "OXO Pro", price: 149499 },
      { name: "OXO-X", price: 161999 }
    ]
  },
  {
    state: "Haryana",
    variants: [
      { name: "OXO Prime", price: 133499 },
      { name: "OXO Prime Pro", price: 141499 },
      { name: "OXO", price: 137999 },
      { name: "OXO Pro", price: 148499 },
      { name: "OXO-X", price: 160999 }
    ]
  },
  {
    state: "Himachal Pradesh",
    variants: [
      { name: "OXO Prime", price: 133999 },
      { name: "OXO Prime Pro", price: 141999 },
      { name: "OXO", price: 138499 },
      { name: "OXO Pro", price: 148999 },
      { name: "OXO-X", price: 160999 }
    ]
  },
  {
    state: "Jammu & Kashmir",
    variants: [
      { name: "OXO Prime", price: 134999 },
      { name: "OXO Prime Pro", price: 142499 },
      { name: "OXO", price: 139499 },
      { name: "OXO Pro", price: 149499 },
      { name: "OXO-X", price: 161999 }
    ]
  },
  {
    state: "Jharkhand",
    variants: [
      { name: "OXO Prime", price: 135999 },
      { name: "OXO Prime Pro", price: 143999 },
      { name: "OXO", price: 140499 },
      { name: "OXO Pro", price: 150999 },
      { name: "OXO-X", price: 162999 }
    ]
  },
  {
    state: "Karnataka",
    variants: [
      { name: "OXO Prime", price: 136999 },
      { name: "OXO Prime Pro", price: 144999 },
      { name: "OXO", price: 141999 },
      { name: "OXO Pro", price: 151999 },
      { name: "OXO-X", price: 164499 }
    ]
  },
  {
    state: "Madhya Pradesh",
    variants: [
      { name: "OXO Prime", price: 134999 },
      { name: "OXO Prime Pro", price: 142499 },
      { name: "OXO", price: 139499 },
      { name: "OXO Pro", price: 149499 },
      { name: "OXO-X", price: 161999 }
    ]
  },
  {
    state: "Maharashtra",
    variants: [
      { name: "OXO Prime", price: 135999 },
      { name: "OXO Prime Pro", price: 143999 },
      { name: "OXO", price: 140999 },
      { name: "OXO Pro", price: 150999 },
      { name: "OXO-X", price: 163499 }
    ]
  },
  {
    state: "Odisha",
    variants: [
      { name: "OXO Prime", price: 136499 },
      { name: "OXO Prime Pro", price: 143999 },
      { name: "OXO", price: 140999 },
      { name: "OXO Pro", price: 150999 },
      { name: "OXO-X", price: 163499 }
    ]
  },
  {
    state: "Punjab",
    variants: [
      { name: "OXO Prime", price: 133999 },
      { name: "OXO Prime Pro", price: 141999 },
      { name: "OXO", price: 138499 },
      { name: "OXO Pro", price: 148999 },
      { name: "OXO-X", price: 161399 }
    ]
  },
  {
    state: "Rajasthan",
    variants: [
      { name: "OXO Prime", price: 133999 },
      { name: "OXO Prime Pro", price: 141999 },
      { name: "OXO", price: 138499 },
      { name: "OXO Pro", price: 148999 },
      { name: "OXO-X", price: 160999 }
    ]
  },
  {
    state: "Tamil Nadu",
    variants: [
      { name: "OXO Prime", price: 138499 },
      { name: "OXO Prime Pro", price: 145999 },
      { name: "OXO", price: 142999 },
      { name: "OXO Pro", price: 152999 },
      { name: "OXO-X", price: 165499 }
    ]
  },
  {
    state: "Telangana",
    variants: [
      { name: "OXO Prime", price: 135999 },
      { name: "OXO Prime Pro", price: 143999 },
      { name: "OXO", price: 140999 },
      { name: "OXO Pro", price: 150999 },
      { name: "OXO-X", price: 162999 }
    ]
  },
  {
    state: "Uttar Pradesh",
    variants: [
      { name: "OXO Prime", price: 134999 },
      { name: "OXO Prime Pro", price: 142499 },
      { name: "OXO", price: 139499 },
      { name: "OXO Pro", price: 149499 },
      { name: "OXO-X", price: 161999 }
    ]
  },
  {
    state: "Uttarakhand",
    variants: [
      { name: "OXO Prime", price: 133999 },
      { name: "OXO Prime Pro", price: 141999 },
      { name: "OXO", price: 138999 },
      { name: "OXO Pro", price: 148999 },
      { name: "OXO-X", price: 160999 }
    ]
  },
  {
    state: "West Bengal",
    variants: [
      { name: "OXO Prime", price: 135999 },
      { name: "OXO Prime Pro", price: 143999 },
      { name: "OXO", price: 140999 },
      { name: "OXO Pro", price: 150999 },
      { name: "OXO-X", price: 163499 }
    ]
  }
];

