import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import axios from "axios";
import PureModal from "react-pure-modal";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import "./dealerlist.css";
import form_search from "../../assets/images/searchIcon.png";
import form_search2 from "../../assets/images/refreshbutton.png";
import Download from "../../assets/images/directdownload.png";
import { Config, NODE_BASE_URL } from "../../utils/apiUrl";
import { useScrollTo } from "../../hooks/useScrollTo";

const Enquiries = () => {

  useScrollTo(0, 0);
  const [isQuerytoken, setIsQueryToken] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [filterdata, setFilterData] = useState([]);
  const [search, setSearch] = useState("");
  const [dateerror, setDaterror] = useState(false);
  const [enquiries, setEnquiries] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sourceModalData, setSourceModalData] = useState();
  const [sourceModalShow, setSourceModalShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.toLocaleLowerCase() == "/list-enquiries") {
      if (location.search !== "?user=gO6aQrQvS4d2YmcTnA/3xpr/7fGuQnMd") {
        setIsQueryToken(true);
      } else {
      }
    }
  }, []);

  useEffect(() => {
    setTimeLeft(6);
  }, []);

  useEffect(() => {
    getAllEnquiries();
  }, []);

  useEffect(() => {
    if (!timeLeft) return;

    if (timeLeft === 1 && isQuerytoken) {
      goToHome();
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    document.getElementById("form").addEventListener("scroll", (e) => {
      var yAxis = $(".form_container").scrollTop();
      if (yAxis >= 60) {
        $(".header").hide();
      } else {
        $(".header").show();
      }
    });
  }, []);

  const getAllEnquiries = async () => {
    try {
      let response = await axios.get(Config.listEnquiries);
      if (response) {
        setEnquiries(response.data.data);
        setFilterData(response.data.data);
      }
    } catch (error) {}
  };

  const goToHome = () => {
    navigate("/home");
  };

  const handleSearch = (event) => {
    handleFilter(event.target.value);
    setSearch(event.target.value);
  };

  const handleFilter = (filterText) => {
    const filteredItems = enquiries?.filter(
      (item) =>
        JSON.stringify(item)
          ?.toLowerCase()
          .indexOf(filterText?.toLowerCase()) !== -1
    );
    setFilterData(filteredItems);
  };

  const handleStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleCompleteSearch = () => {
    if (!search && !startDate && !endDate) {
      notify();
    }
    if (startDate) {
      if (!endDate) {
        notify2();
      }
    }

    if (endDate) {
      if (!startDate) {
        notify3();
      }
    }

    if (startDate && endDate && !search) {
      if (startDate > endDate) {
        notify1();
      } else {
        const filtered = enquiries.filter((item) => {
          const itemDate = new Date(item.date).toISOString().split("T")[0];
          return itemDate >= startDate && itemDate <= endDate;
        });
        setFilterData(filtered);
      }
    }

    if (startDate && endDate && search) {
      if (startDate > endDate) {
        notify1();
      } else {
        const filtered = enquiries.filter((item) => {
          const itemDate = new Date(item.date).toISOString().split("T")[0];
          const itemJSON = JSON.stringify(item).toLowerCase();
          return (
            (search === "" || itemJSON.includes(search.toLowerCase())) &&
            (startDate === "" || itemDate >= startDate) &&
            (endDate === "" || itemDate <= endDate)
          );
        });
        setFilterData(filtered);
      }
    }

    if (!startDate && !endDate && search) {
      handleFilter(search);
    }
  };

  const notify = () => {
    toast("Please Enter the Keyword   ", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 800,
    });
  };

  const notify1 = () => {
    toast("End date should be greater than Start date", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 800,
    });
  };

  const notify2 = () => {
    toast("Please select  end date", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 800,
    });
  };

  const notify3 = () => {
    toast("Please select  start date", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 800,
    });
  };

  const refreshdata = () => {
    document.getElementById("date1").value = "";
    document.getElementById("date2").value = "";
    setStartDate();
    setEndDate();
    setFilterData(enquiries);
    setSearch("");
    setDaterror(false);
    getAllEnquiries();
  };

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.name.toLowerCase();
    const b = rowB.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortnumber = (rowA, rowB) => {
    const a = rowA.phone_num.toLowerCase();
    const b = rowB.phone_num.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveProductType = (rowA, rowB) => {
    const a = rowA.product_type.toLowerCase();
    const b = rowB.product_type.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveProductVariant = (rowA, rowB) => {
    const a = rowA.product_variant.toLowerCase();
    const b = rowB.product_variant.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortdate = (rowA, rowB) => {
    const a = rowA.date.toLowerCase();
    const b = rowB.date.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "Date",
      selector: (enquiry) => {
        return moment(enquiry.date).utc().format("DD-MM-YYYY");
      },
      sortable: true,
      sortFunction: caseInsensitiveSortdate,
    },
    {
      name: "Name",
      selector: (enquiry) => enquiry.name,
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "Phone Number",
      selector: (enquiry) => enquiry.phone_num,
      sortable: true,
      sortFunction: caseInsensitiveSortnumber,
    },

    {
      name: "Product Type",
      selector: (enquiry) => enquiry.product_type,
      sortable: true,
      sortFunction: caseInsensitiveProductType,
    },
    {
      name: "Product Variant",
      selector: (enquiry) => enquiry.product_variant,
      sortable: true,
      sortFunction: caseInsensitiveProductVariant,
    },
    {
      name: "Source",
      selector: (enquiry) => JSON.parse(enquiry?.compaign_ad)?.utm_source,
      cell: (row) => {
        return (
          <button
            style={{
              textTransform: "capitalize",
              borderBottom: JSON.parse(row.compaign_ad)?.utm_source
                ? "1px solid #00eaff"
                : "",
              cursor: JSON.parse(row.compaign_ad)?.utm_source
                ? "pointer"
                : "default",
            }}
            onClick={() =>
              JSON.parse(row.compaign_ad)?.utm_source
                ? handleSourceClick(row)
                : null
            }
          >
            {JSON.parse(row.compaign_ad)?.utm_source
              ? JSON.parse(row.compaign_ad)?.utm_source
              : "OXO Website"}
          </button>
        );
      },
    },
  ];

  const handleSourceClick = (row) => {
    setSourceModalData(row?.compaign_ad && JSON.parse(row?.compaign_ad));
    setSourceModalShow(true);
  };

  const ExportEnquiryDataToExcel = async () => {
    if (filterdata.length > 0) {
      const requestOptions1 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: filterdata,
        }),
      };
      const url = NODE_BASE_URL + "uploads/enquiries.xlsx";

      try {
        const response = await fetch(Config.exportEnquiries, requestOptions1);
        if (response) {
          const json = await response.json();
          window.open(url);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <Header />
      <div
        style={{ zIndex: 999999999, position: "fixed" }}
        className="form-group modal_popup test_ride_popup form-group modal_popup close_button dasboard_cancel singleBook source_detail"
        id="cancel_testride"
      >
        <PureModal
          onClose={() => {
            setSourceModalShow(false);
            return true;
          }}
          isOpen={sourceModalShow}
          closeButton={<div className="close_btn"></div>}
          closeButtonPosition="top"
          width="100%"
        >
          <div className="popup_cont color_change reschedule_info requested">
            <h2>Source Details </h2>
            <ul>
              {sourceModalData?.utm_source && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Source: </strong>
                  {sourceModalData?.utm_source}
                </li>
              )}

              {sourceModalData?.utm_campaign_name && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Compaign Name: </strong>
                  {sourceModalData?.utm_campaign_name}
                </li>
              )}
              {sourceModalData?.utm_campaign && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Compaign Name: </strong>
                  {sourceModalData?.utm_campaign}
                </li>
              )}

              {sourceModalData?.utm_ad_name && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Ad Name: </strong>
                  {sourceModalData?.utm_ad_name}
                </li>
              )}

              {sourceModalData?.utm_ad_id && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Ad Id: </strong>
                  {sourceModalData?.utm_ad_id}
                </li>
              )}

              {sourceModalData?.utm_campaign_id && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Compaign Id: </strong>
                  {sourceModalData?.utm_campaign_id}
                </li>
              )}

              {sourceModalData?.utm_adset_name && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Ad Set Name: </strong>
                  {sourceModalData?.utm_adset_name}
                </li>
              )}

              {sourceModalData?.utm_adgroupid && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Ad Group Id: </strong>
                  {sourceModalData?.utm_adgroupid}
                </li>
              )}
              {sourceModalData?.utm_device && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Device: </strong>
                  {sourceModalData?.utm_device}
                </li>
              )}

              {sourceModalData?.utm_keyword && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Keyword: </strong>
                  {sourceModalData?.utm_keyword}
                </li>
              )}

              {sourceModalData?.utm_matchtype && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM MatchType: </strong>
                  {sourceModalData?.utm_matchtype}
                </li>
              )}
              {sourceModalData?.utm_medium && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Medium: </strong>
                  {sourceModalData?.utm_medium}
                </li>
              )}

              {sourceModalData?.utm_network && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Network: </strong>
                  {sourceModalData?.utm_network}
                </li>
              )}

              {sourceModalData?.utm_placement && (
                <li style={{ textTransform: "capitalize" }}>
                  <strong>UTM Placement: </strong>
                  {sourceModalData?.utm_placement}
                </li>
              )}
            </ul>
          </div>
        </PureModal>
      </div>

      {isQuerytoken ? (
        <>
          <div className="height_one_csrol header_hidecss">
            <div id="form_sec" className="design_effa">
              <div className="dealer_detail form_container">
                <div className="content_error">
                  <h3>404 | Page Not Found</h3>
                  <p>
                    Redirecting to Home
                    <span>{timeLeft - 1}</span>
                  </p>
                  <p
                    style={{
                      marginTop: "20px",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={goToHome}
                  >
                    Click to Redirect Manually
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="height_one_csrol header_hidecss">
          <div id="form_sec" className="design_effa">
            <div className="dealer_detail form_container" id="form">
              <div className="top_barsearch">
                <div className="form-group search_calendar">
                  <input
                    value={startDate}
                    type="date"
                    id="date1"
                    placeholder="dd-mm-yyyy"
                    className="form-control"
                    onChange={handleStartDate}
                  />
                </div>

                <div className="form-group search_calendar">
                  <input
                    value={endDate}
                    type="date"
                    id="date2"
                    className="form-control"
                    onChange={handleEndDate}
                  />
                </div>

                <div className="form-group">
                  <input
                    class="form-control"
                    type="search"
                    placeholder="Search Filter"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>

                <div className="form-group button_group">
                  <button
                    class="form-control second tooltip"
                    onClick={handleCompleteSearch}
                  >
                    <img  src={form_search} alt="imhvgb" />
                    <span class="tooltiptext">Search</span>
                  </button>
                  <button
                    className="first tooltip"
                    onClick={ExportEnquiryDataToExcel}
                  >
                    <img  src={Download} alt="idowvgb" />
                    <span class="tooltiptext">Download</span>
                  </button>
                  <button
                    class="form-control third tooltip"
                    onClick={refreshdata}
                  >
                    <img  src={form_search2} alt="imhfvgb" />
                    <span class="tooltiptext last">Refresh</span>
                  </button>
                </div>
              </div>

              <div className="datatable_show">
                <DataTable
                  columns={columns}
                  data={filterdata}
                  noDataComponent={
                    dateerror
                      ? "As per the date range no records found"
                      : "There are no records to display"
                  }
                  pagination
                />
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};
export default Enquiries;
