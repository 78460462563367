import React from "react";
import Slider from "react-slick";
import "./style.css";
import { IMAGES } from "../../../constants/IMAGES";

const EnquiryProductsSlider = () => {
  const { OXO_400, LYF_400, LEO_400 } = IMAGES;

  const DataOfSlide = [
    {
      id: 1,
      image: OXO_400,
      first_head: "OXO",
    },
    {
      id: 2,
      image: LYF_400,
      first_head: "LYF",
    },
    {
      id: 3,
      image: LEO_400,
      first_head: "LEO",
    },
    {
      id: 4,
      image: OXO_400,
      first_head: "OXO",
    },
    {
      id: 5,
      image: LYF_400,
      first_head: "LYF",
    },
    {
      id: 6,
      image: LEO_400,
      first_head: "LEO",
    },
  ];

  // const totalSlides = 3;
  const settings = {
    dots: false,
    infinite: true,
    smooth: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    focusOnSelect: true,
    easing: "ease",
    responsive: [
      {
        breakpoint: 768, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          rows: 1,
          // margin: 10,
    centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="product_slider enquiry_product_page">
      <h1>Our Products</h1>

      <Slider {...settings}>
        {DataOfSlide?.map((item) => (
          <div key={item.id} className="slide_enquiry">
            <img  src={item.image} alt="image_slide" />
            <div className="content_slide">
              <h2>{item.first_head}</h2>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default EnquiryProductsSlider;
