import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import logo_img from "../../../assets/images/HopLogo.svg";

function Header() {
  const [isHidden, setIsHidden] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      setIsHidden(true);
    } else {
      setIsHidden(true);
    }
  }, [location]);

  const removeclass = () => {
    let form = document.querySelector(
      ".HamburgerMenu_hamburger__aXVme.HamburgerMenu_hamburgeractive__9iPUg"
    );
    return form ? form.click() : "";
  };

  return (
    <>
      <header className="header">
        <div className="uk-container uk-flex uk-flex-between">
          <div className="logo">
            <Link to="/">
              <img src={logo_img} alt="Logo image" />
            </Link>
          </div>
          <div className="right_sec">
            <div className="menu_bar">
              <HamburgerMenu />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
