import React from 'react'
import "./index.css";
import news_img from "../../../assets/images/about_us/news_img.png";
import logos_img from "../../../assets/images/about_us/logos_img.png";

const Section10 = () => {
  return (
    <div class="sec_news_article">
        <div className="container_about_us">
          <h2>IN MEDIA & News</h2>
          <p className="recoganized selection_background">Recognised by Industry</p>
        
          <figure className="news">
            <img  src={news_img} alt="image not found" />
          </figure>
          
          <div className="partners_logo">
          <p className="featured-text selection_background">Featured In</p>
            <figure>
              <img  src={logos_img} alt="image not found" />
            </figure>
          </div>
        </div>
      </div>
  )
}

export default Section10