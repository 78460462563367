import { useState, useEffect } from "react";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import "../Testride/test-ride.css";
import "../UserDashboard/user-dashboard.css";
import hoplogo from "../../assets/images/HopLogo.svg";
import user_photo from "../../assets/images/user_dash.jpg";
import AlltestRides from "../../components/common/layout/AlltestRides";
import AllBookings from "../../components/common/layout/AllBookings";
import BookAnother from "../../components/common/layout/BookAnother";
import BookaVehicles from "../../components/common/layout/BookaVehicles";
import WelcomeUser from "../../components/common/layout/WelcomeUser";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { getCookie } from "../../utils";
import { useScrollTo } from "../../hooks/useScrollTo";

const UserDashboard = () => {

  useScrollTo(0, 0);
  const navigate = useNavigate();
  const [open2, setOpen2] = useState(false);
  const [activeTab, setActiveTab] = useState("WelcomeUser");
  const [AlltestRidess] = useState("AlltestRides");
  const [AllBookingss] = useState("AllBookings");
  const [BookaVehicless] = useState("BookaVehicles");
  const [BookAnothers] = useState("BookAnother");
  const [WelcomeUsers] = useState("WelcomeUser");

  const renderTab = () => {
    switch (activeTab) {
      case AlltestRidess:
        return <AlltestRides />;

      case AllBookingss:
        return <AllBookings />;

      case BookaVehicless:
        return <BookaVehicles />;

      case BookAnothers:
        return <BookAnother />;

      case WelcomeUsers:
        return <WelcomeUser />;

      default:
        return null;
    }
  };

  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      navigate("/");
    }
  };

  useEffect(() => {
    getCookiedata("userdata");
  }, []);

  const isActive = (tab) => {
    if (activeTab === tab) {
      return "active";
    }
    return "";
  };

  const mobile3 = (e) => {
    e.stopPropagation();
    setActiveTab(AlltestRidess);
    setOpen2(false);
  };

  const mobile9 = (e) => {
    e.stopPropagation();
    setActiveTab(WelcomeUsers);
    setOpen2(false);
  };
  const deletecookie = (item, e) => {
    e.preventDefault();
    document.cookie =
      item + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    navigate("/");
  };

  useEffect(() => {
    $(document).ready(function () {
      $(".header").hide();
      $("#footer").show();
    });

    $(window).on("load", function () {
      $(".header").hide();
      $("#footer").show();
    });
  }, []);

  return (
    <>
      <Header />
      <div className="height_one_csrol">
        <div className="user_dashboardmain" id="user_dashboar">
          <div className="dashboardmain">
            <div className="dashboard_header">
              <div className="dash_logo">
                <Link to="/">
                  {" "}
                  <img  src={hoplogo} alt="imhvgb" />{" "}
                </Link>
              </div>
              <div className="haeder_rigth">
                <div className="user_login">
                  <figure>
                    {" "}
                    <img  src={user_photo} alt="imhvgb" />{" "}
                  </figure>
                  <span>
                    HOWDY{" "}
                    {getCookie("userdata")
                      ? JSON.parse(getCookie("userdata")).user_name
                      : ""}
                  </span>
                </div>
                <div
                  className={
                    open2
                      ? "menu_responsive mobile_menu_respon"
                      : "mobile_menu_respon"
                  }
                  onClick={() => (open2 ? setOpen2(false) : setOpen2(true))}
                >
                  <div className="menu_mobile">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className="menu_list">
                    <ul>
                      <li onClick={mobile9}>
                        <Link to="" className={`${isActive(WelcomeUsers)}`}>
                          Dashboard
                        </Link>
                      </li>
                      <li onClick={mobile3}>
                        <Link to="" className={`${isActive(AlltestRidess)}`}>
                          My Activities
                        </Link>
                      </li>
                      <li onClick={mobile3}>
                        <Link
                          to=" "
                          onClick={(e) => deletecookie("userdata", e)}
                        >
                          Logout
                        </Link>
                      </li>

                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard_sec">
              <div className="dashboard_tosec dashboard_menu_left">
                <div className="menu_list">
                  <ul>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveTab(WelcomeUsers);
                      }}
                    >
                      <Link to="" className={`${isActive(WelcomeUsers)}`}>
                        Dashboard
                      </Link>
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveTab(AlltestRidess);
                      }}
                    >
                      <Link to="" className={`${isActive(AlltestRidess)}`}>
                        My Activities
                      </Link>
                      <Link to=" " onClick={(e) => deletecookie("userdata", e)}>
                        Logout
                      </Link>
                    </li>

                   
                  </ul>
                </div>
              </div>
              <div className="dashboard_tosec dashboard_right">
                {renderTab()}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default UserDashboard;
