import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import "../Testride/test-ride.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import blackimage from "../../assets/images/blackimage/1.png";
import yellowimage from "../../assets/images/yellowimage/1.png";
import redimage from "../../assets/images/redimage/1.png";
import blueimage from "../../assets/images/blueimage/1.png";

import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { Config } from "../../utils/apiUrl";
import { getCookie } from "../../utils";

const OrderConfirmation = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [bookingToConfirm, setBookingToConfirm] = useState();
  const [paymentButtonClicked, setPaymentButtonClicked] = useState(false);

  const y = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const response = await fetch(Config.mybookings, requestOptions);
      if (response) {
        const parsedResponse = await response.json();
        let filteredResponse = parsedResponse.bookings.filter(
          (item) => item.ticket_id == id
        );
        setBookingToConfirm(filteredResponse[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    y();
  }, []);

  useEffect(() => {
    getCookiedata("userdata");
  }, []);

  useEffect(() => {
    headerHide();
  }, []);

  useEffect(() => {
    localStorage.setItem("estimatedate", JSON.stringify(estimate_date));
  });

  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      navigate("/");
    }
  };

  const headerHide = () => {
    document
      .getElementById("orderConfrmPage")
      .addEventListener("scroll", (e) => {
        var yAxis = $(".testride_righsec > .order_sec_id").offset().top;
        if (yAxis < 250) {
          $(".header").hide();
        } else {
          $(".header").show();
        }
      });
  };

  // useEffect(() => {
  //   const rzpPaymentForm = document.getElementById("rzp_payment_form");

  //   if (!rzpPaymentForm.hasChildNodes()) {
  //     const script = document.createElement("script");
  //     script.src = "https://checkout.razorpay.com/v1/payment-button.js";
  //     script.async = true;
  //     script.dataset.payment_button_id = "pl_LatgEd6cmU5N1L";
  //     rzpPaymentForm.appendChild(script);
  //   }
  // });

  const date = new Date();
  const estimateMonth = date.getMonth() + 1;
  const estimateYear = date.getFullYear();
  const newDate = new Date(estimateYear, estimateMonth + 3);
  const dateName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const estimate_date =
    dateName[estimateMonth] +
    " - " +
    dateName[newDate.getMonth()] +
    " " +
    newDate.getFullYear();

  const goToUserDashboard = () => {
    navigate("/userDashboard");
  };

  return (
    <>
      <Header />
      <div className="height_one_csrol" id="orderConfrmPage">
        {/* <form id="rzp_payment_form"></form> */}
        <div id="OrderConfirmation" className="top_header_pm">
          <div className="testride_map Confirmation graphics_sec Order_Confirmation">
            <div className="map_left Confirmation_left">
              <figure>
                <img src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure>
                  <img
                    src={
                      bookingToConfirm?.color == "Twilight Grey"
                        ? blackimage
                        : bookingToConfirm?.color == "Electric Yellow"
                        ? yellowimage
                        : bookingToConfirm?.color == "Magnetic Blue"
                        ? blueimage
                        : bookingToConfirm?.color == "Candy Red"
                        ? redimage
                        : blackimage
                    }
                    alt="imhvgb"
                  />
                </figure>
              </div>
            </div>

            <div className="testride_righsec order_id">
              <div className="logo_right_cont destop_show">
                <img src={logo_right} alt="imhvgb" />
                <p>Est. Delivery Date: {estimate_date}</p>
              </div>

              <div className="only_mobileshow mobile_show">
                <img src={popuplogomo} alt="imhvgb" />
                <h3 className="mobile_show">Confirmation</h3>
              </div>

              <h3 className="destop_show">Confirmation</h3>
              <div className="order_sec_id">
                <p className="destop_show">
                  We have received your booking request. kindly make the payment
                  to confirm your order.
                </p>
                <p className="mobile_show">
                  We have received your booking request. kindly make the payment
                  to confirm your order.
                </p>
                <div class="order_sec_id">
                  <strong>
                    <span>Order ID :</span> {bookingToConfirm?.order_id}
                  </strong>
                </div>
              </div>
              <div className="user_order_detail">
                <ul>
                  <li>
                    <strong>Name :</strong> {bookingToConfirm?.name}
                  </li>
                  {bookingToConfirm?.email ? (
                    <li>
                      <strong>Email Id :</strong> {bookingToConfirm?.email}
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <strong>Phone No. :</strong> {bookingToConfirm?.phone_num}
                  </li>
                  <li>
                    <strong>Pin Code :</strong> {bookingToConfirm?.pin_code}
                  </li>
                  <li>
                    <strong>Model :</strong> {bookingToConfirm?.model_name}
                  </li>
                  <li>
                    <strong>Model Color:</strong> {bookingToConfirm?.color}
                  </li>
                </ul>
              </div>
              {paymentButtonClicked ? (
                <div className="next_btn">
                  <button onClick={goToUserDashboard} className="default_btn">
                    GO TO HOME
                  </button>
                </div>
              ) : (
                <div className="next_btn">
                  <button  className="default_btn">
                    Continue Payment
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default OrderConfirmation;
