import React from 'react'
import "./index.css";
import hop_store from "../../../assets/images/about_us/hop_store.png";


const Section6 = () => {
  return (
    <>
      <div class="sec_content_image_6">
        <div class="overlay"></div>
        <div className="container_about_us">
          <h2>Current Network</h2>
          <div className="net_counters">
            <div className="count_box">
              <div className="count_items">
                  <p className="count_num">15 + </p>
                  <p className="count_lable">States</p>
              </div>
              <div className="count_items">
                  <p className="count_num">150 + </p>
                  <p className="count_lable">Exclusive Hop Experience centres </p>
              </div>
              <div className="count_items">
                  <p className="count_num">500+</p>
                  <p className="count_lable">New Location to be operation in 23-24</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Section6