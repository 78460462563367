import React, { useEffect, useState } from "react";
import "./scooter.css";
import { toast } from "react-toastify";
import scooterBanner from "../../assets/images/scooter-banner.png";
import { Config } from "../../utils/apiUrl";
import { useScrollTo } from "../../hooks/useScrollTo";

const NewLeadGen = () => {
  useScrollTo(0, 0);
  const [fullNameWanto, setFullNameWanto] = useState("");
  const [mobNumberWanto, setMobNumberWanto] = useState("");
  const [otpWanto, setOtpWanto] = useState("");
  const [zipCodeWanto, setZipCodeWanto] = useState("");

  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");

  const [display, setDisplay] = useState(true);
  const [otpErrorWanto, setOtpErrorWanto] = useState(true);
  const [zipError, setZipError] = useState(true);

  const [activeOtpBtn, setActiveOtpBtn] = useState("none");
  const [resendOtp, setResendOtp] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [verifyOtp, setVerifyOtp] = useState(true);
  const [packageName, setPackageName] = useState("");

  const notify = () => {
    toast("Please verify your phone number.");
  };

  const notify1 = () => {
    toast("Thank you. Our team will contact you soon!");
  };

  const notify2 = () => {
    toast("Please Enter Valid Mobile Number.");
  };

  const notify3 = () => {
    toast("Please Enter Mobile Number.");
  };

  const notify4 = () => {
    toast("Please Enter Valid OTP.");
  };

  useEffect(() => {
    getDataFromUrl();
  }, []);

  const getDataFromUrl = () => {
    const urlParamsSource = new URLSearchParams(window.location.search).get(
      "utm_source"
    );
    setUtmSource(urlParamsSource);
    const urlParamsMedium = new URLSearchParams(window.location.search).get(
      "utm_medium"
    );
    setUtmMedium(urlParamsMedium);
    const urlParamsCampaign = new URLSearchParams(window.location.search).get(
      "utm_campaign"
    );
    setUtmCampaign(urlParamsCampaign);
  };

  const handleNameChangeWanto = (event) => {
    setFullNameWanto(event.target.value);
  };

  const handleMobNumberChangeWanto = (event) => {
    var nLength = event.target.value.length;
    if (event.target.value.length < 11) {
      setMobNumberWanto(event.target.value);
    }
    if (nLength < 10) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  };

  const handleOtpChangeWanto = (event) => {
    var nLength = event.target.value.length;
    if (event.target.value.length < 7) {
      setOtpWanto(event.target.value);
    }
    if (nLength < 6) {
      setOtpErrorWanto(false);
    } else {
      setOtpErrorWanto(true);
    }
  };

  const onKeyPressNumberWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const onKeyPressOtpWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const handleZipCodeChangeWanto = (event) => {
    var zLength = event.target.value.length;
    if (zLength < 7) {
      setZipCodeWanto(event.target.value);
    }
    if (zLength < 6) {
      setZipError(false);
    } else {
      setZipError(true);
    }
  };

  const onKeyPressZipWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const requestOtp = async () => {
    if (!mobNumberWanto) {
      notify3();
      return;
    }
    if (mobNumberWanto) {
      if (mobNumberWanto.length < 10) {
        notify2();
        return;
      }
    }
    const apiUrl = Config.otp_varification;
    const sendOtpOptn = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ phone_num: `${mobNumberWanto}`, otp: "" }),
    };
    try {
      const response = await fetch(apiUrl, sendOtpOptn);
      const json = await response.json();
      if (json.message == "sms sent sucessfully") {
        setActiveOtpBtn("block");
        setResendOtp(true);
      } else {
        setActiveOtpBtn("none");
        setResendOtp(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const requestOtpComplete = async () => {
    const apiUrl = Config.otp_varification;
    const sendOtpOptn = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        phone_num: `${mobNumberWanto}`,
        otp: `${otpWanto}`,
      }),
    };
    try {
      const response = await fetch(apiUrl, sendOtpOptn);
      const json = await response.json();
      if (json.authenticated_user == "True") {
        setToken(json.token);
        setUserId(json.user_id);
        setVerifyOtp(false);
      } else {
        notify4();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  async function changePackage(e) {
    setPackageName(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mobNumberWanto.length < 10) {
      return false;
    }
    if (zipCodeWanto.length < 6) {
      return false;
    }
    const data = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        phone_num: `${mobNumberWanto}`,
        email: "",
        name: `${fullNameWanto}`,
        pin_code: `${zipCodeWanto}`,
        dealer_id: "",
        address: ``,
        alt_num: ``,
        model: `${packageName}`,
        source: `OXO website (Source: ${utmSource}, Medium: ${utmMedium}, Campaign: ${utmCampaign})`,
        user_id: `${userId}`,
        landmark: "null",
        color: "",
        flag: "admin",
      }),
    };
    const apiUrl = Config.pre_booking_ticket;
    try {
      const response = await fetch(apiUrl, data);
      const json = await response.json();
      if (json.error == "Unauthorized") {
        notify();
      } else if (json != undefined) {
        setMobNumberWanto("");
        setOtpWanto("");
        setActiveOtpBtn("none");
        setVerifyOtp(true);
        setFullNameWanto("");
        setZipCodeWanto("");
        setPackageName("");
        notify1();
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <div id="new_lead_gen">
      <div className="main-content-sec">
        <div className="content">
          <div className="left-sec">
            <img  src={scooterBanner} alt="left banner image" />
          </div>
          <div className="right-sec">
            <div className="heading">
              <h2>Thank you for your interest in Hop Electric.</h2>
              <p>
                We request you to fill-up the form below. Our representative
                will get in touch with you shortly.
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <fieldset className="uk-fieldset foem_cus">
                <div className="form_input">
                  <legend className="uk-legend">Phone </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input"
                      type="text"
                      required
                      value={mobNumberWanto}
                      onChange={handleMobNumberChangeWanto}
                      name="number"
                      onKeyPress={onKeyPressNumberWanto}
                    />
                    {!display && (
                      <span className="error">Invalid Phone Number</span>
                    )}
                  </div>
                  <div
                    className="requestOtp"
                    title={
                      mobNumberWanto.length == 10
                        ? ""
                        : "Please Enter Mobile Number First."
                    }
                    style={{
                      opacity: mobNumberWanto.length < 10 ? 0.5 : 1,
                      cursor:
                        mobNumberWanto.length == 10 ? "pointer" : "not-allowed",
                    }}
                    onClick={requestOtp}
                  >
                    {resendOtp === false ? "Request OTP" : "Resend OTP"}
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">Enter OTP </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input"
                      type="text"
                      value={otpWanto}
                      required
                      name="otp"
                      onChange={handleOtpChangeWanto}
                      onKeyPress={onKeyPressOtpWanto}
                    />
                    {!otpErrorWanto && (
                      <span className="error">Enter valid OTP</span>
                    )}
                    {!verifyOtp && (
                      <span className="success">OTP verified successfully</span>
                    )}
                  </div>
                  <div
                    className="requestOtp otp"
                    onClick={otpWanto.length == 6 ? requestOtpComplete : null}
                    title={
                      otpWanto.length == 6 ? "" : "Please Enter OTP first."
                    }
                    style={{
                      display:
                        mobNumberWanto.length == 10 ? activeOtpBtn : "none",
                      cursor: otpWanto.length == 6 ? "pointer" : "not-allowed",
                      opacity: otpWanto.length == 6 ? 1 : 0.5,
                    }}
                  >
                    Verify OTP
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">Name </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input uk-form-controls"
                      required
                      type="text"
                      value={fullNameWanto}
                      onChange={handleNameChangeWanto}
                    />
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">Pincode </legend>
                  <div className="uk-margin">
                    <input
                      className="uk-input"
                      type="text"
                      required
                      value={zipCodeWanto}
                      onChange={handleZipCodeChangeWanto}
                      onKeyPress={onKeyPressZipWanto}
                    />
                    {!zipError && (
                      <span className="error">Invalid Pin Code</span>
                    )}
                  </div>
                </div>
                <div className="form_input">
                  <legend className="uk-legend">Variant </legend>
                  <select
                    className="select-price-btn2"
                    onChange={changePackage}
                    value={packageName}
                  >
                    <option value="">Select a variant</option>
                    <option value="LEO">LEO</option>
                    <option value="LYF">LYF</option>
                    <option value="LEO - E">LEO - E</option>
                    <option value="LYF - E">LYF - E</option>
                  </select>
                </div>
                {/* <div className="form_input">
                                    <legend className="uk-legend">When are you planning to purchase? </legend>
                                    <ul>
                                        <li className={planClass} onClick={planClassFun}>Within 7 days</li>
                                        <li className={planClass1} onClick={planClassFun1}>Within a month</li>
                                        <li className={planClass2} onClick={planClassFun2}>After a month</li>
                                        <li className={planClass3} onClick={planClassFun3}>Not decided</li>
                                    </ul>
                                </div>
                                <div className="form_input">
                                    <legend className="uk-legend">I authorise Hop Electric Company to contact me via SMS, email, WhatsApp and other modes of communication. </legend>
                                    <ul>
                                        <li className={authClass} onClick={authClassFun}>Yes</li>
                                        <li className={authClass1} onClick={authClassFun1}>No</li>
                                    </ul>
                                </div> */}
                <div className="btn_sec">
                  <button
                    className="uk-button"
                    id="scooter_lead"
                    type="submit"
                    disabled={
                      !fullNameWanto ||
                      !mobNumberWanto ||
                      !otpWanto ||
                      !zipCodeWanto ||
                      !packageName
                    }
                  >
                    Submit
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};
export default NewLeadGen;
