// ProtectedRoute.js
import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCookie } from "../utils";

const ProtectedRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const deletecookie = (item) => {
    document.cookie =
      item + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };

  let cookies = getCookie("userdata");
  if (cookies) {
    let parsed_user = JSON.parse(cookies);
    if (parsed_user) {
      const value = parsed_user.user_token;
      if (value) {
        return <Outlet />;
      } else {
        toast.info("Please Click on the Menu to Login", { autoClose: 10000 });
        navigate("/");
      }
    } else {
      toast.info("Please Click on the Menu to Login", { autoClose: 10000 });
      deletecookie("userdata");
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  } else {
    toast.info("Please Click on the Menu to Login", { autoClose: 10000 });
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return;
};

export default ProtectedRoute;
