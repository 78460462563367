import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../Testride/test-ride.css";
import "../Order/order.css";
import "../dashboard/dashboard.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import Dashor_bike from "../../assets/images/Dashorder_bike.png";
import da_orderimg from "../../assets/images/dashb_orderimg.jpg";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { cancelBookingAPI } from "../../APIs";
import { Config } from "../../utils/apiUrl";
import { getCookie } from "../../utils";
import { useScrollTo } from "../../hooks/useScrollTo";

const Cancelbooking = () => {

  useScrollTo(0, 0);
  let { id } = useParams();
  const navigate = useNavigate();
  const [myBooking, setMyBooking] = useState();
  const [alreadyCancelled, setAlreadyCancelled] = useState(false);

  useEffect(() => {
    getBookingData();
  }, []);

  const getBookingData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const responseBook = await fetch(Config.mybookings, requestOptions);
      if (responseBook) {
        const jsonBook = await responseBook.json();
        let filtered_booking = jsonBook.bookings.filter(
          (item) => item.ticket_id == id
        );
        if (filtered_booking[0].status == 3) {
          setAlreadyCancelled(true);
        }
        setMyBooking(filtered_booking[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelbooking = async () => {
    const cancelRes = await cancelBookingAPI(id);
    if (cancelRes) {
      if (cancelRes.data.error) {
        toast.error(cancelRes.data.error, { autoClose: 1500 });
      } else if (cancelRes.data[0].data) {
        toast.success(cancelRes.data[0].data, { autoClose: 1500 });
        navigate("/userDashboard");
      }
    }
  };

  const BackToHome = () => {
    navigate("/userDashboard");
  };

  return (
    <>
      <Header />

      <div className="height_one_csrol">
        <div id="Cancelbooking" className="top_header_pm">
          <div className="testride_map Confirmation graphics_sec dashboard_orde Registration_add Cancelbooking_page">
            <div className="map_left Confirmation_left dashboard_leftsec">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure className="gra_logo">
                  <img  src={popuplogomo} alt="imhvgb" />
                </figure>

                <figure>
                  <img  src={Dashor_bike} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec dashboard_rightsec Registration_add_right Cancelbooking_right">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
              </div>

              <h3>Cancel Booking</h3>

              <div className="order_iddetails regiadd_form">
                <div className="mobile_show das_orderimg">
                  <img  src={da_orderimg} alt="imhvgb" />
                </div>

                <h4>
                  <span>Order ID:</span>
                  {myBooking?.order_id}
                </h4>
                {alreadyCancelled ? (
                  <p>Booking Already Cancelled </p>
                ) : (
                  <p>Press Continue To Cancel Order</p>
                )}

                <div className="orderid_btn">
                  <button
                    class={
                      alreadyCancelled ? "disabled_btn" : "default_btn tran_btn"
                    }
                    style={{
                      cursor: alreadyCancelled ? "not-allowed" : "pointer",
                    }}
                    onClick={cancelbooking}
                    disabled={alreadyCancelled}
                  >
                    Continue
                  </button>
                  <button class="default_btn test_again" onClick={BackToHome}>
                    Back To Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Cancelbooking;
