import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { POST_ApplyAPI } from "../../APIs";
import { useScrollTo } from "../../hooks/useScrollTo";

const Career = () => {
  useScrollTo(0, 0);
  const inputFileRef = useRef();
  const navigate = useNavigate();
  const [appliedValue, setAppliedValue] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [ctcValue, setCtcValue] = useState("");
  const [expValue, setExpValue] = useState("");
  const [resumeFile, setResumeFile] = useState();
  const [appliedErr, setAppliedErr] = useState("");
  const [NameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [mobile_NumberErr, setMobileNumberErr] = useState("");
  const [ctcErr, setCtcErr] = useState("");
  const [expErr, setExpErr] = useState("");
  const [resumeFileErr, setResumeFileErr] = useState("");

  useEffect(() => {
    document.getElementById("CareerID").addEventListener("scroll", (e) => {
      let yAxisForHeader = document.querySelector("#UsingForHeader").scrollTop;
      var yAxisCareer = document.getElementById("CareerID").scrollTop;
      if (yAxisForHeader > 10 || yAxisCareer > 10) {
        window.document.querySelector("header").style.display = "none";
      } else {
        window.document.querySelector("header").style.display = "block";
      }
    });
    return () =>
      document.getElementById("CareerID")?.addEventListener("scroll");
  }, []);

  useEffect(() => {
    document
      .getElementById("UsingForHeader")
      .addEventListener("scroll", (e) => {
        let yAxisForHeader =
          document.querySelector("#UsingForHeader").scrollTop;
        let yAxisCareer = document.getElementById("CareerID").scrollTop;
        if (yAxisForHeader > 10 || yAxisCareer > 10) {
          window.document.querySelector("header").style.display = "none";
        } else {
          window.document.querySelector("header").style.display = "block";
        }
      });
    return () =>
      document.getElementById("UsingForHeader")?.addEventListener("scroll");
  }, []);

  const handleSubmit1 = (e) => {
    if (e.ctrlKey || e.altKey) {
      e.preventDefault();
    } else {
      var key = e.keyCode;

      if (
        !(
          key == 8 ||
          key == 9 ||
          key == 32 ||
          key == 46 ||
          (key >= 35 && key <= 40) ||
          (key >= 65 && key <= 90)
        )
      ) {
        e.preventDefault();
      }
    }
  };

  const onKeyPressNumberWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const handleMobileOrder = (event) => {
    var nLength = event.target.value.length;
    if (11 > nLength) {
      setMobileNumber(event.target.value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const NameRegex = /^[a-z ,.'-]+$/i;
    const EmailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    let err = 0;
    if (appliedValue == "") {
      setAppliedErr("Please Select Applied For.");
      err++;
    } else {
      setAppliedErr("");
    }

    if (name == "") {
      setNameErr("Please Enter Name.");
      err++;
    } else if (!NameRegex.test(name)) {
      setNameErr("Please Enter Valid Name.");
      err++;
    } else {
      setNameErr("");
    }

    if (email == "") {
      setEmailErr("Please Enter Email.");
      err++;
    } else if (!EmailRegex.test(email)) {
      setEmailErr("Please Enter Valid Email.");
      err++;
    } else {
      setEmailErr("");
    }

    if (mobileNumber == "") {
      setMobileNumberErr("Please Enter Mobile Number.");
      err++;
    } else if (mobileNumber.length !== 10) {
      setMobileNumberErr("Please Enter Valid Mobile Number.");
      err++;
    } else {
      setMobileNumberErr("");
    }

    if (ctcValue == "") {
      setCtcErr("Please Select Current CTC.");
      err++;
    } else {
      setCtcErr("");
    }

    if (expValue == "") {
      setExpErr("Please Select Total Experience.");
      err++;
    } else {
      setExpErr("");
    }
    if (!resumeFile) {
      setResumeFileErr("Please Choose Resume File.");
      err++;
    } else {
      const MAX_FILE_SIZE = 5120; // 5MB
      const fileSizeKiloBytes = resumeFile.size / 1024;
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        setResumeFileErr("Resume file size should not be greater than 5MB  .");
        err++;
      } else {
        if (
          resumeFile.type === "application/pdf" ||
          resumeFile.type === "application/msword" ||
          resumeFile.type == "image/jpeg" ||
          resumeFile.type == "image/png" ||
          resumeFile.type == "image/jpg" ||
          resumeFile.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          setResumeFileErr("");
        } else {
          setResumeFileErr(
            "Only .jpeg, .png, .doc, .docx, .pdf files are allowed."
          );
          err++;
        }
      }
    }

    if (err == 0) {
      const formData = new FormData();

      const obj = {
        applied_for: appliedValue,
        name: name,
        email: email,
        phone_num: mobileNumber,
        current_ctc: ctcValue,
        experience: expValue,
        file: resumeFile,
      };
      for (var key in obj) {
        formData.append(key, obj[key]);
      }
      try {
        let res = await POST_ApplyAPI(formData);
        if (res) {
          if (res.data.data == "success") {
            setAppliedValue("");
            setName("");
            setEmail("");
            setMobileNumber("");
            setCtcValue("");
            setExpValue("");
            setResumeFile();
            inputFileRef.current.value = null;
            toast.success(
              "Thank You, Your Job Application Has Been Received.",
              {
                autoClose: 2000,
              }
            );
            navigate("/");
          } else {
            toast.error("Something went wrong...", {
              autoClose: 2000,
            });
          }
        }
      } catch (error) {
        console.log("catch", error.message);
        toast.error("Backend Issue :" + error.message, { autoClose: 1500 });
      }
    }
  };

  return (
    <>
      <Header />
      <div className="height_one_csrol career_one" id="UsingForHeader">
        <div className="min_color">
          <div className="form_main_sec ">
            <div className="form_sec_new_inner">
              <div className="form_sec_new" id="CareerID">
                <div className="scroll_header">
                  <div className="form_head">
                    <h2>Submit Your Resume</h2>
                  </div>
                  <form>
                    <div className="form-group">
                      <label>Applied For*</label>
                      <select
                        value={appliedValue}
                        className="form-control"
                        onChange={(e) => setAppliedValue(e.target.value)}
                      >
                        <option value="" disabled>
                          --- Select ---
                        </option>
                        <option value="Internship and Training ">
                          Internship and Training
                        </option>
                        <option value="Product Development, R&D">
                          Product Development, R&D
                        </option>
                        <option value="Production and Quality ">
                          Production and Quality{" "}
                        </option>
                        <option value="Electronics and Mechanical ">
                          Electronics and Mechanical{" "}
                        </option>
                        <option value="Supply chain">Supply chain</option>
                        <option value="Sales and Marketing">
                          Sales and Marketing
                        </option>
                        <option value="Others">Others</option>
                      </select>
                      <small style={{ color: "red" }}>{appliedErr}</small>
                    </div>

                    <div className="form-group">
                      <label>Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={(e) => handleSubmit1(e)}
                      />
                      <small style={{ color: "red" }}>{NameErr}</small>
                    </div>

                    <div className="form-group">
                      <label>Email Address*</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <small style={{ color: "red" }}>{emailErr}</small>
                    </div>

                    <div className="form-group">
                      <label>Mobile Number*</label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Mobile Number"
                        value={mobileNumber}
                        onChange={(e) => handleMobileOrder(e)}
                        onKeyPress={onKeyPressNumberWanto}
                      />
                      <small style={{ color: "red" }}>{mobile_NumberErr}</small>
                    </div>

                    <div className="form-group">
                      <label>Current CTC*</label>
                      <select
                        value={ctcValue}
                        className="form-control"
                        onChange={(e) => setCtcValue(e.target.value)}
                      >
                        <option value="">--- Select ---</option>
                        <option value="1 lakh to 3 lakh">
                          1 lakh to 3 lakh
                        </option>
                        <option value="3 lakh to 8 lakh">
                          3 lakh to 8 lakh
                        </option>
                        <option value="8 lakh to 12 lakh">
                          8 lakh to 12 lakh
                        </option>
                        <option value="12 lakh to 16 lakh">
                          12 lakh to 16 lakh
                        </option>
                      </select>
                      <small style={{ color: "red" }}>{ctcErr}</small>
                    </div>

                    <div className="form-group">
                      <label>Total Experience*</label>
                      <select
                        value={expValue}
                        className="form-control"
                        onChange={(e) => setExpValue(e.target.value)}
                      >
                        <option value="">--- Select ---</option>
                        <option value="Less than 1 Year">
                          Less than 1 year
                        </option>
                        <option value="1 - 2 years">1 - 2 years</option>
                        <option value="2+ years">2+ years</option>
                      </select>
                      <small style={{ color: "red" }}>{expErr}</small>
                    </div>

                    <div className="form-group file_upload">
                      <label className="cv_upload">
                        Upload Your CV (Preferable Formats - PDF or Word Doc)*
                      </label>
                      <input
                        ref={inputFileRef}
                        className="form-control"
                        type="file"
                        id="myfile"
                        accept=".pdf,.docx,.doc,.jpg,.jpeg,.png"
                        name="myfile"
                        onChange={(e) => setResumeFile(e.target.files[0])}
                      />
                      <small style={{ color: "red" }}>{resumeFileErr}</small>
                    </div>
                    <div className="send_btn forms_click_btn">
                      <button
                        className="default_btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Career;
