
import {
    GoogleMap,
    MarkerF,
  } from '@react-google-maps/api'
  import { useState } from 'react'
  import { useNavigate } from "react-router-dom";
import { JAIPUR_LATITUDE, JAIPUR_LONGITUDE } from '../../constants';
  
  const TestRideGoogleMapMob = (props) => {
    const { lant, long, id } = props;
    const styles = require('../LandingPage/Googlemap/GoogleMapStyles.json')
    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const navigate = useNavigate();

    return (
      <div className="map_left testride_map">
        <GoogleMap
          center={{ lat: parseFloat(lant !==null ? lant : JAIPUR_LATITUDE), lng: parseFloat(long !==null ? long : JAIPUR_LONGITUDE) }}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: styles
          }}
          onLoad={map => setMap(map)}
        ><MarkerF position={{ lat: parseFloat(lant !==null ? lant : JAIPUR_LATITUDE), lng: parseFloat(long !==null ? long : JAIPUR_LONGITUDE) }} />
        </GoogleMap>
      </div>
    )
  }
  
  export default TestRideGoogleMapMob;