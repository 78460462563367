import React from "react";
import Slider from "react-slick";

import "./style.css";
import { IMAGES } from "../../../constants/IMAGES";

const EnquirySlider = () => {
  const { EnquiryBanner, home_banner_2, home_banner_3 } = IMAGES;

  const DataOfSlide = [
    {
      id: 1,
      image: EnquiryBanner,
      first_head: "Introducing",
      second_head: "Internal Doors",
    },
    // {
    //   id: 1,
    //   image: home_banner_2,
    //   first_head: "Trusted By Millions",
    //   second_head: "India's No. 1 Windows and Doors Brand",
    // },
    // {
    //   id: 1,
    //   image: home_banner_3,
    //   first_head: "Introducing",
    //   second_head: "Aluminium Windows & Doors",
    // },
  ];

  const totalSlides = 1;
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    smooth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 1.1,
          slidesToScroll: 1,
          rows: 1,
          margin: 10,
        },
      },
    ],
  };

  return (
    <div className="product_slide">
      <Slider {...settings}>
        {DataOfSlide?.map((item) => (
          <div key={item.id} className="slide_enquiry">
            <img  src={item.image} className="banner_image" alt="image_slide" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default EnquirySlider;
