
const initialState = {
    optVarification: false,
    userId: null,
    phoneNum: null,
    userName: null,
    userEmail: null,
    pincode: null,
    TestRideToken: null,
    OrderToken: null,
    BikeColor: null,
    userAddress: {
        house: null,
        userpincode: null,
        altPhone: null,
    },
    orderModal:false
};

const UserDetails = (state = initialState, action) => {
    switch (action.type) {
        case "ADD":
            const payload = action.payload;
            state.optVarification = payload.messageStatus;
            state.userId = payload.userId;
            state.phoneNum = payload.phoneNum;
            return state;
        case "ADDSEARCH":
            const payloadSearch = action.payload;
            state.pincode = payloadSearch.pincode;
            return state;

        case "UPDATEBASIC":
            const payloads = action.payload; 
            state.userName = payloads.userName;
            state.userEmail = payloads.userEmail; 
            return state;
        case "TestRideToken":
            const payloadToken = action.payload;
            state.TestRideToken = payloadToken.testRideToken;
            return state;

        case "OrderToken":
            const payloadOrderToken = action.payload;
            state.OrderToken = payloadOrderToken.orderToken;
            return state;

        case "BikeColor":
            const payloadBikeColor = action.payload;
            state.BikeColor = payloadBikeColor.bikeColor;
            return state;

        case "UserName":
            const payloadUserName = action.payload;
            state.userName = payloadUserName.userName;
            return state;

        case "UserEmail":
            const payloadUserEmail = action.payload;
            state.userEmail = payloadUserEmail.userEmail;
            return state;

        case "PhoneNum":
            const payloadPhoneNum = action.payload;
            state.phoneNum = payloadPhoneNum.phoneNum;
            return state;

        case "PinCode":
            const payloadPinCode = action.payload;
            state.pincode = payloadPinCode.pinCode;
            return state;

        case "OrderModal":
            const payloadOrderModal = action.payload;
            state.orderModal = payloadOrderModal.orderModal;
            return state;

        case "UPDATEADDRESS":
            const payloadss = action.payload;
            state.userAddress.house = payloadss.house;
            state.userAddress.userpincode = payloadss.userpincode;
            state.userAddress.altPhone = payloadss.altPhone
            return state;

        case "TOGGLE":
            const orderModals = action.payload;
            if(orderModals.toggle === true){
                state.orderModal=true
            }
            if(orderModals.toggle === false){
                state.orderModal=false
            }
            // if()
            // state.orderModal=!orderModals
            return state;

        default: return state;
    }
}

export default UserDetails;