import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import InputMask from "react-text-mask";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "../Testride/test-ride.css";
import "../Order/order.css";
import "../dashboard/dashboard.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import blackimage from "../../assets/images/blackimage/1.png";
import Dashor_bike from "../../assets/images/Dashorder_bike.png";
import yellowimage from "../../assets/images/yellowimage/1.png";
import redimage from "../../assets/images/redimage/1.png";
import blueimage from "../../assets/images/blueimage/1.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { bikeColor } from "../../actions";
import { Config } from "../../utils/apiUrl";
import { getCookie } from "../../utils";
import { POST_modifyBookingColorAPI } from "../../APIs";
import $ from "jquery";
import { useScrollTo } from "../../hooks/useScrollTo";

const Registrationaddress = () => {
  useScrollTo(0, 0);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [blackActive, setBlackActive] = useState("active");
  const [redActive, setRedActive] = useState("");
  const [blueActive, setBlueActive] = useState("");
  const [yellowActive, setYellowActive] = useState("");
  const [greyActive, setGreyActive] = useState("");
  const [color, setColor] = useState("True Black");
  const [image, setImage] = useState(blackimage);
  const [myBooking, setMyBooking] = useState();
  const [showOnlyBlack, setShowOnlyBlack] = useState("");
  const [showSection, setShowSection] = useState("address");
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [DOB, setDOB] = useState();
  const [pincode, setPinCode] = useState();
  const [house_flat_no, setHouseFlatNumber] = useState();
  const [areaStreet, setAreaStreet] = useState();
  const [landmark, setLandmark] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();

  //error states
  const [nameErr, setNameErr] = useState();
  const [emailErr, setEmailErr] = useState();
  const [DOBErr, setDOBErr] = useState();
  const [pincodeErr, setPinCodeErr] = useState();
  const [house_flat_noErr, setHouseFlatNumberErr] = useState();
  const [AreaStreetErr, setAreaStreetErr] = useState();
  const [landmarkErr, setLandmarkErr] = useState();

  const handActiveClass = (colors) => {
    setColor(colors);

    if (colors === "Candy Red") {
      setRedActive("active");
      setBlackActive("");
      setGreyActive("");
      setYellowActive("");
      setBlueActive("");
      setColor("Candy Red");
      setImage(redimage);
    }
    if (colors === "Electric Yellow") {
      setRedActive("");
      setBlackActive("");
      setYellowActive("active");
      setGreyActive("");
      setBlueActive("");
      setColor("Electric Yellow");
      setImage(yellowimage);
    }
    if (colors === "Magnetic Blue") {
      setRedActive("");
      setBlackActive("");
      setYellowActive("");
      setGreyActive("");
      setBlueActive("active");
      setColor("Cyan Blue");
      setImage(blueimage);
    }
    if (colors === "Midnight Black") {
      setRedActive("");
      setBlueActive("");
      setYellowActive("");
      setGreyActive("");
      setBlackActive("active");
      setColor("Midnight Black");
      setImage(blackimage);
    }
    if (colors === "Twilight Grey") {
      setRedActive("");
      setBlackActive("");
      setBlueActive("");
      setYellowActive("");
      setGreyActive("active");
      setColor("Twilight Grey");
      setImage(blackimage);
    }
    if (colors === "True Black") {
      setRedActive("");
      setBlackActive("");
      setBlueActive("");
      setYellowActive("");
      setBlackActive("active");
      setGreyActive("");
      setColor("True Black");
      setImage(blackimage);
    }
    dispatch(bikeColor({ bikeColor: color }));
  };

  const getBookingData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const responseBook = await fetch(Config.mybookings, requestOptions);
      if (responseBook) {
        const jsonBook = await responseBook.json();
        let filtered_booking = jsonBook.bookings.filter(
          (item) => item.ticket_id === id
        );
        setMyBooking(filtered_booking[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBookingData();
  }, []);

  useEffect(() => {
    if (myBooking?.model_name === "OXO") {
      setColor(myBooking?.color);
      handActiveClass(myBooking?.color);
    } else {
      setShowOnlyBlack(false);
      setColor(myBooking?.color);
      handActiveClass(myBooking?.color);
    }
    setEmail(myBooking?.email);
    setName(myBooking?.name);
    setDOB(myBooking?.dob);
    setPinCode(myBooking?.pin_code);
    setHouseFlatNumber(myBooking?.flatno);
    setAreaStreet(myBooking?.area_street);
    setLandmark(myBooking?.landmark);
    setCity(myBooking?.address.split(",")[0].split("-")[1].trim());
    setState(myBooking?.address.split(",")[1].split("-")[1].trim());
  }, [myBooking]);

  const ModifyColor = async () => {
    let response = await POST_modifyBookingColorAPI(
      state,
      city,
      email,
      name,
      DOB,
      pincode,
      house_flat_no,
      areaStreet,
      landmark,
      myBooking?.ticket_id,
      color,
      myBooking?.model_name
    );
    if (response) {
      toast.success(response.data.message, { autoClose: 1500 });
      navigate("/userDashboard");
    }
  };

  const BackToHome = () => {
    navigate("/userDashboard");
  };

  function validateDate(date) {
    let dateformat = /^(0?[1-9]|[1-2][0-9]|3[01])[\/](0?[1-9]|1[0-2])/;

    if (date.match(dateformat)) {
      let operator = date.split("/");
      let datepart = [];
      if (operator.length > 1) {
        datepart = date.split("/");
      }
      let day = parseInt(datepart[0]);
      let month = parseInt(datepart[1]);
      let year = parseInt(datepart[2]);
      if (month > 12 || month < 1) {
        return [false, "Invalid DOB!"];
      }
      let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return [false, "Invalid DOB!"];
        }
      } else if (month === 2) {
        let leapYear = false;
        if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
        if (leapYear === false && day >= 29) {
          return [false, "Invalid Date!"];
        } else if (leapYear === true && day > 29) {
          return [false, "Invalid DOB!"];
        }
      }
    } else {
      return [false, "Invalid DOB"];
    }
    return [true, "Valid DOB"];
  }

  const onConfirmAddress = () => {
    let err = 0;
    if (!name) {
      setNameErr("Please Enter Name.");
      err++;
    } else {
      setNameErr("");
    }

    if (!email) {
      setEmailErr("Please Enter Email.");
      err++;
    } else {
      setEmailErr("");
    }

    if (!DOB) {
      setDOBErr("Please Enter DOB.");
      err++;
    } else {
      if (!validateDate(DOB)[0]) {
        setDOBErr("Please Enter Valid Date");
        err++;
      } else {
        setDOBErr("");
      }
    }

    if (!pincode) {
      setPinCodeErr("Please Enter Pincode.");
      err++;
    } else {
      setPinCodeErr("");
    }

    if (!house_flat_no) {
      setHouseFlatNumberErr("Please Enter House/Flat No.");
      err++;
    } else {
      setHouseFlatNumberErr("");
    }

    if (!areaStreet) {
      setAreaStreetErr("Please Enter Area/Street");
      err++;
    } else {
      setAreaStreetErr("");
    }

    if (!landmark) {
      setLandmarkErr("Please Enter Landmark");
      err++;
    } else {
      setLandmarkErr("");
    }
    if (err === 0) {
      setShowSection("color");
    }
  };

  useEffect(() => {
    document.getElementById("height_one_home_confirmation").addEventListener("scroll", (e) => {
      var yAxis = $(".graphics_sec").offset().top;
      if (yAxis < 0) {
        $(".header").hide();
      } else {
        $(".header").show();
      }
    });
  }, []);

  return (
    <>
      <Header />
      <div className="height_one_csrol" id="height_one_home_confirmation">
        {showSection === "address" ? (
          <div id="Registrationaddress" className="top_header_pm">
            <div className="testride_map Confirmation graphics_sec dashboard_orde Registration_add">
              <div className="map_left Confirmation_left dashboard_leftsec">
                <figure>
                  <img  src={confirm_bike} alt="imhvgb" />
                </figure>

                <div className="after_savings">
                  <figure className="gra_logo">
                    <img  src={popuplogomo} alt="imhvgb" />
                  </figure>

                  <figure>
                    <img  src={Dashor_bike} alt="imhvgb" />
                  </figure>
                </div>
              </div>

              <div className="testride_righsec dashboard_rightsec Registration_add_right">
                <div className="logo_right_cont destop_show">
                  <img  src={logo_right} alt="imhvgb" />
                </div>

                <h3>Confirm registration address</h3>

                <div className="registration_detai">
                  <h4>Delivery & registration details</h4>
                  <p>
                    Please provide details of the person in whose name the
                    scooter is to be registered. Name must match Aadhaar. If
                    Delivery Address is different from Aadhaar, you’ll have to
                    provide an additional address proof in same person’s name,
                    after payment.Info
                  </p>
                </div>

                <div className="order_iddetails regiadd_form">
                  <form>
                    <div className="form_group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Full Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div style={{ textAlign: "left", marginTop: "-15px" }}>
                      <small style={{ color: "red" }}>
                        {nameErr && nameErr}
                      </small>
                    </div>
                    <div className="form_group">
                      <input
                        type="Email"
                        class="form-control"
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                    <div style={{ textAlign: "left", marginTop: "-15px" }}>
                      <small style={{ color: "red" }}>
                        {emailErr && emailErr}
                      </small>
                    </div>

                    <div className="form_group" style={{ padding: "10px 0" }}>
                      <InputMask
                        id="date_input_mask"
                        placeholder="Date of birth (DD/MM/YYYY)"
                        style={{
                          width: "100%",
                          color: "rgba(255, 255, 255, 0.5)",
                          background: "transparent",
                        }}
                        mask={[
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        value={DOB}
                        defaultValue={DOB}
                        onChange={(e) => setDOB(e.target.value)}
                      />
                    </div>
                    <div style={{ textAlign: "left", marginTop: "-15px" }}>
                      <small style={{ color: "red" }}>{DOBErr && DOBErr}</small>
                    </div>
                    <span>Delivery Address (As per address proof)</span>
                    <div className="form_group">
                      <input
                        type="Pincode"
                        class="form-control"
                        placeholder="Pincode"
                        onChange={(e) => setPinCode(e.target.value)}
                        value={pincode}
                      />
                    </div>
                    <div style={{ textAlign: "left", marginTop: "-15px" }}>
                      <small style={{ color: "red" }}>
                        {pincodeErr && pincodeErr}
                      </small>
                    </div>
                    <div className="form_group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="House/Flatno."
                        onChange={(e) => setHouseFlatNumber(e.target.value)}
                        value={house_flat_no}
                      />
                    </div>
                    <div style={{ textAlign: "left", marginTop: "-15px" }}>
                      <small style={{ color: "red" }}>
                        {house_flat_noErr && house_flat_noErr}
                      </small>
                    </div>

                    <div className="form_group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Area or Street"
                        value={areaStreet}
                        onChange={(e) => setAreaStreet(e.target.value)}
                      />
                    </div>
                    <div style={{ textAlign: "left", marginTop: "-15px" }}>
                      <small style={{ color: "red" }}>
                        {AreaStreetErr && AreaStreetErr}
                      </small>
                    </div>

                    <div className="form_group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Landmark"
                        onChange={(e) => setLandmark(e.target.value)}
                        value={landmark}
                      />
                    </div>
                    <div style={{ textAlign: "left", marginTop: "-15px" }}>
                      <small style={{ color: "red" }}>
                        {landmarkErr && landmarkErr}
                      </small>
                    </div>
                  </form>

                  <div className="orderid_btn">
                    <button
                      class="default_btn tran_btn"
                      onClick={onConfirmAddress}
                    >
                      Confirm
                    </button>
                    <button class="default_btn test_again" onClick={BackToHome}>
                      Back To Home
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div id="Changecolorpreferences" className="top_header_pm">
            <div className="testride_map Confirmation graphics_sec Changecolorpreferences_page">
              <div className="map_left Confirmation_left">
                <figure>
                  <img  src={confirm_bike} alt="imhvgb" />
                </figure>

                <div className="after_savings">
                  <figure>
                    <img  src={image} alt="imhvgb" />
                  </figure>
                </div>
              </div>

              <div className="testride_righsec graphics_rigth Changecolorpreferences_right">
                <div className="logo_right_cont destop_show">
                  <img  src={logo_right} alt="imhvgb" />
                </div>
                <h3>Update color preferences</h3>

                <div className="mobile_show das_orderimg">
                  <img  src={image} alt="imhvgb" />
                </div>

                <div className="graphics_colors color_step Changecolor_sec">
                  {showOnlyBlack === true ? (
                    <form style={{ justifyContent: "center" }}>

                      <div className="form-check">
                        <input
                          className={`form-check-input ${blackActive}`}
                          onClick={() => handActiveClass("Midnight Black")}
                          type="radio"
                          name="color_btn"
                          id="Black"
                        />
                        <label className="form-check-label" for="Black">
                          {myBooking?.color}
                        </label>
                      </div>
                    </form>
                  ) : showOnlyBlack === false ? (
                    <form>
                      <div className="form-check">
                        <input
                          className={`form-check-input ${blackActive}`}
                          onClick={() => handActiveClass("True Black")}
                          type="radio"
                          name="color_btn"
                          id="Black"
                        />
                        <label className="form-check-label" for="Black">
                          True Black
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className={`form-check-input ${greyActive}`}
                          onClick={() => handActiveClass("Twilight Grey")}
                          type="radio"
                          name="color_btn"
                          id="Grey"
                        />
                        <label className="form-check-label" for="Black">
                          Twilight Grey
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className={`form-check-input ${yellowActive}`}
                          onClick={() => handActiveClass("Electric Yellow")}
                          type="radio"
                          name="color_btn"
                          id="Yellow"
                        />
                        <label className="form-check-label" for="Yellow">
                          Electric Yellow
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className={`form-check-input ${blueActive}`}
                          onClick={() => handActiveClass("Magnetic Blue")}
                          type="radio"
                          name="color_btn"
                          id="Blue"
                        />
                        <label className="form-check-label" for="Blue">
                          Magnetic Blue
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className={`form-check-input ${redActive}`}
                          onClick={() => handActiveClass("Candy Red")}
                          type="radio"
                          name="color_btn"
                          id="Red"
                        />
                        <label className="form-check-label" for="Red">
                          Candy Red
                        </label>
                      </div>
                    </form>
                  ) : null}
                </div>

                <div className="colorsele_btn">
                  <div className="orderid_btn">
                    <button class="default_btn tran_btn" onClick={ModifyColor}>
                      Confirm selection
                    </button>
                    <button
                      class="default_btn sel_Back_home "
                      onClick={BackToHome}
                    >
                      Back To Home
                    </button>
                  </div>
                </div>

                {loader === false ? "" : <BeatLoader color="#36d7b7" />}
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};
export default Registrationaddress;
