import React from "react";
import DetailFooter from "./DetailFooter";
import ContactFooter from "./ContactFooter";
import { Link } from "react-router-dom";

function FooterLanding() {
  return (
    <>
      <div id="footer" className="landing_footer">
        <div>
          <div className="faq_sec" id="faq">
            <div className="landing_page_container">
              <div className="sec_heading">
                <h2 className="mb-2">
                  <Link href="/faq">FAQs</Link>
                </h2>
                <h2 className="mb-2">
                  {/* <a href={process.env.REACT_APP_WEBSITE_URL + "/community"} > */}
                  {/* <Link> */}
                  Media
                  {/* </Link> */}
                </h2>
                <h2 className="mb-2">
                  {/* <a
                    href={
                      process.env.REACT_APP_WEBSITE_URL + "/community/blogs"
                    }
                  > */}
                  {/* <Link> */}
                    Blog
                  {/* </Link> */}
                </h2>
              </div>
            </div>
          </div>
          <DetailFooter />
          <ContactFooter />
        </div>
      </div>
    </>
  );
}
export default FooterLanding;
