import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import $ from "jquery";
import "../Testride/test-ride.css";
import "../Order/order.css";
import "../dashboard/dashboard.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import Dashor_bike from "../../assets/images/Dashorder_bike.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";

import { Config } from "../../utils/apiUrl";
import { useScrollTo } from "../../hooks/useScrollTo";
import { getCookie } from "../../utils";

const Colorpreferences = () => {
  useScrollTo(0, 0);
  let { id } = useParams();
  const [myBooking, setMyBooking] = useState();

  const getBookingData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const responseBook = await fetch(Config.mybookings, requestOptions);
      if (responseBook) {
        const jsonBook = await responseBook.json();
        let filtered_booking = jsonBook.bookings.filter(
          (item) => item.ticket_id == id
        );
        setMyBooking(filtered_booking[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.getElementById("height_one_home_confirmation").addEventListener("scroll", (e) => {
      var yAxis = $(".dashboard_orde").offset().top;
      if (yAxis < 0) {
        $(".header").hide();
      } else {
        $(".header").show();
      }
    });
    getBookingData();
  }, []);

  return (
    <>
      <Header />
      <div className="height_one_csrol" id="height_one_home_confirmation">
        <div id="Colorpreferences" className="top_header_pm">
          <div className="testride_map Confirmation graphics_sec dashboard_orde Colorpreferences_page">
            <div className="map_left Confirmation_left dashboard_leftsec">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure  className="gra_logo">
                  <img src={popuplogomo} alt="imhvgb" />
                </figure>

                <figure>
                  <img  src={Dashor_bike} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec dashboard_rightsec Colorpreferences_right">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
              </div>

              <h3>Update color preferences</h3>

              <div className="mobile_show das_orderimg">
                <img  src={Dashor_bike} alt="imhvgb" />
              </div>

              <div className="order_iddetails mobile_show">
                <h4>
                  <span>Order ID:</span> {myBooking?.order_id}
                </h4>
              </div>

              <div className="color_preferences">
                <ul className="steps_colorsec">
                  <li className="compelled">Vehicle Reserved</li>
                  <li className="process">Balance amount</li>
                  <li>Upload Documents</li>
                  <li>Getting your Vehicle Ready</li>
                  <li>Vehicle registration</li>
                  <li>Out for delivery</li>
                </ul>

                <ul className="steps_colorsec next_pageview">
                  <li>
                    <Link to={"/Registrationaddress/" + id}>
                      Confirm registration address
                    </Link>
                  </li>
                  <li>
                    <Link to="/viewreceipts">View receipts</Link>
                  </li>
                  <li>
                    <Link to={"/Registrationaddress/" + id}>Modify Color</Link>
                  </li>
                  <li>
                    <Link to="/">Get support</Link>
                  </li>
                  <li>
                    <Link to={`/Cancelbooking/` + id}>Cancel order</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Colorpreferences;
